import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ApplicationLinkContainer from '../shared/applicationLink/applicationLinkContainer';
import {
    ABOUT_THE_BBC_URL,
    ACCESSIBILITY_URL,
    PARENTAL_GUIDANCE_URL,
    CONTACT_THE_BBC_URL,
    PAN_BBC_NEWSLETTER_URL,
} from '../../../shared/endpoints/externalUrls';
import {
    TERMS_AND_CONDITIONS_URL,
    PRIVACY_POLICY_URL,
    COOKIES_URL,
} from '../../../shared/endpoints/usingTheBbc';

const footerItems = [
    {
        href: TERMS_AND_CONDITIONS_URL,
        id: 'common.link.footer.termsOfUse.value',
    },
    {
        href: ABOUT_THE_BBC_URL,
        id: 'common.link.footer.aboutBBC.value',
    },
    {
        href: PRIVACY_POLICY_URL,
        id: 'common.link.footer.privacyPolicy.value',
    },
    {
        href: COOKIES_URL,
        id: 'common.link.footer.cookieHelp.value',
    },
    {
        href: ACCESSIBILITY_URL,
        id: 'common.link.footer.accessibilityHelp.value',
    },
    {
        href: PARENTAL_GUIDANCE_URL,
        id: 'common.link.footer.parentalGuidance.value',
    },
    {
        href: CONTACT_THE_BBC_URL,
        id: 'common.link.footer.contactBBC.value',
    },
    {
        href: PAN_BBC_NEWSLETTER_URL,
        id: 'link.footer.newsletter.value',
    },
];

const BbcFooterLinks = ({ target }) => (
    <ul>
        {footerItems.map(item => (
            <li key={item.id}>
                <ApplicationLinkContainer
                    href={item.href}
                    target={target}
                    external
                    tertiary
                >
                    <FormattedMessage id={item.id} />
                </ApplicationLinkContainer>
            </li>
        ))}
    </ul>
);

BbcFooterLinks.displayName = 'BbcFooterLinks';

BbcFooterLinks.propTypes = {
    target: PropTypes.string,
};

export default BbcFooterLinks;

import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, Heading } from '@bbc-account/id-components';
import CassoText from '../shared/cassoText';
import Message from '../shared/message';
import InternationalImage from './internationalImage';
import MarketingPreferenceExplainer from './marketingPreferenceExplainer';
import Track from '../shared/analytics/track';
import MarketingPreferenceOption from './marketingPreferenceOption';
import { BBCX_MARKETING_PREFERENCE_URL } from '../../../shared/endpoints/externalUrls';
import { useStateContext } from '../../modules/stateContext';
import Layout from '../pageLayout/layout';
import { REGISTER_FEDERATED_THANKS_URL } from '../../../shared/endpoints/account';
import Form from '../shared/form';

const submitMetricMap = {
    on: 'marketing-opt-in',
    off: 'marketing-opt-out',
};

export const FormDetails = ({
    preference,
    setPreference,
    showValidationMessage,
    showEmailVerificationReminder,
}) => (
    <>
        <Heading level={2} fontScale="sectionHeading" fontWeight="regular">
            <FormattedMessage id="common.newsletterOptIn.international.body" />
        </Heading>

        <InternationalImage />

        <fieldset className="field buttons buttons--radio buttons--inline">
            <div className="buttons__wrapper">
                <MarketingPreferenceOption
                    ariaLabel="Send me personalised email newsletters"
                    checked={preference === 'on'}
                    fieldName="marketingOptIn"
                    id="optIn"
                    label={
                        <FormattedMessage id="common.newsletterOptIn.button.international.accept" />
                    }
                    onChange={setPreference}
                    submitValue="on"
                />

                <MarketingPreferenceOption
                    ariaLabel="Do not send me personalised email newsletters"
                    checked={preference === 'off'}
                    fieldName="marketingOptIn"
                    id="optOut"
                    label={
                        <FormattedMessage id="common.newsletterOptIn.button.decline" />
                    }
                    onChange={setPreference}
                    submitValue="off"
                    text="No, thanks"
                />
            </div>

            {showValidationMessage && (
                <Track container="register" name="marketingOptIn-error">
                    <div
                        id="form-message-marketingOptIn"
                        aria-live="assertive"
                        className="form-message form-message--error form-message--marketingOptIn field__error"
                    >
                        <p className="form-message__text">
                            <FormattedMessage id="validation.missing" />
                            <span>. </span>
                            <FormattedMessage id="validation.tryAgain" />
                        </p>
                    </div>
                </Track>
            )}

            <MarketingPreferenceExplainer />
        </fieldset>

        {showEmailVerificationReminder && (
            <Track name="info-message">
                <Message
                    name="info"
                    className="form-message--info-icon form-message--with-icon"
                >
                    <FormattedMessage id="emailVerification.checkInboxMvt" />
                </Message>
            </Track>
        )}

        <CassoText additionalClasses="u-margin-top-quad" />
    </>
);

FormDetails.propTypes = {
    preference: PropTypes.string,
    setPreference: PropTypes.func,
    showValidationMessage: PropTypes.bool,
    showEmailVerificationReminder: PropTypes.bool,
};

function InternationalMarketingPreferenceForm({
    showEmailVerificationReminder,
}) {
    const [preference, setPreference] = useState();
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    const { isFederated } = useStateContext();

    const marketingPreferenceUrl = BBCX_MARKETING_PREFERENCE_URL;

    const action = isFederated
        ? REGISTER_FEDERATED_THANKS_URL
        : marketingPreferenceUrl;

    const submitMetric =
        submitMetricMap[preference] || 'marketing-none-selected-error';

    return (
        <Layout
            heading={
                <FormattedMessage id="common.newsletterOptIn.international.title" />
            }
        >
            <Form
                action={action}
                autoComplete="off"
                method="post"
                data-testid="form"
            >
                <FormDetails
                    preference={preference}
                    setPreference={setPreference}
                    showValidationMessage={showValidationMessage}
                    showEmailVerificationReminder={
                        showEmailVerificationReminder
                    }
                />
                <Track ignoreViews name={submitMetric}>
                    <Button
                        id="submit-button"
                        isFullWidth
                        isSubmit
                        onClick={event => {
                            if (!preference) {
                                event.preventDefault();
                                setShowValidationMessage(true);
                            }
                        }}
                    >
                        <FormattedMessage id="button.continue.value" />
                    </Button>
                </Track>
            </Form>
        </Layout>
    );
}

InternationalMarketingPreferenceForm.displayName =
    'InternationalMarketingPreferenceForm';

InternationalMarketingPreferenceForm.propTypes = {
    showEmailVerificationReminder: PropTypes.bool,
};

export default InternationalMarketingPreferenceForm;

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { email as emailValidator } from '../../../../shared/validation/validators';
import Explainer from '../explainer';
import TextInput from '../textInput';

const validator = guardianEmail => emailValidator.validate([guardianEmail]);

const GuardianEmailField = props => (
    <Field
        id="guardianEmail-input"
        name="guardianEmail"
        autoComplete="email"
        required
        type="email"
        label={<FormattedMessage id="label.guardianEmail" />}
        component={TextInput}
        validate={validator}
        {...props}
    >
        <Explainer
            explainers={props.guardianEmailExplainers}
            name="guardianEmail"
        />
    </Field>
);

GuardianEmailField.displayName = 'GuardianEmailField';

GuardianEmailField.propTypes = {
    guardianEmailExplainers: PropTypes.node,
};

GuardianEmailField.defaultProps = {
    guardianEmailExplainers: [
        <FormattedMessage id="policyUplift.message.guardian.needPermission" />,
        <FormattedMessage id="policyUplift.message.guardian.enterEmail" />,
    ],
};

export default GuardianEmailField;

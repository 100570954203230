import { css, styled } from 'styled-components';
import { LinksOrientation } from './linksOrientation';

const StyledVerticalLinks = css`
    flex-direction: column;
`;

export const StyledLinks = styled.div`
    display: flex;
    gap: var(--spacing-4);

    ${({ $orientation }) =>
        $orientation === LinksOrientation.VERTICAL && StyledVerticalLinks}
`;

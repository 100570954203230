import { styled } from 'styled-components';

import { GROUP_3 } from '../../gel/breakpoints/breakpoints';
import { createSize } from '../../gel/tools/sizes';

const BUTTON = {
    WIDTH_XS: 99,
    WIDTH: 144,
    PADDING: 32,
    HEIGHT: 50,
};

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--spacing-3);
`;

Container.displayName = 'Container';

export const Fieldset = styled.fieldset`
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);

    @media (min-width: ${GROUP_3}) {
        flex-direction: row;
        gap: var(--spacing-4);
    }

    &:has(.sb-button-inline#sb-text-input-minimal-toggle-password-view) {
        margin-bottom: var(--spacing-3);
    }

    & .sb-field-minimal {
        margin: auto 0 0;
        flex: 1;
    }

    & .sb-button {
        padding: 0 var(--spacing-4);
        height: ${BUTTON.HEIGHT}px;
        margin-top: auto;
        min-width: ${createSize(BUTTON.WIDTH_XS + BUTTON.PADDING)};
        font-size: ${createSize(15)};
        font-weight: 700;
        line-height: normal;

        &:hover,
        &:focus {
            z-index: 2;
        }

        @media (min-width: ${GROUP_3}) {
            min-width: ${createSize(BUTTON.WIDTH + BUTTON.PADDING)};
        }
    }
`;

Fieldset.displayName = 'Fieldset';

import { mapKeys, omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
    ATTRIBUTES_MAP,
    DETAILS_MAP,
    DEFAULT_SOURCE,
} from '../../shared/data/analytics';

const eventAttributesShape = PropTypes.shape({
    createEventAttributes: PropTypes.func.isRequired,
    createEventHandler: PropTypes.func.isRequired,
});

const getDisplayName = WrappedComponent =>
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

const getContainer = pathname => pathname.split('/')[1];

const mapEventData = (data, map) =>
    mapKeys(data, (value, propertyKey) => map.get(propertyKey) || propertyKey);

const omitProps = props =>
    omit(props, ['dispatch', 'history', 'match', 'staticContext']);

function dispatchEvent(details) {
    document.dispatchEvent(
        new CustomEvent('bbc-user-click', {
            detail: mapEventData(details, DETAILS_MAP),
        })
    );
}

const withEventTracking = Component => {
    const WithEventTracking = ({
        location,
        eventData: eventDataFromProps,
        ...restProps
    }) => {
        const defaultData = {
            container: getContainer(location.pathname),
            source: DEFAULT_SOURCE,
            ...eventDataFromProps,
        };

        const createEventHandler = eventData => () => {
            const details = { ...defaultData, ...eventData };

            dispatchEvent(details);
        };

        const createEventAttributes = eventData =>
            mapEventData({ ...defaultData, ...eventData }, ATTRIBUTES_MAP);

        return (
            <Component
                {...omitProps(restProps)}
                createEventHandler={createEventHandler}
                createEventAttributes={createEventAttributes}
            />
        );
    };

    WithEventTracking.displayName = `WithEventTracking${getDisplayName(
        Component
    )}`;

    WithEventTracking.propTypes = {
        location: ReactRouterPropTypes.location.isRequired,
        eventData: PropTypes.shape({
            container: PropTypes.string,
            name: PropTypes.string,
            result: PropTypes.string,
            type: PropTypes.string,
            source: PropTypes.string,
        }),
    };

    return withRouter(WithEventTracking);
};

export { dispatchEvent, eventAttributesShape };

export default withEventTracking;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from './link';
import { LinksOrientation } from './linksOrientation';
import { StyledLinks } from './links.style';

export function Links({
    className,
    'data-testid': dataTestId,
    items,
    linkAs,
    orientation,
}) {
    return (
        <StyledLinks
            className={className}
            data-testid={dataTestId}
            $orientation={orientation}
        >
            {items.map((props, index) => (
                <Link {...props} as={linkAs} key={index} />
            ))}
        </StyledLinks>
    );
}

Links.propTypes = {
    className: PropTypes.string,
    'data-testid': PropTypes.string,
    items: PropTypes.array,
    linkAs: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
    orientation: PropTypes.oneOf(Object.values(LinksOrientation)),
};

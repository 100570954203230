import ReactRouterPropTypes from 'react-router-prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import RadioButton from '../radioButton';
import RadioButtons from '../radioButtons';
import { errorShape } from '../../../propTypes';
import Explainer from '../explainer';
import FormError from '../formError';
import FormattedMessageWithAttributes from '../formattedMessageWithAttributes';
import * as config from '../../../../server/config';
import { HELP_NEWSLETTER_URL } from '../../../../shared/endpoints/usingTheBbc';
import { HELP_FEDERATED_WHAT_EMAILS_AND_NEWSLETTERS } from '../../../../shared/endpoints/account';
import { buildHrefFromFederatedStatus } from '../../../../shared/utilities/url';
import { useStateContext } from '../../../modules/stateContext';

const IMAGE_CHEF_RECIPE = '448xn';

const getMarketingImageUrl = () =>
    `${config.get('imageChefUrl')}/${IMAGE_CHEF_RECIPE}/${config.get(
        'imageFileNameMarketingOptIn'
    )}`;

const MarketingOptInField = ({ error, location: routerLocation }) => {
    const { isFederated } = useStateContext();
    const helpNewsletterAttributes = {
        href: buildHrefFromFederatedStatus({
            isFederated,
            federatedUrl: HELP_FEDERATED_WHAT_EMAILS_AND_NEWSLETTERS,
            nonFederatedUrl: HELP_NEWSLETTER_URL,
            routerLocation,
        }),
        external: true,
    };

    return (
        <RadioButtons className="buttons--inline" id="marketingOptIn">
            <img
                src={getMarketingImageUrl()}
                alt="Never miss out on BBC highlights - sign up to our emails"
                width="100%"
                className="u-margin-bottom-double"
            />
            <div className="buttons__wrapper">
                <Field
                    id="optIn"
                    name="marketingOptIn"
                    component={RadioButton}
                    label={
                        <FormattedMessage id="common.newsletterOptIn.button.accept" />
                    }
                    aria-label="Send me personalised email newsletters"
                    required
                    value="on"
                    type="radio"
                />
                <Field
                    id="optOut"
                    name="marketingOptIn"
                    component={RadioButton}
                    label={
                        <FormattedMessage id="common.newsletterOptIn.button.decline" />
                    }
                    aria-label="Do not send me personalised email newsletters"
                    required
                    value="off"
                    type="radio"
                />
            </div>

            <input type="hidden" name="requiresMarketingOptIn" value="true" />

            <FormError
                name="marketingOptIn"
                className="field__error"
                error={error}
                isAssertive
            />

            <Explainer
                cta={<FormattedMessage id="register.link.newsletters" />}
                explainers={
                    <FormattedMessageWithAttributes
                        id="register.explainer.newsletters"
                        attributes={helpNewsletterAttributes}
                    />
                }
                name="marketingOptIn"
            />
        </RadioButtons>
    );
};

MarketingOptInField.displayName = 'MarketingOptIn';

MarketingOptInField.propTypes = {
    error: errorShape,
    location: ReactRouterPropTypes.location.isRequired,
};

export default MarketingOptInField;

import React from 'react';

export const BackButtonIcon = () => (
    <svg
        width="12"
        height="18"
        viewBox="0 0 12 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.85 8.04375L11.9062 17.4375H8.30625L0.09375 9L8.30625 0.5625H11.9062L2.85 9.95625V8.04375Z"
            fill="currentColor"
        />
    </svg>
);

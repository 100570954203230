import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CategoryIcon from '../../gel/iconography/icons';

import './feedback.scss';

const OptionalIcon = ({ icon, children }) => {
    if (!icon) return children;

    return (
        <span className="sb-feedback__icon-wrapper">
            <CategoryIcon
                className="sb-icon sb-icon--fix-min-size sb-icon--left"
                name={icon}
            />
            {children}
        </span>
    );
};

const Feedback = ({
    children,
    variant,
    icon,
    hasBorderInsteadOfBackground,
}) => {
    const classes = classnames('sb-feedback', `sb-feedback--${variant}`, {
        'sb-feedback--bordered': hasBorderInsteadOfBackground,
    });

    return (
        <div className={classes}>
            <OptionalIcon icon={icon}>{children}</OptionalIcon>
        </div>
    );
};

OptionalIcon.propTypes = {
    icon: PropTypes.string,
    children: PropTypes.node.isRequired,
};

Feedback.propTypes = {
    /**
     The content of the feedback component
    */
    children: PropTypes.node.isRequired,
    /**
     This sets the background colour of the feedback component.
     */
    variant: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
    /**
     Determines what icon is shown on the left side of the feedback component. If not passed then no icon will be shown.
     */
    icon: PropTypes.oneOf(['signed-in', 'sign-in', 'confirm']),
    /**
     This sets a solid border to the feedback component instead of using a filled background
     */
    hasBorderInsteadOfBackground: PropTypes.bool,
};

Feedback.defaultProps = {
    variant: 'error',
    hasBorderInsteadOfBackground: false,
};

Feedback.displayName = 'Feedback';

export { Feedback };

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CallToActionButton, FlexContainer } from '@bbc-account/id-components';
import Text from '../../shared/text';
import Layout from '../../pageLayout/layout';
import {
    AUTH_MAGIC_LINK_VALIDATE_CANCEL_PATH,
    AUTH_MAGIC_LINK_VALIDATE_PATH,
} from '../../../../shared/endpoints/account';
import { useStateContext } from '../../../modules/stateContext';
import renderQueryString from '../../../../shared/urls/renderQueryString';
import { useLocationSearchParam } from '../../../hooks/router';
import Track from '../../shared/analytics/track';

const MagicLinkValidateApprove = () => {
    const userContext = useStateContext();
    const {
        translations: { countries },
        magicLink: { country, city },
        featureToggles: { magicLinkSequenceId },
    } = userContext;
    const token = useLocationSearchParam('token');
    const traceId = useLocationSearchParam('traceId');

    const confirmationHref = magicLinkSequenceId
        ? `${AUTH_MAGIC_LINK_VALIDATE_PATH}${renderQueryString.call(
              userContext,
              { skipIpCheck: 'true', token, sequenceId: traceId }
          )}`
        : `${AUTH_MAGIC_LINK_VALIDATE_PATH}${renderQueryString.call(
              userContext,
              { skipIpCheck: 'true', token }
          )}`;

    const cancelHref = magicLinkSequenceId
        ? `${AUTH_MAGIC_LINK_VALIDATE_CANCEL_PATH}${renderQueryString.call(
              userContext,
              { sequenceId: traceId }
          )}`
        : `${AUTH_MAGIC_LINK_VALIDATE_CANCEL_PATH}${renderQueryString.call(
              userContext
          )}`;

    const shouldDisplayLocation = country && city && city !== '?';
    const displayCity = city?.charAt(0).toUpperCase() + city?.slice(1);

    return (
        <Layout
            pageId="signin-page"
            heading={
                <FormattedMessage id="auth.magicLink.validationApprove.title" />
            }
            showServiceIdentifiers
        >
            {shouldDisplayLocation && (
                <>
                    <Text heading>
                        <FormattedMessage id="auth.magicLink.validationApprove.intro" />
                    </Text>
                    <Text className="u-margin-bottom-none" heading>
                        <FormattedMessage id="auth.magicLink.validationApprove.locationTitle" />
                    </Text>
                    <Text>
                        {displayCity}, {countries[country]}
                    </Text>
                </>
            )}
            <FlexContainer>
                <Track
                    container="magic-link"
                    ignoreViews
                    name="validate-approve"
                >
                    <CallToActionButton
                        href={confirmationHref}
                        hideUnderlineOnHover
                    >
                        <FormattedMessage id="auth.magicLink.validationApprove.yes" />
                    </CallToActionButton>
                </Track>
                <Track
                    container="magic-link"
                    ignoreViews
                    name="validate-cancel"
                >
                    <CallToActionButton href={cancelHref} hideUnderlineOnHover>
                        <FormattedMessage id="auth.magicLink.validationApprove.cancel" />
                    </CallToActionButton>
                </Track>
            </FlexContainer>
        </Layout>
    );
};

export default MagicLinkValidateApprove;

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, Fields } from 'redux-form';
import { Button } from '@bbc-account/id-components';
import FormError from '../formError';
import DateOfBirthFields, {
    DAY,
    MONTH,
    YEAR,
    DOB_FIELDS,
} from '../inputFields/dateOfBirthFields';
import Form from '../form';
import { errorShape } from '../../../propTypes';

const DateOfBirthSelect = ({
    action,
    error,
    explainer,
    explainerHelpUrl,
    initialErrors = {},
    title,
    withAttempts = false,
}) => {
    return (
        <Fragment>
            <FormError
                name="general"
                error={initialErrors.general}
                isAssertive
            />
            <Form method="post" action={action}>
                <Fields
                    component={DateOfBirthFields}
                    error={error}
                    explainer={explainer}
                    explainerHelpUrl={explainerHelpUrl}
                    initialErrors={initialErrors}
                    names={DOB_FIELDS}
                    title={title}
                />

                {withAttempts && (
                    <Field component="input" name="attempts" type="hidden" />
                )}

                <Button isFullWidth isSubmit id="submit-button">
                    <FormattedMessage id="button.continue.value" />
                </Button>
            </Form>
        </Fragment>
    );
};

DateOfBirthSelect.displayName = 'DateOfBirthSelect';

DateOfBirthSelect.propTypes = {
    action: PropTypes.string,
    error: errorShape,
    explainer: PropTypes.string,
    explainerHelpUrl: PropTypes.string.isRequired,
    initialValues: PropTypes.shape({
        [DAY]: PropTypes.string,
        [MONTH]: PropTypes.string,
        [YEAR]: PropTypes.string,
    }),
    initialErrors: PropTypes.shape({
        dateOfBirth: errorShape,
    }),
    title: PropTypes.string,
    withAttempts: PropTypes.bool,
};

export default DateOfBirthSelect;

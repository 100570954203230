import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setJsEnabled } from '../modules/jsEnabled';

function jsDetection(ComposedComponent) {
    class JsDetection extends React.Component {
        componentDidMount() {
            this.props.dispatch(setJsEnabled());
        }

        render() {
            return <ComposedComponent {...this.props} {...this.state} />;
        }
    }

    JsDetection.displayName = 'JsDetection';

    JsDetection.propTypes = {
        dispatch: PropTypes.func.isRequired,
    };

    return JsDetection;
}

const connectedHoC = connect();

export { jsDetection };
export default composedComponent =>
    connectedHoC(jsDetection(composedComponent));

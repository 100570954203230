import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { propTypes as reduxFormPropTypes } from 'redux-form';
import { Button } from '@bbc-account/id-components';
import { errorShape } from '../../../propTypes';
import Form from '../../shared/form';
import FormError from '../../shared/formError';
import Explainer from '../../shared/explainer';
import GuardianEmailField from '../../shared/inputFields/guardianEmailField';
import { UsernameField } from '../../shared/inputFields/usernameField';

const RequestChildPermissionsManagement = props => {
    const { initialErrors = {} } = props;

    const formError =
        initialErrors.general !== true ? initialErrors.general : undefined;

    return (
        <Fragment>
            <FormError name="general" error={formError} isAssertive />
            <Form action="/permissions/children/request" method="post">
                <UsernameField
                    usernameLabel={
                        <FormattedMessage id="label.childUsername" />
                    }
                    initialError={initialErrors.username}
                >
                    <Explainer
                        cta={
                            <FormattedMessage id="common.explainer.link.childUsername" />
                        }
                        explainers={
                            <FormattedMessage id="explainer.childUsername" />
                        }
                        name="username"
                    />
                </UsernameField>

                <GuardianEmailField
                    guardianEmailExplainers={
                        <FormattedMessage id="explainer.guardianEmail" />
                    }
                    initialError={initialErrors.guardianEmail}
                />

                <Button isFullWidth isSubmit id="submit-button">
                    <FormattedMessage id="button.sendEmail.value" />
                </Button>
            </Form>
        </Fragment>
    );
};

RequestChildPermissionsManagement.displayName =
    'RequestChildPermissionsManagement';

RequestChildPermissionsManagement.propTypes = {
    ...reduxFormPropTypes,
    initialErrors: PropTypes.shape({
        guardianEmail: errorShape,
        username: errorShape,
    }),
};

export default RequestChildPermissionsManagement;

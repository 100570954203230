import React from 'react';
import { FormattedMessage } from 'react-intl';
import ContinueButton from '../../shared/continueButton';
import DefaultLayout from '../../shared/defaultLayout';
import Text from '../../shared/text';

const ManageChildPermissionsThanks = () => (
    <DefaultLayout heading="complete.title">
        <Text introduction>
            <FormattedMessage id="permissions.informChild" />
        </Text>
        <ContinueButton translationId="button.ok.value" />
    </DefaultLayout>
);

ManageChildPermissionsThanks.displayName = 'ManageChildPermissionsThanks';

export default ManageChildPermissionsThanks;

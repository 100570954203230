import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

const RadioButtons = ({ className, children, id, legend }) => {
    const classes = classnames('field buttons buttons--radio', className);

    return (
        <fieldset id={id} className={classes}>
            {legend ? (
                <legend className="buttons__legend">{legend}</legend>
            ) : null}
            {children}
        </fieldset>
    );
};

RadioButtons.displayName = 'RadioButtons';

RadioButtons.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    id: PropTypes.string,
    legend: PropTypes.node,
};

export default RadioButtons;

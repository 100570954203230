import styled from 'styled-components';

export const StyledEmailFirstTextFormInputWrapper = styled.div`
    input {
        text-transform: uppercase;
    }

    /* WebKit browsers */
    ::-webkit-input-placeholder,
    /* Mozilla Firefox 4 to 18 */
    :-moz-placeholder,
    /* Mozilla Firefox 19+ */
    ::-moz-placeholder,
    /* Internet Explorer 10+ */
    :-ms-input-placeholder,
    /* Recent browsers */
    ::placeholder {
        text-transform: none;
    }
`;

import { useFieldState, useFieldValidation } from '@bbc-account/id-formaxe';
import { useCallback, useEffect, useMemo, useState } from 'react';

function execOtherEventHandlers({ event, fieldEvents, eventName, hookEvents }) {
    const eventMethodName = `on${eventName.slice(0, 1).toUpperCase() +
        eventName.slice(1)}`;

    if (typeof fieldEvents === 'object') {
        if (typeof fieldEvents[eventMethodName] === 'function') {
            fieldEvents[eventMethodName](event);
        }
    }

    if (typeof hookEvents[eventMethodName] !== 'undefined') {
        hookEvents[eventMethodName](event);
    }
}

const defaultValidator = () => true;

function hasValue(value) {
    return value?.length > 0;
}

function hasEventValue(event) {
    return hasValue(event.target.value);
}

export function useEmailFirstInputFieldEvents({
    id,
    onBlur,
    onChange,
    validator,
}) {
    const [isValid, setIsValid] = useState(false);

    const [touched, setTouched] = useState(false);

    const [shouldValidate, setShouldValidate] = useState(false);

    const { eventHandlers: fieldEventHandlers, value } = useFieldState(id);

    const { errors, isValid: asyncIsValid, validate } = useFieldValidation(
        id,
        validator?.validate || defaultValidator
    );

    const execOtherEventHandlersByName = useCallback(
        ({ event, eventName }) => {
            execOtherEventHandlers({
                event,
                eventName,
                fieldEvents: fieldEventHandlers,
                hookEvents: {
                    onBlur,
                    onChange,
                },
            });
        },
        [fieldEventHandlers, onBlur, onChange, touched]
    );

    const handleInputBlur = useCallback(
        event => {
            if (touched && hasEventValue(event)) {
                setShouldValidate(true);
            }

            execOtherEventHandlersByName({
                event,
                eventName: 'blur',
            });
        },
        [touched, execOtherEventHandlersByName]
    );

    const handleInputChange = useCallback(
        event => {
            setTouched(true);

            if (errors && hasEventValue(event)) {
                setShouldValidate(true);
            }

            execOtherEventHandlersByName({
                event,
                eventName: 'change',
            });
        },
        [errors, execOtherEventHandlersByName]
    );

    const eventHandlers = useMemo(
        () => ({
            ...fieldEventHandlers,
            onBlur: handleInputBlur,
            onChange: handleInputChange,
        }),
        [fieldEventHandlers, handleInputBlur, handleInputChange]
    );

    const runValidation = useCallback(async () => {
        if (shouldValidate) {
            await validate(value);

            setShouldValidate(false);
        }
    }, [value, shouldValidate, validate]);

    useEffect(() => {
        async function asyncEffect() {
            const newIsValid = await asyncIsValid(value, {
                disableSetter: true,
            });

            setIsValid(newIsValid);
        }

        if (hasValue(value)) {
            asyncEffect();
        } else {
            setIsValid(false);
        }
    }, [asyncIsValid, value]);

    useEffect(() => {
        runValidation();
    }, [runValidation]);

    return {
        errors,
        eventHandlers,
        isValid,
        validate,
    };
}

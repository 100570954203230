import React from 'react';

import './serviceIdentifiers.scss';

const ServiceIdentifiers = () => {
    return (
        <div className="sb-service-identifier-container">
            <div className="sb-service-identifier sb-service-identifier--iplayer" />
            <div className="sb-service-identifier sb-service-identifier--sounds" />
            <div className="sb-service-identifier sb-service-identifier--news" />
            <div className="sb-service-identifier sb-service-identifier--sport" />
            <div className="sb-service-identifier sb-service-identifier--weather" />
            <div className="sb-service-identifier sb-service-identifier--bitesize" />
        </div>
    );
};

ServiceIdentifiers.displayName = 'ServiceIdentifiers';

export { ServiceIdentifiers };

import psl from 'psl';

function getTld(urlToExtract) {
    const { hostname } = new URL(urlToExtract);
    return psl.parse(hostname).tld;
}

function setUrlTld(baseUrl, tld) {
    const baseTld = getTld(baseUrl);

    const newTld = tld === 'com' ? 'com' : 'co.uk';

    return baseUrl.replace(baseTld, newTld);
}

export { setUrlTld };

import React from 'react';
import { Button } from '@bbc-account/id-components';
import { FormattedMessage } from 'react-intl';
import DefaultLayout from '../shared/defaultLayout';
import Introduction from './introduction';
import Form from '../shared/form';

const MoreDetailsLinkedAccount = () => {
    return (
        <DefaultLayout heading="policyUplift.title.guardian.emailSent">
            <Form method="post">
                <Introduction message="policyUplift.message.guardian.awaitingPermission" />
                <Button isFullWidth isSubmit id="submit-button">
                    <FormattedMessage id="permissions.button.askPermission" />
                </Button>
            </Form>
        </DefaultLayout>
    );
};

MoreDetailsLinkedAccount.displayName = 'MoreDetailsLinkedAccount';

export default MoreDetailsLinkedAccount;

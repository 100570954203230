import PropTypes from 'prop-types';
import React from 'react';
import Layout from '../pageLayout/layout';
import { RegisterLayoutHeading } from './heading';

const RegisterChildLayout = ({ children }) => (
    <Layout
        heading={<RegisterLayoutHeading />}
        showLoadingSpinner
        showServiceIdentifiers
    >
        {children}
    </Layout>
);

RegisterChildLayout.displayName = 'RegisterChildLayout';

RegisterChildLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default RegisterChildLayout;

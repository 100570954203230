import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import formNames from '../../../../shared/data/formNames';

import RegisterGuardianProspect from './prospect';

const mapStateToProps = ({ location, registerGuardianProspect }) => ({
    initialErrors: registerGuardianProspect.initialErrors || {},
    initialValues: registerGuardianProspect.data,
    location,
});

export default connect(mapStateToProps)(
    reduxForm({
        form: formNames.REGISTER_GUARDIAN_PROSPECT,
        destroyOnUnmount: false,
    })(RegisterGuardianProspect)
);

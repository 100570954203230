import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './additionalInfo.scss';

const AdditionalInfo = ({ children, className, layout, isTextContent }) => (
    <div
        className={classNames(
            'sb-additional-info',
            {
                'sb-additional-info-space-between': layout === 'space-between',
                'sb-additional-info-full-width': layout === 'full-width',
                'sb-additional-info-text-content': isTextContent,
            },
            className
        )}
    >
        {children}
    </div>
);

AdditionalInfo.propTypes = {
    /**
     * nested content
     */
    children: PropTypes.node.isRequired,

    /**
     * custom class name
     */
    className: PropTypes.string,

    /**
     * optional, layout for nested content
     */
    layout: PropTypes.oneOf(['full-width', 'space-between']),

    /**
     * optional, is it a text content
     */
    isTextContent: PropTypes.bool,
};

AdditionalInfo.displayName = 'AdditionalInfo';

export { AdditionalInfo };

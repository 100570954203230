import { useStateContext } from '../modules/stateContext';
import { isExUk } from '../utilities/isExUk';

export function useShowExUkContent() {
    const { location } = useStateContext();

    const showExUkContent = isExUk(location);

    return showExUkContent;
}

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ApplicationLinkContainer from './applicationLink/applicationLinkContainer';
import { useStateContext } from '../../modules/stateContext';

const ContinueButton = props => {
    const { href, translationId, translationValues, ...linkProps } = props;

    const { ptrt } = useStateContext();

    const linkHref = href || ptrt.value;
    const withExternalHash = href ? undefined : false;

    return (
        <div className="u-margin-top-triple">
            <ApplicationLinkContainer
                button
                primary
                id="return-to-ptrt"
                external
                fullWidth
                withExternalHash={withExternalHash}
                {...linkProps}
                href={linkHref}
            >
                <FormattedMessage
                    id={translationId}
                    values={translationValues}
                />
            </ApplicationLinkContainer>
        </div>
    );
};

ContinueButton.displayName = 'ContinueButton';

ContinueButton.propTypes = {
    href: PropTypes.string,
    translationId: PropTypes.string,
    translationValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    className: PropTypes.string,
};

ContinueButton.defaultProps = {
    translationId: 'button.continue.value',
    className: 'u-margin-top-triple',
};

export default ContinueButton;

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, propTypes as reduxFormPropTypes } from 'redux-form';
import { Button, Heading } from '@bbc-account/id-components';
import { errorShape, locationShape } from '../../../propTypes';
import { email as emailValidator } from '../../../../shared/validation/validators';
import Explainer from '../../shared/explainer';
import GuardianEmailFieldNotifications from '../../shared/guardianEmailFieldNotifications';
import Form from '../../shared/form';
import FormError from '../../shared/formError';
import Text from '../../shared/text';
import TextInput from '../../shared/textInput';

const validator = email => emailValidator.validate([email]);

const RegisterGuardianProspect = props => {
    const {
        initialErrors = {},
        initialValues: { initialEmail },
    } = props;

    const formError =
        initialErrors.general !== true ? initialErrors.general : undefined;

    return (
        <>
            <div className="u-padding-bottom-double u-padding-top-quad">
                <Heading
                    level={2}
                    fontScale="sectionHeading"
                    fontWeight="regular"
                >
                    <FormattedMessage id="register.parentLed.title.getGuardian" />
                </Heading>
            </div>
            <Text introduction>
                <FormattedMessage id="register.parentLed.message.getGuardian" />
            </Text>
            <FormError name="general" error={formError} isAssertive />

            <Form method="post">
                <Field
                    additionalFieldClasses="u-margin-bottom-hex"
                    autoComplete="email"
                    component={TextInput}
                    id="email-input"
                    initialError={initialErrors.email}
                    label={<FormattedMessage id="label.guardianEmail" />}
                    name="email"
                    required
                    type="email"
                    validate={validator}
                >
                    <Explainer
                        cta={
                            <FormattedMessage id="common.explainer.link.purpose" />
                        }
                        explainers={
                            <FormattedMessage id="common.explainer.message.parentLedGuardianEmail" />
                        }
                        name="email"
                    />
                    <GuardianEmailFieldNotifications />
                </Field>

                <input name="initialEmail" type="hidden" value={initialEmail} />

                <Button isFullWidth isSubmit id="submit-button">
                    <FormattedMessage id="button.continue.value" />
                </Button>
            </Form>
        </>
    );
};

RegisterGuardianProspect.displayName = 'RegisterGuardianProspect';

RegisterGuardianProspect.propTypes = {
    ...reduxFormPropTypes,
    initialErrors: PropTypes.shape({
        email: errorShape,
        general: errorShape,
    }),
    location: locationShape.isRequired,
};

export default RegisterGuardianProspect;

import { Message } from '@bbc-account/id-components';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import Track from '../../../shared/analytics/track';
import Form from '../../../shared/form';
import { useForgottenPasswordPath } from '../../useForgottenPasswordPath';
import { useForgottenPasswordConfirmationContext } from '../context';
import { ForgottenPasswordConfirmationResendEmailFormButton } from './button';

export function ForgottenPasswordConfirmationResendEmailForm() {
    const {
        email,
        emailHasBeenResent,
        handleResendEmailFormSubmit,
    } = useForgottenPasswordConfirmationContext();

    const forgottenPasswordPath = useForgottenPasswordPath();

    const handleSubmitButtonClick = useCallback(() => {
        handleResendEmailFormSubmit?.({ email });
    }, [email, handleResendEmailFormSubmit]);

    return (
        <Form
            action={forgottenPasswordPath}
            data-testid="forgotten-password-confirmation-resend-email-form"
            method="post"
        >
            <input
                data-testid="forgotten-password-confirmation-resend-email-form-email-field"
                name="email"
                value={email || ''}
                type="hidden"
            />
            <Track ignoreViews name="resend forgotten password email">
                <ForgottenPasswordConfirmationResendEmailFormButton
                    onClick={handleSubmitButtonClick}
                />
                {emailHasBeenResent && (
                    <div className="sb-field">
                        <Message
                            isAssertive
                            isInfo
                            isFullWidth={false}
                            name="magic-link-email-sent"
                        >
                            <FormattedMessage id="passwordReset.message.emailResent" />
                        </Message>
                    </div>
                )}
            </Track>
        </Form>
    );
}

import React from 'react';
import PropTypes from 'prop-types';

import './bbcFooter.scss';

const BbcFooter = ({ links, copyright }) => (
    <footer className="sb-bbc-footer">
        <div className="sb-bbc-footer__links">{links}</div>
        <div className="sb-bbc-footer__copyright">{copyright}</div>
    </footer>
);

BbcFooter.propTypes = {
    /**
     A list of links (expectation is they are provided in a ul)
    */
    links: PropTypes.node.isRequired,
    /**
     The content of the copyright section in the footer
    */
    copyright: PropTypes.node.isRequired,
};

BbcFooter.displayName = 'BbcFooter';

export { BbcFooter };

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import CommentingPermission from './commentingPermission';
import NotificationPermission from './notificationPermission';
import PersonalisationPermission from './personalisationPermission';
import UploadingPermission from './uploadingPermission';
import CheckboxField from '../checkboxField';
import { PERMISSION_ALLOW_ALL_FIELD } from '../../../../../shared/data/permissionFieldConstants';

const PermissionCheckboxFields = props => {
    const { jsEnabled, onChange } = props;

    const renderPermissions = () => {
        return (
            <Fragment>
                <PersonalisationPermission {...props} />
                <CommentingPermission {...props} />
                <UploadingPermission {...props} />
                <NotificationPermission {...props} />
            </Fragment>
        );
    };

    return (
        <Fragment>
            {jsEnabled && (
                <CheckboxField
                    ariaLabel="childPermission.label.allowAll"
                    jsEnabled={jsEnabled}
                    label="childPermission.label.allowAll"
                    name={PERMISSION_ALLOW_ALL_FIELD}
                    onChange={onChange}
                />
            )}

            {renderPermissions()}
        </Fragment>
    );
};

PermissionCheckboxFields.displayName = 'PermissionCheckboxFields';

PermissionCheckboxFields.propTypes = {
    onChange: PropTypes.func.isRequired,
    jsEnabled: PropTypes.bool.isRequired,
};

export default PermissionCheckboxFields;

import * as config from '../../server/config';

function prependAccountBaseUrl(path) {
    return `${config.get('localBaseUrl')}${path}`;
}

function prependBbcBaseUrl(path) {
    return `${config.get('bbcBaseUrl')}${path}`;
}

function prependBbcXProxyBaseUrl(path) {
    return `${config.get('bbcXProxyBaseUrl')}${path}`;
}

function prependBackendGatewayBaseUrl(path) {
    return `${config.get('backendGatewayBaseUrl')}${path}`;
}

function prependIdctaBaseUrl(path) {
    return `${config.get('idctaBaseUrl')}${path}`;
}

function prependOpenAmBaseUrl(path) {
    return `${config.get('openAmBaseUrl')}${path}`;
}

function prependOpenIdmBaseUrl(path) {
    return `${config.get('openIdmBaseUrl')}${path}`;
}

function prependOpenIdm2BaseUrl(path) {
    return `${config.get('openIdm2BaseUrl')}${path}`;
}

function prependSessionBaseUrl(path) {
    return `${config.get('idSessionBaseUrl')}${path}`;
}

export {
    prependAccountBaseUrl,
    prependBbcBaseUrl,
    prependBbcXProxyBaseUrl,
    prependBackendGatewayBaseUrl,
    prependIdctaBaseUrl,
    prependOpenAmBaseUrl,
    prependOpenIdmBaseUrl,
    prependOpenIdm2BaseUrl,
    prependSessionBaseUrl,
};

import {
    CREATE_PASSWORD_PATH,
    FORGOTTEN_PASSWORD_FEDERATED_PATH,
    FORGOTTEN_PASSWORD_PATH,
} from '../../../shared/endpoints/account';
import { useStateContext } from '../../modules/stateContext';
import { isExUk } from '../../utilities/isExUk';

export function useForgottenPasswordPath() {
    const { isFederated, featureToggles, location } = useStateContext();

    if (!featureToggles?.createPasswordContent) {
        return isFederated
            ? FORGOTTEN_PASSWORD_FEDERATED_PATH
            : FORGOTTEN_PASSWORD_PATH;
    }

    if (isFederated) {
        return FORGOTTEN_PASSWORD_FEDERATED_PATH;
    }

    if (isExUk(location)) {
        return CREATE_PASSWORD_PATH;
    }

    return FORGOTTEN_PASSWORD_PATH;
}

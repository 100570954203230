import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../pageLayout/layout';
import Text from '../shared/text';
import FormattedMessageWithAttributes from '../shared/formattedMessageWithAttributes';
import { FORGOTTEN_PASSWORD_FEDERATED_PATH } from '../../../shared/endpoints/account';

const AccountLockedFederated = () => (
    <Layout heading={<FormattedMessage id="accountLocked.title" />}>
        <Text>
            <FormattedMessageWithAttributes
                id="accountLocked.message.resetPassword"
                attributes={{
                    href: FORGOTTEN_PASSWORD_FEDERATED_PATH,
                    external: true,
                    withState: true,
                    primary: true,
                }}
            />
        </Text>
    </Layout>
);

AccountLockedFederated.displayName = 'AccountLockedFederated';

export default AccountLockedFederated;

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, propTypes as reduxFormPropTypes } from 'redux-form';
import { Button } from '@bbc-account/id-components';
import { errorShape } from '../../propTypes';
import NeedHelpLink from './needHelpLink';
import EmailField from '../shared/inputFields/emailField';
import EmailOrUsernameField from '../shared/inputFields/emailOrUsernameField';
import PasswordSignInField from '../shared/inputFields/passwordSignInField';
import Form from '../shared/form';
import FormError from '../shared/formError';
import { useStateContext } from '../../modules/stateContext';
import Track from '../shared/analytics/track';

function SignIn(props) {
    const {
        additionalErrorSentence,
        emailInfo,
        form,
        formAction,
        isReauth,
        initialErrors = {},
        isNativeMobileApplication,
        jsEnabled,
        pristine,
        usernameAllowed,
    } = props;

    const { clientId } = useStateContext();

    useEffect(() => {
        return () => {
            props.clearPassword(props.form);
        };
    }, []);

    function getEmailOrUsernameField() {
        const userIdentifierProps = {
            additionalErrorSentence,
            initialError: initialErrors.userIdentifier,
            readOnly: isReauth,
            info: emailInfo,
            showValid: false,
        };

        return usernameAllowed ? (
            <EmailOrUsernameField {...userIdentifierProps} />
        ) : (
            <EmailField {...userIdentifierProps} />
        );
    }

    const formError =
        pristine && initialErrors.general !== true
            ? initialErrors.general
            : undefined;

    const removeInitialStateErrors = (...args) =>
        props.removeInitialStateErrors(form, ...args);

    return (
        <>
            <FormError error={formError} name="general" isAssertive />
            <Form action={formAction} method="post">
                {getEmailOrUsernameField()}

                <PasswordSignInField
                    removeInitialStateErrors={removeInitialStateErrors}
                    initialError={initialErrors.password}
                    jsEnabled={jsEnabled}
                    isNativeMobileApplication={isNativeMobileApplication}
                    showValid={false}
                />

                <Track metadata={{ clientId }}>
                    <Button isFullWidth isSubmit id="submit-button">
                        <FormattedMessage id="signIn.button.submit.value" />
                    </Button>
                </Track>
                <p className="button__label">
                    <NeedHelpLink />
                </p>

                <Field component="input" name="attempts" type="hidden" />
            </Form>
        </>
    );
}

SignIn.displayName = 'SignIn';

SignIn.propTypes = {
    ...reduxFormPropTypes,
    additionalErrorSentence: PropTypes.string,
    clearPassword: PropTypes.func.isRequired,
    emailInfo: PropTypes.node,
    formAction: PropTypes.string.isRequired,
    initialErrors: PropTypes.shape({
        username: errorShape,
        password: errorShape,
        general: errorShape,
    }).isRequired,
    isNativeMobileApplication: PropTypes.bool.isRequired,
    jsEnabled: PropTypes.bool.isRequired,
    isReauth: PropTypes.bool,
    removeInitialStateErrors: PropTypes.func.isRequired,
    usernameAllowed: PropTypes.bool,
};

SignIn.defaultProps = {
    isReauth: false,
};

export default SignIn;

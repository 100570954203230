import React from 'react';
import { useIntl } from 'react-intl';

import {
    PRIVACY_POLICY_UK_FULL_URL,
    PRIVACY_POLICY_INTERNATIONAL_URL,
} from '../../../../shared/endpoints/usingTheBbc';
import { isCrownDependency } from '../../../../shared/data/crownDependencies';
import { setUrlTld } from '../../../../shared/urls/tld';
import { useStateContext } from '../../../modules/stateContext';

export const POLICY_TITLE = 'help.pageTitle.privacy';

const HelpPrivacy = () => {
    const { location } = useStateContext();
    const intl = useIntl();

    const isInternational = !isCrownDependency(location.country);

    const policyUrl = isInternational
        ? PRIVACY_POLICY_INTERNATIONAL_URL
        : PRIVACY_POLICY_UK_FULL_URL;

    const policyTitleInt = intl.formatMessage({ id: POLICY_TITLE });

    return (
        <iframe
            title={policyTitleInt}
            src={`${setUrlTld(policyUrl, 'com')}?nolinks=yes`}
        />
    );
};

export default HelpPrivacy;

import styled from 'styled-components';
import { StyledEmailFirstTextFormInput } from '../text/emailFirstTextFormInput.style';

export const Fields = styled.div`
    display: flex;
    gap: 2rem;

    > * {
        width: 33.33%;
    }

    ${StyledEmailFirstTextFormInput} {
        &.field {
            margin-bottom: 0.5rem;
        }
    }
`;

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import RouteWithLayout from './helpers/routeWithLayout';

import RegisterAdult from '../components/register/adult/registerContainer';
import RegisterAgeSelect from '../components/register/registerAgeSelect';
import RegisterSorry from '../components/register/blocked/registerSorry';
import RegisterChildInvalid from '../components/register/child/invalid';
import RegisterChildInvalidGuardian from '../components/register/child/invalidGuardian';
import RegisterChildWizard from '../components/register/child/wizard';
import RegisterChildDateOfBirthSelect from '../components/register/child/dateOfBirthSelect';
import RegisterGuardianProspectContainer from '../components/register/guardian/prospectContainer';
import RegisterDateOfBirthSelect from '../components/register/registerDateOfBirthSelect';
import RegisterLayout from '../components/register/registerLayout';
import RegisterChildLayout from '../components/register/registerChildLayout';
import RegisterProfileLayout from '../components/register/profile/layout';
import RegisterThanks from '../components/register/adult/thanks';
import RegisterThanksProfile from '../components/register/profile/thanks';
import RegisterThanksChildrenContainer from '../components/register/child/thanksContainer';
import RegisterThanksChildrenLinked from '../components/register/child/thanksLinked';
import RegisterThanksGuardian from '../components/register/guardian/thanks';
import RegisterProfile from '../components/register/profile/register';
import RegisterOtsi from '../components/otsi/register';

function Register() {
    return (
        <Switch>
            <RouteWithLayout
                exact
                path="/register"
                layout={RegisterLayout}
                component={RegisterAgeSelect}
            />
            <RouteWithLayout
                exact
                path="/register/details/children"
                layout={RegisterChildLayout}
                component={RegisterChildWizard}
            />

            <RouteWithLayout
                exact
                path="/register/details/profile"
                layout={RegisterProfileLayout}
                component={RegisterProfile}
            />

            <Route
                path="/register/thanks/profile"
                component={RegisterThanksProfile}
            />

            <RouteWithLayout
                path="/register/details/children/age"
                layout={RegisterChildLayout}
                component={RegisterChildDateOfBirthSelect}
            />

            <RouteWithLayout
                path="/register/details/children/invalid"
                layout={RegisterChildLayout}
                component={RegisterChildInvalid}
            />

            <RouteWithLayout
                path="/register/details/children/invalid-guardian"
                layout={RegisterChildLayout}
                component={RegisterChildInvalidGuardian}
            />

            <RouteWithLayout
                path="/register/details/children/more-details"
                layout={RegisterChildLayout}
                component={RegisterChildWizard}
            />

            <RouteWithLayout
                path="/register/details/children/permissions"
                layout={RegisterChildLayout}
                component={RegisterChildWizard}
            />

            <RouteWithLayout
                path="/register/details/guardian"
                layout={RegisterChildLayout}
                component={RegisterGuardianProspectContainer}
            />

            <RouteWithLayout
                path="/register/thanks/guardian"
                layout={RegisterChildLayout}
                component={RegisterThanksGuardian}
            />

            <RouteWithLayout
                path="/register/thanks/children"
                layout={RegisterChildLayout}
                component={RegisterThanksChildrenContainer}
            />

            <RouteWithLayout
                path="/register/thanks/children/linked"
                layout={RegisterChildLayout}
                component={RegisterThanksChildrenLinked}
            />

            <Route path="/register/sorry" component={RegisterSorry} />

            <RouteWithLayout
                path="/register/details/age"
                layout={RegisterLayout}
                component={RegisterDateOfBirthSelect}
            />

            <RouteWithLayout
                exact
                path="/register/details"
                layout={RegisterLayout}
                component={RegisterAdult}
            />

            <Route exact path="/register/thanks" component={RegisterThanks} />

            <Route
                path="/register/thanks/children"
                component={RegisterThanksChildrenContainer}
            />

            <Route path="/register/otsi" component={RegisterOtsi} />
        </Switch>
    );
}

export default Register;

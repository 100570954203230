import React from 'react';
import PropTypes from 'prop-types';
import { StyledSpacing } from './spacing.style';

const gelSpacingUnits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

const spaceShape = PropTypes.shape({
    top: PropTypes.oneOf(gelSpacingUnits),
    right: PropTypes.oneOf(gelSpacingUnits),
    bottom: PropTypes.oneOf(gelSpacingUnits),
    left: PropTypes.oneOf(gelSpacingUnits),
});

export const Spacing = ({ children, margin, padding }) => {
    return (
        <StyledSpacing $margin={margin} $padding={padding}>
            {children}
        </StyledSpacing>
    );
};

Spacing.propTypes = {
    /**
    The children to be wrapped in the spacing parent div
    */
    children: PropTypes.node,
    /**
    The top, right, bottom, and left margin to be applied
    */
    margin: spaceShape,
    /**
    The top, right, bottom, and left padding to be applied
    */
    padding: spaceShape,
};

Spacing.defaultProps = {
    children: PropTypes.node,
    margin: {},
    padding: {},
};

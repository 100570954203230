import { Heading } from '@bbc-account/id-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ContinueButton from '../../shared/continueButton';
import RegisterLayout from '../registerLayout';
import { useStateContext } from '../../../modules/stateContext';
import { ParentOutsideUkConsentAgeMessage } from './messages';

const OutsideUkBlocked = () => {
    const {
        featureToggles: { consentAgeByCountry },
    } = useStateContext();

    return (
        <RegisterLayout>
            <div className="u-margin-bottom-double u-padding-top-quad">
                <Heading
                    level={2}
                    fontScale="sectionHeading"
                    fontWeight="regular"
                >
                    {consentAgeByCountry ? (
                        <ParentOutsideUkConsentAgeMessage />
                    ) : (
                        <FormattedMessage id="register.parentLed.parentOutsideUK" />
                    )}
                </Heading>
            </div>
            <ContinueButton external translationId="button.ok.value" />
        </RegisterLayout>
    );
};

OutsideUkBlocked.displayName = 'OutsideUkBlocked';

export default OutsideUkBlocked;

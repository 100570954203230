import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@bbc-account/id-components';
import ContinueButton from '../../shared/continueButton';
import Text from '../../shared/text';

const ThanksEmailFlow = ({ username, parentHasAccount }) => {
    const usernameOrDefaultMessage = username || (
        <FormattedMessage id="validation.informationSent" />
    );

    return (
        <>
            <div className="u-margin-bottom-double u-padding-top-quad">
                <Heading
                    level={2}
                    fontScale="sectionHeading"
                    fontWeight="regular"
                >
                    <FormattedMessage id="parentLed.title.accountCreated" />
                </Heading>
            </div>

            <div className="form-message form-message--info u-margin-bottom-quad">
                <div className="form-message__section form-message__section--first form-message__section--divider form-message--with-icon form-message--info-icon">
                    <Text className="form-message__title u-margin-bottom-none">
                        <FormattedMessage id="parentLed.message.childCredentials" />
                    </Text>
                </div>
                <div className="form-message__section">
                    <Text className="form-message__text u-margin-bottom-none">
                        <FormattedMessage id="parentLed.message.childUsername" />{' '}
                        {usernameOrDefaultMessage}
                    </Text>
                    <Text className="form-message__text u-margin-bottom-none">
                        <FormattedMessage id="parentLed.message.childPassword" />
                    </Text>
                </div>
            </div>

            {parentHasAccount && (
                <Text>
                    <FormattedMessage id="parentLed.message.managePermissions" />
                </Text>
            )}

            <ContinueButton />
        </>
    );
};

ThanksEmailFlow.displayName = 'ThanksEmailFlow';

ThanksEmailFlow.propTypes = {
    username: PropTypes.string,
    parentHasAccount: PropTypes.bool,
};

ThanksEmailFlow.defaultProps = {
    parentHasAccount: false,
};

export default ThanksEmailFlow;

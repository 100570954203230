import asyncValidate from './asyncValidate';

const cache = {};

const returnFromCache = key =>
    Promise.resolve().then(() => {
        if (cache[key] !== true) {
            throw cache[key];
        }
    });

const returnFromAsyncValidate = (key, fieldName, value, pathname, lang) =>
    asyncValidate(fieldName, value, pathname, lang)
        .then(asyncCallSuccess => {
            if (asyncCallSuccess) {
                // this value will never be returned
                // redux-form only checks if `asyncValidate` function throws an error or resolves
                cache[key] = true;
            }
        })
        .catch(error => {
            cache[key] = error;
            throw error;
        });

const asyncValidateWithCache = (fieldName, value, pathname, lang) => {
    const key = `${fieldName}${value}${pathname}${lang}`;

    const valueInCache = {}.hasOwnProperty.call(cache, key);

    return valueInCache
        ? returnFromCache(key)
        : returnFromAsyncValidate(key, fieldName, value, pathname, lang);
};

export default asyncValidateWithCache;

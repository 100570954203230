import PropTypes from 'prop-types';
import React from 'react';
import { ForgottenPasswordConfirmationResendEmailForm } from '../forms';

export function ForgottenPasswordConfirmationContent({
    afterFormContent,
    beforeFormContent,
}) {
    return (
        <div>
            {beforeFormContent}
            <ForgottenPasswordConfirmationResendEmailForm />
            {afterFormContent}
        </div>
    );
}

ForgottenPasswordConfirmationContent.propTypes = {
    afterFormContent: PropTypes.node,
    beforeFormContent: PropTypes.node,
};

import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as reduxFormActionCreators from '../../../modules/reduxForm';
import formNames from '../../../../shared/data/formNames';

import RegisterChildPermissions from './permissions';

const mapStateToProps = state => {
    const {
        register,
        jsEnabled,
        mvt,
        form: {
            registerChild: { values: previousFormData = {} },
        },
    } = state;

    return {
        jsEnabled,
        initialErrors: register.initialErrors || {},
        initialValues: {
            ...register.data,
            dateOfBirth: {
                day: previousFormData.dateOfBirth.day,
                month: previousFormData.dateOfBirth.month,
                year: previousFormData.dateOfBirth.year,
            },
        },
        previousFormData,
        mvt,
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            ...reduxFormActionCreators,
        },
        dispatch
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: formNames.REGISTER_CHILD,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
    })(RegisterChildPermissions)
);

import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormattedMessageWithAttributes from '../../shared/formattedMessageWithAttributes';
import Message from '../../shared/message';
import Text from '../../shared/text';
import DefaultLayout from '../../shared/defaultLayout';
import { CHILD_PERMISSIONS_URL } from '../../../../shared/endpoints/usingTheBbc';
import Track from '../../shared/analytics/track';

const ManageChildPermissionsIncorrectDateOfBirth = () => {
    return (
        <DefaultLayout heading="permissions.title.childAge">
            <Text>
                <FormattedMessage id="register.parentLed.message.childAge" />
            </Text>

            <Track name="incorrect-date-of-birth">
                <Message isError name="incorrect-date-of-birth">
                    <FormattedMessageWithAttributes
                        id="permissions.finalIncorrectDOB"
                        attributes={{
                            href: CHILD_PERMISSIONS_URL,
                            external: true,
                        }}
                    />
                </Message>
            </Track>
        </DefaultLayout>
    );
};

ManageChildPermissionsIncorrectDateOfBirth.displayName =
    'ManageChildPermissionsIncorrectDateOfBirth';

export default ManageChildPermissionsIncorrectDateOfBirth;

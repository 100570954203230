import React from 'react';
import { FormattedMessage } from 'react-intl';
import { emailFirstRoutes } from '../../../../../pages/email-first/emailFirstRouteNames';
import { EmailFirstForm } from '../../emailFirstForm';
import {
    EMAIL_FIRST_DATE_OF_BIRTH_FORM_INPUT_NAME,
    EmailFirstDateOfBirthField,
} from '../../inputs';
import { useEmailFirstForm } from '../../useEmailFirstForm';
import { StyledTermsAndConditions } from '../emailFirstDetailsForm.style';
import { useStateContext } from '../../../../../modules/stateContext';

const activeFields = [EMAIL_FIRST_DATE_OF_BIRTH_FORM_INPUT_NAME];

export const EmailFirstDetailsFormExUk = () => {
    const {
        location: { country },
    } = useStateContext();

    const {
        formError,
        handleBack,
        handleSubmit,
        handleSubmitInvalid,
    } = useEmailFirstForm({
        activeFields,
        countryCode: country,
        prevRoute: emailFirstRoutes.password.path,
        submitToRegister: true,
    });

    return (
        <EmailFirstForm
            activeFields={activeFields}
            heading={<FormattedMessage id="emailFirst.detailsForm.title" />}
            formError={formError}
            onBackButtonClick={handleBack}
            onSubmit={handleSubmit}
            onSubmitInvalid={handleSubmitInvalid}
            pageId="registration-email-first-details"
        >
            <EmailFirstDateOfBirthField />
            <StyledTermsAndConditions
                isInternational
                linkTarget="_blank"
                messageId="register.message.acceptTerms"
            />
        </EmailFirstForm>
    );
};

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ChecklistA11y } from './a11y';
import { ChecklistItem } from './item';
import { StyledChecklistHeader, StyledChecklistList } from './checklist.style';
import './checklist.scss';

/**
 * @typedef {object} ChecklistProps
 * @property {string} fieldId Related input field element identifier. This is required for a11y purposes
 * @property {React.ReactNode} label Content to appear in the component's header
 * @property {Array.<object>} items Collection of items
 */

/**
 * The Checklist component is a collection of visual indicators in list form.
 *
 * @param {ChecklistProps} props
 * @returns {React.ReactElement}
 */
export const Checklist = ({ fieldId, items, label }) => {
    const progress = useMemo(
        () =>
            items.reduce((prevProgress, { checked }) => {
                if (checked) {
                    return prevProgress + 1;
                }

                return prevProgress;
            }, 0),
        [items]
    );

    const totalProgress = items.length;

    return (
        <div>
            <StyledChecklistHeader className="checklist__header">
                {label}
            </StyledChecklistHeader>
            <StyledChecklistList>
                {items.map(
                    ({
                        checked,
                        'data-test-id': dataTestId,
                        description,
                        id,
                        label: itemLabel,
                    }) => (
                        <ChecklistItem
                            aria-describedby={fieldId}
                            checked={checked}
                            className="checklist__item"
                            data-testid={dataTestId}
                            description={description}
                            iconClassName="checklist__item__icon"
                            key={`checklist-item-${id}`}
                            label={itemLabel}
                        />
                    )
                )}
            </StyledChecklistList>
            <ChecklistA11y progress={progress} totalProgress={totalProgress} />
        </div>
    );
};

Checklist.propTypes = {
    fieldId: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            'data-test-id': PropTypes.string,
            description: ChecklistItem.propTypes.description,
            id: PropTypes.string.isRequired,
            label: ChecklistItem.propTypes.label,
            checked: ChecklistItem.propTypes.checked,
        })
    ).isRequired,
    label: PropTypes.node,
};

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BackButton from './backButton';
import BbcBlocks from '../shared/icons/bbcBlocks';

const HelpLayout = ({ children, iFramedContent }) => {
    const pageContentWrapperClasses = classnames('help-page__content-wrapper', {
        framed: iFramedContent,
    });

    return (
        <div className="help-page">
            <div className="gel-wrap">
                <div className="gel-layout gel-layout--center">
                    <div className="gel-layout__item gel-10_12 gel-1_1--tablet u-margin-top-double u-margin-bottom-double">
                        <div className="u-margin-left-none">
                            <BbcBlocks />
                        </div>
                    </div>
                </div>
                <div className="gel-layout gel-layout--center u-padding-bottom-hex">
                    <div className="gel-layout__item gel-10_12 gel-4_12--tablet">
                        <BackButton className="button button--full-width button--left-align button--left-icon button--icon-previous" />
                    </div>
                    <div className="gel-layout__item gel-10_12 gel-8_12--tablet">
                        <div className={pageContentWrapperClasses}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

HelpLayout.propTypes = {
    children: PropTypes.node.isRequired,
    iFramedContent: PropTypes.bool,
};

HelpLayout.defaultProps = {
    iFramedContent: false,
};

HelpLayout.displayName = 'HelpLayout';

export default HelpLayout;

import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactRouterPropTypes from 'react-router-prop-types';
import { ButtonInline } from '@bbc-account/id-components';
import Layout from '../pageLayout/layout';
import Form from '../shared/form';
import Avatar from '../shared/avatar';
import ApplicationLinkContainer from '../shared/applicationLink/applicationLinkContainer';
import Text from '../shared/text';
import {
    REGISTER_FEDERATED_PATH,
    REGISTER_PATH,
} from '../../../shared/endpoints/account';
import { parseQueryParams } from '../../../shared/utilities/url';
import { useStateContext } from '../../modules/stateContext';
import { dispatchEvent } from '../withEventTracking';

const Register = ({ location: { search: queryString } }) => {
    const { displayName, identifier, isFederated } = useStateContext();
    const { clientId } = parseQueryParams(queryString);

    const getRegisterUrl = renderQueryString => {
        const registerUrl = isFederated
            ? REGISTER_FEDERATED_PATH
            : REGISTER_PATH;

        const queryStringWithDisableOtsiRedirect = renderQueryString({
            disableOtsiRedirect: true,
        });

        return `${registerUrl}${queryStringWithDisableOtsiRedirect}`;
    };

    const userDetails = displayName ? (
        <Fragment>
            <p className="text heading heading--tertiary u-margin-bottom">
                {displayName}
            </p>
            <p className="text text--muted">{identifier}</p>
        </Fragment>
    ) : (
        <p className="text text--heading">{identifier}</p>
    );

    return (
        <Layout
            heading={
                <FormattedMessage id="register.title.registerNewAccount" />
            }
        >
            <Text>
                <FormattedMessage id="register.message.previouslySignedIn" />
            </Text>

            <div className="u-display-flex u-flex-direction-column u-flex-auto u-justify-content-space-between">
                <div className="u-margin-bottom-hex">
                    <ApplicationLinkContainer
                        fullWidth
                        button
                        primary
                        href={getRegisterUrl}
                        eventData={{
                            'data-bbc-container': 'otsi-register',
                            'data-bbc-title': 'register-account-with-otsi',
                            'data-bbc-source': clientId,
                        }}
                    >
                        <FormattedMessage id="register.button.registerNewAccount.value" />
                    </ApplicationLinkContainer>
                </div>

                <div className="card u-box-shadow">
                    <div className="u-margin-bottom-double">
                        <Avatar
                            type="secondary"
                            text={displayName || identifier}
                        />
                    </div>
                    <div className="u-text-align-center">{userDetails}</div>
                    <Form method="post">
                        <div className="u-text-align-center">
                            <ButtonInline
                                isSubmit
                                id="submit-button"
                                onClick={() =>
                                    dispatchEvent({
                                        container: 'otsi-register',
                                        label: 'signin-with-otsi',
                                        source: clientId,
                                    })
                                }
                            >
                                <FormattedMessage id="signIn.button.submit.value" />
                            </ButtonInline>
                        </div>
                    </Form>
                </div>
            </div>
        </Layout>
    );
};

Register.propTypes = {
    location: ReactRouterPropTypes.location.isRequired,
};

export default Register;

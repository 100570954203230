import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../../pageLayout/layout';
import Text from '../../shared/text';
import ContinueButton from '../../shared/continueButton';

const VerifyEmailComplete = () => (
    <Layout heading={<FormattedMessage id="emailVerification.title" />}>
        <Text>
            <FormattedMessage id="emailVerification.message" />
        </Text>
        <ContinueButton />
    </Layout>
);

VerifyEmailComplete.displayName = 'VerifyEmailComplete';

export default VerifyEmailComplete;

import PropTypes from 'prop-types';
import { omit } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { propTypes as reduxFormPropTypes } from 'redux-form';
import { Button, Heading } from '@bbc-account/id-components';
import { password as validatePassword } from '../../../../shared/validation/validators';
import { errorShape } from '../../../propTypes';
import PasswordGroup from '../../shared/inputFields/passwordGroup';
import { UsernameField } from '../../shared/inputFields/usernameField';
import FormError from '../../shared/formError';
import Text from '../../shared/text';
import Explainer from '../../shared/explainer';

const passwordValidator = [
    (password, { username }) =>
        validatePassword.validate([password, undefined, username]),
];

const formHasErrors = (fieldErrors, omitFields = []) => {
    const fields = omit(fieldErrors, omitFields);
    return Object.keys(fields).some(field => fields[field] !== false);
};

const getGeneralError = (errors, jsEnabled) =>
    !errors.general &&
    jsEnabled &&
    formHasErrors(errors, ['username', 'password'])
        ? 'validation.errorNotOnPage'
        : errors.general;

const RegisterChildCredentials = props => {
    const {
        actions,
        form,
        handleSubmit,
        initialErrors = {},
        jsEnabled,
    } = props;

    const removeInitialStateErrors = (...args) =>
        actions.removeInitialStateErrors(form, ...args);

    return (
        <>
            <div className="u-padding-bottom-double u-padding-top-quad">
                <Heading
                    level={2}
                    fontScale="sectionHeading"
                    fontWeight="regular"
                >
                    <FormattedMessage id="register.parentLed.title.childCredentials" />
                </Heading>
            </div>
            <Text introduction>
                <FormattedMessage id="register.parentLed.message.childCredentials" />
            </Text>
            <FormError
                name="general"
                error={getGeneralError(initialErrors, jsEnabled)}
                isAssertive
            />

            <UsernameField
                initialError={initialErrors.username}
                usernameLabel={<FormattedMessage id="label.childUsername" />}
            >
                <Explainer
                    explainers={
                        <FormattedMessage id="register.parentLed.explainer.childCredentials" />
                    }
                    name="username"
                />
            </UsernameField>

            <PasswordGroup
                additionalFieldClasses="u-margin-bottom-hex"
                initialErrors={initialErrors}
                jsEnabled={jsEnabled}
                passwordLabel={<FormattedMessage id="label.childPassword" />}
                validate={passwordValidator}
                removeInitialStateErrors={removeInitialStateErrors}
            />

            {jsEnabled && (
                <Button
                    isFullWidth
                    onClick={() => handleSubmit()}
                    id="continue-to-more-details-button"
                >
                    <FormattedMessage id="button.continue.value" />
                </Button>
            )}
        </>
    );
};

RegisterChildCredentials.displayName = 'RegisterChildCredentials';

RegisterChildCredentials.propTypes = {
    ...reduxFormPropTypes,
    actions: PropTypes.shape({
        removeInitialStateErrors: PropTypes.func.isRequired,
    }).isRequired,
    initialErrors: PropTypes.shape({
        username: errorShape,
        password: errorShape,
        location: errorShape,
        general: errorShape,
        gender: errorShape,
        genderOther: errorShape,
    }),
    jsEnabled: PropTypes.bool.isRequired,
};

export default RegisterChildCredentials;

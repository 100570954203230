import styled from 'styled-components';

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const StyledEmailFirstPageLayoutContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

import PropTypes from 'prop-types';
import React from 'react';
import { IntlProvider } from 'react-intl';

function internationalisationDecorator(ComposedComponent) {
    const InternationalisationDecorator = props => (
        /*
            We use 'en' as a static locale as we do not use locale specific formatting features
            of the FormatJS library
        */
        <IntlProvider
            locale="en"
            messages={props.translations}
            textComponent="span"
        >
            <ComposedComponent {...props} />
        </IntlProvider>
    );

    InternationalisationDecorator.displayName = 'InternationalisationDecorator';

    // TODO separate translations and non-flattened countries so we can verify key: string propTypes
    InternationalisationDecorator.propTypes = {
        translations: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    };

    return InternationalisationDecorator;
}

export default internationalisationDecorator;

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { errorShape } from '../../../propTypes';
import TextInput from '../textInput';
import PasswordInput from './passwordInput';
import FieldNotifications from '../fieldNotifications';

const NOTIFICATION_ITEM_IDS = [
    'validation.hint.password.minLength',
    'validation.hint.password.letters',
    'validation.hint.password.symbols',
];

const PasswordGroup = ({
    initialErrors,
    jsEnabled,
    passwordLabel,
    ariaDescribedBy,
    ...passwordProps
}) => (
    <Fragment>
        <FieldNotifications
            ariaDescribedBy={ariaDescribedBy}
            itemIds={NOTIFICATION_ITEM_IDS}
            promptId="validation.hint.password.title"
        />
        <Field
            additionalFieldClasses="u-margin-bottom-quad"
            autoComplete="new-password"
            component={PasswordInput}
            id="password-input"
            initialError={initialErrors.password}
            jsEnabled={jsEnabled}
            label={passwordLabel}
            name="password"
            required
            ariaDescribedBy={ariaDescribedBy}
            {...passwordProps}
        />
        {!jsEnabled && (
            <Field
                additionalFieldClasses="u-hide-pre-component-mount"
                autoComplete="current-password"
                component={TextInput}
                id="repeat-password-input"
                initialError={initialErrors.repeatPassword}
                label={<FormattedMessage id="label.password.repeat.title" />}
                name="repeatPassword"
                required
                type="password"
            />
        )}
    </Fragment>
);

PasswordGroup.displayName = 'Password';

PasswordGroup.propTypes = {
    jsEnabled: PropTypes.bool.isRequired,
    passwordLabel: PropTypes.node.isRequired,
    ariaDescribedBy: PropTypes.string,
    initialErrors: PropTypes.shape({
        password: errorShape,
        repeatPassword: errorShape,
    }),
    validate: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
};

export default PasswordGroup;

import * as config from '../../server/config';

function getQueryString(url) {
    const queryString = url.split('?');
    return queryString.length > 1 ? queryString[1] : '';
}

function parseQueryParams(url) {
    const queryString = getQueryString(url);

    if (queryString === '') {
        return {};
    }

    const queryStringValues = queryString.split('&');
    return queryStringValues.reduce((result, value) => {
        const keyValue = value.split('=');
        result[decodeURIComponent(keyValue[0])] = decodeURIComponent(
            keyValue[1]
        );
        return result;
    }, {});
}

function joinQueryParams(queryParams) {
    return Object.keys(queryParams)
        .map(key => {
            return `${key}=${queryParams[key]}`;
        })
        .join('&');
}

const buildUrlWithPtrt = (url, ptrt = '') => {
    const localBaseUrl = config.get('localBaseUrl');
    const ptrtUrl =
        typeof ptrt === 'object' // react-router location prop
            ? `${localBaseUrl}${ptrt.pathname}${ptrt.search}`
            : ptrt;

    const [plainUrl] = url.split('?');
    const queryParams = parseQueryParams(url);

    queryParams.ptrt = encodeURIComponent(ptrtUrl);

    const joinedParams = joinQueryParams(queryParams);

    return `${plainUrl}?${joinedParams}`;
};

const buildHrefFromFederatedStatus = ({
    isFederated,
    federatedUrl,
    nonFederatedUrl,
    routerLocation,
}) =>
    isFederated
        ? renderQueryString =>
              buildUrlWithPtrt(
                  `${federatedUrl}${renderQueryString()}`,
                  routerLocation
              )
        : nonFederatedUrl;

export { parseQueryParams, buildUrlWithPtrt, buildHrefFromFederatedStatus };

const PERMISSION_ALLOW_ALL_FIELD = 'permissionAllowAll';
const PERMISSION_ALLOW_COMMENTING_FIELD = 'permissionAllowCommenting';
const PERMISSION_ALLOW_PERSONALISATION_FIELD = 'permissionAllowPersonalisation';
const PERMISSION_ALLOW_NOTIFICATIONS_FIELD = 'permissionAllowNotifications';
const PERMISSION_ALLOW_UPLOADS_FIELD = 'permissionAllowUploads';

export {
    PERMISSION_ALLOW_ALL_FIELD,
    PERMISSION_ALLOW_COMMENTING_FIELD,
    PERMISSION_ALLOW_PERSONALISATION_FIELD,
    PERMISSION_ALLOW_NOTIFICATIONS_FIELD,
    PERMISSION_ALLOW_UPLOADS_FIELD,
};

const ATTRIBUTES_MAP = new Map([
    ['container', 'data-bbc-container'],
    ['name', 'data-bbc-title'],
    ['result', 'data-bbc-result'],
    ['type', 'data-bbc-event-type'],
    ['source', 'data-bbc-source'],
    ['ignoreViews', 'data-bbc-ignore-views'],
    ['metadata', 'data-bbc-metadata'],
]);

const DETAILS_MAP = new Map([
    ['container', 'container'],
    ['name', 'label'],
    ['result', 'result'],
    ['type', 'type'],
    ['source', 'source'],
]);

const DEFAULT_SOURCE = 'responsive_web';

const IGNORE_VIEWS = '1';

export { ATTRIBUTES_MAP, DETAILS_MAP, DEFAULT_SOURCE, IGNORE_VIEWS };

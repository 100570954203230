import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CallToAction } from '@bbc-account/id-components';
import Track from '../shared/analytics/track';
import { SIGN_OUT_URL } from '../../../shared/endpoints/session';
import { useStatefulQueryString } from '../../modules/statefulQueryString';
import { useStateContext } from '../../modules/stateContext';

const SignOutLink = () => {
    const { isNativeMobileApplication } = useStateContext();

    const externalHash = isNativeMobileApplication ? '#external' : '';
    const statefulQueryString = useStatefulQueryString({
        additionalParameters: { switchTld: true },
    });

    const statefulSignOutUrl = new URL(
        `${SIGN_OUT_URL}${statefulQueryString}${externalHash}`
    );

    return (
        <Track ignoreViews result={statefulSignOutUrl.pathname}>
            <CallToAction href={statefulSignOutUrl.toString()}>
                <FormattedMessage id="profile.reauthSignOut" />
            </CallToAction>
        </Track>
    );
};

SignOutLink.displayName = 'SignOutLink';

export default SignOutLink;

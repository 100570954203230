import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './callToAction.scss';

const CallToAction = ({
    ariaLabel,
    children,
    variant,
    eventAttributes,
    href,
    id,
    target,
    onClick,
}) => {
    const classes = classnames('sb-link', `sb-link--${variant}`);

    return (
        <a
            href={href}
            id={id}
            aria-label={ariaLabel}
            className={classes}
            target={target}
            onClick={onClick}
            {...eventAttributes}
        >
            {children}
        </a>
    );
};

CallToAction.propTypes = {
    /**
     This attribute defines a string value that labels an interactive element.
    */
    ariaLabel: PropTypes.string,
    /**
     The content of the link
    */
    children: PropTypes.node.isRequired,
    /**
     Style of link needed. Determination of when to use each variant is described below.
     */
    variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    /**
     The URL that the hyperlink points to.
    */
    href: PropTypes.string,
    /**
    Sets the link html ID to the provided string.
    */
    id: PropTypes.string,
    /**
     These are the attributes that are sent to our analytics service when the link is clicked.
    */
    eventAttributes: PropTypes.objectOf(PropTypes.string),
    /**
     * The target window of the hyperlink.
     */
    target: PropTypes.oneOfType([
        PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
        PropTypes.string,
    ]),
};

CallToAction.defaultProps = {
    variant: 'primary',
    eventAttributes: {},
    href: '#',
};

CallToAction.displayName = 'Call To Action';

export { CallToAction };

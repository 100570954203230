import errorMessages from '../../server/errors/errorMessages';
import { AUTH_CHECK_EMAIL_PATH } from '../../shared/endpoints/authV2';
import makeRequest from '../fetch';

const checkEmailUniqueness = async email => {
    try {
        return await makeRequest(AUTH_CHECK_EMAIL_PATH, {
            method: 'post',
            body: { email },
        });
    } catch (error) {
        return { success: false, message: errorMessages.gracefulDegradation };
    }
};

export default checkEmailUniqueness;

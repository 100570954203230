import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { renderAutocomplete } from '../../../utilities/renderAutocomplete';
import Explainer from '../explainer';
import FormattedMessageWithAttributes from '../formattedMessageWithAttributes';
import TextInput from '../textInput';
import { hometown as hometownValidator } from '../../../../shared/validation/validators';
import { PERSONAL_DETAILS_URL } from '../../../../shared/endpoints/usingTheBbc';

class HometownField extends React.Component {
    componentDidMount() {
        const hometownInput = this.hometownInput.getRenderedComponent().input;

        const updateFields = (hometown, geoname) => {
            this.props.change('hometown', hometown);
            this.props.change('geoname', geoname);
            this.geonameInput.value = geoname;
        };

        this.autocompleteMounted = new Promise(resolve => {
            renderAutocomplete(
                this.container,
                hometownInput,
                this.props.lang,
                updateFields,
                autocomplete => {
                    resolve(autocomplete);
                }
            );
        });
    }

    componentWillUnmount() {
        this.autocompleteMounted.then(autocomplete => {
            autocomplete.events.clear();
        });
    }

    render() {
        const { dispatch, explainer, ...restProps } = this.props;

        return (
            <Field
                id="hometown-input"
                name="hometown"
                additionalFieldClasses="u-margin-none"
                autocomplete="address-level2"
                aria-required="true"
                required
                ref={input => {
                    this.hometownInput = input;
                }}
                label={this.props.hometownLabel}
                component={TextInput}
                validate={[hometownValidator.validate]}
                forwardRef
                {...restProps}
            >
                <div
                    className="field__autocomplete"
                    id="hometown-autocomplete"
                    ref={container => {
                        this.container = container;
                    }}
                />
                <Explainer explainers={this.props.explainer} name="hometown" />
                <input
                    id="geoname-input"
                    type="hidden"
                    name="geoname"
                    ref={input => {
                        this.geonameInput = input;
                    }}
                />
            </Field>
        );
    }
}

HometownField.displayName = 'HometownField';

HometownField.propTypes = {
    dispatch: PropTypes.func,
    explainer: PropTypes.element.isRequired,
    change: PropTypes.func.isRequired,
    hometownLabel: PropTypes.node,
    lang: PropTypes.string,
    required: PropTypes.bool.isRequired,
};

HometownField.defaultProps = {
    hometownLabel: <FormattedMessage id="label.hometown" />,
};

const mapStateToProps = ({ isFederated }) => ({
    explainer: isFederated ? (
        <FormattedMessage id="explainer.message.hometown" />
    ) : (
        <FormattedMessageWithAttributes
            id="explainer.message.hometown"
            attributes={{
                href: PERSONAL_DETAILS_URL,
                external: true,
            }}
        />
    ),
});

export default connect(mapStateToProps)(HometownField);

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './accountLayoutSection.scss';

const AccountLayoutSection = ({ hasTopBorder, isCentreAligned, children }) => {
    const classes = classNames('sb-account-section', {
        'sb-account-section--with-top-border': hasTopBorder,
        'sb-account-section--centre-aligned-content': isCentreAligned,
    });

    return <div className={classes}>{children}</div>;
};

AccountLayoutSection.propTypes = {
    /**
     The content within the section
    */
    children: PropTypes.node.isRequired,
    /**
     Determines whether a border is shown at the top of the section
     */
    hasTopBorder: PropTypes.bool,
    /**
     Determines whether the content in the section is centre aligned
     */
    isCentreAligned: PropTypes.bool,
};

AccountLayoutSection.defaultProps = {
    hasTopBorder: false,
    isCentreAligned: false,
};

AccountLayoutSection.displayName = 'AccountLayoutSection';

export { AccountLayoutSection };

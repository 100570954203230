import { Links } from '@bbc-account/id-components';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { HELP_SIGN_IN_URL } from '../../../../shared/endpoints/usingTheBbc';
import { HelpLink } from './helpLink';

export const HelpLinks = ({ className, items, showMoreHelpLink }) => {
    const allItems = useMemo(() => {
        const returnValue = [...items];

        if (showMoreHelpLink) {
            returnValue.push({
                href: HELP_SIGN_IN_URL,
                label: 'signIn.link.moreHelp',
            });
        }

        return returnValue;
    }, [items, showMoreHelpLink]);

    return (
        <Links
            className={className}
            data-testid="help-links"
            items={allItems}
            linkAs={HelpLink}
            orientation="vertical"
        />
    );
};

HelpLinks.propTypes = {
    className: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            href: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
    showMoreHelpLink: PropTypes.bool,
};

HelpLinks.defaultProps = {
    showMoreHelpLink: true,
};

import { sortBy } from 'lodash';

function sortCountries(countries) {
    const mappedCountries = Object.keys(countries).map(code => {
        return { code, name: countries[code] };
    });
    return sortBy(mappedCountries, [country => country.name.toLowerCase()]);
}

export default sortCountries;

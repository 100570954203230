import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Message } from '@bbc-account/id-components';
import PropTypes from 'prop-types';
import FormattedMessageWithAttributes from './formattedMessageWithAttributes';
import DeprecatedMessageComponent from './message';
import { errorShape } from '../../propTypes';
import { isValid } from '../../../shared/validation/validationResult';
import Track from './analytics/track';

const isJsonKey = key => /[a-z]\.[a-z]/i.test(key);

const getFormattedError = className => (item, index) => {
    if (item?.id && item?.attributes) {
        const updateAttributeArray = attributeArray =>
            attributeArray.map(attributes => ({
                bypassChameleon: true,
                className,
                ...attributes,
            }));

        const updatedAttributes = Array.isArray(item.attributes)
            ? updateAttributeArray(item.attributes)
            : {
                  bypassChameleon: true,
                  className,
                  ...item.attributes,
              };

        return (
            <FormattedMessageWithAttributes
                id={item.id}
                attributes={updatedAttributes}
                key={item.id}
            />
        );
    }

    if (isJsonKey(item)) {
        return <FormattedMessage id={item} key={item} />;
    }

    if (typeof item === 'object' && item !== null) {
        return <FormattedMessage {...item} />;
    }

    // react/no-array-index-key
    return <span key={index}>{item}</span>;
};

const getErrorParagraph = (paragraph, className, index = 0) => (
    <span key={index}>{paragraph.map(getFormattedError(className))}</span>
);

const getTrackingMetadata = error => {
    return { error: JSON.stringify(error) };
};

let trackingKey = 0;

const getTrackingKey = () => {
    trackingKey += 1;

    return trackingKey;
};

const FormError = props => {
    const className = 'link__form-error';

    const {
        'data-testid': dataTestId,
        error,
        isAssertive,
        isWarning,
        migrateToIdComponents,
        ...messageProps
    } = props;

    if (isValid(error)) {
        return null;
    }

    const errors = Array.isArray(error) ? error : [error];
    const isMultipleParagraphs = errors.some(something =>
        Array.isArray(something)
    );
    const messageType = {
        isError: !isWarning,
        isWarning,
    };

    if (migrateToIdComponents) {
        const { name } = messageProps;

        return (
            <Track
                data-testid={dataTestId}
                name={isWarning ? `${name}-message` : `${name}-error`}
                metadata={getTrackingMetadata(error)}
                key={getTrackingKey()}
            >
                <Message
                    error={error}
                    name={name}
                    isAssertive={isAssertive}
                    isError={!isWarning}
                >
                    {isMultipleParagraphs
                        ? errors.map((paragraph, index) =>
                              getErrorParagraph(paragraph, className, index)
                          )
                        : getErrorParagraph(errors, className)}
                </Message>
            </Track>
        );
    }

    return (
        <Track
            data-testid={dataTestId}
            name={
                isWarning
                    ? `${messageProps.name}-message`
                    : `${messageProps.name}-error`
            }
            metadata={getTrackingMetadata(error)}
            key={getTrackingKey()}
        >
            <DeprecatedMessageComponent {...messageType} {...messageProps}>
                {isMultipleParagraphs
                    ? errors.map((paragraph, index) =>
                          getErrorParagraph(paragraph, className, index)
                      )
                    : getErrorParagraph(errors, className)}
            </DeprecatedMessageComponent>
        </Track>
    );
};

FormError.displayName = 'FormError';

FormError.propTypes = {
    'data-testid': PropTypes.string,
    error: errorShape,
    isAssertive: PropTypes.bool,
    isWarning: PropTypes.bool,
    migrateToIdComponents: PropTypes.bool,
    name: PropTypes.string,
};

FormError.defaultProps = {
    error: false,
    isAssertive: false,
    isWarning: false,
    migrateToIdComponents: false,
};

export default memo(FormError);

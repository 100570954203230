import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import RequestChildPermissionsManagement from './requestChildPermissionsManagement';
import formNames from '../../../../shared/data/formNames';

const mapStateToProps = ({ requestPermissionsManagement }) => ({
    initialErrors: requestPermissionsManagement.initialErrors || {},
    initialValues: {
        ...requestPermissionsManagement.data,
    },
});

export default connect(mapStateToProps)(
    reduxForm({
        form: formNames.REQUEST_PERMISSIONS_MANAGEMENT,
        destroyOnUnmount: false,
    })(RequestChildPermissionsManagement)
);

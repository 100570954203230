import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { dateShape } from '../../propTypes';
import dateOfBirthFields from '../../../shared/data/dateOfBirthFields';

const RegisterAgeHiddenInputs = ({ dateOfBirth, isMinimumAge }) => (
    <Fragment>
        <input
            name={dateOfBirthFields.DAY}
            type="hidden"
            value={dateOfBirth.day}
        />
        <input
            name={dateOfBirthFields.MONTH}
            type="hidden"
            value={dateOfBirth.month}
        />
        <input
            name={dateOfBirthFields.YEAR}
            type="hidden"
            value={dateOfBirth.year}
        />
        <input name="isMinimumAge" type="hidden" value={isMinimumAge} />
    </Fragment>
);

RegisterAgeHiddenInputs.displayName = 'RegisterAgeHiddenInputs';

RegisterAgeHiddenInputs.propTypes = {
    dateOfBirth: dateShape,
    isMinimumAge: PropTypes.bool.isRequired,
};

RegisterAgeHiddenInputs.defaultProps = {
    dateOfBirth: {
        day: '',
        month: '',
        year: '',
    },
};

export default RegisterAgeHiddenInputs;

import React from 'react';

/**
 * Default icon for a checklist item
 *
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>} props Element properties
 * @returns {React.ReactElement}
 */
export const ChecklistItemBulletIcon = (props) => (
    <span {...props}>&bull;</span>
);

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Checkbox from '../checkbox';
import { Explainer } from '../explainer';

const CheckboxField = props => {
    const {
        additionalClasses,
        ariaLabel,
        explainers,
        jsEnabled,
        label,
        name,
    } = props;

    const classes = classnames('checkbox-group', additionalClasses);

    return (
        <div className={classes}>
            <Field
                {...props}
                ariaLabel={ariaLabel}
                component={Checkbox}
                id={`${name}`}
                jsEnabled={jsEnabled}
                label={label}
                name={`${name}`}
                type="checkbox"
            />
            {explainers && (
                <Explainer
                    additionalButtonClasses="field-explainer__toggle--left-align"
                    cta={
                        <FormattedMessage id="common.explainer.link.purpose" />
                    }
                    explainers={explainers}
                    jsEnabled={jsEnabled}
                    name={name}
                />
            )}
        </div>
    );
};

CheckboxField.propTypes = {
    additionalClasses: PropTypes.string,
    ariaLabel: PropTypes.string.isRequired,
    explainers: PropTypes.node,
    jsEnabled: PropTypes.bool.isRequired,
    label: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
};

export default CheckboxField;

import React, { useCallback } from 'react';
import { AdditionalInfo, TextInput } from '@bbc-account/id-components';
import { useFieldState, useFieldValidation } from '@bbc-account/id-formaxe';
import { email } from '../../../../../shared/validation/validators';
import { useStateContext } from '../../../../modules/stateContext';
import { useIframe } from '../../../../hooks/iframe/useIframe';
import { isCrownDependency } from '../../../../../shared/data/crownDependencies';
import FormError from '../../../shared/formError';
import { IFRAME_IDENTIFIER } from '../../constants';
import { AuthSubmitButton } from '../../buttons';
import { AuthIframeInputWithButton } from './authIframeInputWithButton';
import { StyledTermsAndConditions } from './authIframeIdentifierInput.style';

const termsAndConditionsLinkTarget = '_blank';

const termsAndConditionsAttributes = [
    {
        href: 'https://www.bbc.co.uk/usingthebbc/account/newsletters',
        isExternal: true,
        target: termsAndConditionsLinkTarget,
    },
];

export const getServerError = ({
    currentValue,
    initialErrors,
    initialValues,
}) => {
    if (!initialErrors?.general || !initialValues[IFRAME_IDENTIFIER.FIELD_NAME])
        return null;
    if (currentValue !== initialValues[IFRAME_IDENTIFIER.FIELD_NAME])
        return null;

    return initialErrors.general;
};

export const AuthIframeIdentifierInput = () => {
    const {
        location,
        signIn: { initialErrors, initialValues },
        featureToggles,
    } = useStateContext();

    const { sendEventToParent } = useIframe();

    const { value, state, eventHandlers } = useFieldState(
        IFRAME_IDENTIFIER.FIELD_NAME
    );
    const { validate, errors } = useFieldValidation(
        IFRAME_IDENTIFIER.FIELD_NAME,
        email.validate
    );

    const handleSubscribeClick = useCallback(() => {
        sendEventToParent('event.click.subscribe');
    }, [sendEventToParent]);

    const errorMessage =
        errors ||
        getServerError({
            currentValue: value,
            initialValues,
            initialErrors,
        });

    return (
        <AuthIframeInputWithButton
            button={
                <AuthSubmitButton
                    isFullWidth
                    variant="focus-outlined"
                    onClick={handleSubscribeClick}
                >
                    Subscribe
                </AuthSubmitButton>
            }
            input={
                <TextInput
                    minimal
                    label={IFRAME_IDENTIFIER.LABEL}
                    id={IFRAME_IDENTIFIER.ID}
                    name={IFRAME_IDENTIFIER.FIELD_NAME}
                    type={IFRAME_IDENTIFIER.TYPE}
                    value={value || ''}
                    state={state}
                    autoComplete="username"
                    eventHandlers={{
                        ...eventHandlers,
                        onBlur: event => {
                            validate([event.target.value]);
                            eventHandlers.onBlur(event);
                        },
                    }}
                    isInvalid={!!errorMessage}
                    message={
                        !!errorMessage && (
                            <FormError
                                data-testid="identifier-input-form-error"
                                error={errorMessage}
                                name={IFRAME_IDENTIFIER.FIELD_NAME}
                                migrateToIdComponents
                            />
                        )
                    }
                />
            }
        >
            {featureToggles.passwordlessExUk && (
                <AdditionalInfo>
                    <StyledTermsAndConditions
                        attributes={termsAndConditionsAttributes}
                        isInternational={!isCrownDependency(location.country)}
                        linkTarget={termsAndConditionsLinkTarget}
                        messageId="newsletter.subscribe.acceptTerms"
                    />
                </AdditionalInfo>
            )}
        </AuthIframeInputWithButton>
    );
};

AuthIframeIdentifierInput.displayName = 'AuthIframeIdentifierInput';

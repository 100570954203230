import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import ContinueButton from '../shared/continueButton';
import VerifyEmail from '../shared/verifyEmail';
import Text from '../shared/text';
import Layout from '../pageLayout/layout';

const MoreDetailsNextSteps = ({
    email,
    bodyCopy,
    headerId,
    displayContinueButton,
}) => {
    return (
        <Layout
            showServiceIdentifiers
            heading={<FormattedMessage id={headerId} />}
        >
            {email ? (
                <VerifyEmail email={email} isEmailVerified={false} />
            ) : (
                <Fragment>
                    {bodyCopy.map((copy, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Text key={index}>{copy}</Text>
                    ))}
                </Fragment>
            )}

            {displayContinueButton && <ContinueButton />}
        </Layout>
    );
};

MoreDetailsNextSteps.displayName = 'MoreDetailsNextSteps';

MoreDetailsNextSteps.propTypes = {
    email: PropTypes.string,
    bodyCopy: PropTypes.arrayOf(PropTypes.string),
    headerId: PropTypes.string,
    displayContinueButton: PropTypes.bool,
};

MoreDetailsNextSteps.defaultProps = {
    email: '',
    bodyCopy: ['Your account has been updated.'],
    headerId: 'policyUplift.title.guardian.emailSent',
    displayContinueButton: true,
};

export default MoreDetailsNextSteps;

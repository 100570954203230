import { useCallback, useState } from 'react';
import { checkPasswordBreach } from '../../../services/checkPasswordBreach';

export function usePasswordInputBreachWarning() {
    const [warning, setWarning] = useState();

    const inputValueChangeHandler = useCallback(
        async ({ target: { value: currentValue } }) => {
            try {
                if (currentValue?.length > 0) {
                    const { error } = await checkPasswordBreach(currentValue);

                    setWarning(error);
                }
            } catch (error) {
                if (process.env.NODE_ENV !== 'production') {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
            }
        },
        []
    );

    return {
        inputValueChangeHandler,
        warning,
    };
}

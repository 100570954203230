import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../../pageLayout/layout';
import Text from '../../shared/text';
import ContinueButton from '../../shared/continueButton';
import { EDIT_EMAIL_URL } from '../../../../shared/endpoints/profile';
import { useStateContext } from '../../../modules/stateContext';
import FormattedMessageWithAttributes from '../../shared/formattedMessageWithAttributes';

const VerifyEmailResendLimit = () => {
    const {
        verifyEmail: { email },
    } = useStateContext();

    return (
        <Layout
            heading={
                <FormattedMessage id="emailVerification.resendLimit.title" />
            }
        >
            <Text>
                <FormattedMessage id="emailVerification.resendLimit.message" />
            </Text>
            <Text>
                <FormattedMessage id="emailVerification.resendLimit.pleaseCheck" />
                &hellip;
            </Text>
            <Text heading>{email}</Text>
            <Text>
                <FormattedMessageWithAttributes
                    id="emailVerification.resendLimit.changeYourEmail"
                    attributes={{
                        external: true,
                        withState: true,
                        href: EDIT_EMAIL_URL,
                        id: 'change-email',
                    }}
                />
            </Text>
            <ContinueButton />
        </Layout>
    );
};

VerifyEmailResendLimit.displayName = 'VerifyEmailResendLimit';

export default VerifyEmailResendLimit;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import RequestChildPermissionsManagementContainer from './requestChildPermissionsManagementContainer';
import DefaultLayout from '../../shared/defaultLayout';
import Text from '../../shared/text';

const ChildPermissionsManagementRequest = () => (
    <DefaultLayout heading="permissions.request.title">
        <Text>
            <FormattedMessage id="permissions.request.message" />
        </Text>
        <RequestChildPermissionsManagementContainer />
    </DefaultLayout>
);

export default ChildPermissionsManagementRequest;

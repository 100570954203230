import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import {
    EmailFirstEmailForm,
    EmailFirstPasswordForm,
    EmailFirstDetailsForm,
    EmailFirstDetailsFormExUk,
} from '../../../../components/forms/email-first';
import { useShowExUkContent } from '../../../../hooks/useShowExUkContent';
import {
    basePath,
    emailFirstRoutes,
    getEmailFirstRoutes,
} from '../../emailFirstRouteNames';
import { useEmailFirstRegistrationRoutes } from './useEmailFirstRegistrationRoutes';
import { useStateContext } from '../../../../modules/stateContext';

export const EmailFirstRegistrationRoutes = () => {
    const { search } = useEmailFirstRegistrationRoutes();

    const showExUkContent = useShowExUkContent();

    const {
        isFederated,
        featureToggles: { emailFirstFederated },
    } = useStateContext();

    const { emailFirstPaths, emailFirstBasePath } = getEmailFirstRoutes(
        isFederated
    );

    const DetailsForm = showExUkContent
        ? EmailFirstDetailsFormExUk
        : EmailFirstDetailsForm;

    const emailPath = emailFirstFederated
        ? emailFirstPaths.email.path
        : emailFirstRoutes.email.path;

    const passwordPath = emailFirstFederated
        ? emailFirstPaths.password.path
        : emailFirstRoutes.password.path;

    const detailsPath = emailFirstFederated
        ? emailFirstPaths.details.path
        : emailFirstRoutes.details.path;

    const rootPath = emailFirstFederated ? emailFirstBasePath : basePath;

    return (
        <Switch>
            <Route exact path={emailPath} component={EmailFirstEmailForm} />
            <Route
                exact
                path={passwordPath}
                component={EmailFirstPasswordForm}
            />
            <Route exact path={detailsPath} component={DetailsForm} />
            <Route path={`${rootPath}/*`}>
                <Redirect to={`${emailFirstPaths.email.path}${search}`} />
            </Route>
        </Switch>
    );
};

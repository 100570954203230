import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { EXTERNAL_LINKING_HELP_URL } from '../../../shared/endpoints/externalUrls';
import ApplicationLinkContainer from '../shared/applicationLink/applicationLinkContainer';

const StyledParagraph = styled.p`
    -webkit-text-size-adjust: 100%;
`;

const BbcFooterCopyright = ({ target }) => (
    <StyledParagraph>
        <span>
            <FormattedMessage
                id="common.footer.copyright"
                values={{ year: new Date().getFullYear() }}
            />
        </span>{' '}
        <FormattedMessage id="common.footer.externalSites" />{' '}
        <ApplicationLinkContainer
            href={EXTERNAL_LINKING_HELP_URL}
            target={target}
            external
        >
            <FormattedMessage id="common.footer.externalLinking" />
        </ApplicationLinkContainer>
    </StyledParagraph>
);

BbcFooterCopyright.displayName = 'BbcFooterCopyright';

BbcFooterCopyright.propTypes = {
    target: PropTypes.string,
};

export default BbcFooterCopyright;

import { useEffect, useState } from 'react';

function useJsEnabled() {
    const [jsEnabled, setJsEnabled] = useState(false);

    useEffect(() => {
        setJsEnabled(true);
    }, []);

    return jsEnabled;
}

export default useJsEnabled;

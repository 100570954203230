import React from 'react';
import { FormattedMessage } from 'react-intl';
import Text from '../../shared/text';
import DefaultLayout from '../../shared/defaultLayout';

const RequestSentThanks = () => (
    <DefaultLayout heading="message.emailSentConfirmation">
        <Text>
            <FormattedMessage id="message.permissions.closePage" />
        </Text>
    </DefaultLayout>
);

RequestSentThanks.displayName = 'RequestSentThanks';

export default RequestSentThanks;

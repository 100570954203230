import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../pageLayout/layout';
import Text from '../shared/text';

const TvThanks = () => {
    return (
        <Layout heading={<FormattedMessage id="pinAndPair.paired.title" />}>
            <Fragment>
                <Text>
                    <FormattedMessage id="pinAndPair.paired.signedIn" />
                </Text>
                <Text>
                    <FormattedMessage id="pinAndPair.paired.finished" />
                </Text>
            </Fragment>
        </Layout>
    );
};

TvThanks.displayName = 'TvThanks';

export default TvThanks;

import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { VERIFY_EMAIL_ISSUE_URL } from '../../../../shared/endpoints/account';
import ApplicationLinkContainer from '../../shared/applicationLink/applicationLinkContainer';
import Text from '../../shared/text';

const getVerifyEmailUrl = renderQueryString => {
    return `${VERIFY_EMAIL_ISSUE_URL}${renderQueryString()}`;
};

const getUrlWithPtrt = path => renderQueryString => {
    const ptrt = getVerifyEmailUrl(renderQueryString);

    return `${path}${renderQueryString({ ptrt })}`;
};

const VerifyEmailSorryNotSignedIn = () => {
    const signInLink = (
        <ApplicationLinkContainer href={getUrlWithPtrt('/signin')} primary>
            <FormattedMessage id="emailVerification.notSignedIn.signIn" />
        </ApplicationLinkContainer>
    );
    const registerLink = (
        <ApplicationLinkContainer href={getUrlWithPtrt('/register')} primary>
            <FormattedMessage id="emailVerification.notSignedIn.register" />
        </ApplicationLinkContainer>
    );

    return (
        <Fragment>
            <Text>
                <FormattedMessage id="emailVerification.notSignedIn.message" />
            </Text>
            <Text>
                {signInLink}{' '}
                <FormattedMessage id="emailVerification.notSignedIn.or" />{' '}
                {registerLink}
            </Text>
        </Fragment>
    );
};

VerifyEmailSorryNotSignedIn.displayName = 'VerifyEmailSorryNotSignedIn';

export default VerifyEmailSorryNotSignedIn;

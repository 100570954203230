import { CHILD_PERMISSIONS_URL } from '../../shared/endpoints/usingTheBbc';
import {
    SIGN_IN_PATH,
    SIGN_IN_FEDERATED_PATH,
    AUTH_PATH,
    FORGOTTEN_PASSWORD_PATH,
} from '../../shared/endpoints/account';

const buildSignInLink = href => ({
    id: 'validation.signin.link',
    attributes: { href },
});
const buildDuplicateLink = (signInUrl, resetUrl) => ({
    id: 'emailFirst.email.duplicate',
    attributes: [{ href: signInUrl }, { href: resetUrl }],
});

const DUPLICATE_LINK = buildDuplicateLink(AUTH_PATH, FORGOTTEN_PASSWORD_PATH);
const DUPLICATE_LINK_FEDERATED = buildDuplicateLink(
    SIGN_IN_FEDERATED_PATH,
    FORGOTTEN_PASSWORD_PATH
);
const SIGN_IN_AUTH_LINK = buildSignInLink(AUTH_PATH);
const SIGN_IN_LINK = buildSignInLink(SIGN_IN_PATH);
const SIGN_IN_FEDERATED_LINK = buildSignInLink(SIGN_IN_FEDERATED_PATH);

const FIND_OUT_MORE_ABOUT_CHILD_PERMISSIONS_LINK = {
    id: 'permissions.link.findOutMore',
    attributes: {
        href: CHILD_PERMISSIONS_URL,
        external: true,
    },
};

export default {
    // FIXME: remove inconsistency with duplicate messages punctuation
    duplicate: {
        email: ['validation.email.duplicate', ' ', SIGN_IN_AUTH_LINK],
        emailDeprecated: ['validation.email.duplicate', ' ', SIGN_IN_LINK],
        federatedEmail: [
            'validation.email.duplicate',
            ' ',
            SIGN_IN_FEDERATED_LINK,
        ],
        parentLedUsername: 'validation.username.duplicate',
        username: ['validation.username.duplicate', '. ', SIGN_IN_AUTH_LINK],
        usernameDeprecated: [
            'validation.username.duplicate',
            '. ',
            SIGN_IN_LINK,
        ],
    },
    duplicateV2: {
        email: DUPLICATE_LINK,
        federatedEmail: DUPLICATE_LINK_FEDERATED,
    },
    policy: {
        childEmailNotVerified: 'validation.email.notVerified',
        childAccountAlreadyLinked: 'validation.alreadyLinked',
        emailAlreadyVerified: 'validation.email.alreadyVerified',
        guardianAccountDoesNotExist: 'validation.guardianAccount.doesNotExist',
        guardianAccountIsUnderAge: 'validation.guardianAccount.underAge',
        guardianAccountOutstandingRequest:
            'validation.guardianAccount.waitingToRespond',
        guardianAccountIsInvalid: 'validation.guardianAccount.invalid',
        resendLimitReached: 'validation.reachedLimit',
        valueNotAcceptable: 'validation.invalidValue',
        unknownError: 'validation.unknownError',
    },
    sendAnonymousPermissionsEmail: {
        childAccountAlreadyLinked: 'validation.permissions.alreadyLinked',
        childNotFound: [
            'validation.username.notFound',
            ' ',
            'validation.username.askChild',
        ],
        requestLimitExceeded: [
            'validation.maxSubmissions.accountLimitReached',
            ' ',
            'validation.maxSubmissions.checkOrTryAgain',
        ],
        guardianIsUnderAge: 'validation.email.invalidDetails',
        guardianEmailNotAssociatedWithChildAccount: [
            'validation.email.notRelated',
            ' ',
            'validation.email.checkCorrect',
        ],
        userIsOverAgeForPermissions: [
            'validation.ageBoundary.childTooOld',
            ' ',
            FIND_OUT_MORE_ABOUT_CHILD_PERMISSIONS_LINK,
        ],
    },
    displayName: {
        profanity: 'validation.displayName.profanity',
    },
    gracefulDegradation: 'validation.generic.error',
};

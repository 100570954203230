import { useCallback, useEffect, useRef } from 'react';

export function useResizeObserver({ onResize } = {}) {
    const resizeObserverRef = useRef();

    const ref = useRef();

    const resizeHandler = useCallback(
        entries => {
            const { bottom, top, right, left } = entries.reduce(
                (bounds, { contentRect }) => {
                    bounds.bottom += contentRect.bottom;
                    bounds.left += contentRect.left;
                    bounds.right += contentRect.right;
                    bounds.top += contentRect.top;

                    return bounds;
                },
                {
                    bottom: 0,
                    left: 0,
                    right: 0,
                    top: 0,
                }
            );

            const height = bottom - top;

            const width = right - left;

            onResize({
                height,
                width,
            });
        },
        [onResize]
    );

    function teardown() {
        if (resizeObserverRef.current) {
            resizeObserverRef.current.disconnect();
            resizeObserverRef.current = null;
        }
    }

    function setup() {
        if (resizeObserverRef.current) {
            teardown();
        }

        resizeObserverRef.current = new ResizeObserver(resizeHandler);
    }

    function observe() {
        resizeObserverRef.current.observe(ref.current);
    }

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        setup();
        observe();

        // eslint-disable-next-line consistent-return
        return teardown;
    }, [resizeHandler]);

    return {
        ref,
    };
}

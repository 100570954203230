import { SelectInput } from '@bbc-account/id-components';
import React from 'react';
import { EmailFirstTextFormInput } from '../text';

export const EmailFirstSelectFormInput = ({
    autoComplete,
    children,
    defaultValue,
    id,
    label,
    name,
    options,
    validator,
}) => {
    return (
        <EmailFirstTextFormInput
            as={SelectInput}
            autoComplete={autoComplete}
            defaultValue={defaultValue}
            id={id}
            label={label}
            name={name}
            options={options}
            validator={validator}
        >
            {children}
        </EmailFirstTextFormInput>
    );
};

EmailFirstSelectFormInput.propTypes = {
    autoComplete: EmailFirstTextFormInput.propTypes.autoComplete,
    children: EmailFirstTextFormInput.propTypes.children,
    defaultValue: EmailFirstTextFormInput.propTypes.defaultValue,
    id: EmailFirstTextFormInput.propTypes.id,
    label: EmailFirstTextFormInput.propTypes.label,
    name: EmailFirstTextFormInput.propTypes.name,
    options: EmailFirstTextFormInput.propTypes.options.isRequired,
    validator: EmailFirstTextFormInput.propTypes.validator,
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@bbc-account/id-components';
import { FormattedMessage } from 'react-intl';
import Text from '../../shared/text';
import {
    REGISTER_URL,
    REGISTER_FEDERATED_URL,
} from '../../../../shared/endpoints/account';
import { useStateContext } from '../../../modules/stateContext';
import FormattedMessageWithAttributes from '../../shared/formattedMessageWithAttributes';

const getRegisterUrl = registerUrl => renderQueryString => {
    return `${registerUrl}${renderQueryString()}`;
};

const HelpProblemsWithMyUsername = () => {
    const { isFederated } = useStateContext();
    const registerUrl = isFederated ? REGISTER_FEDERATED_URL : REGISTER_URL;

    return (
        <Fragment>
            <div className="u-padding-bottom-quad">
                <Heading level={1} fontScale="headlineSmall">
                    <FormattedMessage id="help.problemsWithMyUsername.title" />
                </Heading>
            </div>
            <Text>
                <FormattedMessage id="help.problemsWithMyUsername.toCheck" />
            </Text>

            <ol>
                <Text listItem>
                    <FormattedMessage id="help.problemsWithMyUsername.tryEnteringEmail" />
                </Text>

                <Text listItem>
                    <FormattedMessage id="help.problemsWithMyUsername.checkYourUsername" />
                </Text>

                <Text listItem>
                    <FormattedMessage id="help.problemsWithMyUsername.areYouSure" />
                </Text>
            </ol>

            <Text>
                <FormattedMessageWithAttributes
                    id="help.problemsWithMyUsername.ifYouCantRemember"
                    attributes={{
                        href: getRegisterUrl(registerUrl),
                        primary: true,
                    }}
                />
            </Text>
        </Fragment>
    );
};

HelpProblemsWithMyUsername.propTypes = {
    registerUrl: PropTypes.string.isRequired,
};

export default HelpProblemsWithMyUsername;

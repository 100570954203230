import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import { propTypes as reduxFormPropTypes } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { Button } from '@bbc-account/id-components';

import { password as validatePassword } from '../../../../shared/validation/validators';
import { isCrownDependency } from '../../../../shared/data/crownDependencies';
import ageBrackets from '../../../../shared/data/ageBrackets';
import { dateShape, errorShape, locationShape } from '../../../propTypes';
import { useStateContext } from '../../../modules/stateContext';
import LocationInputGroup from '../../shared/inputFields/locationInputGroup';
import PasswordGroup from '../../shared/inputFields/passwordGroup';
import GenderGroup from '../../shared/inputFields/genderGroup';
import EmailField from '../../shared/inputFields/emailField';
import Track from '../../shared/analytics/track';
import FormError from '../../shared/formError';
import Form from '../../shared/form';
import RegisterAgeHiddenInputs from '../registerAgeHiddenInputs';
import TermsAndConditions from '../termsAndConditions';

function RegisterAdult(props) {
    const {
        actions,
        change,
        dispatch,
        form,
        gender,
        initialErrors = {},
        initialValues,
        jsEnabled,
        location,
        pristine,
        selectedCountry,
    } = props;

    const { clientId } = useStateContext();

    useEffect(() => {
        return () => {
            props.actions.clearPassword(props.form);
        };
    }, []);

    const isMinimumAge = initialValues.ageBracket === ageBrackets.OVER_18;
    const formError =
        pristine && initialErrors.general !== true
            ? initialErrors.general
            : undefined;

    const removeInitialStateErrors = (...args) =>
        actions.removeInitialStateErrors(form, ...args);

    const countryMetadata =
        location.geoIpCountry !== selectedCountry
            ? { geoLocation: location.geoIpCountry, selectedCountry }
            : undefined;

    const passwordValidator = [
        (password, { email }) =>
            validatePassword.validate([password, email, null]),
    ];

    const isLocationInput = Boolean(location?.isUk || !jsEnabled);

    const isGenderInput = Boolean(location?.isUk || !jsEnabled);

    return (
        <Fragment>
            <FormError error={formError} name="general" isAssertive />
            <Form method="post">
                <EmailField initialError={initialErrors.email} />

                <PasswordGroup
                    ariaDescribedBy="password-hint"
                    initialErrors={initialErrors}
                    jsEnabled={jsEnabled}
                    passwordLabel={
                        <FormattedMessage id="label.password.title" />
                    }
                    validate={passwordValidator}
                    removeInitialStateErrors={removeInitialStateErrors}
                />

                {isLocationInput ? (
                    <LocationInputGroup
                        change={change}
                        collectPostcode={isMinimumAge}
                        dispatch={dispatch}
                        initialErrors={initialErrors}
                        jsEnabled={jsEnabled}
                        location={location}
                    />
                ) : null}

                {isGenderInput ? (
                    <GenderGroup
                        additionalClasses="u-padding-bottom-quad"
                        gender={gender}
                        initialError={initialErrors.gender}
                        removeInitialStateErrors={removeInitialStateErrors}
                        jsEnabled={jsEnabled}
                    />
                ) : null}

                <RegisterAgeHiddenInputs
                    isMinimumAge={isMinimumAge}
                    dateOfBirth={initialValues.dateOfBirth}
                />

                <TermsAndConditions
                    messageId="register.message.acceptTerms"
                    isInternational={!isCrownDependency(selectedCountry)}
                />

                <Track
                    metadata={{
                        clientId,
                        ...countryMetadata,
                    }}
                >
                    <Button isFullWidth isSubmit id="submit-button">
                        <FormattedMessage id="register.button.submit.value" />
                    </Button>
                </Track>
            </Form>
        </Fragment>
    );
}

RegisterAdult.displayName = 'RegisterAdult';

RegisterAdult.propTypes = {
    ...reduxFormPropTypes,
    actions: PropTypes.shape({
        clearPassword: PropTypes.func.isRequired,
        removeInitialStateErrors: PropTypes.func.isRequired,
    }).isRequired,
    initialValues: PropTypes.shape({
        location: PropTypes.string.isRequired,
        dateOfBirth: dateShape,
        ageBracket: PropTypes.string.isRequired,
    }),
    initialErrors: PropTypes.shape({
        email: errorShape,
        password: errorShape,
        location: errorShape,
        postcode: errorShape,
        general: errorShape,
        gender: errorShape,
        genderOther: errorShape,
    }),
    jsEnabled: PropTypes.bool.isRequired,
    location: locationShape.isRequired,
    gender: PropTypes.string,
    selectedCountry: PropTypes.string,
};

export default RegisterAdult;

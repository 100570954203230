import { readFileSync } from 'fs';
import { resolve } from 'path';
import { getContentFromMetaTag } from '../../app/utilities/dom/meta';

function mergeServerConfig(config) {
    const {
        secure_configuration: secureConfiguration,
        configuration: insecureConfiguration,
        environment,
        release,
    } = config;

    return {
        secure: secureConfiguration,
        insecure: {
            ...insecureConfiguration,
            environment,
            release,
        },
    };
}

function readJsonFile(path) {
    try {
        const fileContents = readFileSync(path, 'utf-8');

        return JSON.parse(fileContents);
    } catch (error) {
        return undefined;
    }
}

function server() {
    const bakeConfig = readJsonFile('/etc/bake-scripts/config.json');

    if (bakeConfig) {
        return mergeServerConfig(bakeConfig);
    }

    const insecure = readJsonFile(
        resolve(__dirname, '../../../config/local.json')
    );

    return {
        insecure,
        secure: {},
    };
}

function client() {
    const config = {
        insecure: JSON.parse(getContentFromMetaTag('config')),
        secure: {},
    };

    return config;
}

export { server, client };

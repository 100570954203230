import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { StyledShowHideToggle } from './emailFirstTextFormInputShowHideToggle.style';

export const EmailFirstTextFormInputShowHideToggle = ({
    onToggleShowPassword,
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const intl = useIntl();

    const showHideToggleId = `link.password.${showPassword ? 'hide' : 'show'}`;

    const handleClick = useCallback(() => {
        setShowPassword(currentShowPassword => !currentShowPassword);
    }, []);

    useEffect(() => {
        if (typeof onToggleShowPassword !== 'undefined') {
            onToggleShowPassword(showPassword);
        }
    }, [showPassword, onToggleShowPassword]);

    return (
        <StyledShowHideToggle
            aria-label={intl.formatMessage({ id: 'link.password.show' })}
            aria-pressed={showPassword ? 'true' : 'false'}
            onClick={handleClick}
        >
            <FormattedMessage id={showHideToggleId} />
        </StyledShowHideToggle>
    );
};

EmailFirstTextFormInputShowHideToggle.propTypes = {
    onToggleShowPassword: PropTypes.func,
};

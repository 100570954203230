import asyncValidateWithCache from '../asyncValidateWithCache';

const createReduxFieldValidator = (fieldName, localValidatePath) => (
    { [fieldName]: fieldValue },
    dispatch,
    { userContext }
) => {
    return asyncValidateWithCache(
        fieldName,
        fieldValue,
        localValidatePath,
        userContext.lang
    );
};

export default createReduxFieldValidator;

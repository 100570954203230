import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './accountBackgroundLayout.scss';

const AccountBackgroundLayout = ({
    children,
    bypassChameleon,
    backgroundBottomRight,
}) => {
    const backgroundClasses = classNames('sb-background', {
        'sb-background--non-chameleon': bypassChameleon,
        'sb-background--bottom-right': bypassChameleon && backgroundBottomRight,
    });

    return (
        <>
            <div className={backgroundClasses} />
            <div className="sb-layout-wrapper">
                <div className="sb-layout">{children}</div>
            </div>
        </>
    );
};

AccountBackgroundLayout.propTypes = {
    /**
     The content within the layout
    */
    children: PropTypes.node.isRequired,
    /**
     Bypasses the chameleon background to use the pre-chameleon background positioning
    */
    bypassChameleon: PropTypes.bool,
    /**
     Positions the background image in the bottom right of the screen. This only makes a difference used in conjunction with bypassChameleon prop.
    */
    backgroundBottomRight: PropTypes.bool,
};

AccountBackgroundLayout.defaultProps = {
    bypassChameleon: false,
};

AccountBackgroundLayout.displayName = 'AccountBackgroundLayout';

export { AccountBackgroundLayout };

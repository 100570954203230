import React from 'react';
import DefaultLayout from '../../shared/defaultLayout';
import DateOfBirthSelectContainer from '../../shared/pages/dateOfBirthSelectContainer';
import { under13Validator } from '../../../../shared/validation/validators/manageDateValidators';
import { CHILD_PERMISSIONS_AGE_CONFIRMATION_PATH } from '../../../../shared/endpoints/account';

const ManageChildPermissionsDateOfBirthSelect = () => (
    <DefaultLayout heading="permissions.title.childAge">
        <DateOfBirthSelectContainer
            action={CHILD_PERMISSIONS_AGE_CONFIRMATION_PATH}
            title="register.parentLed.message.childAge"
            validator={under13Validator}
            withAttempts
        />
    </DefaultLayout>
);

export default ManageChildPermissionsDateOfBirthSelect;

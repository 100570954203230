import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { BbcBlocks, Heading } from '@bbc-account/id-components';
import AvatarCard from '../../shared/avatarCard';
import { useStateContext } from '../../../modules/stateContext';

const ProfileThanks = () => {
    const {
        contextualisation: { positionClass },
        displayName,
        ptrt: { value: ptrtHref },
    } = useStateContext();

    const backgroundClasses = classNames(
        'background background--full-screen',
        positionClass
    );

    const headingClasses = classNames(
        'u-margin-top-hex u-margin-top-deca--tablet u-margin-top-octa--desktop u-margin-top-dodeca--widescreen u-text-align-center'
    );

    return (
        <div className="profile-page">
            <div className={backgroundClasses} />

            <div className="u-position-relative">
                <BbcBlocks size="x-small" />

                <div className={headingClasses}>
                    <Heading level={2} fontScale="headlineSmall">
                        <FormattedMessage
                            id="register.profile.confirmation.title"
                            values={{ displayName }}
                        />
                    </Heading>
                </div>

                <div className="avatar-card__container u-align-items-center">
                    <AvatarCard
                        displayName={displayName}
                        externalLink
                        href={ptrtHref}
                        type="picker"
                        active
                    />
                </div>
            </div>
        </div>
    );
};

ProfileThanks.displayName = 'ProfileThanks';

export default ProfileThanks;

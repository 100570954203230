const REMOVE_ERRORS = 'account/initialFormState/REMOVE_ERRORS';

export default function reducer(state = {}, action = {}) {
    switch (action.type) {
        case REMOVE_ERRORS: {
            const { form, fieldKey } = action.payload;

            if (state[form]?.initialErrors) {
                const copy = {
                    ...state,
                    [form]: {
                        ...state[form],
                        initialErrors: {
                            ...state[form].initialErrors,
                        },
                    },
                };
                delete copy[form].initialErrors[fieldKey];
                return copy;
            }

            return state;
        }

        default:
            return state;
    }
}

export function removeInitialStateErrors(form, fieldKey) {
    return {
        type: REMOVE_ERRORS,
        payload: {
            form,
            fieldKey,
        },
    };
}

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import Text from './text';
import Track from './analytics/track';

function ExplainerChildrenWrapper({ children, open, name }) {
    if (open) {
        return <Track name={`${name}-explainer-active`}>{children}</Track>;
    }

    return <>{children}</>;
}

ExplainerChildrenWrapper.propTypes = {
    children: PropTypes.node,
    open: PropTypes.bool,
    name: PropTypes.string,
};

class Explainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle(event) {
        event.preventDefault();

        this.setState(previousState => ({
            open: !previousState.open,
        }));
    }

    render() {
        const {
            additionalClasses,
            additionalButtonClasses,
            cta,
            explainers,
            jsEnabled,
            name,
        } = this.props;

        const { open } = this.state;

        const isOpen = !jsEnabled ? true : open;
        const classes = classnames('field-explainer', additionalClasses, {
            'field-explainer--active': isOpen,
            'field-explainer--no-js': !jsEnabled,
        });
        const buttonClasses = classnames(
            'field-explainer__toggle',
            additionalButtonClasses
        );

        return (
            <div className={classes} aria-live="polite">
                <button
                    aria-expanded={open}
                    className={buttonClasses}
                    onClick={this.toggle}
                    type="button"
                >
                    {cta}
                </button>
                <div className="field-explainer__outer">
                    <div className="field-explainer__inner">
                        <ExplainerChildrenWrapper open={open} name={name}>
                            {React.Children.map(explainers, explainer => (
                                <Text>{explainer}</Text>
                            ))}
                        </ExplainerChildrenWrapper>
                    </div>
                </div>
            </div>
        );
    }
}

Explainer.displayName = 'Explainer';

Explainer.propTypes = {
    additionalClasses: PropTypes.string,
    additionalButtonClasses: PropTypes.string,
    cta: PropTypes.node,
    explainers: PropTypes.node.isRequired,
    jsEnabled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
};

Explainer.defaultProps = {
    cta: <FormattedMessage id="common.explainer.link.purpose" />,
};

export { Explainer };
export default connect(({ jsEnabled }) => ({
    jsEnabled,
}))(Explainer);

import styled from 'styled-components';

export const StyledBackButton = styled.button`
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 0.75rem;
    height: 3rem;
    justify-content: center;
    position: fixed;
    z-index: 3;
    left: var(--spacing-4);
    top: var(--spacing-1);

    @media (min-width: 37.5em) {
        left: var(--spacing-6);
        top: var(--spacing-4);
    }

    .dark & {
        color: var(--full-white);
    }
`;

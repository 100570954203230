export const IFRAME_IDENTIFIER = Object.freeze({
    FIELD_NAME: 'username',
    LABEL: 'Email',
    ID: 'user-identifier-input',
    TYPE: 'text',
});

export const IFRAME_PASSWORD = Object.freeze({
    FIELD_NAME: 'password',
    LABEL: 'Password',
    ID: 'password',
    TYPE: 'password',
    AUTOCOMPLETE: 'current-password',
});

export const IFRAME_MESSAGE_TYPE = Object.freeze({
    INFO: 'info',
    ERROR: 'error',
});

export const IFRAME_MESSAGE_PASSWORD_PAGE = 'mode.password';
export const IFRAME_MESSAGE_SUBSCRIBE_CLICK = 'event.click.subscribe';
export const IFRAME_MESSAGE_RESIZE = 'event.resize';

export const IFRAME_ERROR_IDENTIFIER_NOT_FOUND =
    'validation.signin.notFound.identifier';

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm, propTypes as reduxFormPropTypes } from 'redux-form';
import { connect } from 'react-redux';
import { Button } from '@bbc-account/id-components';
import { errorShape, policyShape } from '../../../propTypes';
import EmailField from '../inputFields/emailField';
import { UsernameField } from '../inputFields/usernameField';
import DisplayNameField from '../inputFields/displayNameField';
import PermissionsManagementEmailField from '../inputFields/permissionsManagementEmailField';
import Form from '../form';
import PolicyFields from '../../update/policyFields';
import FormError from '../formError';
import MarketingOptIn from '../inputFields/marketingOptInField';
import componentMap from '../../../../shared/data/components';
import formNames from '../../../../shared/data/formNames';

const childDisplayNameLabel = <FormattedMessage id="label.childDisplayName" />;
const childDisplayNameExplainer = (
    <FormattedMessage id="explainer.childDisplayName" />
);

const DynamicForm = ({
    isParentLed,
    components,
    initialErrors = {},
    initialValues,
    pristine,
}) => {
    const formError =
        pristine && initialErrors.general !== true
            ? initialErrors.general
            : undefined;

    const hasComponent = component => components.indexOf(component) > -1;

    const displayNameField = () =>
        isParentLed ? (
            <DisplayNameField
                displayNameLabel={childDisplayNameLabel}
                displayNameExplainers={childDisplayNameExplainer}
                initialError={initialErrors.displayName}
            />
        ) : (
            <DisplayNameField
                initialError={initialErrors.displayName}
                displayNameExplainers={
                    <FormattedMessage id="policyUplift.message.displayNameInfo" />
                }
            />
        );

    const submitButtonMessage = hasComponent(
        componentMap.PERMISSIONS_MANAGEMENT_EMAIL
    )
        ? 'button.sendEmail.value'
        : 'button.continue.value';

    return (
        <Fragment>
            <FormError error={formError} name="general" isAssertive />
            <Form method="post">
                {hasComponent(componentMap.USERNAME) && (
                    <UsernameField initialError={initialErrors.username} />
                )}

                {hasComponent(componentMap.EMAIL) && (
                    <EmailField initialError={initialErrors.email} />
                )}

                {hasComponent(componentMap.MARKETING_OPT_IN) && (
                    <MarketingOptIn error={initialErrors.marketingOptIn} />
                )}

                {hasComponent(componentMap.DISPLAY_NAME) && displayNameField()}

                {hasComponent(componentMap.PERMISSIONS_MANAGEMENT_EMAIL) && (
                    <PermissionsManagementEmailField
                        initialError={initialErrors.permissionsManagementEmail}
                    />
                )}

                {initialValues.policy && (
                    <PolicyFields policy={initialValues.policy} />
                )}

                <Button isFullWidth isSubmit id="submit-button">
                    <FormattedMessage id={submitButtonMessage} />
                </Button>
            </Form>
        </Fragment>
    );
};

DynamicForm.displayName = 'DynamicForm';

DynamicForm.propTypes = {
    ...reduxFormPropTypes,
    initialErrors: PropTypes.shape({
        email: errorShape,
        username: errorShape,
        displayName: errorShape,
        password: errorShape,
        permissionsMangementEmail: errorShape,
        marketingOptIn: errorShape,
        postcode: errorShape,
        general: errorShape,
    }),
    initialValues: PropTypes.shape({
        policy: policyShape,
    }),
    isParentLed: PropTypes.bool,
};

DynamicForm.defaultProps = {
    isParentLed: false,
};

const mapStateToProps = state => {
    const { policy, update } = state;
    return {
        components: update.components,
        initialErrors: update.initialErrors || {},
        initialValues: {
            ...update.data,
            policy,
        },
    };
};

export { DynamicForm };

export default connect(mapStateToProps)(
    reduxForm({
        destroyOnUnmount: false,
        form: formNames.UPDATE,
    })(DynamicForm)
);

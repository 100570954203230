const SHARED_PERSISTED_STATE = [
    'ab',
    'action',
    'c',
    'clientId',
    'codeChallenge',
    'context',
    'disableOtsiRedirect',
    'gracefulDegradationError',
    'guardianToken',
    'isOtsi',
    'isCasso',
    'lang',
    'layout',
    'marketingOptIn',
    'nonce',
    'permissionToken',
    'policy',
    'profileId',
    'proxy',
    'ptrt',
    'realm',
    'redirectUri',
    'scope',
    'sequenceId',
    'service',
    'skipTokenCheck',
    'suppressRegisterLinks',
    'switchTld',
    'switchUser',
    'thirdPartyRedirectUri',
    'thirdPartyState',
    'tld',
    'uplift',
    'userCode',
    'userJourney',
    'userOrigin',
];

function extendSharedState(values = []) {
    return Object.freeze(SHARED_PERSISTED_STATE.concat(values).sort());
}

const PERSISTED_CLIENT_STATE_VALUES = extendSharedState([
    'contextualisation',
    'cookiePolicy',
    'csrf',
    'emailToken',
    'featureToggles',
    'iframe',
    'isFederated',
    'isNativeMobileApplication',
    'jsEnabled',
    'location',
    'mvt',
    'sylphidToken',
    'translations',
]);

const PERSISTED_CLIENT_QUERY_VALUES = extendSharedState();

const PERSISTED_SERVER_STATE_VALUES = extendSharedState(['iframe']);

export {
    PERSISTED_CLIENT_STATE_VALUES,
    PERSISTED_CLIENT_QUERY_VALUES,
    PERSISTED_SERVER_STATE_VALUES,
};

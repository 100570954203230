import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { ChecklistItemBulletIcon, ChecklistItemTickIcon } from './icons';
import { StyledChecklistItem } from './checklistItem.style';
import './checklistItem.scss';

/**
 * @typedef {object} ChecklistItemProps
 * @property {boolean} checked Indicator if the item is ok
 * @property {string} className CSS class name
 * @property {string} description A11y description
 * @property {string} iconClassName Icon style class name
 * @property {string} label Item text label
 * @property {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, BaseObject>} props
 */

/**
 * The ChecklistItem component is a visual indicator of a checklist.
 *
 * @param {ChecklistItemProps} props
 * @returns {React.ReactElement}
 */
export const ChecklistItem = ({
    checked,
    className,
    description,
    iconClassName,
    label,
    ...props
}) => {
    return (
        <StyledChecklistItem
            aria-label={`${description}.`}
            {...props}
            className={classNames(className, {
                'checklist-item--checked': checked,
            })}
        >
            <div className={iconClassName}>
                {checked ? (
                    <ChecklistItemTickIcon
                        aria-label="check mark"
                        data-testid="checklist-item-tick-icon"
                    />
                ) : (
                    <ChecklistItemBulletIcon data-testid="checklist-item-bullet-icon" />
                )}
            </div>
            <div>{label}</div>
        </StyledChecklistItem>
    );
};

ChecklistItem.propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    description: PropTypes.string.isRequired,
    iconClassName: PropTypes.string,
    label: PropTypes.node.isRequired,
};

import React, { Fragment } from 'react';
import { Heading } from '@bbc-account/id-components';
import { FormattedMessage } from 'react-intl';
import Text from '../../shared/text';

const HelpFindOutMoreDob = () => (
    <Fragment>
        <div className="u-padding-bottom-quad">
            <Heading level={1} fontScale="headlineSmall">
                <FormattedMessage id="help.whyProvideDobOnRegister.title" />
            </Heading>
        </div>

        <Text>
            <FormattedMessage id="help.whyProvideDobOnRegister.usage" />
        </Text>

        <Text>
            <FormattedMessage id="help.whyProvideDobOnRegister.helpsUs" />
        </Text>

        <Text>
            <FormattedMessage id="help.whyProvideDobOnRegister.moreRelevantInfo" />
        </Text>

        <Text>
            <FormattedMessage id="help.whyProvideDobOnRegister.yearOfBirth" />
        </Text>

        <Text>
            <FormattedMessage id="help.whyProvideDobOnRegister.deleteDayAndMonth" />
        </Text>
    </Fragment>
);

export default HelpFindOutMoreDob;

import { Heading } from '@bbc-account/id-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const RegisterThanksGuardian = () => (
    <div className="u-margin-bottom-double u-padding-top-quad">
        <Heading level={2} fontScale="sectionHeading" fontWeight="regular">
            <FormattedMessage id="message.emailSentConfirmation" />
        </Heading>
    </div>
);

RegisterThanksGuardian.displayName = 'RegisterThanksGuardian';

export default RegisterThanksGuardian;

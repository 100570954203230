import { styled } from 'styled-components';

const breakpoint = `28rem`;

export const StyledAuthIframeInputWithButton = styled.div`
    display: flex;
    gap: var(--spacing-4);
    flex-direction: column;
`;

export const StyledAuthIframeInputWithButtonWrapper = styled.div`
    display: flex;
    gap: var(--spacing-4);
    flex-direction: column;

    @media (min-width: ${breakpoint}) {
        flex-direction: row;
    }
`;

export const StyledAuthIframeInputWithButtonInputWrapper = styled.div`
    flex: 1;

    .sb-field {
        padding-top: 0;
    }
`;

export const StyledAuthIframeInputWithButtonButtonWrapper = styled.div`
    > * {
        width: 100%;
    }

    .sb-button {
        height: 2.75rem;
        padding: var(--spacing-2) var(--spacing-4);
    }

    @media (min-width: ${breakpoint}) {
        margin-top: calc(var(--spacing-6) + 0.45rem);
        width: 8rem;
    }
`;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FlexContainer } from '../../gel/containers/flex/flexContainer';

import './checkbox.scss';

const Checkbox = ({
    checked,
    disabled,
    errorComponent,
    name,
    value,
    onChange,
    label,
    reverse,
    labelSize,
    variant,
}) => {
    const classes = classnames('sb-checkbox', {
        'sb-checkbox--disabled': disabled,
        'sb-checkbox--green': variant === 'green',
        'sb-checkbox--grayscale': variant === 'grayscale',
    });

    const labelClasses = classnames('sb-checkbox__label', {
        'sb-checkbox__label--large': labelSize === 'large',
        'sb-checkbox__label--small': labelSize === 'small',
    });

    return (
        <div className="sb-field sb-field--checkbox">
            <FlexContainer alignCenter reverse={reverse}>
                <input
                    type="checkbox"
                    className={classes}
                    checked={checked}
                    name={name}
                    id={name}
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                />
                <label className={labelClasses} htmlFor={name}>
                    {label}
                </label>
            </FlexContainer>
            {!!errorComponent && (
                <>
                    <div className="sb-checkbox__field-decoration" />
                    <div className="sb-checkbox__error-component-container">
                        {errorComponent}
                    </div>
                </>
            )}
        </div>
    );
};

Checkbox.propTypes = {
    /**
     This sets the checked value of the checkbox input
     */
    checked: PropTypes.bool,
    /**
     This sets the disabled value of the checkbox input
     */
    disabled: PropTypes.bool,
    /**
    Optional error component to be displayed if required
     */
    errorComponent: PropTypes.node,
    /**
    The name of the form element
    */
    name: PropTypes.string,
    /**
    The value of the field when the parent form is submitted
    */
    value: PropTypes.string,
    /**
    The text that is shown next to the checkbox submitted
    */
    label: PropTypes.string,
    /**
    The size of the label text
    */
    labelSize: PropTypes.oneOf(['small', 'large']),
    /**
    The value of the field when the parent form is submitted
    */
    onChange: PropTypes.func,
    /**
    This reverses the elements order putting the checkbox first
    */
    reverse: PropTypes.bool,
    /**
    The variant of the checkbox for colour and icon styling
    */
    variant: PropTypes.oneOf(['grayscale', 'green']),
};

Checkbox.defaultProps = {
    checked: false,
    disabled: false,
    labelSize: 'large',
    onChange: () => {},
    reverse: false,
    variant: 'green',
};

Checkbox.displayName = 'Checkbox';

export { Checkbox };

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../pageLayout/layout';

const MoreDetailsLinkedAccountNextSteps = () => {
    return (
        <Layout
            showServiceIdentifiers
            heading={
                <FormattedMessage id="policyUplift.message.guardian.emailSentCheckInbox" />
            }
        />
    );
};

MoreDetailsLinkedAccountNextSteps.displayName =
    'MoreDetailsLinkedAccountNextSteps';

export default MoreDetailsLinkedAccountNextSteps;

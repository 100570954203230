import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ApplicationLinkContainer from '../../shared/applicationLink/applicationLinkContainer';

export function HelpLink({ href, label }) {
    return (
        <ApplicationLinkContainer href={href} primary>
            <FormattedMessage id={label} />
        </ApplicationLinkContainer>
    );
}

HelpLink.propTypes = {
    href: PropTypes.string,
    label: PropTypes.string.isRequired,
};

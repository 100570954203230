import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

const Checkbox = ({ ariaLabel, input, id, label }) => (
    <Fragment>
        <label
            aria-label={label}
            className="checkbox__label heading heading--tertiary"
            htmlFor={id}
        >
            <FormattedMessage id={label} />
        </label>
        <input
            aria-label={ariaLabel}
            className="checkbox__input"
            id={id}
            type="checkbox"
            {...input}
            // Used to overwrite the default value of empty string in input
            // that Redux form populates which prevents this working with non-js
            value={id}
        />
    </Fragment>
);

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
    ariaLabel: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    jsEnabled: PropTypes.bool.isRequired,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
    }).isRequired,
    label: PropTypes.node.isRequired,
};

export default Checkbox;

import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import ApplicationLinkContainer from '../../../shared/applicationLink/applicationLinkContainer';
import Text from '../../../shared/text';
import { useForgottenPasswordPath } from '../../useForgottenPasswordPath';
import { ForgottenPasswordConfirmationContent } from './forgottenPasswordConfirmationContent';
import { useForgottenPasswordConfirmationContext } from '../context';
import { useForgottenPasswordEmail } from '../../useForgottenPasswordEmail';

export function ForgottenPasswordConfirmationContentExUk() {
    const { setEmailToSessionStorage } = useForgottenPasswordEmail();

    const forgottenPasswordPath = useForgottenPasswordPath();

    const { email } = useForgottenPasswordConfirmationContext();

    const handleEditEmailClick = useCallback(() => {
        if (email) {
            setEmailToSessionStorage(email);
        }
    }, [email, setEmailToSessionStorage]);

    return (
        <ForgottenPasswordConfirmationContent
            beforeFormContent={
                <>
                    <Text>
                        {email ? (
                            <FormattedMessage
                                id="passwordReset.message.resetFromEmailExUk"
                                values={{ email }}
                            />
                        ) : (
                            <FormattedMessage id="passwordReset.message.resetFromEmailExUkNoEmail" />
                        )}
                    </Text>
                    <Text>
                        <FormattedMessage id="passwordReset.message.emailNotReceivedExUk" />
                    </Text>
                    <Text>
                        <FormattedMessage id="passwordReset.message.weDoThisExUk" />
                    </Text>
                </>
            }
            afterFormContent={
                <>
                    <br />
                    <ApplicationLinkContainer
                        button
                        external
                        fullWidth
                        secondary
                        href={forgottenPasswordPath}
                        onClick={handleEditEmailClick}
                        withState
                    >
                        <FormattedMessage id="passwordReset.button.editEmail" />
                    </ApplicationLinkContainer>
                </>
            }
        />
    );
}

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@bbc-account/id-components';
import ContinueButton from '../../shared/continueButton';
import Text from '../../shared/text';
import { REGISTER_CHILD_GUARDIAN_PATH } from '../../../../shared/endpoints/account';

const RegisterChildInvalidGuardian = () => (
    <>
        <div className="u-margin-bottom-double u-padding-top-quad">
            <Heading level={2} fontScale="sectionHeading" fontWeight="regular">
                <FormattedMessage id="register.title.guardianRulesInvalid" />
            </Heading>
        </div>
        <Text>
            <FormattedMessage id="register.message.guardianRulesInvalid" />
        </Text>
        <ContinueButton
            href={REGISTER_CHILD_GUARDIAN_PATH}
            translationId="button.ok.value"
        />
    </>
);

RegisterChildInvalidGuardian.displayName = 'RegisterChildInvalidGuardian';

export default RegisterChildInvalidGuardian;

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import TextInput from '../textInput';
import ShowHideToggle from '../showHideToggle';
import jsDetection from '../../jsDetection';
import { errorShape } from '../../../propTypes';

class PasswordInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            revealPassword: false,
        };

        this.onShowHideToggle = this.onShowHideToggle.bind(this);
    }

    onShowHideToggle(event) {
        event.preventDefault();
        this.setState(previousState => ({
            revealPassword: !previousState.revealPassword,
        }));
    }

    render() {
        const { revealPassword } = this.state;
        const {
            jsEnabled,
            meta: { error: { attributes: { isWarning = false } = {} } = {} },
        } = this.props;

        const additionalInputClasses = classnames({
            'field__input--password-toggle': jsEnabled,
        });
        const showHideToggleId = `link.password.${
            revealPassword ? 'hide' : 'show'
        }`;

        return (
            <TextInput
                inputChildren={
                    jsEnabled && (
                        <ShowHideToggle
                            id="toggle-password-type"
                            onClick={this.onShowHideToggle}
                        >
                            <FormattedMessage id={showHideToggleId} />
                        </ShowHideToggle>
                    )
                }
                additionalInputClasses={additionalInputClasses}
                isWarning={isWarning}
                type={revealPassword ? 'text' : 'password'}
                {...this.props}
            />
        );
    }
}

PasswordInput.displayName = 'PasswordInput';

PasswordInput.propTypes = {
    jsEnabled: PropTypes.bool.isRequired,
    meta: PropTypes.shape({
        error: errorShape,
    }).isRequired,
};

export { PasswordInput };
export default jsDetection(PasswordInput);

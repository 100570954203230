import { styled } from 'styled-components';

const conditionallyApplyGelSpacing = (unit) =>
    unit !== undefined && `var(--spacing-${unit})`;

export const StyledSpacing = styled.div(({ $margin, $padding }) => ({
    marginTop: conditionallyApplyGelSpacing($margin.top),
    marginRight: conditionallyApplyGelSpacing($margin.right),
    marginBottom: conditionallyApplyGelSpacing($margin.bottom),
    marginLeft: conditionallyApplyGelSpacing($margin.left),
    paddingTop: conditionallyApplyGelSpacing($padding.top),
    paddingRight: conditionallyApplyGelSpacing($padding.right),
    paddingBottom: conditionallyApplyGelSpacing($padding.bottom),
    paddingLeft: conditionallyApplyGelSpacing($padding.left),
}));

import { styled } from 'styled-components';

export const StyledChecklistA11y = styled.p`
    height: 1px;
    left: -10000px;
    overflow: hidden;
    position: absolute;
    top: auto;
    width: 1px;
`;

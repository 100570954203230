import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonInline } from '@bbc-account/id-components';
import Text from './text';
import Form from './form';
import ApplicationLinkContainer from './applicationLink/applicationLinkContainer';
import { EDIT_EMAIL_URL } from '../../../shared/endpoints/profile';

const VerifyEmail = ({ email, isEmailVerified }) => {
    return (
        <Fragment>
            <Text className="text--heading">
                {email}{' '}
                <ApplicationLinkContainer
                    href={EDIT_EMAIL_URL}
                    id="change-email"
                    withState
                    external
                >
                    <FormattedMessage id="common.message.email.change.value" />
                </ApplicationLinkContainer>
            </Text>
            {!isEmailVerified && (
                <Form action="/signin/verify/email/thanks">
                    <Text>
                        <FormattedMessage id="emailVerification.checkInbox" />
                    </Text>
                    <Text>
                        <FormattedMessage id="emailVerification.button.resend.prefix" />{' '}
                        <ButtonInline
                            isSubmit
                            id="resend-email-verification"
                            variant="secondary"
                        >
                            <FormattedMessage id="emailVerification.button.resend.value" />
                        </ButtonInline>
                        .
                    </Text>
                </Form>
            )}
        </Fragment>
    );
};

VerifyEmail.displayName = 'VerifyEmail';

VerifyEmail.propTypes = {
    email: PropTypes.string,
    isEmailVerified: PropTypes.bool.isRequired,
};

VerifyEmail.defaultProps = {
    email: 'Unknown Email Address',
};

export default VerifyEmail;

import { PERSISTED_CLIENT_QUERY_VALUES } from '../data/persistedState';

const isUnique = (item, index, arr) => arr.indexOf(item) === index;

const doesNotContain = (arr, item) => arr.indexOf(item) === -1;

function convertToQuery(state) {
    return Object.keys(state).reduce((result, key) => {
        const value = state[key];
        const separator = result === '' ? '?' : '&';
        result += `${separator}${key}=${encodeURIComponent(value)}`;
        return result;
    }, '');
}

function serialiseToKey(key, state) {
    return `${key}=${encodeURIComponent(JSON.stringify(state))}`;
}

function renderQueryString(additional = {}, omitted = [], serialisationKey) {
    const keys = Object.keys(additional)
        .concat(PERSISTED_CLIENT_QUERY_VALUES)
        .filter(isUnique)
        .filter(item => doesNotContain(omitted, item));

    const state = keys.reduce((result, item) => {
        const itemObject = additional[item] || this[item];

        if (itemObject) {
            const { value = itemObject } = itemObject;

            if (!itemObject.isDefault) {
                result[item] = value;
            }
        }

        return result;
    }, {});
    return serialisationKey
        ? serialiseToKey(serialisationKey, state)
        : convertToQuery(state);
}

export default renderQueryString;

import PropTypes from 'prop-types';
import React, { Children } from 'react';

class LegacyStateProvider extends React.Component {
    getChildContext() {
        return {
            userContext: this.props.data,
        };
    }

    render() {
        return Children.only(this.props.children);
    }
}

LegacyStateProvider.displayName = 'LegacyStateProvider';

LegacyStateProvider.childContextTypes = {
    userContext: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

LegacyStateProvider.propTypes = {
    children: PropTypes.node.isRequired,
    data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default LegacyStateProvider;

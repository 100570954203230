import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CallToActionButton } from '@bbc-account/id-components';
import Layout from '../../pageLayout/layout';
import { AUTH_PATH } from '../../../../shared/endpoints/account';
import Track from '../../shared/analytics/track';
import renderQueryString from '../../../../shared/urls/renderQueryString';
import { useStateContext } from '../../../modules/stateContext';

const MagicLinkError = () => {
    const userContext = useStateContext();

    const {
        featureToggles: { magicLinkSequenceId },
    } = userContext;

    const queryString = magicLinkSequenceId
        ? renderQueryString.call(userContext)
        : '';

    return (
        <Layout
            pageId="signin-page"
            heading={
                <FormattedMessage id="auth.magicLink.validationError.title" />
            }
            showServiceIdentifiers
        >
            <Track container="magic-link" ignoreViews name="magic-link-error">
                <CallToActionButton
                    href={`${AUTH_PATH}${queryString}`}
                    isFullWidth
                >
                    <FormattedMessage id="auth.magicLink.validationError.button" />
                </CallToActionButton>
            </Track>
        </Layout>
    );
};

export default MagicLinkError;

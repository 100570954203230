import { dateOfBirth as dateOfBirthValidators } from '../validators';
import { isMonthDayYearCountry } from '../../i18n/dateFormat';
import { getConsentAgeByLocation } from '../../utilities/getConsentAgeByLocation';
import { isLocationUk } from '../../utilities/isLocationUk';

const under13Validator = dateOfBirth =>
    dateOfBirthValidators.validateUnder13(
        dateOfBirth,
        'validation.ageBoundary.tooOld'
    );

const getConsentAge = ({ consentAgeByCountryEnabled, location, isUk }) => {
    if (consentAgeByCountryEnabled) {
        return getConsentAgeByLocation({ location });
    }

    // TODO this line remains for legacy purposes when the feature dial is off
    // It may be removed in the future if decided that we do not need the dial
    return isUk ? 13 : 16;
};

const getValidatorByConsentAge = consentAge => {
    const validator = dateOfBirthValidators[`validate${consentAge}toMaxAge`];

    if (!validator) {
        throw new ReferenceError(
            `validator for age ${consentAge} does not exist`
        );
    }

    return validator;
};

const getValidatorOptions = ({
    attributes,
    consentAge,
    consentAgeByCountryEnabled,
    isUk,
}) => {
    const underAgeErrorMessageKey = `under${consentAge}ErrorMessage`;

    let underAgeErrorMessageValue = consentAgeByCountryEnabled
        ? {
              id: 'register.parentLed.parentOutsideUKConsentAge',
              values: { consentAge },
          }
        : `validation.dateOfBirth.under${consentAge}`;

    if (isUk) {
        underAgeErrorMessageValue = `validation.dateOfBirth.long.under${consentAge}`;
    }

    if (typeof attributes !== 'undefined') {
        if (typeof underAgeErrorMessageValue === 'object') {
            underAgeErrorMessageValue = {
                ...underAgeErrorMessageValue,
                attributes: {
                    ...underAgeErrorMessageValue.attributes,
                    ...attributes,
                },
            };
        } else {
            underAgeErrorMessageValue = {
                id: underAgeErrorMessageValue,
                attributes,
            };
        }
    }

    return {
        [underAgeErrorMessageKey]: underAgeErrorMessageValue,
    };
};

const getAdultRegistrationDateValidator = ({
    attributes,
    consentAgeByCountryEnabled,
    location,
}) => {
    const { country } = location;
    const isUk = isLocationUk(location);
    const maxAgeErrorMessage = 'validation.dateOfBirth.overMaxAge';

    const invalidDateMessage = isMonthDayYearCountry(country)
        ? 'validation.dateOfBirth.realDate.monthDayYear'
        : 'validation.dateOfBirth.realDate.dayMonthYear';

    const consentAge = getConsentAge({
        consentAgeByCountryEnabled,
        location,
        isUk,
    });

    return date =>
        getValidatorByConsentAge(consentAge)(date, {
            maxAgeErrorMessage,
            invalidDateMessage,
            ...getValidatorOptions({
                attributes,
                consentAge,
                consentAgeByCountryEnabled,
                isUk,
            }),
        });
};

export { under13Validator, getAdultRegistrationDateValidator };

import { isCrownDependency } from '../../shared/data/crownDependencies';

const UPDATE_LOCATION = 'account/location/UPDATE_LOCATION';

export default function reducer(
    state = {
        country: 'gb',
        isUk: true,
        initiallyUk: false,
    },
    action = {}
) {
    switch (action.type) {
        case UPDATE_LOCATION: {
            const { country } = action.payload;
            return {
                ...state,
                country,
                isUk: isCrownDependency(country),
            };
        }

        default:
            return state;
    }
}

export function updateLocation(country) {
    return {
        type: UPDATE_LOCATION,
        payload: {
            country,
        },
    };
}

const components = {
    DISPLAY_NAME: 'displayName',
    GEONAME: 'geoname',
    HOMETOWN: 'hometown',
    INTRODUCTION: 'introduction',
    PERMISSIONS_MANAGEMENT_EMAIL: 'permissionsManagementEmail',
    EMAIL: 'email',
    PASSWORD: 'password',
    USERNAME: 'username',
    LOCATION: 'location',
    MARKETING_OPT_IN: 'marketingOptIn',
};

export default components;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../../pageLayout/layout';
import ApplicationLinkContainer from '../../shared/applicationLink/applicationLinkContainer';
import { SIGN_IN_FEDERATED_PATH } from '../../../../shared/endpoints/account';
import { useShowExUkContent } from '../../../hooks/useShowExUkContent';
import { useStateContext } from '../../../modules/stateContext';
import Text from '../../shared/text';
import {
    ForgottenPasswordConfirmationContentExUk,
    ForgottenPasswordConfirmationContentUk,
} from './content';
import { ForgottenPasswordConfirmationContextProvider } from './context';

const ForgottenPasswordConfirmation = () => {
    const {
        isFederated,
        featureToggles: { createPasswordContent },
    } = useStateContext();

    const showExUkContent = useShowExUkContent();

    const ForgottenPasswordConfirmationContent =
        createPasswordContent && showExUkContent
            ? ForgottenPasswordConfirmationContentExUk
            : ForgottenPasswordConfirmationContentUk;

    return (
        <ForgottenPasswordConfirmationContextProvider>
            <Layout
                heading={
                    <FormattedMessage
                        id={
                            showExUkContent
                                ? 'passwordReset.checkInbox.titleExUk'
                                : 'passwordReset.checkInbox.title'
                        }
                    />
                }
            >
                <ForgottenPasswordConfirmationContent />
                {isFederated && (
                    <Text className="u-margin-top">
                        <ApplicationLinkContainer
                            href={SIGN_IN_FEDERATED_PATH}
                            external
                            withState
                            primary
                        >
                            <FormattedMessage id="common.link.signInAgain" />
                        </ApplicationLinkContainer>
                    </Text>
                )}
            </Layout>
        </ForgottenPasswordConfirmationContextProvider>
    );
};

ForgottenPasswordConfirmation.displayName = 'ForgottenPasswordConfirmation';

export default ForgottenPasswordConfirmation;

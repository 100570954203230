import { Button, ButtonInline } from '@bbc-account/id-components';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useShowExUkContent } from '../../../../../hooks/useShowExUkContent';
import { useStateContext } from '../../../../../modules/stateContext';

export function ForgottenPasswordConfirmationResendEmailFormButton({
    onClick,
}) {
    const {
        featureToggles: { createPasswordContent },
    } = useStateContext();

    const showExUkContent = createPasswordContent && useShowExUkContent();

    const ButtonComponent = showExUkContent ? Button : ButtonInline;

    return (
        <ButtonComponent
            isSubmit
            isFullWidth
            onClick={onClick}
            variant={showExUkContent ? 'primary' : 'secondary'}
        >
            <FormattedMessage
                id={
                    showExUkContent
                        ? 'passwordReset.button.resendEmail'
                        : 'cama.link.resendEmail'
                }
            />
        </ButtonComponent>
    );
}

ForgottenPasswordConfirmationResendEmailFormButton.propTypes = {
    onClick: PropTypes.func,
};

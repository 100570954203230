import PropTypes from 'prop-types';
import React from 'react';
import { postMessageToken } from '../postMessageToken';
import connectToState from './connectToState';

class TokenRefreshResult extends React.Component {
    componentDidMount() {
        const token = `bbcidv5_token_${this.props.tokenState}`;
        postMessageToken(this.props.iframe, token);
    }

    render() {
        return null;
    }
}

TokenRefreshResult.propTypes = {
    tokenState: PropTypes.oneOf(['success', 'invalid', 'error']).isRequired,
    iframe: PropTypes.bool,
};

TokenRefreshResult.defaultProps = {
    iframe: false,
};

TokenRefreshResult.displayName = 'TokenRefreshResult';

export { TokenRefreshResult };
export default connectToState(TokenRefreshResult, ['iframe']);

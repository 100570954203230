import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Avatar from './avatar';
import ApplicationLinkContainer from './applicationLink/applicationLinkContainer';

const AvatarCard = ({
    active,
    analytics,
    disabled,
    displayName,
    externalLink,
    href,
    type,
}) => {
    const avatarWithName = (
        <>
            <Avatar text={displayName} type={type} disabled={disabled} />
            <p className="avatar-card__heading">{displayName}</p>
        </>
    );

    const cardClasses = classNames('avatar-card', {
        'avatar-card--picker': type === 'picker',
        'avatar-card--active': active,
        'avatar-card--disabled': disabled,
    });

    if (href && !disabled) {
        return (
            <ApplicationLinkContainer
                analytics={analytics}
                className={cardClasses}
                external={externalLink}
                href={href}
                bypassChameleon
            >
                {avatarWithName}
            </ApplicationLinkContainer>
        );
    }

    return (
        <div className={`${cardClasses} u-box-shadow`}>{avatarWithName}</div>
    );
};

AvatarCard.displayName = 'AvatarCard';

AvatarCard.propTypes = {
    displayName: PropTypes.string.isRequired,
    href: PropTypes.string,
    externalLink: PropTypes.bool,
    disabled: PropTypes.bool,
    type: PropTypes.oneOf(['primary', 'picker']),
    active: PropTypes.bool,
    analytics: PropTypes.shape({
        container: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    }),
};

AvatarCard.defaultProps = {
    externalLink: false,
    type: 'primary',
    active: false,
};

export default AvatarCard;

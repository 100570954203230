import PropTypes from 'prop-types';
import React from 'react';
import { flatten, zip } from 'lodash';
import { injectIntl } from 'react-intl';
import ApplicationLinkContainer from './applicationLink/applicationLinkContainer';
import { intlShape } from '../../propTypes';

const COPY_AROUND_ANCHORS_REGEX = /<a ?(?:[a-z-="{}]+ ?)*?>.+?<\/a>/gi;
const ANCHORS_REGEX = /<a ?(?:[a-z-="{}]+ ?)*?>(.+?)<\/a>/gi;

const getAllMatchedRegexGroups = (regex, string) => {
    let result = [];
    let iterationResult = regex.exec(string);

    while (iterationResult !== null) {
        const matchedGroups = iterationResult.slice(1);
        result = result.concat(matchedGroups);
        iterationResult = regex.exec(string);
    }

    return result;
};

class FormattedMessageWithAttributes extends React.PureComponent {
    render() {
        const {
            id,
            attributes,
            intl: { messages },
        } = this.props;

        const attributesArray = Array.isArray(attributes)
            ? attributes
            : [attributes];
        const message = messages[id] ? messages[id] : id;

        const copyAroundAnchors = message.split(COPY_AROUND_ANCHORS_REGEX);
        const anchorMatches = getAllMatchedRegexGroups(ANCHORS_REGEX, message);
        const links = anchorMatches.map((inner, index) => (
            <ApplicationLinkContainer
                {...attributesArray[index]}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
            >
                {inner}
            </ApplicationLinkContainer>
        ));
        const mergedLinks = flatten(zip(copyAroundAnchors, links))
            .slice(0, -1)
            .filter(element => element !== '');

        return <span>{mergedLinks}</span>;
    }
}

FormattedMessageWithAttributes.displayName = 'FormattedMessageWithAttributes';

FormattedMessageWithAttributes.propTypes = {
    attributes: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.object),
        PropTypes.object,
    ]).isRequired,
    id: PropTypes.string.isRequired,
    intl: intlShape,
};

export default injectIntl(FormattedMessageWithAttributes);
export { FormattedMessageWithAttributes };

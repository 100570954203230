import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../../pageLayout/layout';
import ContinueButton from '../../shared/continueButton';
import Text from '../../shared/text';

const VerifyEmailAlreadyVerified = () => (
    <Layout
        heading={
            <FormattedMessage id="emailVerification.alreadyVerified.title" />
        }
    >
        <Text>
            <FormattedMessage id="emailVerification.alreadyVerified.message" />
        </Text>
        <ContinueButton />
    </Layout>
);

VerifyEmailAlreadyVerified.displayName = 'VerifyEmailAlreadyVerified';

export default VerifyEmailAlreadyVerified;

import { compact, get } from 'lodash';

const MONTHS = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
];

const IS_NUMBER_REGEX = /^\d+$/;

const isOneDigitNumber = value =>
    value.length === 1 && IS_NUMBER_REGEX.test(value);
const isTwoDigitNumber = value =>
    value.length === 2 && IS_NUMBER_REGEX.test(value);

const numberToTwoDigits = number => {
    return number < 10 ? `0${number}` : `${number}`;
};

const translatedMonths = validMonths =>
    MONTHS.map(month =>
        compact([
            get(validMonths, `${month}.full`),
            get(validMonths, `${month}.short`),
            get(validMonths, `${month}.alt.short`),
        ]).map(translation => translation.toLowerCase())
    );

const monthNameToNumber = (value, validMonths) => {
    const monthNumber = translatedMonths(validMonths).findIndex(validMonth =>
        validMonth.includes(value.toLowerCase())
    );
    return monthNumber !== -1 ? numberToTwoDigits(monthNumber + 1) : value;
};

const transformMonth = (value, validMonths) => {
    if (!value || isTwoDigitNumber(value)) {
        return value;
    }

    if (isOneDigitNumber(value)) {
        return numberToTwoDigits(value);
    }

    return validMonths ? monthNameToNumber(value, validMonths) : value;
};

const transformMonthToTwoDigitNumber = value => {
    if (isOneDigitNumber(value)) {
        return numberToTwoDigits(value);
    }

    return value;
};

const transformDay = value => {
    return isOneDigitNumber(value) ? numberToTwoDigits(value) : value;
};

export { transformDay, transformMonth, transformMonthToTwoDigitNumber };

import React from 'react';
import FieldNotifications from './fieldNotifications';

const GUARDIAN_INFORMATION_TRANSLATION_KEYS = [
    'explainer.message.over18',
    'explainer.message.residentUK',
    'explainer.message.legalGuardian',
];

const GuardianEmailFieldNotifications = () => (
    <FieldNotifications
        itemIds={GUARDIAN_INFORMATION_TRANSLATION_KEYS}
        promptId="explainer.message.guardianExplanation"
    />
);

GuardianEmailFieldNotifications.displayName = 'GuardianEmailFieldNotifications';

export default GuardianEmailFieldNotifications;

import { useCallback } from 'react';
import { useSessionStorage } from '../../hooks/useSessionStorage';

export const ONE_TIME_PERSISTENT_FORGOTTEN_PASSWORD_EMAIL =
    'oneTimePersistentForgottenPasswordEmail';

const ONE_TIME_FORGOTTEN_PASSWORD_EMAIL_HAS_BEEN_SENT =
    'oneTimeForgottenPasswordEmailHasBeenSent';

export function useForgottenPasswordEmail() {
    const {
        getSessionStorageItemAndRemove,
        sessionStorage,
    } = useSessionStorage();

    const getEmailFromSessionStorage = useCallback(
        () =>
            getSessionStorageItemAndRemove(
                ONE_TIME_PERSISTENT_FORGOTTEN_PASSWORD_EMAIL
            ),
        [getSessionStorageItemAndRemove]
    );

    const getEmailHasBeenResentFromSessionStorage = useCallback(
        () =>
            getSessionStorageItemAndRemove(
                ONE_TIME_FORGOTTEN_PASSWORD_EMAIL_HAS_BEEN_SENT
            ),
        [getSessionStorageItemAndRemove]
    );

    const setEmailToSessionStorage = useCallback(
        email => {
            if (typeof email !== 'undefined' && email !== null) {
                sessionStorage?.setItem(
                    ONE_TIME_PERSISTENT_FORGOTTEN_PASSWORD_EMAIL,
                    email
                );
            } else {
                sessionStorage?.removeItem(
                    ONE_TIME_PERSISTENT_FORGOTTEN_PASSWORD_EMAIL
                );
            }
        },
        [sessionStorage]
    );

    const setEmailHasBeenResentToSessionStorage = useCallback(() => {
        sessionStorage?.setItem(
            ONE_TIME_FORGOTTEN_PASSWORD_EMAIL_HAS_BEEN_SENT,
            true
        );
    }, [sessionStorage]);

    return {
        getEmailFromSessionStorage,
        getEmailHasBeenResentFromSessionStorage,
        setEmailToSessionStorage,
        setEmailHasBeenResentToSessionStorage,
    };
}

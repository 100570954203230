import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { displayName as displayNameValidator } from '../../../../shared/validation/validators';
import TextInput from '../textInput';
import Explainer from '../explainer';

const validator = displayName => displayNameValidator.validate([displayName]);

const DisplayNameField = props => {
    const { displayNameExplainers } = props;

    return (
        <Field
            id="displayName-input"
            name="displayName"
            autoComplete="nickname"
            required
            label={props.displayNameLabel}
            component={TextInput}
            validate={validator}
            {...props}
        >
            {displayNameExplainers && (
                <Explainer
                    explainers={props.displayNameExplainers}
                    name="displayName"
                />
            )}
        </Field>
    );
};

DisplayNameField.displayName = 'DisplayNameField';

DisplayNameField.propTypes = {
    displayNameLabel: PropTypes.node,
    displayNameExplainers: PropTypes.node,
};

DisplayNameField.defaultProps = {
    displayNameLabel: <FormattedMessage id="label.displayName" />,
};

export default DisplayNameField;

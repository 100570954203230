import { css } from 'styled-components';
import { createSize } from '../tools/sizes';
import { GROUP_3 } from '../breakpoints/breakpoints';

export const reithSans = css`
    font-family: ReithSans, 'BBC Reith Sans', Helvetica, Arial, freesans,
        sans-serif;
`;

export const reithSerif = css`
    font-family: ReithSerif, 'BBC Reith Serif', Helvetica, Arial, freesans,
        sans-serif;
`;

export const monospace = css`
    font-family: ui-monospace, SFMono-Regular, 'SF Mono', Consolas,
        'Liberation Mono', Menlo, monospace;
`;

export const headlineLarge = css`
    ${reithSans}
    font-size: ${createSize(32)};
    line-height: ${createSize(36)};

    @media (min-width: ${GROUP_3}) {
        font-size: ${createSize(44)};
        line-height: ${createSize(48)};
    }
`;

export const headlineLargeSerif = css`
    ${headlineLarge}
    ${reithSerif}
`;

export const headlineLargeMonospace = css`
    ${headlineLarge}
    ${monospace}
`;

export const headlineSmall = css`
    font-size: ${createSize(24)};
    line-height: ${createSize(28)};

    @media (min-width: ${GROUP_3}) {
        font-size: ${createSize(32)};
        line-height: ${createSize(36)};
    }
`;

export const headlineSmallSerif = css`
    ${headlineSmall}
    ${reithSerif}
`;

export const headlineSmallMonospace = css`
    ${headlineSmall}
    ${monospace}
`;

export const sectionHeading = css`
    font-size: ${createSize(18)};
    line-height: ${createSize(22)};

    @media (min-width: ${GROUP_3}) {
        font-size: ${createSize(20)};
        line-height: ${createSize(24)};
    }
`;

export const sectionHeadingSerif = css`
    ${sectionHeading}
    ${reithSerif}
`;

export const sectionHeadingMonospace = css`
    ${sectionHeading}
    ${monospace}
`;

export const indexHeadlineLarge = css`
    font-size: ${createSize(24)};
    line-height: ${createSize(28)};

    @media (min-width: ${GROUP_3}) {
        font-size: ${createSize(28)};
        line-height: ${createSize(32)};
    }
`;

export const indexHeadlineLargeSerif = css`
    ${indexHeadlineLarge}
    ${reithSerif}
`;

export const indexHeadlineLargeMonospace = css`
    ${indexHeadlineLarge}
    ${monospace}
`;

export const indexHeadlineMedium = css`
    font-size: ${createSize(20)};
    line-height: ${createSize(24)};

    @media (min-width: ${GROUP_3}) {
        font-size: ${createSize(24)};
        line-height: ${createSize(28)};
    }
`;

export const indexHeadlineMediumSerif = css`
    ${indexHeadlineMedium}
    ${reithSerif}
`;

export const indexHeadlineMediumMonospace = css`
    ${indexHeadlineMedium}
    ${monospace}
`;

export const indexHeadlineSmall = css`
    font-size: ${createSize(16)};
    line-height: ${createSize(20)};

    @media (min-width: ${GROUP_3}) {
        font-size: ${createSize(18)};
        line-height: ${createSize(22)};
    }
`;

export const indexHeadlineSmallSerif = css`
    ${indexHeadlineMedium}
    ${reithSerif}
`;

export const indexHeadlineSmallMonospace = css`
    ${indexHeadlineMedium}
    ${monospace}
`;

export const bodyCopy = css`
    font-size: ${createSize(16)};
    line-height: ${createSize(22)};
`;

export const bodyCopySerif = css`
    ${bodyCopy}
    ${reithSerif}
`;

export const bodyCopyMonospace = css`
    ${bodyCopy}
    ${monospace}
`;

export const description = css`
    font-size: ${createSize(14)};
    line-height: ${createSize(18)};
`;

export const descriptionSerif = css`
    ${description}
    ${reithSerif}
`;

export const descriptionMonospace = css`
    ${description}
    ${monospace}
`;

export const metaData = css`
    font-size: ${createSize(13)};
    line-height: ${createSize(16)};
`;

export const metaDataSerif = css`
    ${metaData}
    ${reithSerif}
`;

export const metaDataMonospace = css`
    ${metaData}
    ${monospace}
`;

export const labels = css`
    font-size: ${createSize(12)};
    line-height: ${createSize(16)};
`;

export const labelsSerif = css`
    ${labels}
    ${reithSerif}
`;

export const labelsMonospace = css`
    ${labels}
    ${monospace}
`;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Button } from '@bbc-account/id-components';
import { errorShape } from '../../propTypes';
import Layout from '../pageLayout/layout';
import ApplicationLinkContainer from '../shared/applicationLink/applicationLinkContainer';
import Form from '../shared/form';
import FormError from '../shared/formError';
import formNames from '../../../shared/data/formNames';
import PinEntryField from '../shared/inputFields/pinEntryField';
import { HELP_SIGN_IN_TV_URL } from '../../../shared/endpoints/usingTheBbc';

const TvPinEntry = ({ initialErrors = {}, initialValues, touch, valid }) => {
    const { _csrf: csrfToken } = initialValues;

    const formError =
        initialErrors.general !== true ? initialErrors.general : undefined;

    const onSubmit = submitEvent => {
        if (!valid) {
            submitEvent.preventDefault();
            touch(['pin']);
        }
    };

    return (
        <Layout heading={<FormattedMessage id="pinAndPair.code.intro" />}>
            <FormError name="general" error={formError} isAssertive />

            <Form method="post">
                <Field
                    component="input"
                    name="_csrf"
                    type="hidden"
                    value={csrfToken}
                />
                <PinEntryField
                    value={initialValues.pin}
                    initialError={initialErrors.pin}
                />

                <div className="u-margin-bottom-triple">
                    <Button
                        isFullWidth
                        isSubmit
                        id="submit-button"
                        onClick={onSubmit}
                    >
                        <FormattedMessage id="pinAndPair.signIn.button.submit.value" />
                    </Button>
                </div>

                <ApplicationLinkContainer href={HELP_SIGN_IN_TV_URL} external>
                    <FormattedMessage id="cama.title.tvHelp" />
                </ApplicationLinkContainer>
            </Form>
        </Layout>
    );
};

TvPinEntry.propTypes = {
    initialValues: PropTypes.shape({
        pin: PropTypes.string,
        _csrf: PropTypes.string,
    }),
    initialErrors: PropTypes.shape({
        pin: errorShape,
        general: errorShape,
    }),
    touch: PropTypes.func,
    valid: PropTypes.bool,
};

TvPinEntry.displayName = 'TvPinEntryForm';

const mapStateToProps = state => {
    const {
        tv = {},
        csrf: { settings: csrfToken },
    } = state;
    const initialValues = {
        ...tv.data,
        _csrf: csrfToken,
    };

    return {
        initialValues,
        initialErrors: tv.errors || {},
    };
};

export default connect(mapStateToProps)(
    reduxForm({
        form: formNames.TV_PIN_ENTRY,
    })(TvPinEntry)
);

import { Message } from '@bbc-account/id-components';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import ApplicationLinkContainer from '../../../../shared/applicationLink/applicationLinkContainer';
import userJourneys from '../../../../../../shared/data/userJourneys';
import {
    AUTH_MAGIC_LINK_AUTHENTICATE,
    AUTH_PATH,
} from '../../../../../../shared/endpoints/account';
import { useNewslettersEvents } from '../../../../../hooks/iframe/useNewslettersEvents';
import { useLocationSearchParam } from '../../../../../hooks/router';
import authPages from '../../../../../../shared/data/authPages';
import FormError from '../../../../shared/formError';
import renderQueryString from '../../../../../../shared/urls/renderQueryString';
import { useStateContext } from '../../../../../modules/stateContext';
import { IframeResizeObserver } from '../../../../../utilities/observers';
import AuthForm from '../../../form';
import {
    StyledIframeMagicLinkConfirmation,
    StyledIframeMagicLinkConfirmationFormWrapper,
    StyledIframeMagicLinkConfirmationLinks,
    StyledIframeMagicLinkConfirmationList,
    StyledIframeMagicLinkConfirmationTextWrapper,
    StyledResendMagicLink,
} from './iframeMagicLinkConfirmation.style';
import { LinkButtonForm } from '../../../../forms/linkButton';
import useMagicLinkPolling from '../../../../../hooks/auth/useMagicLinkPolling';

const linkEventTracker = {
    container: authPages.IFRAME_MAGIC_LINK_CONFIRMATION,
    result: AUTH_PATH,
    type: 'link',
};

export function IframeMagicLinkConfirmation() {
    const resendMagicLink = useLocationSearchParam('resendMagicLink');

    const userContext = useStateContext();

    const {
        signIn: {
            context: { hasPassword },
            data: { email, jti },
            initialErrors,
            initialValues: { isMinimumAge },
        },
    } = userContext;

    const confirmationError = initialErrors?.confirmation;

    const magicLinkAction = useMemo(
        () =>
            `${AUTH_MAGIC_LINK_AUTHENTICATE}${renderQueryString.call(
                userContext,
                {
                    layout: 'iframe',
                }
            )}`,
        [userContext]
    );

    const resendMagicLinkUrl = useMemo(
        () =>
            `${AUTH_PATH}${renderQueryString.call(userContext, {
                resendMagicLink: true,
            })}`,
        [userContext]
    );

    const editEmailUrl = AUTH_PATH;

    const passwordUrl = useMemo(
        () =>
            `${AUTH_PATH}${renderQueryString.call(userContext, {
                userJourney: userJourneys.PASSWORD,
            })}`,
        [userContext]
    );

    useNewslettersEvents();

    useMagicLinkPolling();

    return (
        <IframeResizeObserver>
            <StyledIframeMagicLinkConfirmation data-testid="iframe-magic-link-confirmation">
                <StyledIframeMagicLinkConfirmationTextWrapper data-testid="iframe-magic-link-confirmation-text-wrapper">
                    <div>
                        <span className="u-bold">
                            <FormattedMessage id="auth.magicLink.checkEmail.introductionShort" />
                        </span>
                        <StyledIframeMagicLinkConfirmationList data-testid="iframe-magic-link-confirmation-list">
                            <li>
                                <FormattedMessage id="auth.magicLink.checkEmail.validation2" />
                                <span>:&nbsp;</span>
                                <span className="u-bold">{email}</span>
                            </li>
                            <li>
                                <FormattedMessage id="auth.magicLink.checkEmail.comeBack2" />
                            </li>
                        </StyledIframeMagicLinkConfirmationList>
                    </div>
                    <StyledIframeMagicLinkConfirmationFormWrapper data-testid="iframe-magic-link-confirmation-form-wrapper">
                        <AuthForm
                            action={magicLinkAction}
                            buttonTranslationKey="button.continue.value"
                            data-testid="iframe-magic-link-confirmation-form"
                        >
                            <input
                                aria-hidden="true"
                                type="hidden"
                                value={email}
                                id="email-input"
                                name="email"
                            />
                            <input
                                aria-hidden="true"
                                type="hidden"
                                value={jti}
                                id="jti-input"
                                name="jti"
                            />
                            {hasPassword && (
                                <input
                                    aria-hidden="true"
                                    type="hidden"
                                    value={hasPassword}
                                    id="has-password"
                                    name="hasPassword"
                                />
                            )}
                        </AuthForm>
                        {confirmationError && (
                            <div className="sb-field">
                                <FormError
                                    error={confirmationError}
                                    name="confirmation"
                                    migrateToIdComponents
                                    isAssertive
                                />
                            </div>
                        )}
                    </StyledIframeMagicLinkConfirmationFormWrapper>
                    <StyledIframeMagicLinkConfirmationLinks data-testid="iframe-magic-link-confirmation-links">
                        <StyledResendMagicLink data-testid="iframe-magic-link-confirmation-resend-magic-link">
                            <LinkButtonForm
                                action={resendMagicLinkUrl}
                                data={{
                                    email,
                                    isMinimumAge,
                                }}
                                eventTracker={linkEventTracker}
                                method="post"
                            >
                                <FormattedMessage id="auth.magicLink.checkEmail.resendEmail" />
                            </LinkButtonForm>
                            {resendMagicLink !== null && (
                                <div className="sb-field">
                                    <Message
                                        isInfo
                                        name="magic-link-email-sent"
                                    >
                                        <FormattedMessage id="auth.magicLink.checkEmail.resend.success" />
                                    </Message>
                                </div>
                            )}
                        </StyledResendMagicLink>
                        <ApplicationLinkContainer
                            href={editEmailUrl}
                            target="_self"
                            withState
                        >
                            <FormattedMessage id="auth.magicLink.checkEmail.editEmail" />
                        </ApplicationLinkContainer>
                        {hasPassword && (
                            <LinkButtonForm
                                action={passwordUrl}
                                data={{
                                    username: email,
                                    isMinimumAge,
                                }}
                                eventTracker={linkEventTracker}
                                method="post"
                            >
                                <FormattedMessage id="auth.magicLink.checkEmail.usePassword2" />
                            </LinkButtonForm>
                        )}
                    </StyledIframeMagicLinkConfirmationLinks>
                </StyledIframeMagicLinkConfirmationTextWrapper>
            </StyledIframeMagicLinkConfirmation>
        </IframeResizeObserver>
    );
}

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useShowExUkContent } from '../../../../hooks/useShowExUkContent';
import {
    emailFirstRoutes,
    getEmailFirstRoutes,
} from '../../../../pages/email-first/emailFirstRouteNames';
import { EmailFirstForm } from '../emailFirstForm';
import {
    EMAIL_FIRST_PASSWORD_FORM_INPUT_NAME,
    EmailFirstPasswordFormInput,
} from '../inputs';
import { useEmailFirstForm } from '../useEmailFirstForm';
import { useStateContext } from '../../../../modules/stateContext';

const activeFields = [EMAIL_FIRST_PASSWORD_FORM_INPUT_NAME];

export const EmailFirstPasswordForm = () => {
    const {
        isFederated,
        featureToggles: { emailFirstFederated },
    } = useStateContext();

    const { emailFirstPaths } = getEmailFirstRoutes(isFederated);

    const {
        fieldValues,
        handleBack,
        handleSubmit,
        handleSubmitInvalid,
    } = useEmailFirstForm({
        activeFields,
        nextRoute: emailFirstFederated
            ? emailFirstPaths.details.path
            : emailFirstRoutes.details.path,
        prevRoute: emailFirstFederated
            ? emailFirstPaths.email.path
            : emailFirstRoutes.email.path,
    });

    const showExUkContent = useShowExUkContent();

    return (
        <EmailFirstForm
            activeFields={activeFields}
            heading={
                <FormattedMessage
                    id={
                        showExUkContent
                            ? 'emailFirst.passwordForm.titleExUk'
                            : 'emailFirst.passwordForm.title'
                    }
                />
            }
            onBackButtonClick={handleBack}
            onSubmit={handleSubmit}
            onSubmitInvalid={handleSubmitInvalid}
            submitButtonLabel="button.continue.value"
        >
            <EmailFirstPasswordFormInput
                defaultValue={fieldValues && fieldValues.password}
                shouldValidate
            />
        </EmailFirstForm>
    );
};

import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import { useStateContext } from '../modules/stateContext';

const consentAgeContext = createContext();

export function useConsentAge() {
    const context = useContext(consentAgeContext);

    if (!context) {
        throw new Error(
            'useConsentAge must be used within a ConsentAgeProvider'
        );
    }

    return context;
}

export function ConsentAgeProvider({ children }) {
    const { consentAge } = useStateContext();

    return (
        <consentAgeContext.Provider value={{ consentAge }}>
            {children}
        </consentAgeContext.Provider>
    );
}

ConsentAgeProvider.propTypes = {
    children: PropTypes.node,
};

function createScriptElement(src, onLoad = false) {
    const script = document.createElement('script');
    script.src = src;
    script.onload = onLoad;
    script.async = false;

    return script;
}

function insertNewScript(script) {
    const firstScript = document.getElementsByTagName('script')[0];

    firstScript.parentNode.insertBefore(script, firstScript);
}

export { createScriptElement, insertNewScript };

export default {
    DATE_OF_BIRTH_SELECT: 'dateOfBirthSelect',
    FORGOTTEN_PASSWORD: 'forgottenPassword',
    MANAGE_PERMISSIONS: 'permissionsManagement',
    REGISTER: 'register',
    REGISTER_CHILD: 'registerChild',
    REGISTER_GUARDIAN_PROSPECT: 'registerGuardianProspect',
    REGISTER_PROFILE: 'registerProfile',
    REGISTER_THANKS: 'registerThanks',
    REQUEST_PERMISSIONS_MANAGEMENT: 'requestPermissionsManagement',
    RESET_PASSWORD: 'resetPassword',
    SIGN_IN: 'signIn',
    TV_PIN_ENTRY: 'tvPinEntry',
    UPDATE: 'update',
};

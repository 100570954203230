import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { propTypes as reduxFormPropTypes } from 'redux-form';
import { Button, Heading } from '@bbc-account/id-components';
import { errorShape, locationShape } from '../../../propTypes';
import GenderGroup from '../../shared/inputFields/genderGroup';
import LocationInputGroup from '../../shared/inputFields/locationInputGroup';
import TermsAndConditions from '../termsAndConditions';
import DisplayNameField from '../../shared/inputFields/displayNameField';

const RegisterChildMoreDetails = props => {
    const {
        change,
        dispatch,
        gender,
        handleSubmit,
        initialErrors = {},
        jsEnabled,
        location,
    } = props;

    return (
        <>
            <div className="u-padding-bottom-double u-padding-top-quad">
                <Heading
                    level={2}
                    fontScale="sectionHeading"
                    fontWeight="regular"
                >
                    <FormattedMessage id="register.parentLed.title.childMoreDetails" />
                </Heading>
            </div>

            <LocationInputGroup
                change={change}
                collectPostcode={false}
                dispatch={dispatch}
                hometownLabel={<FormattedMessage id="label.childHometown" />}
                initialErrors={initialErrors}
                jsEnabled={jsEnabled}
                location={location}
            />

            <GenderGroup
                initialError={initialErrors.gender}
                gender={gender}
                genderLabel={<FormattedMessage id="label.childGender" />}
                jsEnabled={jsEnabled}
            />

            <DisplayNameField
                additionalFieldClasses="u-padding-bottom-quad"
                displayNameLabel={
                    <FormattedMessage id="label.childDisplayName" />
                }
                initialError={initialErrors.displayName}
                displayNameExplainers={
                    <FormattedMessage id="policyUplift.message.displayNameInfo" />
                }
            />

            {jsEnabled && (
                <TermsAndConditions messageId="register.parentLed.acceptTerms" />
            )}

            {jsEnabled && (
                <Button
                    isFullWidth
                    onClick={() => handleSubmit()}
                    id="continue-to-permissions-button"
                >
                    <FormattedMessage id="button.continue.value" />
                </Button>
            )}
        </>
    );
};

RegisterChildMoreDetails.displayName = 'RegisterChildMoreDetails';

RegisterChildMoreDetails.propTypes = {
    ...reduxFormPropTypes,
    actions: PropTypes.shape({
        removeInitialStateErrors: PropTypes.func.isRequired,
    }).isRequired,
    initialErrors: PropTypes.shape({
        displayName: errorShape,
        username: errorShape,
        password: errorShape,
        location: errorShape,
        general: errorShape,
        gender: errorShape,
        genderOther: errorShape,
    }),
    jsEnabled: PropTypes.bool.isRequired,
    location: locationShape.isRequired,
    gender: PropTypes.string,
};

export default RegisterChildMoreDetails;

import React, { useMemo } from 'react';
import { email } from '@bbc-account/id-validators';
import { EmailFirstTextFormInput } from '../text';

/**
 * Note: the email field is named `username` so that password managers can
 * capture the value when prompting the user to save the login details.
 *
 * This value is then saved as such in `id-formaxe`. It needs to be converted to
 * `email` before submitting to the `register` endpoint.
 */
export const EMAIL_FIRST_EMAIL_FORM_INPUT_NAME = 'username';

export const EmailFirstEmailFormInput = ({ autoComplete, defaultValue }) => {
    const validator = useMemo(
        () => ({
            validate: value => email.validate([value]),
        }),
        []
    );

    return (
      <EmailFirstTextFormInput
        autoComplete={autoComplete}
        defaultValue={defaultValue}
        id={EMAIL_FIRST_EMAIL_FORM_INPUT_NAME}
        label="label.email.title"
        name={EMAIL_FIRST_EMAIL_FORM_INPUT_NAME}
        validator={validator}
      />
    );
};

EmailFirstEmailFormInput.propTypes = {
    autoComplete: EmailFirstTextFormInput.propTypes.autoComplete,
    defaultValue: EmailFirstTextFormInput.propTypes.defaultValue,
};

EmailFirstEmailFormInput.defaultProps = {
    autoComplete: EMAIL_FIRST_EMAIL_FORM_INPUT_NAME,
};

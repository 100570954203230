import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Confirm = (props) => {
    return (
        <svg viewBox="0 0 32 32" width="1em" height="1em" {...props}>
            <path d="M32 7.2l-2.5-2.4L11 23.3h2L2.4 12.6 0 15.1l12 12.1 20-20z" />
        </svg>
    );
};

const Danger = (props) => {
    return (
        <svg viewBox="0 0 32 32" width="1em" height="1em" {...props}>
            <path d="M3.1 30.2h25.8c1.6 0 2.3-.8 2.3-1.9 0-.8-.3-1.4-1-2.9L18.6 3.8c-.8-1.5-1.5-2-2.6-2s-1.8.5-2.6 2L1.8 25.4C1 26.9.7 27.5.7 28.3c.1 1.1.8 1.9 2.4 1.9zM16 26.9c-1.6 0-2.4-1-2.4-2.1 0-1.2.8-2.1 2.4-2.1s2.4 1 2.4 2.1-.8 2.1-2.4 2.1zm-1.7-6.7-.8-10.6h5l-.8 10.6h-3.4z" />
        </svg>
    );
};

const Info = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1"
            viewBox="0 0 24 24"
            width="1em"
            height="1em"
            {...props}
        >
            <g fillRule="evenodd">
                <path d="M10.4925,8.25 L13.4925,8.25 L13.4925,5.25 L10.4925,5.25 L10.4925,8.25 Z M10.4925,18.75 L13.4925,18.75 L13.4925,9.75 L10.4925,9.75 L10.4925,18.75 Z M12,0 C5.373,0 0,5.373 0,12 C0,18.627 5.373,24 12,24 C18.627,24 24,18.627 24,12 C24,5.373 18.627,0 12,0 L12,0 Z" />
            </g>
        </svg>
    );
};

const SignIn = (props) => {
    return (
        <svg viewBox="0 0 32 32" width="1em" height="1em" {...props}>
            <path d="M16 23c-3.3 0-5.8-2.5-5.8-5.8s2.5-5.8 5.8-5.8c3.2 0 5.8 2.5 5.8 5.8S19.2 23 16 23zm15-5.7c0-8.4-6.6-15-15-15-8.5 0-15 6.6-15 15 0 5.2 2.5 9.8 6.4 12.4 1.9-2.8 5-4.6 8.6-4.6s6.7 1.8 8.6 4.6C28.5 27 31 22.5 31 17.3z" />
        </svg>
    );
};

const SignedIn = (props) => {
    return (
        <svg viewBox="0 0 32 32" width="1em" height="1em" {...props}>
            <path d="M16 1C7.7 1 1 7.4 1 16c0 8.5 6.7 15 15 15 2.8 0 5.6-.8 7.8-2.2-1.5-3.2-4.6-5-8.6-5-2.6 0-4.8.8-6.5 2.2-3.1-2.3-5.1-5.9-5.1-10C3.7 9 9.2 3.6 16 3.6S28.3 8.9 28.3 16c0 3.7-1.5 6.8-3.9 9.1l1.6 2.1c3.1-2.7 5-6.7 5-11.2 0-8.7-6.7-15-15-15zm0 20.8c3.2 0 5.8-2.5 5.8-5.8s-2.5-5.8-5.8-5.8-5.8 2.5-5.8 5.8 2.5 5.8 5.8 5.8z" />
        </svg>
    );
};

const Spinner = ({ className }) => {
    return (
        <svg
            viewBox="0 0 20 21"
            width="1em"
            height="1em"
            className={classnames('sb-icon-spinner', className)}
        >
            <path
                fill="#fff"
                d="M18.125 5.688l-1.563.937c.688 1.125 1 2.438 1 3.875 0 4.313-3.312 7.625-7.562 7.625-4.313 0-7.563-3.313-7.563-7.625 0-4.313 3.25-7.625 7.563-7.625v-1.75C4.687 1.125.625 5.188.625 10.5c0 5.313 4.063 9.375 9.375 9.375 5.313 0 9.375-4.063 9.375-9.375 0-1.813-.438-3.438-1.25-4.813z"
            />
        </svg>
    );
};

Spinner.propTypes = {
    className: PropTypes.string,
};

const Padlock = ({ className, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 19"
            className={className}
            {...props}
        >
            <path d="M11.306 8.825h2.138V5.788c0-2.7-1.688-4.782-4.388-4.782S4.613 3.088 4.613 5.788v3.037H6.75V5.788c0-1.575.9-2.644 2.306-2.644 1.407 0 2.307 1.125 2.307 2.644v3.037h-.057zm1.519 9.113c2.813 0 2.981-1.407 2.981-2.982V7.531H2.25v7.482c0 1.575.112 2.925 2.925 2.925h7.65zm-9.112-9.9l-.957.956h12.488l-.957-.956v6.975c0 1.125-.337 1.518-1.462 1.518H5.231c-1.125 0-1.462-.393-1.462-1.518V8.038h-.057z" />
        </svg>
    );
};

Padlock.propTypes = {
    className: PropTypes.string,
};

const icons = {
    confirm: Confirm,
    danger: Danger,
    info: Info,
    'sign-in': SignIn,
    'signed-in': SignedIn,
    spinner: Spinner,
    padlock: Padlock,
};

const CategoryIcon = ({ name, ...props }) => {
    const Icon = icons[name];

    return Icon ? <Icon {...props} /> : null;
};

CategoryIcon.propTypes = {
    name: PropTypes.oneOf([
        'confirm',
        'danger',
        'info',
        'sign-in',
        'signed-in',
        'spinner',
        'padlock',
    ]).isRequired,
};

export default CategoryIcon;

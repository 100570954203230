import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CallToActionButton } from '@bbc-account/id-components';
import Layout from '../../pageLayout/layout';
import Track from '../../shared/analytics/track';
import { useStateContext } from '../../../modules/stateContext';

const MagicLinkPasswordSuccess = () => {
    const {
        ptrt: { value: ptrtValue },
    } = useStateContext();

    return (
        <Layout
            pageId="signin-page"
            heading={<FormattedMessage id="cama.passwordUpdated" />}
            showServiceIdentifiers
        >
            <Track container="magic-link" ignoreViews name="go-to-ptrt">
                <CallToActionButton href={ptrtValue} isFullWidth>
                    <FormattedMessage id="auth.magicLink.signedIn.continue" />
                </CallToActionButton>
            </Track>
        </Layout>
    );
};

export default MagicLinkPasswordSuccess;

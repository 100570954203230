import React from 'react';
import { useStateContext } from '../../../modules/stateContext';
import UkMarketingPreferenceForm from '../../marketingPreference/ukMarketingPreferenceForm';
import InternationalMarketingPreferenceForm from '../../marketingPreference/internationalMarketingPreferenceForm';

const RegisterThanks = () => {
    const { isFederated, location } = useStateContext();

    if (isFederated && location?.isUk === false) {
        return (
            <InternationalMarketingPreferenceForm
                showEmailVerificationReminder
            />
        );
    }

    if (isFederated || location?.isUk !== false) {
        return <UkMarketingPreferenceForm />;
    }

    return (
        <InternationalMarketingPreferenceForm showEmailVerificationReminder />
    );
};

RegisterThanks.displayName = 'RegisterThanks';

export default RegisterThanks;

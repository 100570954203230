import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../pageLayout/layout';
import Text from '../shared/text';
import SignInContainer from './signInContainer';
import SignOutLink from './signOutLink';
import CloseButton from '../pageLayout/closeButton';
import * as config from '../../../server/config';

const ReauthenticationLayout = () => (
    <Layout
        showServiceIdentifiers
        pageId="reauthentication-page"
        heading={<FormattedMessage id="profile.reauth.title" />}
        closeButton={<CloseButton closeUrl={config.get('defaultPtrt')} />}
    >
        <Text introduction>
            <FormattedMessage id="message.password" />
        </Text>
        <SignInContainer
            emailInfo={<SignOutLink />}
            formAction="/signin/reauthenticate"
            isReauth
        />
    </Layout>
);

ReauthenticationLayout.displayName = 'ReauthenticationLayout';

export default ReauthenticationLayout;

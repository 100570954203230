import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm, propTypes as reduxFormPropTypes } from 'redux-form';
import { connect } from 'react-redux';
import { dateOfBirth as dateOfBirthValidators } from '../../../../shared/validation/validators';
import { isInvalid } from '../../../../shared/validation/validationResult';
import { DAY, MONTH, YEAR } from '../inputFields/dateOfBirthFields';
import DateOfBirthSelect from './dateOfBirthSelect';
import formNames from '../../../../shared/data/formNames';
import { errorShape } from '../../../propTypes';
import {
    transformDay,
    transformMonthToTwoDigitNumber,
} from '../../../../shared/data/transformDate';

const isClient = process.env.CLIENT;

const validate = (
    { [DAY]: day = '', [MONTH]: month = '', [YEAR]: year = '' },
    { validator = dateOfBirthValidators.validateUnderMaxAge }
) => {
    const date = {
        day: transformDay(day.trim()),
        month: transformMonthToTwoDigitNumber(month.trim()),
        year: year.trim(),
    };
    const errors = {
        DAY: false,
        MONTH: false,
        YEAR: false,
    };

    const validationErrors = validator(date);

    if (isInvalid(validationErrors.day)) {
        errors[DAY] = 'error';
    }

    if (isInvalid(validationErrors.month)) {
        errors[MONTH] = 'error';
    }

    if (isInvalid(validationErrors.year)) {
        errors[YEAR] = 'error';
    }

    if (typeof validationErrors.error !== 'undefined') {
        errors._error = validationErrors.error; // eslint-disable-line no-underscore-dangle
    }

    return errors;
};

const DateOfBirthSelectContainer = props => <DateOfBirthSelect {...props} />;

DateOfBirthSelectContainer.displayName = 'DateOfBirthSelectContainer';

DateOfBirthSelectContainer.propTypes = {
    ...reduxFormPropTypes,
    initialValues: PropTypes.shape({
        [DAY]: PropTypes.string,
        [MONTH]: PropTypes.string,
        [YEAR]: PropTypes.string,
    }),
    initialErrors: PropTypes.shape({
        dateOfBirth: errorShape,
    }),
    validator: PropTypes.func,
    withAttempts: PropTypes.bool,
};

const mapStateToProps = ({ dateOfBirthSelect, featureToggles }) => ({
    initialValues: dateOfBirthSelect.data || {},
    initialErrors: dateOfBirthSelect.initialErrors || {},
    featureToggles,
});

export { DateOfBirthSelectContainer };

export default connect(mapStateToProps)(
    reduxForm({
        validate: isClient ? validate : () => {},
        form: formNames.DATE_OF_BIRTH_SELECT,
    })(DateOfBirthSelectContainer)
);

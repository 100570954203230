import React from 'react';
import ageBrackets from '../../../shared/data/ageBrackets';
import DynamicForm from '../shared/pages/dynamicForm';
import DefaultLayout from '../shared/defaultLayout';
import Introduction from './introduction';
import { useStateContext } from '../../modules/stateContext';

const MoreDetailsForm = () => {
    const { ab: ageBracket } = useStateContext();
    const isParentLed = ageBracket && ageBracket === ageBrackets.UNDER_13;

    return (
        <DefaultLayout
            heading={
                isParentLed
                    ? 'register.parentLed.title.getGuardian'
                    : 'common.moreDetails.title.introduction'
            }
        >
            <Introduction
                message={
                    isParentLed
                        ? 'policyUplift.parentLed.message.moreDetails'
                        : 'policyUplift.message.moreInfo'
                }
            />
            <DynamicForm isParentLed={isParentLed} />
        </DefaultLayout>
    );
};

MoreDetailsForm.displayName = 'MoreDetailsForm';

export default MoreDetailsForm;

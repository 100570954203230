import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@bbc-account/id-components';
import Layout from '../../pageLayout/layout';
import Text from '../../shared/text';

const MagicLinkValidation = () => {
    return (
        <Layout
            pageId="signin-page"
            heading={<FormattedMessage id="auth.magicLink.validation.title" />}
            showServiceIdentifiers
        >
            <Heading level={2} fontScale="sectionHeading">
                <FormattedMessage id="auth.magicLink.checkEmail.whatNext" />
            </Heading>

            <ol>
                <Text listItem>
                    1.{' '}
                    <FormattedMessage id="auth.magicLink.validation.goBack" />
                </Text>
                <Text listItem>
                    2.{' '}
                    <FormattedMessage id="auth.magicLink.validation.continue" />
                </Text>
            </ol>
        </Layout>
    );
};

export default MagicLinkValidation;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CategoryIcon from '../../gel/iconography/icons';
import EventAttributesProps from '../../propTypes/eventAttributes';
import './messages.scss';

const Message = ({
    className,
    children,
    isAssertive,
    isError,
    isInfo,
    name,
    title,
    eventAttributes,
}) => {
    const classes = classNames(
        'sb-form-message',
        {
            'sb-form-message--error': isError,
            'sb-form-message--info': isInfo,
        },
        `sb-form-message--${name}`,
        className
    );

    return (
        <div
            aria-live={isAssertive ? 'assertive' : 'polite'}
            className={classes}
            id={`form-message-${name}`}
            {...eventAttributes}
        >
            {isError && (
                <CategoryIcon
                    className="sb-form-message__icon"
                    data-testid="message-danger-icon"
                    name="danger"
                />
            )}
            {isInfo && (
                <CategoryIcon
                    className="sb-form-message__icon"
                    data-testid="message-info-icon"
                    name="info"
                />
            )}
            <div className="sb-form-message__content">
                {title && (
                    <p className="sb-form-message__content__title">{title}</p>
                )}
                {children
                    ? React.Children.map(children, (child) => (
                          <p className="sb-form-message__content__text">
                              {child}
                          </p>
                      ))
                    : null}
            </div>
        </div>
    );
};

Message.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    isAssertive: PropTypes.bool,
    isError: PropTypes.bool,
    isInfo: PropTypes.bool,
    name: PropTypes.string.isRequired,
    title: PropTypes.node,
    eventAttributes: EventAttributesProps,
};

Message.defaultProps = {
    isAssertive: false,
    isError: false,
    isInfo: false,
};

Message.displayName = 'Message';

export { Message };

import React from 'react';
import PropTypes from 'prop-types';

import './accountFooter.scss';

const AccountFooter = ({ children }) => (
    <div className="sb-account-footer">{children}</div>
);

AccountFooter.propTypes = {
    /**
     The content within the footer
    */
    children: PropTypes.node.isRequired,
};

AccountFooter.displayName = 'AccountFooter';

export { AccountFooter };

import React from 'react';
import { BbcBlocks as ChameleonBlocks } from '@bbc-account/id-components';
import ApplicationLinkContainer from '../applicationLink/applicationLinkContainer';
import { useStateContext } from '../../../modules/stateContext';

const getBlocks = () => {
    const { isFederated } = useStateContext();

    if (!isFederated) {
        return (
            <ApplicationLinkContainer
                href="http://www.bbc.co.uk"
                ariaLabel="Go to the BBC Homepage"
                className="header__logo-link"
                link={false}
                external
            >
                <ChameleonBlocks size="x-small" />
            </ApplicationLinkContainer>
        );
    }

    return <ChameleonBlocks size="x-small" />;
};

const BbcBlocks = () => (
    <div className="header__logo">
        <div
            className="scaling-svg__container"
            style={{ paddingBottom: '30.125%' }}
        >
            {getBlocks()}
        </div>
    </div>
);

BbcBlocks.displayName = 'BbcBlocks';

export default BbcBlocks;

import React from 'react';
import { useFieldState, useFieldValidation } from '@bbc-account/id-formaxe';
import { FormattedMessage } from 'react-intl';
import { email } from '../../../../shared/validation/validators';
import IdentifierInput from './identifierInput';

const EmailInput = () => {
    const { eventHandlers } = useFieldState('email');
    const { validate } = useFieldValidation('email', email.validate);

    return (
        <IdentifierInput
            name="email"
            label={<FormattedMessage id="label.email.title" />}
            onBlur={event => {
                validate([event.target.value]);
                eventHandlers.onBlur(event);
            }}
        />
    );
};

export default EmailInput;

import React, { useState } from 'react';
import { useFieldState, useFieldValidation } from '@bbc-account/id-formaxe';
import { TextInput } from '@bbc-account/id-components';
import { FormattedMessage } from 'react-intl';
import { useStateContext } from '../../../modules/stateContext';
import FormError from '../../shared/formError';
import { password as validatePassword } from '../../../../shared/validation/validators';
import ShowHideToggle from '../../shared/showHideToggle';
import useJsEnabled from '../../../hooks/useJsEnabled';

const PasswordInput = () => {
    const [revealPassword, setRevealPassword] = useState(false);

    const { value, state, eventHandlers } = useFieldState('password');
    const { validate, errors } = useFieldValidation(
        'password',
        validatePassword.validate
    );

    const onShowHideToggle = event => {
        event.preventDefault();
        setRevealPassword(!revealPassword);
    };

    const {
        signIn: {
            data: { username },
        },
    } = useStateContext();

    const jsEnabled = useJsEnabled();

    const hasErrors = Array.isArray(errors) ? errors.length > 0 : !!errors;

    const showErrors = hasErrors && !state.active;

    const showHideToggleId = `link.password.${
        revealPassword ? 'hide' : 'show'
    }`;

    return (
        <>
            <input
                aria-hidden="true"
                className="u-hide"
                type="username"
                value={username}
                id="user-identifier-input"
                autoComplete="username"
                name="username"
            />

            <TextInput
                autoComplete="current-password"
                type={revealPassword ? 'text' : 'password'}
                id="password-input"
                name="password"
                value={value}
                state={state}
                label={<FormattedMessage id="label.password.title" />}
                eventHandlers={{
                    ...eventHandlers,
                    onBlur: event => {
                        validate([event.target.value]);
                        eventHandlers.onBlur(event);
                    },
                }}
                isInvalid={hasErrors}
                message={
                    showErrors ? (
                        <FormError
                            error={errors}
                            name="password"
                            migrateToIdComponents
                            isAssertive
                        />
                    ) : null
                }
                hasShowPasswordToggle={jsEnabled}
            >
                {jsEnabled && (
                    <ShowHideToggle
                        id="toggle-password-type"
                        onClick={onShowHideToggle}
                    >
                        <FormattedMessage id={showHideToggleId} />
                    </ShowHideToggle>
                )}
            </TextInput>
        </>
    );
};

export default PasswordInput;

import { add as addScripts } from './dom/scripts';
import * as config from '../../server/config';

const supportsEs2015 = () =>
    'startsWith' in String.prototype &&
    'endsWith' in String.prototype &&
    'includes' in Array.prototype &&
    'assign' in Object &&
    'keys' in Object;

const getRequiredPolyfills = () => {
    const polyfills = [];

    if (!supportsEs2015()) {
        polyfills.push(() =>
            import(
                /* webpackChunkName: "js/vendor/core-js-shim" */ 'core-js/stable'
            )
        );
    }

    if (!window.fetch) {
        polyfills.push(() =>
            import(/* webpackChunkName: "js/vendor/fetch" */ 'unfetch/polyfill')
        );
    }

    if (!window.Intl) {
        polyfills.push(() =>
            import(/* webpackChunkName: "js/vendor/intl" */ 'intl/dist/Intl')
        );
    }

    if (!window.CustomEvent) {
        polyfills.push(() =>
            import(
                /* webpackChunkName: "js/vendor/custom-event" */ 'custom-event-polyfill/polyfill'
            )
        );
    }

    return polyfills;
};

const applyPolyfills = (polyfills, onload) =>
    Promise.all(polyfills.map(polyfill => polyfill())).then(() => onload());

function loadPolyfills(onload) {
    const staticAssetsBaseUrl = config.get('staticAssetsBaseUrl');
    const release = config.get('release');

    const polyfills = getRequiredPolyfills();

    if (!window.Promise) {
        const promisePolyfillUrl = `${staticAssetsBaseUrl}/${release}/js/vendor/promise.min.js`;

        return addScripts([promisePolyfillUrl], () =>
            applyPolyfills(polyfills, onload)
        );
    }

    return applyPolyfills(polyfills, onload);
}

export { getRequiredPolyfills, loadPolyfills as default };

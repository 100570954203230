import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Layout from '../pageLayout/layout';
import Text from '../shared/text';

const TvIntroduction = ({ idctaHtml, showSignInMessage }) => (
    <Layout
        showServiceIdentifiers
        heading={<FormattedMessage id="pinAndPair.signIn.title" />}
    >
        {showSignInMessage && (
            <Text introduction>
                <FormattedMessage id="pinAndPair.signIn.message" />
            </Text>
        )}
        <div dangerouslySetInnerHTML={{ __html: idctaHtml }} />
    </Layout>
);

TvIntroduction.displayName = 'TvIntroduction';

TvIntroduction.propTypes = {
    idctaHtml: PropTypes.string.isRequired,
    showSignInMessage: PropTypes.bool.isRequired,
};

export default TvIntroduction;

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { postcode as postcodeValidator } from '../../../../shared/validation/validators';
import TextInput from '../textInput';
import Explainer from '../explainer';
import FormattedMessageWithAttributes from '../formattedMessageWithAttributes';
import { PERSONAL_DETAILS_URL } from '../../../../shared/endpoints/usingTheBbc';
import { useStateContext } from '../../../modules/stateContext';

const PostCodeField = ({ dispatch, ...restProps }) => {
    const { isFederated } = useStateContext();

    return (
        <Field
            id="postcode-input"
            name="postcode"
            additionalInputClasses="field__input--uppercase"
            autoComplete="postal-code"
            required
            label={<FormattedMessage id="label.postcode" />}
            component={TextInput}
            validate={value => postcodeValidator.validate(value)}
            {...restProps}
        >
            <Explainer
                explainers={
                    isFederated ? (
                        <FormattedMessage id="explainer.message.postcode" />
                    ) : (
                        <FormattedMessageWithAttributes
                            id="explainer.message.postcode"
                            attributes={{
                                href: PERSONAL_DETAILS_URL,
                                external: true,
                            }}
                        />
                    )
                }
                name="postcode"
            />
        </Field>
    );
};

PostCodeField.displayName = 'PostCodeField';

PostCodeField.propTypes = {
    dispatch: PropTypes.func,
    required: PropTypes.bool.isRequired,
};

export default PostCodeField;

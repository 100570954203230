import React from 'react';
import FormattedMessageWithAttributes from '../../shared/formattedMessageWithAttributes';
import Text from '../../shared/text';
import DefaultLayout from '../../shared/defaultLayout';
import { CHILD_PERMISSIONS_URL } from '../../../../shared/endpoints/usingTheBbc';

const ManageChildPermissionsInvalid = () => (
    <DefaultLayout heading="validation.goneWrongError">
        <Text>
            <FormattedMessageWithAttributes
                id="validation.permissions.tryAgain"
                attributes={{
                    href: CHILD_PERMISSIONS_URL,
                    external: true,
                }}
            />
        </Text>
    </DefaultLayout>
);

ManageChildPermissionsInvalid.displayName = 'ManageChildPermissionsInvalid';

export default ManageChildPermissionsInvalid;

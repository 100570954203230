import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import RegisterChildCredentialsContainer from './credentialsContainer';
import RegisterChildMoreDetailsContainer from './moreDetailsContainer';
import RegisterChildPermissionsContainer from './permissionsContainer';
import Form from '../../shared/form';
import {
    REGISTER_CHILD_PATH,
    REGISTER_CHILD_PERMISSIONS_PATH,
    REGISTER_CHILD_MORE_DETAILS_PATH,
} from '../../../../shared/endpoints/account';

const nextPage = (path, history) => {
    history.push(`${REGISTER_CHILD_PATH}${path}${history.location.search}`);
};

const RegisterChildWizard = props => {
    const { jsEnabled, history } = props;

    const {
        location: { pathname },
    } = history;

    return (
        <Form action={REGISTER_CHILD_PATH} method="post">
            {(pathname === REGISTER_CHILD_PATH || !jsEnabled) && (
                <RegisterChildCredentialsContainer
                    onSubmit={() => nextPage('/more-details', history)}
                    {...props}
                />
            )}

            {(pathname === REGISTER_CHILD_MORE_DETAILS_PATH || !jsEnabled) && (
                <RegisterChildMoreDetailsContainer
                    onSubmit={() => nextPage('/permissions', history)}
                    {...props}
                />
            )}

            {(pathname === REGISTER_CHILD_PERMISSIONS_PATH || !jsEnabled) && (
                <RegisterChildPermissionsContainer {...props} />
            )}
        </Form>
    );
};

RegisterChildWizard.displayName = 'RegisterChildWizard';

RegisterChildWizard.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    jsEnabled: PropTypes.bool.isRequired,
    guardianToken: PropTypes.string,
};

const mapStateToProps = state => {
    const { jsEnabled, guardianToken } = state;

    return { jsEnabled, guardianToken };
};

export { RegisterChildWizard };
export default connect(mapStateToProps)(RegisterChildWizard);

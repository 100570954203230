import React from 'react';
import RegisterThanksChildrenContainer from './thanksContainer';

const RegisterThanksChildrenLinked = props => (
    <RegisterThanksChildrenContainer parentHasAccount {...props} />
);

RegisterThanksChildrenLinked.displayName = 'RegisterThanksChildrenLinked';

export default RegisterThanksChildrenLinked;

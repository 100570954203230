import React from 'react';
import PropTypes from 'prop-types';

export function Link({ as: Element = 'a', ...props }) {
    return <Element {...props} />;
}

Link.propTypes = {
    as: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
};

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from './pageLayout/layout';
import Text from './shared/text';

const MaintenanceMode = () => {
    return (
        <Layout heading={<FormattedMessage id="maintenance.title" />}>
            <Text introduction>
                <p className="text">
                    <FormattedMessage id="maintenance.message" />
                </p>

                <p className="text">
                    <FormattedMessage id="maintenance.tryAgain" />
                </p>
            </Text>
        </Layout>
    );
};

MaintenanceMode.displayName = 'MaintenanceMode';

export default MaintenanceMode;

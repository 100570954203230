import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import {
    emailFirstRoutes,
    getEmailFirstRoutes,
} from '../../emailFirstRouteNames';
import { useStateContext } from '../../../../modules/stateContext';

export function useEmailFirstRegistrationRoutes() {
    const intl = useIntl();

    const history = useHistory();

    const { path: routePath } = useRouteMatch();

    const { pathname, search } = useLocation();

    const { isFederated, featureToggles } = useStateContext();

    const { emailFirstPaths } = getEmailFirstRoutes(isFederated);

    useEffect(() => {
        const route = Object.values(
            featureToggles.emailFirstFederated
                ? emailFirstPaths
                : emailFirstRoutes
        ).find(emailFirstRoute => pathname === emailFirstRoute.path);

        if (route) {
            document.title = intl.formatMessage({ id: route.title });
        }
    }, [routePath, pathname]);

    useEffect(() => {
        const targetPath = featureToggles.emailFirstFederated
            ? emailFirstPaths.email.path
            : emailFirstRoutes.email.path;

        if (window.location.pathname !== targetPath) {
            history.replace(`${targetPath}${search}`);
        }
    }, [routePath, history, search]);

    return {
        search,
    };
}

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../pageLayout/layout';
import CassoText from '../shared/cassoText';
import SignInContainer from './signInContainer';
import RegisterCta from './registerCta';
import TokenRefreshResult from '../tokenRefreshResult';

const SignInLayout = () => (
    <Layout
        pageId="signin-page"
        heading={<FormattedMessage id="signIn.title.introduction" />}
        secondaryContent={<RegisterCta />}
        showServiceIdentifiers
    >
        <SignInContainer formAction="/signin" />
        <CassoText additionalClasses="u-padding-top-double u-margin-bottom-none" />
        <TokenRefreshResult tokenState="invalid" />
    </Layout>
);

SignInLayout.displayName = 'SignInLayout';

export default SignInLayout;

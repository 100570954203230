import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ManageChildPermissions from '../components/permissions/manage/manageChildPermissions';
import ManageChildPermissionsDateOfBirthSelect from '../components/permissions/manage/manageChildPermissionsDateOfBirthSelect';
import ManageChildPermissionsIncorrectDateOfBirth from '../components/permissions/manage/manageChildPermissionsIncorrectDateOfBirth';
import ManageChildPermissionsInvalid from '../components/permissions/manage/manageChildPermissionsInvalid';
import ManageChildPermissionsExpired from '../components/permissions/manage/manageChildPermissionsExpired';
import ManageChildPermissionsThanks from '../components/permissions/manage/manageChildPermissionsThanks';
import ChildPermissionsManagementRequest from '../components/permissions/requestManagement/childPermissionsManagementRequest';
import RequestSentThanks from '../components/permissions/requestManagement/requestSentThanks';

function Permissions() {
    return (
        <Switch>
            <Route
                exact
                path="/permissions/children/age-confirmation"
                component={ManageChildPermissionsDateOfBirthSelect}
            />

            <Route
                exact
                path="/permissions/children/manage"
                component={ManageChildPermissions}
            />

            <Route
                exact
                path="/permissions/children/thanks"
                component={ManageChildPermissionsThanks}
            />

            <Route
                exact
                path="/permissions/children/invalid"
                component={ManageChildPermissionsInvalid}
            />

            <Route
                exact
                path="/permissions/children/expired"
                component={ManageChildPermissionsExpired}
            />

            <Route
                exact
                path="/permissions/children/incorrect-date-of-birth"
                component={ManageChildPermissionsIncorrectDateOfBirth}
            />

            <Route
                exact
                path="/permissions/children/request"
                component={ChildPermissionsManagementRequest}
            />

            <Route
                exact
                path="/permissions/children/email-sent"
                component={RequestSentThanks}
            />
        </Switch>
    );
}

export default Permissions;

import { Checklist } from '@bbc-account/id-components';
import {
    length as lengthValidators,
    string as stringValidators,
} from '@bbc-account/id-validators';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

export const EmailFirstPasswordFormInputChecklist = ({ value }) => {
    const intl = useIntl();

    const hasCharacter = useMemo(
        () => !!value && stringValidators.containsCharacters(value),
        [value]
    );

    const hasMinCharacters = useMemo(
        () => !!value && !lengthValidators.isTooShort(value),
        [value]
    );

    const hasNumericOrSymbol = useMemo(
        () => !!value && stringValidators.containsNumberOrSymbol(value),
        [value]
    );

    const items = useMemo(() => {
        const minCharactersLabel = intl.formatMessage({
            id: 'validation.hint.password.minLength',
        });

        const requireCharacterLabel = intl.formatMessage({
            id: 'validation.hint.password.letters',
        });

        const requireNumericOrSymbolLabel = intl.formatMessage({
            id: 'validation.hint.password.symbols',
        });

        return [
            {
                checked: hasMinCharacters,
                description: minCharactersLabel,
                id: 'min-characters',
                label: minCharactersLabel,
            },
            {
                checked: hasCharacter,
                description: requireCharacterLabel,
                id: 'require-character',
                label: requireCharacterLabel,
            },
            {
                checked: hasNumericOrSymbol,
                description: requireNumericOrSymbolLabel,
                id: 'require-numeric-or-symbol',
                label: requireNumericOrSymbolLabel,
            },
        ];
    }, [hasCharacter, hasMinCharacters, hasNumericOrSymbol, intl]);

    return (
      <Checklist
        items={items}
        label={intl.formatMessage({
                id: 'validation.hint.password.title',
            })}
      />
    );
};

EmailFirstPasswordFormInputChecklist.propTypes = {
    value: PropTypes.string,
};

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './callToActionButton.scss';

const CallToActionButton = ({
    ariaLabel,
    children,
    href,
    variant,
    id,
    isFullWidth,
    hideUnderlineOnHover,
    eventAttributes,
    onClick,
}) => {
    const classes = classnames('sb-link-button', `sb-link-button--${variant}`, {
        'sb-link-button--full-width': isFullWidth,
        'sb-link-button--no-hover-underline': hideUnderlineOnHover,
    });

    return (
        <a
            href={href}
            aria-label={ariaLabel}
            id={id}
            className={classes}
            onClick={onClick}
            {...eventAttributes}
        >
            {children}
        </a>
    );
};

CallToActionButton.propTypes = {
    /**
     This attribute defines a string value that labels an interactive element.
    */
    ariaLabel: PropTypes.string,
    /**
     The content of the link
    */
    children: PropTypes.node.isRequired,
    /**
     The URL that the hyperlink points to.
    */
    href: PropTypes.string,
    /**
    Sets the link html ID to the provided string. If not provided, then it has a default value of undefined.
    */
    id: PropTypes.string,
    /**
     Determines whether the width of the link in terms of styling is full width
    */
    isFullWidth: PropTypes.bool,
    /**
     This determines whether there should be an underline when hovering/focussing on the link. This should only be used when the link is part of a flow which includes similarly styled buttons, so for consistency there shouldn't be an underline on hover.
    */
    hideUnderlineOnHover: PropTypes.bool,
    /**
     Style of button needed. Values are primary, secondary. Defaults to primary.
    */
    variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    /**
     These are the attributes that are sent to our analytics when the link is clicked
    */
    eventAttributes: PropTypes.objectOf(PropTypes.string),
};

CallToActionButton.defaultProps = {
    variant: 'primary',
    eventAttributes: {},
    isFullWidth: false,
    href: '#',
    hideUnderlineOnHover: false,
};

CallToActionButton.displayName = 'Call To Action - Button';

export { CallToActionButton };

import { createScriptElement, insertNewScript } from './clientSideScript';

const isLastScript = (index, count) => index === count - 1;

function add(urls, onload) {
    const count = urls.length;

    if (count === 0) {
        onload();
    } else {
        urls.forEach((url, index) => {
            const scriptOnload = isLastScript(index, count)
                ? onload
                : undefined;

            const script = createScriptElement(url, scriptOnload);
            insertNewScript(script);
        });
    }
}

export { add };

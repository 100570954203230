import React from 'react';
import { Switch } from 'react-router-dom';

import RouteWithLayout from './helpers/routeWithLayout';
import HelpLayout from '../components/help/layout';
import HelpProblemsWithMyEmailAddress from '../components/help/pages/problemsWithMyEmailAddress';
import HelpWhatEmailsAndNewsLetters from '../components/help/pages/whatEmailsAndNewsLetters';
import HelpPasswordsMobileDevices from '../components/help/pages/passwordsMobileDevices';
import HelpFindOutMoreDob from '../components/help/pages/findOutMoreDob';
import HelpProblemsWithMyPassword from '../components/help/pages/problemsWithMyPassword';
import HelpProblemsWithMyUsername from '../components/help/pages/problemsWithMyUsername';
import HelpPrivacy from '../components/help/pages/privacy';
import HelpTermsOfUse from '../components/help/pages/termsOfUse';

function HelpFederated() {
    return (
        <Switch>
            <RouteWithLayout
                exact
                path="/help/federated/what-emails-and-newsletters-will-the-bbc-send-me"
                layout={HelpLayout}
                component={HelpWhatEmailsAndNewsLetters}
            />

            <RouteWithLayout
                exact
                path="/help/federated/passwords-mobile-devices"
                layout={HelpLayout}
                component={HelpPasswordsMobileDevices}
            />

            <RouteWithLayout
                exact
                path="/help/federated/why-do-i-have-to-provide-my-dob-when-i-register"
                layout={HelpLayout}
                component={HelpFindOutMoreDob}
            />

            <RouteWithLayout
                exact
                path="/help/federated/im-having-problems-with-my-email-address"
                layout={HelpLayout}
                component={HelpProblemsWithMyEmailAddress}
            />

            <RouteWithLayout
                exact
                path="/help/federated/im-having-problems-with-my-password"
                layout={HelpLayout}
                component={HelpProblemsWithMyPassword}
            />

            <RouteWithLayout
                exact
                path="/help/federated/im-having-problems-with-my-username"
                layout={HelpLayout}
                component={HelpProblemsWithMyUsername}
            />

            <RouteWithLayout
                exact
                path="/help/federated/privacy"
                layout={props => <HelpLayout {...props} iFramedContent />}
                component={HelpPrivacy}
            />

            <RouteWithLayout
                exact
                path="/help/federated/terms-of-use"
                layout={props => <HelpLayout {...props} iFramedContent />}
                component={HelpTermsOfUse}
            />
        </Switch>
    );
}

export default HelpFederated;

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import Explainer from '../explainer';
import FormattedMessageWithAttributes from '../formattedMessageWithAttributes';
import SelectInput from '../selectInput';
import TextInput from '../textInput';
import { PERSONAL_DETAILS_URL } from '../../../../shared/endpoints/usingTheBbc';
import {
    gender as genderValidator,
    genderOther as genderOtherValidator,
} from '../../../../shared/validation/validators';
import { useStateContext } from '../../../modules/stateContext';

const genderOptions = [
    {
        disabled: true,
        nameId: 'select.prompt',
        value: '',
    },
    {
        nameId: 'gender.female',
        value: 'female',
    },
    {
        nameId: 'gender.male',
        value: 'male',
    },
    {
        nameId: 'gender.nonBinary',
        value: 'non-binary',
    },
    {
        nameId: 'gender.other',
        value: 'other',
    },
    {
        nameId: 'gender.preferNotToSay',
        value: 'prefer not to say',
    },
];

const validateGenderSelect = gender => {
    const validationResult = genderValidator.validate([gender]);
    return validationResult === true ? undefined : validationResult;
};

const GenderGroup = props => {
    const { isFederated } = useStateContext();
    const { dispatch, ...restProps } = props;

    const {
        additionalClasses,
        gender,
        genderLabel,
        removeInitialStateErrors,
        jsEnabled,
    } = restProps;

    const isGenderOther = gender === 'other';
    const onGenderChange = () =>
        typeof removeInitialStateErrors === 'function' &&
        removeInitialStateErrors('gender');

    return (
        <div className={additionalClasses} id="gender-group">
            <Field
                id="gender-input"
                name="gender"
                additionalFieldClasses="u-margin-bottom"
                required
                label={genderLabel}
                component={SelectInput}
                options={genderOptions.map(({ value, nameId, disabled }) => (
                    <FormattedMessage id={nameId} key={value}>
                        {name => (
                            <option value={value} disabled={disabled}>
                                {name}
                            </option>
                        )}
                    </FormattedMessage>
                ))}
                onChange={onGenderChange}
                showInitialError={!isGenderOther}
                validate={validateGenderSelect}
                {...restProps}
            />

            {isGenderOther || !jsEnabled ? (
                <Field
                    id="genderOther-input"
                    name="genderOther"
                    required={false}
                    // eslint-disable-next-line max-len
                    additionalFieldClasses="u-hide-pre-component-mount u-animation-fade-in u-margin-bottom"
                    additionalInputClasses="field__input--with-note"
                    label={<FormattedMessage id="label.prompt.title" />}
                    component={TextInput}
                    validate={[genderOtherValidator.validate]}
                    inputChildren={
                        <div className="field__note">
                            <FormattedMessage id="field.nonMandatory" />
                        </div>
                    }
                    {...restProps}
                />
            ) : null}

            <Explainer
                explainers={
                    isFederated ? (
                        <FormattedMessage id="explainer.message.gender" />
                    ) : (
                        <FormattedMessageWithAttributes
                            id="explainer.message.gender"
                            attributes={{
                                href: PERSONAL_DETAILS_URL,
                                external: true,
                            }}
                        />
                    )
                }
                name="genderOther"
            />
        </div>
    );
};

GenderGroup.displayName = 'GenderField';

GenderGroup.propTypes = {
    additionalClasses: PropTypes.string,
    dispatch: PropTypes.func,
    gender: PropTypes.string,
    genderLabel: PropTypes.node,
    removeInitialStateErrors: PropTypes.func,
    jsEnabled: PropTypes.bool,
};

GenderGroup.defaultProps = {
    genderLabel: <FormattedMessage id="label.gender.title" />,
    jsEnabled: false,
};

export default GenderGroup;

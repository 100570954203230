import { createContext, useContext } from 'react';

export const pageBeforeUnloadEventContext = createContext(null);

export const pageBeforeUnloadEventDispatchContext = createContext(null);

export const usePageBeforeUnloadEventContext = () =>
    useContext(pageBeforeUnloadEventContext);

export const usePageBeforeUnloadEventDispatchContext = () =>
    useContext(pageBeforeUnloadEventDispatchContext);

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EDIT_EMAIL_URL } from '../../../../shared/endpoints/profile';
import Layout from '../../pageLayout/layout';
import ApplicationLinkContainer from '../../shared/applicationLink/applicationLinkContainer';
import ContinueButton from '../../shared/continueButton';
import Text from '../../shared/text';

const VerifyEmailThanks = ({ email }) => {
    const changeEmailLink = (
        <ApplicationLinkContainer
            external
            withState
            href={EDIT_EMAIL_URL}
            id="change-email"
        >
            <FormattedMessage id="emailVerification.thanks.changeEmail" />
        </ApplicationLinkContainer>
    );
    return (
        <Layout
            heading={<FormattedMessage id="emailVerification.thanks.title" />}
        >
            <Text heading>
                {email} {changeEmailLink}.
            </Text>
            <Text>
                <FormattedMessage id="emailVerification.thanks.pleaseCheck" />
            </Text>
            <ContinueButton />
        </Layout>
    );
};

VerifyEmailThanks.displayName = 'VerifyEmailThanks';

VerifyEmailThanks.propTypes = {
    email: PropTypes.string.isRequired,
};

export default VerifyEmailThanks;

import React, { useMemo } from 'react';
import { postcode } from '@bbc-account/id-validators';
import { Explainer } from '../../../../shared/explainer';
import { EmailFirstTextFormInput } from '../text';
import FormattedMessageWithAttributes from '../../../../shared/formattedMessageWithAttributes';
import { PERSONAL_DETAILS_URL } from '../../../../../../shared/endpoints/usingTheBbc';
import { checkPostcode } from '../../../../../services/checkPostcode';
import { StyledEmailFirstTextFormInputWrapper } from './emailFirstPostCodeFormInput.style';

export const EMAIL_FIRST_POSTCODE_FORM_INPUT_NAME = 'postcode';

export const EmailFirstPostCodeFormInput = ({ autoComplete, defaultValue }) => {
    const validator = useMemo(
        () => ({
            validate: async value => {
                const validationResult = postcode.validate(value);

                if (validationResult === true) {
                    const { success, message } = await checkPostcode(value);

                    if (success) {
                        return true;
                    }

                    return message;
                }

                return validationResult;
            },
        }),
        []
    );

    return (
      <div>
        <StyledEmailFirstTextFormInputWrapper>
          <EmailFirstTextFormInput
            autoComplete={autoComplete}
            defaultValue={defaultValue}
            id={EMAIL_FIRST_POSTCODE_FORM_INPUT_NAME}
            label="label.postcode"
            name={EMAIL_FIRST_POSTCODE_FORM_INPUT_NAME}
            validator={validator}
          />
        </StyledEmailFirstTextFormInputWrapper>
        <Explainer
          explainers={(
            <FormattedMessageWithAttributes
              id="explainer.message.postcode"
              attributes={{
                            external: true,
                            href: PERSONAL_DETAILS_URL,
                        }}
            />
                  )}
          jsEnabled
          name={EMAIL_FIRST_POSTCODE_FORM_INPUT_NAME}
        />
      </div>
    );
};

EmailFirstPostCodeFormInput.propTypes = {
    autoComplete: EmailFirstTextFormInput.propTypes.autoComplete,
    defaultValue: EmailFirstTextFormInput.propTypes.defaultValue,
};

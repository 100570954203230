import styled from 'styled-components';

export const StyledEmailFirstTextFormInput = styled.div`
    &.field--with-warning
        > .sb-field--invalid
        > .sb-field__input-container
        > .sb-field__decoration:before {
        border-color: inherit;
    }

    &.field {
        margin-bottom: 0;
    }
`;

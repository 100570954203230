import pathToTitleLabelMap from '../../../config/pathToTitleMap.json';
import pathToTitleLabelMapExUkOverride from '../../../config/pathToTitleMapExUk.json';

const FALLBACK_TITLE = 'BBC';

const getTitleMap = useExUkOverride => {
    if (useExUkOverride) {
        return {
            ...pathToTitleLabelMap,
            ...pathToTitleLabelMapExUkOverride,
        };
    }

    return pathToTitleLabelMap;
};

export default function getTitle(
    currentPath,
    translations,
    useExUkOverride,
    ipBlockingFeature
) {
    const titleMap = getTitleMap(useExUkOverride);

    const currentLabel = titleMap[currentPath.toLowerCase()];

    if (!currentLabel) {
        return FALLBACK_TITLE;
    }

    if (
        !ipBlockingFeature &&
        currentPath.toLowerCase() === '/auth/magic-link/validate'
    ) {
        return translations['auth.magicLink.validation.title'];
    }

    return translations[currentLabel] || FALLBACK_TITLE;
}

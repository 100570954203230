import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../../pageLayout/layout';
import ApplicationLinkContainer from '../../shared/applicationLink/applicationLinkContainer';
import { BASE_URL } from '../../../../shared/endpoints/session';
import { useStateContext } from '../../../modules/stateContext';
import { useShowExUkContent } from '../../../hooks/useShowExUkContent';

const getSessionUrl = ({ value }) => {
    const url = new URL(BASE_URL);

    if (value) {
        url.searchParams.set('ptrt', value);
    }

    url.searchParams.set('switchTld', '1');
    return url.toString();
};

const VerifyPasswordThanks = () => {
    const { ptrt, featureToggles } = useStateContext();
    const sessionUrl = getSessionUrl(ptrt);
    const showExUkContent = useShowExUkContent();

    return (
        <Layout
            heading={
                <FormattedMessage
                    id={
                        showExUkContent && featureToggles?.createPasswordContent
                            ? 'cama.passwordUpdatedExUK'
                            : 'cama.passwordUpdated'
                    }
                />
            }
        >
            <div className="u-margin-top--triple">
                <ApplicationLinkContainer
                    button
                    primary
                    fullWidth
                    href={sessionUrl}
                    external
                >
                    <FormattedMessage id="signIn.button.submit.value" />
                </ApplicationLinkContainer>
            </div>
        </Layout>
    );
};

export default VerifyPasswordThanks;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../pageLayout/layout';
import Text from '../shared/text';
import { useShouldRenderOrRedirect } from './useShouldRenderOrRedirect';

const AccountLockedEmail = () => {
    useShouldRenderOrRedirect();

    return (
        <Layout
            heading={<FormattedMessage id="accountLocked.email.title" />}
            showServiceIdentifiers
        >
            <Text>
                <FormattedMessage id="accountLocked.email.intro" />
            </Text>
            <Text heading>
                <FormattedMessage id="accountLocked.email.subtitle" />
            </Text>
            <Text>
                <FormattedMessage id="accountLocked.email.explainer" />
            </Text>
        </Layout>
    );
};

AccountLockedEmail.displayName = 'AccountLockedEmail';

export default AccountLockedEmail;

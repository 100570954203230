import React from 'react';
import { Switch, Route } from 'react-router-dom';

import TvLandingPage from '../components/tv/index';
import TvThanks from '../components/tv/thanks';

function Account() {
    return (
        <Switch>
            <Route exact path="/tvcode" component={TvLandingPage} />
            <Route path="/tvcode/thanks" component={TvThanks} />
        </Switch>
    );
}

export default Account;

import React from 'react';
import PermissionsManagementContainer from './permissionsManagementContainer';
import DefaultLayout from '../../shared/defaultLayout';

const ManageChildPermissions = () => (
    <DefaultLayout heading="permissions.title.childPermissions">
        <PermissionsManagementContainer />
    </DefaultLayout>
);

export default ManageChildPermissions;

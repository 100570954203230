import React from 'react';
import { useStateContext } from '../../modules/stateContext';
import * as Pages from './pages';
import authPages from '../../../shared/data/authPages';

const AuthLayout = () => {
    const {
        signIn: { page = authPages.IDENTIFIER_FORM } = {},
    } = useStateContext();

    const Page = Pages[page];

    return <Page />;
};

AuthLayout.displayName = 'AuthLayout';

export default AuthLayout;

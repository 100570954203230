import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

const RadioButton = ({
    'aria-label': ariaLabel,
    className,
    input,
    id,
    label,
    required = true,
}) => {
    const classes = classnames('button, button--radio', className);

    return (
        <div className={`button button--radio ${classes}`}>
            <label className="button__radio-label" htmlFor={id}>
                <input
                    className="button__input"
                    type="radio"
                    id={id}
                    required={required}
                    aria-label={ariaLabel}
                    {...input}
                />
                <div className="button__text-wrapper">
                    <div className="button__text">{label}</div>
                </div>
            </label>
        </div>
    );
};

RadioButton.displayName = 'RadioButton';

RadioButton.propTypes = {
    'aria-label': PropTypes.string.isRequired,
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
    }).isRequired,
    label: PropTypes.node,
    required: PropTypes.bool,
};

export default RadioButton;

import React from 'react';
import { useLocation } from 'react-router';
import OutsideUkBlocked from './outsideUkBlocked';
import U13Blocked from './u13Blocked';

const RegisterSorry = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const reason = params.get('reason');

    if (reason === 'u13') {
        return <U13Blocked />;
    }

    return <OutsideUkBlocked />;
};

export default RegisterSorry;

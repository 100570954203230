import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './heading.scss';

const Heading = ({ children, level, fontScale, fontWeight }) => {
    const classes = classnames(
        `sb-heading--${fontScale}`,
        `sb-heading--${fontWeight}`
    );

    return React.createElement(`h${level}`, { className: classes }, children);
};

Heading.propTypes = {
    /**
     It is the most prominent text of the form and renders in an H1.
    */
    children: PropTypes.node.isRequired,
    /**
     The heading level. Number between 1 and 6.
    */
    level: PropTypes.PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
    /**
     Font size based on GEL font scales.
    */
    fontScale: PropTypes.PropTypes.oneOf([
        'headlineLarge',
        'headlineSmall',
        'sectionHeading',
        'indexHeadlineLarge',
        'indexHeadlineMedium',
        'indexHeadlineSmall',
        'bodyCopy',
        'description',
        'metaData',
        'labels',
    ]).isRequired,
    /**
     Font size based on GEL font weights.
    */
    fontWeight: PropTypes.PropTypes.oneOf([
        'light',
        'regular',
        'medium',
        'bold',
        'extraBold',
    ]),
};

Heading.defaultProps = {
    fontWeight: 'bold',
};
Heading.displayName = 'Heading';

export { Heading };

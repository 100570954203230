import { useFieldState } from '@bbc-account/id-formaxe';
import { password } from '@bbc-account/id-validators';
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { usePasswordInputBreachWarning } from '../../../hooks';
import { EmailFirstTextFormInput } from '../text';
import { EmailFirstPasswordFormInputChecklist } from './checklist';
import { EmailFirstPasswordFormInputContextProvider } from './context';
import { EmailFirstTextFormInputShowHideToggle } from './toggle';
import { StyledEmailFirstPasswordFormInputWrapper } from './emailFirstPasswordFormInput.style';

export const EMAIL_FIRST_PASSWORD_FORM_INPUT_NAME = 'password';

const TYPES = {
    PASSWORD: 'password',
    TEXT: 'text',
};

export const EmailFirstPasswordFormInput = ({
    autoComplete,
    className,
    defaultValue,
}) => {
    const [type, setType] = useState(TYPES.PASSWORD);

    const { value } = useFieldState(EMAIL_FIRST_PASSWORD_FORM_INPUT_NAME);

    const {
        inputValueChangeHandler,
        warning,
    } = usePasswordInputBreachWarning();

    const validator = useMemo(
        () => ({
            validate: async passwordValue => password.validate([passwordValue]),
        }),
        []
    );

    const handleToggleShowPassword = useCallback(showPassword => {
        setType(showPassword ? TYPES.TEXT : TYPES.PASSWORD);
    }, []);

    return (
      <EmailFirstPasswordFormInputContextProvider>
        {({ isValidChangeHandler }) => (
          <StyledEmailFirstPasswordFormInputWrapper className={className}>
            <EmailFirstPasswordFormInputChecklist value={value} />
            <EmailFirstTextFormInput
              autoComplete={autoComplete}
              defaultValue={defaultValue}
              id={EMAIL_FIRST_PASSWORD_FORM_INPUT_NAME}
              label="label.password.title"
              name={EMAIL_FIRST_PASSWORD_FORM_INPUT_NAME}
              onBlur={inputValueChangeHandler}
              onIsValidChange={isValidChangeHandler}
              type={type}
              validator={validator}
              warning={warning}
            >
              <EmailFirstTextFormInputShowHideToggle
                onToggleShowPassword={handleToggleShowPassword}
              />
            </EmailFirstTextFormInput>
          </StyledEmailFirstPasswordFormInputWrapper>
            )}
      </EmailFirstPasswordFormInputContextProvider>
    );
};

EmailFirstPasswordFormInput.propTypes = {
    autoComplete: EmailFirstTextFormInput.propTypes.autoComplete,
    className: PropTypes.string,
    defaultValue: EmailFirstTextFormInput.propTypes.defaultValue,
};

EmailFirstPasswordFormInput.defaultProps = {
    autoComplete: 'current-password',
};

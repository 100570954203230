import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import * as reduxFormActionCreators from '../../../modules/reduxForm';
import formNames from '../../../../shared/data/formNames';

import PermissionsManagement from './permissionsManagement';

const mapStateToProps = state => {
    const { jsEnabled, permissionsManagement = {} } = state;
    return {
        initialErrors: permissionsManagement.initialErrors || {},
        initialValues: permissionsManagement.data || {},
        jsEnabled,
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            ...reduxFormActionCreators,
        },
        dispatch
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: formNames.MANAGE_PERMISSIONS,
        destroyOnUnmount: false,
    })(PermissionsManagement)
);

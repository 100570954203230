import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import withEventTracking from '../withEventTracking';

const Message = ({ className, children, isError, isWarning, name, title }) => {
    const classes = classnames(
        'form-message',
        {
            'form-message--error': isError,
            'form-message--info form-message--with-icon form-message--info-icon': isWarning,
        },
        `form-message--${name}`,
        className
    );

    return (
        <div
            aria-live="assertive"
            className={classes}
            id={`form-message-${name}`}
        >
            {title ? <p className="form-message__title">{title}</p> : null}
            {children
                ? React.Children.map(children, child => (
                      <p className="form-message__text">{child}</p>
                  ))
                : null}
        </div>
    );
};

Message.displayName = 'Message';

Message.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    isError: PropTypes.bool,
    isWarning: PropTypes.bool,
    name: PropTypes.string.isRequired,
    title: PropTypes.node,
};

Message.defaultProps = {
    title: '',
    isError: false,
};

export default withEventTracking(Message);

import { styled } from 'styled-components';

const gap = '0.5rem';

export const StyledChecklistHeader = styled.div`
    margin-bottom: ${gap};
`;

export const StyledChecklistList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: ${gap};
`;

import PropTypes from 'prop-types';
import React from 'react';
import Layout from '../pageLayout/layout';
import { RegisterLayoutHeading } from './heading';

const RegisterLayout = ({ children }) => (
    <Layout
        heading={<RegisterLayoutHeading />}
        showLoadingSpinner
        showServiceIdentifiers
    >
        {children}
    </Layout>
);

RegisterLayout.displayName = 'RegisterLayout';

RegisterLayout.propTypes = {
    children: PropTypes.node.isRequired,
};
export default RegisterLayout;

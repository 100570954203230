import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { StyledBackButton } from './backButton.style';
import { BackButtonIcon } from './backButtonIcon';

export const BackButton = ({ 'data-testid': dataTestId, onClick }) => {
    return (
        <StyledBackButton
            data-testid={dataTestId}
            onClick={onClick}
            type="button"
        >
            <BackButtonIcon /> <FormattedMessage id="button.back.value" />
        </StyledBackButton>
    );
};

BackButton.propTypes = {
    'data-testid': PropTypes.string,
    onClick: PropTypes.func,
};

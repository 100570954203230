import { useState, useEffect, useCallback } from 'react';
import { useStateContext } from '../../modules/stateContext';
import {
    postErrorToParent,
    postMessageToParent,
} from '../../utilities/iframe/postMessage';
import authPages from '../../../shared/data/authPages';

const isDataValid = data =>
    typeof data === 'object' && !!data.type && !!data.message;

export const useIframe = ({ parentOrigin } = {}) => {
    const { featureToggles } = useStateContext();

    const [receivedMessages, setReceivedMessages] = useState([]);

    const sendEventToParent = useCallback(
        (payload, { targetOrigin, isError } = {}) => {
            const origin = targetOrigin || parentOrigin || '*';

            if (isError) {
                postErrorToParent(payload, {
                    featureToggles,
                    targetOrigin: origin,
                });
            } else {
                postMessageToParent(payload, {
                    featureToggles,
                    targetOrigin: origin,
                });
            }
        },
        [parentOrigin]
    );

    const sendErrorEventToParent = useCallback(
        (error, { targetOrigin } = {}) => {
            sendEventToParent(error, { targetOrigin, isError: true });
        },
        [sendEventToParent]
    );

    const sendResizeEventToParent = useCallback(
        ({ height, width }) => {
            sendEventToParent({
                message: 'event.resize',
                meta: {
                    height,
                    width,
                },
            });
        },
        [sendEventToParent]
    );

    const sendPageViewEventToParent = useCallback(
        page => {
            if (featureToggles.legacyIframeEvents) {
                if (page === authPages.IFRAME_PASSWORD_FORM) {
                    sendEventToParent('mode.password');
                }
            } else {
                sendEventToParent(`event.view.${page}`);
            }
        },
        [sendEventToParent]
    );

    useEffect(() => {
        let isMounted = true;

        const handleMessage = event => {
            if (!!parentOrigin && event.origin !== parentOrigin) {
                return;
            }

            if (isMounted && isDataValid(event.data)) {
                setReceivedMessages(current => [...current, event.data]);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            isMounted = false;
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return {
        sendErrorEventToParent,
        sendEventToParent,
        sendResizeEventToParent,
        sendPageViewEventToParent,
        receivedMessages,
        setReceivedMessages,
    };
};

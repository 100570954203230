import { useEffect } from 'react';
import { useStateContext } from '../../modules/stateContext';
import { useIframe } from './useIframe';

const identifierNotFoundErrorMessage = 'validation.signin.notFound.identifier';
const identifierWithoutRegLink =
    'validation.signin.notFound.identifierWithoutRegLink';
const identifierNotRecognised = 'validation.email.notRecognised';

export const identifierErrorMessages = [
    identifierNotFoundErrorMessage,
    identifierNotRecognised,
    identifierWithoutRegLink,
];

export function useNewslettersEvents() {
    const {
        signIn: { page, initialErrors },
        featureToggles,
    } = useStateContext();

    const { sendErrorEventToParent, sendPageViewEventToParent } = useIframe();

    const generalMessage =
        typeof initialErrors.general === 'object'
            ? initialErrors.general?.id
            : initialErrors.general;

    const isIdentityError = identifierErrorMessages.includes(generalMessage);

    useEffect(() => {
        sendPageViewEventToParent(page);
    }, [sendPageViewEventToParent, page]);

    useEffect(() => {
        if (isIdentityError) {
            sendErrorEventToParent(
                featureToggles.legacyIframeEvents
                    ? identifierNotFoundErrorMessage
                    : generalMessage
            );
        }
    }, [
        sendErrorEventToParent,
        isIdentityError,
        generalMessage,
        featureToggles.legacyIframeEvents,
    ]);
}

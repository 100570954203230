import * as config from '../../server/config';

const getAutoCompleteLanguage = (givenLanguage = 'en') => {
    const acceptedLanguages = ['en', 'ga', 'cy', 'gd'];
    const autoCompleteLanguage = givenLanguage.substring(0, 2);

    return acceptedLanguages.includes(autoCompleteLanguage)
        ? autoCompleteLanguage
        : 'en';
};

function renderAutocomplete(
    container,
    hometownInput,
    language,
    updateFieldsCallback,
    loaded
) {
    // eslint-disable-next-line no-unused-vars
    ((require, define) => {
        if (require) {
            require.config({
                paths: {
                    'id-location-autocomplete': `${config.get(
                        'staticAssetsAutocompleteBaseUrl'
                    )}/${config.get(
                        'staticAssetsAutocompleteVersion'
                    )}/id-location-autocomplete`,
                },
            });

            // eslint-disable-next-line import/no-dynamic-require
            require(['id-location-autocomplete'], ({
                AutoComplete,
                Translations,
                Api,
            }) => {
                const apiOptions = {
                    env: 'live',
                    protocol: 'https',
                    filter: 'domestic',
                    order: 'importance',
                    'place-types': ['settlement'],
                    language: getAutoCompleteLanguage(language),
                    rows: 6,
                    stack: 'aws',
                    api_key: config.get(
                        'bbcApiLocationServicesOpenAutocompleteKey'
                    ),
                };

                const autoComplete = new AutoComplete({
                    api: new Api(apiOptions),
                    translations: new Translations(),
                    element: hometownInput,
                    container,
                });

                autoComplete.on('location', location => {
                    let locationName = location[0].name;

                    if (location[0].container) {
                        locationName += `, ${location[0].container}`;
                    }

                    updateFieldsCallback(locationName, location[0].id);
                });

                loaded(autoComplete);
            });
        }
    })(window.require, window.define);
}

export { getAutoCompleteLanguage, renderAutocomplete };

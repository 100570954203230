import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import FormattedMessageWithAttributes from '../shared/formattedMessageWithAttributes';
import {
    HELP_FEDERATED_TERMS_AND_CONDITIONS_PATH,
    HELP_FEDERATED_PRIVACY_PATH,
} from '../../../shared/endpoints/account';
import {
    TERMS_AND_CONDITIONS_URL,
    PRIVACY_POLICY_INTERNATIONAL_URL,
    PRIVACY_POLICY_URL,
} from '../../../shared/endpoints/usingTheBbc';
import { buildHrefFromFederatedStatus } from '../../../shared/utilities/url';
import { useStateContext } from '../../modules/stateContext';

const TermsAndConditions = ({
    attributes: attributesProp,
    className,
    isInternational,
    messageId,
    linkTarget,
}) => {
    const routerLocation = useLocation();
    const { isFederated } = useStateContext();

    const privacyUrl = isInternational
        ? PRIVACY_POLICY_INTERNATIONAL_URL
        : PRIVACY_POLICY_URL;

    const defaultAttributes = useMemo(() => {
        const termsAndConditionsAttributes = {
            href: buildHrefFromFederatedStatus({
                isFederated,
                federatedUrl: HELP_FEDERATED_TERMS_AND_CONDITIONS_PATH,
                nonFederatedUrl: TERMS_AND_CONDITIONS_URL,
                routerLocation,
            }),
            external: true,
            target: linkTarget,
        };

        const privacyAttributes = {
            href: buildHrefFromFederatedStatus({
                isFederated,
                federatedUrl: HELP_FEDERATED_PRIVACY_PATH,
                nonFederatedUrl: privacyUrl,
                routerLocation,
            }),
            external: true,
            target: linkTarget,
        };

        return [termsAndConditionsAttributes, privacyAttributes];
    }, [isFederated, routerLocation, linkTarget]);

    const attributes = useMemo(
        () =>
            typeof attributesProp !== 'undefined'
                ? [...defaultAttributes, ...attributesProp]
                : defaultAttributes,
        [defaultAttributes, attributesProp]
    );

    return (
        <p
            className={classNames(
                className,
                'button__label button__label--fine-print'
            )}
        >
            <FormattedMessageWithAttributes
                id={messageId}
                attributes={attributes}
            />
        </p>
    );
};

TermsAndConditions.propTypes = {
    attributes: PropTypes.arrayOf(
        PropTypes.shape({
            href: PropTypes.string,
            external: PropTypes.bool,
            target: PropTypes.string,
        })
    ),
    className: PropTypes.string,
    isInternational: PropTypes.bool,
    messageId: PropTypes.string.isRequired,
    linkTarget: PropTypes.string,
};

export default TermsAndConditions;

import React from 'react';
import PropTypes from 'prop-types';
import { StyledHint } from './hint.style';

export const Hint = ({ children }) => {
    return <StyledHint>{children}</StyledHint>;
};

Hint.propTypes = {
    children: PropTypes.node.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ApplicationLinkContainer from '../shared/applicationLink/applicationLinkContainer';
import connectToState from '../connectToState';

const BackButton = ({ className, ptrt, backButtonTranslationKey }) => (
    <ApplicationLinkContainer
        bypassChameleon
        className={className}
        href={ptrt.value}
        external
    >
        <FormattedMessage id={backButtonTranslationKey} />
    </ApplicationLinkContainer>
);

BackButton.propTypes = {
    className: PropTypes.string,
    ptrt: PropTypes.shape({
        value: PropTypes.string.isRequired,
    }).isRequired,
    backButtonTranslationKey: PropTypes.string.isRequired,
};

BackButton.displayName = 'BackButton';

export default connectToState(BackButton, ['ptrt', 'backButtonTranslationKey']);

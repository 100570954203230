import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './buttonInline.scss';

const ButtonInline = ({
    ariaLabel,
    children,
    'data-testid': dataTestId,
    isDisabled,
    isSubmit,
    variant,
    id,
    onClick,
}) => {
    const classes = classNames('sb-button-inline', {
        'sb-button-inline--secondary': variant === 'secondary',
    });

    return (
        <button
            data-testid={dataTestId}
            type={isSubmit ? 'submit' : 'button'}
            disabled={isDisabled}
            aria-label={ariaLabel}
            role="link"
            className={classes}
            id={id}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

ButtonInline.propTypes = {
    /**
     This attribute defines a string value that labels an interactive element.
    */
    ariaLabel: PropTypes.string,
    /**
     The content of the button
    */
    children: PropTypes.node.isRequired,
    /**
     * Test identifier
     */
    'data-testid': PropTypes.string,
    /**
     Determines whether the button is disabled (if it can be selected and clicked on). See the accessibility notes below if using on form submit buttons
     */
    isDisabled: PropTypes.bool,
    /**
     Style of button needed. Determination of when to use each variant is described below.
     */
    variant: PropTypes.oneOf(['primary', 'secondary']),
    /**
    Sets the button to the type "submit" if set to true, and "button" if false.
     */
    isSubmit: PropTypes.bool,
    /**
    Sets the button html ID to the provided string.
    */
    id: PropTypes.string,
    /**
    Sets an onClick handler function on the button.
    */
    onClick: PropTypes.func,
};

ButtonInline.defaultProps = {
    isDisabled: false,
    isSubmit: false,
    variant: 'primary',
};

ButtonInline.displayName = 'ButtonInline';

export { ButtonInline };

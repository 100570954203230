import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@bbc-account/id-components';
import ContinueButton from '../../shared/continueButton';
import Avatar from '../../shared/avatar';

const ThanksDirectFlow = ({ username, displayName }) => {
    const usernameOrDefaultMessage = username || (
        <FormattedMessage id="validation.informationSent" />
    );

    return (
        <Fragment>
            <div className="u-margin-top-triple u-margin-bottom-triple">
                <Avatar text={displayName} />

                <Heading level={2} fontScale="headlineSmall">
                    <FormattedMessage
                        id="parentLed.title.accountCreatedWithDisplayName"
                        values={{ displayName }}
                    />
                </Heading>
            </div>

            <div className="form-message form-message--info u-margin-bottom-double">
                <div className="form-message__section form-message__section--first form-message__section--divider">
                    <p className="form-message__text">
                        <FormattedMessage id="parentLed.message.childUsername" />
                        {` ${usernameOrDefaultMessage}`}
                    </p>
                </div>

                <div className="form-message__section">
                    <p className="form-message__text">
                        <FormattedMessage id="parentLed.message.childPassword" />
                    </p>
                </div>
            </div>

            <div className="form-message form-message--info-clear form-message--info-icon form-message--with-icon u-margin-none">
                <p className="form-message__title">
                    <FormattedMessage id="parentLed.message.childCredentials" />
                </p>
            </div>

            <ContinueButton
                className="u-margin-top-double"
                translationId="button.continueWithDisplayName.value"
                translationValues={{ displayName }}
            />
        </Fragment>
    );
};

ThanksDirectFlow.displayName = 'ThanksDirectFlow';

ThanksDirectFlow.propTypes = {
    username: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
};

export default ThanksDirectFlow;

import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../pageLayout/layout';
import { EmailFirstPageLayoutCloseButton } from './buttons';

export const EmailFirstPageLayout = ({
    children,
    'data-testid': dataTestId,
    heading,
    hideBackButton,
    onBackButtonClick,
    pageId,
    secondaryContent,
}) => {
    return (
        <Layout
            closeButton={<EmailFirstPageLayoutCloseButton />}
            data-testid={dataTestId}
            heading={heading}
            linkTarget="_blank"
            onBackButtonClick={onBackButtonClick}
            pageId={pageId}
            secondaryContent={secondaryContent}
            showBackButton={!hideBackButton}
            showServiceIdentifiers
        >
            {children}
        </Layout>
    );
};

EmailFirstPageLayout.propTypes = {
    children: PropTypes.node,
    'data-testid': PropTypes.string,
    heading: Layout.propTypes.heading,
    hideBackButton: PropTypes.bool,
    onBackButtonClick: PropTypes.func,
    pageId: Layout.propTypes.pageId,
    secondaryContent: Layout.propTypes.secondaryContent,
};

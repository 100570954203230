import React from 'react';
import { Switch, Route } from 'react-router-dom';
import MoreDetailsForm from '../components/moreDetails/moreDetailsForm';
import MoreDetailsNextSteps from '../components/moreDetails/nextStepsContainer';
import MoreDetailsLinkedAccount from '../components/moreDetails/linkedAccount';
import MoreDetailsLinkedAccountNextSteps from '../components/moreDetails/linkedAccountNextSteps';
import TvLandingPage from '../components/tv/index';
import TvThanks from '../components/tv/thanks';
import MaintenanceMode from '../components/maintenanceMode';

function Account() {
    return (
        <Switch>
            <Route exact path="/account/tv" component={TvLandingPage} />
            <Route path="/account/tv/thanks" component={TvThanks} />

            <Route
                exact
                path="/account/error/maintenance"
                component={MaintenanceMode}
            />

            <Route
                exact
                path="/account/more-details"
                component={MoreDetailsForm}
            />

            <Route
                exact
                path="/account/more-details/next-steps"
                component={MoreDetailsNextSteps}
            />

            <Route
                exact
                path="/account/more-details/linked-account"
                component={MoreDetailsLinkedAccount}
            />

            <Route
                exact
                path="/account/more-details/linked-account/next-steps"
                component={MoreDetailsLinkedAccountNextSteps}
            />
        </Switch>
    );
}

export default Account;

import React from 'react';
import { TextInput } from '@bbc-account/id-components';
import { useFieldState, useFieldValidation } from '@bbc-account/id-formaxe';
import { password as validatePassword } from '../../../../../shared/validation/validators';
import { useStateContext } from '../../../../modules/stateContext';
import FormError from '../../../shared/formError';
import { AuthSubmitButton } from '../../buttons';
import { IFRAME_IDENTIFIER, IFRAME_PASSWORD } from '../../constants';
import { AuthIframeInputWithButton } from './authIframeInputWithButton';

export const AuthIframePasswordInput = () => {
    const userContext = useStateContext();

    const {
        signIn: { data, initialErrors },
    } = userContext;

    const username = data[IFRAME_IDENTIFIER.FIELD_NAME];

    const { value, state, eventHandlers } = useFieldState(
        IFRAME_PASSWORD.FIELD_NAME
    );

    const { validate, errors } = useFieldValidation(
        IFRAME_PASSWORD.FIELD_NAME,
        validatePassword.validate
    );

    const generalError = initialErrors?.general;

    const error = errors || generalError;

    const hasError = Array.isArray(error) ? error.length > 0 : !!error;

    const showError = hasError && !state.active;

    return (
        <>
            <input
                aria-hidden="true"
                className="u-hide"
                type={IFRAME_IDENTIFIER.TYPE}
                value={username}
                id={IFRAME_IDENTIFIER.ID}
                autoComplete="username"
                name={IFRAME_IDENTIFIER.FIELD_NAME}
                readOnly
            />
            <AuthIframeInputWithButton
                button={
                    <AuthSubmitButton isFullWidth variant="focus-outlined">
                        Sign in
                    </AuthSubmitButton>
                }
                input={
                    <TextInput
                        minimal
                        label={IFRAME_PASSWORD.LABEL}
                        id={IFRAME_PASSWORD.ID}
                        name={IFRAME_PASSWORD.FIELD_NAME}
                        type={IFRAME_PASSWORD.TYPE}
                        value={value || ''}
                        autoComplete="current-password"
                        eventHandlers={{
                            ...eventHandlers,
                            onBlur: event => {
                                validate([event.target.value]);
                                eventHandlers.onBlur(event);
                            },
                        }}
                        isInvalid={hasError}
                        message={
                            showError ? (
                                <FormError
                                    data-testid="password-input-form-error"
                                    error={error}
                                    name={IFRAME_PASSWORD.FIELD_NAME}
                                    migrateToIdComponents
                                />
                            ) : null
                        }
                    />
                }
            />
        </>
    );
};

AuthIframePasswordInput.displayName = 'AuthIframePasswordInput';

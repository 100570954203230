import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

class ScrollToTop extends React.Component {
    componentDidUpdate(previousProps) {
        if (this.props.location !== previousProps.location) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}

ScrollToTop.displayName = 'ScrollToTop';

ScrollToTop.propTypes = {
    children: PropTypes.node.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
};

export { ScrollToTop };

export default withRouter(ScrollToTop);

import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import authPages from '../../../../../../../shared/data/authPages';
import { FORGOTTEN_PASSWORD_PATH } from '../../../../../../../shared/endpoints/account';
import { useStateContext } from '../../../../../../modules/stateContext';
import { ONE_TIME_PERSISTENT_USER_IDENTIFIER_KEY } from '../../../../../forms';
import Track from '../../../../../shared/analytics/track';
import { IFRAME_IDENTIFIER } from '../../../../constants';

function getForgotPasswordUrl(ptrt, url) {
    return ptrt ? `${url}?ptrt=${encodeURIComponent(ptrt)}` : url;
}

export function IframePasswordResetPasswordLink({ label, url }) {
    const userContext = useStateContext();

    const {
        signIn: { data },
        ptrt: { value: ptrpValue },
    } = userContext;

    const username = data[IFRAME_IDENTIFIER.FIELD_NAME];

    const href = useMemo(() => getForgotPasswordUrl(ptrpValue, url), [
        ptrpValue,
        url,
    ]);

    const handleResetPasswordButtonClick = useCallback(() => {
        sessionStorage?.setItem(
            ONE_TIME_PERSISTENT_USER_IDENTIFIER_KEY,
            username
        );
    }, [username]);

    return (
        <Track
            container={authPages.IFRAME_PASSWORD_FORM}
            ignoreViews
            result={FORGOTTEN_PASSWORD_PATH}
            type="link"
        >
            <a
                aria-label={label}
                data-testid="password-input-additional-info-link"
                href={href}
                onClick={handleResetPasswordButtonClick}
                target="_top"
            >
                {label}
            </a>
        </Track>
    );
}

IframePasswordResetPasswordLink.propTypes = {
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

import PropTypes from 'prop-types';
import React from 'react';
import {
    StyledAuthIframeInputWithButton,
    StyledAuthIframeInputWithButtonButtonWrapper,
    StyledAuthIframeInputWithButtonInputWrapper,
    StyledAuthIframeInputWithButtonWrapper,
} from './authIframeInputWithButton.style';

export const AuthIframeInputWithButton = ({ button, children, input }) => (
    <StyledAuthIframeInputWithButton>
        <StyledAuthIframeInputWithButtonWrapper>
            <StyledAuthIframeInputWithButtonInputWrapper>
                {input}
            </StyledAuthIframeInputWithButtonInputWrapper>
            <StyledAuthIframeInputWithButtonButtonWrapper>
                {button}
            </StyledAuthIframeInputWithButtonButtonWrapper>
        </StyledAuthIframeInputWithButtonWrapper>
        {children}
    </StyledAuthIframeInputWithButton>
);

AuthIframeInputWithButton.propTypes = {
    button: PropTypes.node,
    children: PropTypes.node,
    input: PropTypes.node,
};

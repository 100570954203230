import { useEffect } from 'react';
import { isIframeLayout } from '../../../shared/utilities/isIframeLayout';
import { useStateContext } from '../../modules/stateContext';

export const useShouldRenderOrRedirect = () => {
    const { currentUrl, layout } = useStateContext();

    useEffect(() => {
        if (isIframeLayout(layout)) {
            const url = new URL(currentUrl);

            url.searchParams.delete('layout');
            url.searchParams.delete('ptrt');

            url.searchParams.append('ptrt', 'https://bbc.com/newsletters');

            const redirectUrl = url.toString();
            window.top.location.href = redirectUrl;
        }
    }, [layout]);
};

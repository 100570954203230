import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CallToActionButton, Message } from '@bbc-account/id-components';
import Layout from '../../pageLayout/layout';
import Track from '../../shared/analytics/track';
import { useStateContext } from '../../../modules/stateContext';
import ApplicationLinkContainer from '../../shared/applicationLink/applicationLinkContainer';
import FormattedMessageWithAttributes from '../../shared/formattedMessageWithAttributes';

const MagicLinkAuthenticateSuccess = () => {
    const {
        ptrt: { value: ptrtValue },
        featureToggles: { magicLinksSuccessMessage },
    } = useStateContext();

    return (
        <Layout
            pageId="signin-page"
            heading={<FormattedMessage id="auth.magicLink.signedIn.title" />}
            showServiceIdentifiers
        >
            <Track container="magic-link" ignoreViews name="go-to-ptrt">
                <CallToActionButton href={ptrtValue} isFullWidth>
                    <FormattedMessage id="auth.magicLink.signedIn.continue" />
                </CallToActionButton>
            </Track>
            {magicLinksSuccessMessage ? (
                <Message
                    isInfo
                    name="magic-link-signed-in"
                    className="u-margin-top-double u-align-items-center"
                >
                    <FormattedMessageWithAttributes
                        id="auth.magicLink.signedIn.resetPassword"
                        attributes={{
                            href: '/auth/magic-link/password',
                            withState: true,
                        }}
                    />
                </Message>
            ) : (
                <p className="button__label">
                    <ApplicationLinkContainer
                        href="/auth/magic-link/password"
                        withState
                    >
                        <FormattedMessage id="auth.magicLink.signedIn.reset" />
                    </ApplicationLinkContainer>
                </p>
            )}
        </Layout>
    );
};

export default MagicLinkAuthenticateSuccess;

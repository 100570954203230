import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { propTypes as reduxFormPropTypes } from 'redux-form';
import { Button, Heading } from '@bbc-account/id-components';
import { dateShape, errorShape } from '../../../propTypes';
import RegisterAgeHiddenInputs from '../registerAgeHiddenInputs';
import TermsAndConditions from '../termsAndConditions';
import Text from '../../shared/text';
import formNames from '../../../../shared/data/formNames';
import PermissionsCheckboxFields from '../../shared/inputFields/permissionCheckboxFields';

const createOnPermissionFieldChange = actions => (event, value) => {
    const field = event.target.id;
    actions.changePermissions({
        form: formNames.REGISTER_CHILD,
        field,
        newValue: value,
    });
};

const renderAllowlistedWithGenderOtherHiddenFields = formData =>
    [
        'displayName',
        'gender',
        'genderOther',
        'geoname',
        'hometown',
        'location',
        'password',
        'username',
    ].map(fieldName => (
        <input
            type="hidden"
            name={fieldName}
            value={formData[fieldName]}
            key={`hidden-${fieldName}-field`}
        />
    ));

const RegisterChildPermissions = props => {
    const { actions, initialValues, jsEnabled, previousFormData } = props;

    return (
        <>
            {jsEnabled &&
                renderAllowlistedWithGenderOtherHiddenFields(previousFormData)}

            <RegisterAgeHiddenInputs
                isMinimumAge={false}
                dateOfBirth={initialValues.dateOfBirth}
            />

            <div className="u-padding-bottom-double u-padding-top-quad">
                <Heading
                    level={2}
                    fontScale="sectionHeading"
                    fontWeight="regular"
                >
                    <FormattedMessage id="register.parentLed.title.childPermissions" />
                </Heading>
            </div>

            <Text introduction>
                <FormattedMessage id="register.parentLed.voting" />
            </Text>

            <PermissionsCheckboxFields
                onChange={createOnPermissionFieldChange(actions)}
                jsEnabled={jsEnabled}
            />

            {!jsEnabled && (
                <TermsAndConditions messageId="register.parentLed.acceptTerms" />
            )}

            <Button isFullWidth isSubmit id="submit-child-registration-button">
                <FormattedMessage id="button.submit.value" />
            </Button>
        </>
    );
};

RegisterChildPermissions.displayName = 'RegisterChildPermissions';

RegisterChildPermissions.propTypes = {
    ...reduxFormPropTypes,
    actions: PropTypes.shape({
        changePermissions: PropTypes.func.isRequired,
    }).isRequired,
    initialValues: PropTypes.shape({
        location: PropTypes.string.isRequired,
        dateOfBirth: dateShape,
    }).isRequired,
    initialErrors: PropTypes.shape({
        displayName: errorShape,
        username: errorShape,
        password: errorShape,
        location: errorShape,
        general: errorShape,
        gender: errorShape,
        genderOther: errorShape,
    }),
    jsEnabled: PropTypes.bool.isRequired,
};

export default RegisterChildPermissions;

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import * as initialFormState from '../../../modules/initialFormState';
import { asyncUsernameValidate as asyncValidate } from '../../../services/reduxAsyncValidators';
import connectToState from '../../connectToState';

import RegisterChildCredentials from './credentials';

const FORM_NAME = 'registerChild';

const mapStateToProps = state => {
    const {
        jsEnabled,
        register,
        location: { country },
    } = state;

    return {
        jsEnabled,
        initialErrors: register.initialErrors || {},
        initialValues: {
            ...register.data,
            location: country,
        },
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(initialFormState, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    connectToState(
        reduxForm({
            form: FORM_NAME,
            destroyOnUnmount: false,
            forceUnregisterOnUnmount: true,
            asyncValidate,
            asyncBlurFields: ['username'],
        })(RegisterChildCredentials)
    )
);

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CheckboxField from '../checkboxField';
import ApplicationLinkContainer from '../../applicationLink/applicationLinkContainer';
import { PERMISSION_ALLOW_NOTIFICATIONS_FIELD } from '../../../../../shared/data/permissionFieldConstants';
import { NOTIFICATIONS_URL } from '../../../../../shared/endpoints/usingTheBbc';

const getNotificationExplainerKeys = () => [
    <FormattedMessage id="childPermission.explainer.notifications" />,
    <ApplicationLinkContainer href={NOTIFICATIONS_URL} external>
        <FormattedMessage id="link.aboutNotifications" />
    </ApplicationLinkContainer>,
];

const NotificationPermission = ({ jsEnabled, onChange }) => (
    <CheckboxField
        additionalClasses="u-border-bottom-zero u-margin-bottom-triple"
        ariaLabel="childPermission.label.allowNotifications"
        explainers={getNotificationExplainerKeys()}
        jsEnabled={jsEnabled}
        label="childPermission.label.allowNotifications"
        name={PERMISSION_ALLOW_NOTIFICATIONS_FIELD}
        onChange={onChange}
    />
);

NotificationPermission.propTypes = {
    onChange: PropTypes.func.isRequired,
    jsEnabled: PropTypes.bool.isRequired,
};

export default NotificationPermission;

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Heading } from '@bbc-account/id-components';
import { FormattedMessage } from 'react-intl';
import Text from '../../shared/text';
import {
    FORGOTTEN_PASSWORD_FEDERATED_PATH,
    FORGOTTEN_PASSWORD_PATH,
    REGISTER_PATH,
    REGISTER_FEDERATED_PATH,
} from '../../../../shared/endpoints/account';
import { useStateContext } from '../../../modules/stateContext';
import FormattedMessageWithAttributes from '../../shared/formattedMessageWithAttributes';

const HelpProblemsWithMyPassword = () => {
    const { isFederated } = useStateContext();
    const forgottenPasswordPath = isFederated
        ? FORGOTTEN_PASSWORD_FEDERATED_PATH
        : FORGOTTEN_PASSWORD_PATH;

    const registerPath = isFederated ? REGISTER_FEDERATED_PATH : REGISTER_PATH;

    return (
        <Fragment>
            <div className="u-padding-bottom-quad">
                <Heading level={1} fontScale="headlineSmall">
                    <FormattedMessage id="help.problemsWithMyPassword.title" />
                </Heading>
            </div>
            <Text>
                <FormattedMessage id="help.problemsWithMyPassword.remember" />
            </Text>

            <ul>
                <Text listItem className="list-item list-item--bullet">
                    <FormattedMessage id="validation.hint.password.minLength" />
                </Text>

                <Text listItem className="list-item list-item--bullet">
                    <FormattedMessage id="validation.hint.password.letters" />
                </Text>

                <Text listItem className="list-item list-item--bullet">
                    <FormattedMessage id="validation.hint.password.symbols" />
                </Text>
            </ul>

            <Text>
                <FormattedMessage id="help.problemsWithMyPassword.rememberPassword" />
            </Text>

            <div className="u-padding-bottom-double u-padding-bottom--tablet">
                <Heading level={2} fontScale="sectionHeading">
                    <FormattedMessage id="help.problemsWithMyPassword.cantRememberPassword" />
                </Heading>
            </div>

            <Text>
                <FormattedMessage id="help.problemsWithMyPassword.reset" />
            </Text>

            <ul>
                <Text listItem className="list-item list-item--bullet">
                    <FormattedMessageWithAttributes
                        id="help.problemsWithMyPassword.goToForgottenPasswordPage"
                        attributes={{
                            href: forgottenPasswordPath,
                            primary: true,
                        }}
                    />
                </Text>
                <Text listItem className="list-item list-item--bullet">
                    <FormattedMessage id="help.problemsWithMyPassword.whenRequested" />
                </Text>
                <Text listItem className="list-item list-item--bullet">
                    <FormattedMessage id="help.problemsWithMyPassword.resetPassword" />
                </Text>
                <Text listItem className="list-item list-item--bullet">
                    <FormattedMessage id="help.problemsWithMyPassword.clickLink" />
                </Text>
            </ul>

            <div className="u-padding-bottom-double u-padding-bottom--tablet">
                <Heading level={2} fontScale="sectionHeading">
                    <FormattedMessage id="common.button.underAgeBoundary.uk.value" />
                    ?
                </Heading>
            </div>

            <Text>
                <FormattedMessageWithAttributes
                    id="help.problemsWithMyPassword.register"
                    attributes={{
                        href: registerPath,
                        primary: true,
                    }}
                />
            </Text>
        </Fragment>
    );
};

HelpProblemsWithMyPassword.propTypes = {
    forgottenPasswordPath: PropTypes.string.isRequired,
};

export default HelpProblemsWithMyPassword;

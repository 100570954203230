import React from 'react';
import { useIntl } from 'react-intl';

export const TERMS_TITLE = 'help.pageTitle.termsOfUse';

const HelpTermsOfUse = () => {
    const intl = useIntl();

    return (
        <iframe
            title={intl.formatMessage({ id: TERMS_TITLE })}
            src="https://www.bbc.com/usingthebbc/terms-of-use?nolinks=yes"
        />
    );
};

export default HelpTermsOfUse;

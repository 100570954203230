import { ButtonInline } from '@bbc-account/id-components';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Track from '../../shared/analytics/track';

function renderConditionalTracking({ children, eventTracker }) {
    const button = (
        <ButtonInline isSubmit variant="secondary">
            {children}
        </ButtonInline>
    );

    if (eventTracker) {
        const { ignoreViews } = eventTracker;

        return (
            <Track
                {...eventTracker}
                ignoreViews={
                    typeof ignoreViews === 'undefined' ? true : ignoreViews
                }
            >
                {button}
            </Track>
        );
    }

    return button;
}

export function LinkButtonForm({
    action,
    children,
    data,
    eventTracker,
    method,
}) {
    const hiddenFields = useMemo(
        () =>
            data &&
            Object.entries(data).map(([name, value]) => (
                <input
                    key={`${name}${value}`}
                    name={name}
                    value={value}
                    type="hidden"
                />
            )),
        [data]
    );

    return (
        <form action={action} method={method}>
            {hiddenFields}
            {renderConditionalTracking({
                children,
                eventTracker,
            })}
        </form>
    );
}

LinkButtonForm.propTypes = {
    action: PropTypes.string,
    children: PropTypes.node,
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object,
    eventTracker: PropTypes.shape({
        container: PropTypes.string,
        name: PropTypes.string,
        result: PropTypes.string,
        type: PropTypes.string,
        source: PropTypes.string,
        ignoreViews: PropTypes.bool,
        metadata: PropTypes.objectOf(PropTypes.string),
    }),
    method: PropTypes.oneOf(['dialog', 'get', 'post']),
};

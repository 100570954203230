import React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactRouterPropTypes from 'react-router-prop-types';
import Layout from '../pageLayout/layout';
import Form from '../shared/form';
import { parseQueryParams } from '../../../shared/utilities/url';
import OneTapSignIn from './oneTapSignIn';
import { useStateContext } from '../../modules/stateContext';
import {
    SIGN_IN_FEDERATED_OTSI_PATH,
    SIGN_IN_OTSI_PATH,
} from '../../../shared/endpoints/account';

const SignIn = ({ location: { search: queryString } }) => {
    const { clientId } = parseQueryParams(queryString);

    const { isFederated } = useStateContext();

    const action = isFederated
        ? SIGN_IN_FEDERATED_OTSI_PATH
        : SIGN_IN_OTSI_PATH;

    return (
        <Layout
            heading={<FormattedMessage id="signIn.title.signInToYourAccount" />}
        >
            <Form method="post" action={action}>
                <OneTapSignIn clientId={clientId} />
            </Form>
        </Layout>
    );
};

SignIn.propTypes = {
    location: ReactRouterPropTypes.location.isRequired,
};

export default SignIn;

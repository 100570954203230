import styled from 'styled-components';

export const StyledListIcon = styled.span`
    background: var(--core-account);
    color: var(--full-white);
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    display: inline-flex;
    padding: var(--spacing-3);
    width: var(--spacing-5);
    height: var(--spacing-5);
    margin-right: var(--spacing-2);
`;

export const StyledInstructionList = styled.ol`
    li {
        margin-top: var(--spacing-5);
    }
`;

import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@bbc-account/id-components';
import { FormattedMessage } from 'react-intl';
import Text from '../../shared/text';
import ApplicationLinkContainer from '../../shared/applicationLink/applicationLinkContainer';
import Form from '../../shared/form';

const VerifyEmailSorryOver13 = ({ email, signOutSignInUrl }) => {
    const signOutLink = (
        <ApplicationLinkContainer external withState href={signOutSignInUrl}>
            <FormattedMessage id="common.link.signOut" />
        </ApplicationLinkContainer>
    );

    return (
        <Form method="post" action="/signin/verify/email/thanks">
            <Button isSubmit id="resend-email-verification">
                <FormattedMessage id="cama.button.resendEmail" />
            </Button>
            <Text introduction className="u-margin-top-double">
                <FormattedMessage id="emailVerification.sorryOver13.sendNewEmail" />
                <br />
                {email}
            </Text>
            <Text>
                <FormattedMessage id="emailVerification.sorryOver13.notYou" />{' '}
                {signOutLink}
            </Text>
        </Form>
    );
};

VerifyEmailSorryOver13.displayName = 'VerifyEmailSorryOver13';

VerifyEmailSorryOver13.propTypes = {
    email: PropTypes.string.isRequired,
    signOutSignInUrl: PropTypes.string.isRequired,
};

export default VerifyEmailSorryOver13;

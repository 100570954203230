import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import { HELP_FEDERATED_WHAT_EMAILS_AND_NEWSLETTERS } from '../../../shared/endpoints/account';
import { HELP_NEWSLETTER_URL } from '../../../shared/endpoints/usingTheBbc';
import { buildHrefFromFederatedStatus } from '../../../shared/utilities/url';
import { useStateContext } from '../../modules/stateContext';
import Explainer from '../shared/explainer';
import FormattedMessageWithAttributes from '../shared/formattedMessageWithAttributes';
import { isNorthAmericanCountry } from '../../../shared/i18n/region';

function MarketingPreferenceExplainer() {
    const {
        isFederated,
        location: { country },
    } = useStateContext();

    const routerLocation = useLocation();

    const helpNewsletterAttributes = {
        href: buildHrefFromFederatedStatus({
            isFederated,
            federatedUrl: HELP_FEDERATED_WHAT_EMAILS_AND_NEWSLETTERS,
            nonFederatedUrl: HELP_NEWSLETTER_URL,
            routerLocation,
        }),
        external: true,
    };

    const explainerKey = isNorthAmericanCountry(country)
        ? 'register.explainer.northAmerica.newsletters'
        : 'register.explainer.newsletters';

    return (
        <Explainer
            additionalClasses="u-margin-top"
            cta={<FormattedMessage id="register.link.newsletters" />}
            explainers={
                <FormattedMessageWithAttributes
                    id={explainerKey}
                    attributes={helpNewsletterAttributes}
                />
            }
            name="marketing-preference"
        />
    );
}

MarketingPreferenceExplainer.displayName = 'MarketingPreferenceExplainer';

export default MarketingPreferenceExplainer;

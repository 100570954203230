import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import {
    isValid,
    isInvalid,
} from '../../../shared/validation/validationResult';
import FormError from './formError';
import { errorShape } from '../../propTypes';

function getFieldValidityClasses(error, showInitialError) {
    if (!error || !showInitialError) {
        return '';
    }

    return error === true
        ? ' field--valid field--with-icon'
        : ' field--invalid';
}

function getErrorMessage(name, error, showInitialError) {
    return isValid(error) ||
        typeof error === 'undefined' ||
        !showInitialError ? null : (
        <FormError error={error} name={name} />
    );
}

const SelectInput = props => {
    const {
        additionalFieldClasses,
        additionalInputClasses,
        children,
        id,
        inputChildren,
        label,
        meta: { error, pristine, touched },
        input: { name },
        required,
        options,
        initialError,
        showInitialError,
    } = props;

    const shownError =
        pristine &&
        typeof initialError !== 'undefined' &&
        isInvalid(initialError)
            ? initialError
            : touched && error;

    const fieldClasses = classnames(
        'field',
        getFieldValidityClasses(shownError, showInitialError),
        additionalFieldClasses
    );
    const inputClasses = classnames(
        'field__input field__input--dropdown',
        additionalInputClasses
    );

    const ariaRequired = required ? 'true' : undefined;

    return (
        <div id={name} className={fieldClasses}>
            <div className="field__input-container">
                <select
                    className={inputClasses}
                    id={id}
                    required={required}
                    aria-required={ariaRequired}
                    autoCapitalize="none"
                    autoCorrect="off"
                    {...props.input}
                >
                    {options}
                </select>
                <label
                    className="field__label"
                    id={`${name}-label`}
                    htmlFor={id}
                >
                    {label}
                </label>

                <div className="field__decoration" />
                {inputChildren}
            </div>
            {getErrorMessage(name, shownError, showInitialError)}
            {children}
        </div>
    );
};

SelectInput.displayName = 'SelectInput';

SelectInput.propTypes = {
    additionalFieldClasses: PropTypes.string,
    additionalInputClasses: PropTypes.string,
    children: PropTypes.node,
    id: PropTypes.string,
    initialError: errorShape,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
    }).isRequired,
    inputChildren: PropTypes.node,
    label: PropTypes.node,
    required: PropTypes.bool,
    meta: PropTypes.shape({
        error: errorShape,
        pristine: PropTypes.bool.isRequired,
        touched: PropTypes.bool.isRequired,
    }).isRequired,
    options: PropTypes.node.isRequired,
    showInitialError: PropTypes.bool,
};

SelectInput.defaultProps = {
    additionalFieldClasses: '',
    additionalInputClasses: '',
    required: true,
    showInitialError: true,
};

export default SelectInput;

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import ApplicationLinkContainer from './applicationLink/applicationLinkContainer';
import Text from './text';
import { HELP_FEDERATED_PASSWORD_MOBILE_DEVICES_PATH } from '../../../shared/endpoints/account';
import { PASSWORD_STORAGE_URL } from '../../../shared/endpoints/usingTheBbc';
import { buildHrefFromFederatedStatus } from '../../../shared/utilities/url';
import { useStateContext } from '../../modules/stateContext';

const CassoText = ({ additionalClasses }) => {
    const { isCasso, isFederated } = useStateContext();
    const routerLocation = useLocation();

    const href = buildHrefFromFederatedStatus({
        isFederated,
        federatedUrl: HELP_FEDERATED_PASSWORD_MOBILE_DEVICES_PATH,
        nonFederatedUrl: PASSWORD_STORAGE_URL,
        routerLocation,
    });

    const helpLink = {
        href,
        external: true,
        withState: isFederated,
    };

    return isCasso === true || isCasso === 'true' ? (
        <Text className={additionalClasses}>
            <FormattedMessage id="casso.message.sharedDetails" />{' '}
            <ApplicationLinkContainer {...helpLink}>
                <FormattedMessage id="link.more.value" />
            </ApplicationLinkContainer>
            .
        </Text>
    ) : null;
};

CassoText.propTypes = {
    additionalClasses: PropTypes.string,
};

export default CassoText;

import { AdditionalInfo } from '@bbc-account/id-components';
import styled from 'styled-components';

export const StyledIframePasswordForm = styled.div`
    display: flex;
    gap: var(--spacing-4);
    flex-direction: column;
`;

export const StyledIframePasswordAdditionalInfo = styled(AdditionalInfo)`
    flex-flow: column;
    gap: var(--spacing-4);

    @media (min-width: 25rem) {
        flex-flow: row;
    }
`;

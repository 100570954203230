import React from 'react';
import { FormattedMessage } from 'react-intl';

import AuthForm from '../form';
import EmailInput from '../inputs/emailInput';
import Layout from '../../pageLayout/layout';
import Text from '../../shared/text';
import { useStateContext } from '../../../modules/stateContext';
import renderQueryString from '../../../../shared/urls/renderQueryString';
import { AUTH_PATH } from '../../../../shared/endpoints/account';

const Email = () => {
    const userContext = useStateContext();

    const action = `${AUTH_PATH}${renderQueryString.call(userContext)}`;

    return (
        <Layout
            pageId="signin-page"
            heading={<FormattedMessage id="auth.magicLink.entryPage.title" />}
            showServiceIdentifiers
        >
            <Text introduction>
                <FormattedMessage id="auth.magicLink.entryPage.description" />
            </Text>
            <AuthForm
                action={action}
                buttonTranslationKey="auth.magicLink.entryPage.submit.value"
            >
                <EmailInput />
            </AuthForm>
        </Layout>
    );
};

export default Email;

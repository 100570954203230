import React from 'react';
import { FormattedMessage } from 'react-intl';
import ApplicationLinkContainer from '../shared/applicationLink/applicationLinkContainer';
import {
    SIGN_IN_HELP_PATH,
    SIGN_IN_FEDERATED_HELP_PATH,
} from '../../../shared/endpoints/account';
import { useStateContext } from '../../modules/stateContext';

const NeedHelpLink = () => {
    const { isFederated } = useStateContext();

    return (
        <ApplicationLinkContainer
            href={isFederated ? SIGN_IN_FEDERATED_HELP_PATH : SIGN_IN_HELP_PATH}
            primary
        >
            <FormattedMessage id="signIn.link.passwordReminder" />
        </ApplicationLinkContainer>
    );
};

NeedHelpLink.displayName = 'NeedHelpLink';

export default NeedHelpLink;

import { isEmpty } from 'lodash';

const NO_VALIDATION_ERRORS = {};

const resolveWithValidationError = promise =>
    promise.then(() => NO_VALIDATION_ERRORS).catch(error => error);

const runAllValidators = (config, values, dispatch, props) => {
    const fields = Object.keys(config);
    const validations = fields.map(field => {
        const validator = config[field];
        return resolveWithValidationError(validator(values, dispatch, props));
    });

    return Promise.all(validations).then(validationResults => {
        const validationErrors = validationResults.reduce(
            (combinedResults, singleValidationResult) =>
                Object.assign(combinedResults, singleValidationResult),
            {}
        );

        if (!isEmpty(validationErrors)) {
            return Promise.reject(validationErrors);
        }

        return undefined;
    });
};

const combineAsyncValidate = (config = {}) => (
    values,
    dispatch,
    props,
    blurredField
) => {
    if (blurredField) {
        const validator = config[blurredField];
        return validator
            ? validator(values, dispatch, props)
            : Promise.resolve();
    }

    return runAllValidators(config, values, dispatch, props);
};

export default combineAsyncValidate;

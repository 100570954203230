import React from 'react';
import { StyledChecklistItemTickIcon } from './checklistItemTickIcon.style';

/**
 * Checked icon for a checklist item
 *
 * @param {React.SVGProps<SVGSVGElement>} props Element properties
 * @returns {React.ReactElement}
 */
export const ChecklistItemTickIcon = (props) => (
    <StyledChecklistItemTickIcon {...props} name="confirm" />
);

import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';

import React from 'react';
import DateOfBirthSelect from '../shared/pages/dateOfBirthSelectContainer';
import { HELP_FEDERATED_DOB_URL } from '../../../shared/endpoints/account';
import { PERSONAL_DETAILS_URL as HELP_DOB_URL } from '../../../shared/endpoints/usingTheBbc';
import { buildHrefFromFederatedStatus } from '../../../shared/utilities/url';
import { useStateContext } from '../../modules/stateContext';
import { isNorthAmericanCountry } from '../../../shared/i18n/region';
import { getAdultRegistrationDateValidator } from '../../../shared/validation/validators/manageDateValidators';

function getExplainerKey(location) {
    const { isUk, country } = location;

    if (isUk) {
        return 'explainer.message.uk.age';
    }

    if (isNorthAmericanCountry(country)) {
        return 'explainer.message.northAmerica.age';
    }

    return 'explainer.message.international.age';
}

const RegisterDateOfBirthSelect = ({ location: routerLocation, action }) => {
    const {
        isFederated,
        featureToggles: { consentAgeByCountry },
        location,
    } = useStateContext();

    const validator = getAdultRegistrationDateValidator({
        consentAgeByCountryEnabled: consentAgeByCountry,
        location,
    });

    const explainer = getExplainerKey(location);

    const explainerHelpUrl = buildHrefFromFederatedStatus({
        isFederated,
        federatedUrl: HELP_FEDERATED_DOB_URL,
        nonFederatedUrl: HELP_DOB_URL,
        routerLocation,
    });

    return (
        <DateOfBirthSelect
            action={action}
            validator={validator}
            explainer={explainer}
            explainerHelpUrl={explainerHelpUrl}
            title="age.title"
        />
    );
};

RegisterDateOfBirthSelect.propTypes = {
    location: ReactRouterPropTypes.location.isRequired,
    action: PropTypes.string,
};

export default RegisterDateOfBirthSelect;

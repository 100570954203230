import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

function RouteWithLayout(props) {
    const { layout: Layout, component: Component, ...rest } = props;

    return (
        <Route
            {...rest}
            render={routeProps => (
                <Layout {...routeProps}>
                    <Component {...routeProps} />
                </Layout>
            )}
        />
    );
}

RouteWithLayout.propTypes = {
    layout: PropTypes.elementType.isRequired,
    component: PropTypes.elementType.isRequired,
};

export default RouteWithLayout;

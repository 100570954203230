import { useMemo } from 'react';
import { useStateContext } from '../../../modules/stateContext';

export function useEmailFirstFormTracking({ selectedCountry }) {
    const {
        clientId,
        location: { geoIpCountry },
    } = useStateContext();

    const countryMetadata = useMemo(
        () =>
            geoIpCountry !== selectedCountry
                ? {
                      geoLocation: geoIpCountry,
                      selectedCountry,
                  }
                : undefined,
        [geoIpCountry, selectedCountry]
    );

    const trackSubmitMetadata = useMemo(
        () => ({
            clientId,
            ...countryMetadata,
        }),
        [clientId, countryMetadata]
    );

    return {
        trackSubmitMetadata,
    };
}

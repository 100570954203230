import { dateOfBirth as dateOfBirthValidators } from '../../../../shared/validation/validators';
import { isInvalid } from '../../../../shared/validation/validationResult';
import { DAY, MONTH, YEAR } from '../../shared/inputFields/dateOfBirthFields';
import {
    transformDay,
    transformMonth,
} from '../../../../shared/data/transformDate';

function validate({ [DAY]: day = '', [MONTH]: month = '', [YEAR]: year = '' }) {
    const date = {
        day: transformDay(day.trim()),
        month: transformMonth(month.trim()),
        year: year.trim(),
    };

    const errors = {};

    const validationErrors = dateOfBirthValidators.validateUnder13(date);

    if (isInvalid(validationErrors.day)) {
        errors[DAY] = true;
    }

    if (isInvalid(validationErrors.month)) {
        errors[MONTH] = true;
    }

    if (isInvalid(validationErrors.year)) {
        errors[YEAR] = true;
    }

    if (typeof validationErrors.error !== 'undefined') {
        errors._error = { dateOfBirth: validationErrors.error }; // eslint-disable-line no-underscore-dangle
    }

    return errors;
}

export default validate;

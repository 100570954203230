import PropTypes from 'prop-types';
import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import VerifyEmailSorryLayout from './sorryLayout';
import VerifyEmailSorryUnder13 from './sorryUnder13';
import VerifyEmailSorryOver13 from './sorryOver13';
import VerifyEmailSorryNotSignedIn from './sorryNotSignedIn';

const VerifyEmailSorryContainer = props => {
    const { isSignedIn, email, reason } = props;

    let SorryComponent;

    if (!isSignedIn) {
        SorryComponent = VerifyEmailSorryNotSignedIn;
    } else if (!email) {
        SorryComponent = VerifyEmailSorryUnder13;
    } else {
        SorryComponent = VerifyEmailSorryOver13;
    }

    return (
        <VerifyEmailSorryLayout expiredToken={reason === 'expired'}>
            <SorryComponent {...props} />
        </VerifyEmailSorryLayout>
    );
};

VerifyEmailSorryContainer.displayName = 'VerifyEmailSorryContainer';

VerifyEmailSorryContainer.propTypes = {
    email: PropTypes.string,
    reason: PropTypes.string.isRequired,
    isSignedIn: PropTypes.bool.isRequired,
    signOutSignInUrl: PropTypes.string,
};

VerifyEmailSorryContainer.defaultProps = {
    email: '',
};

const mapStateToProps = ({ verifyEmail = {} }) => verifyEmail;

const ConnectedVerifyEmailSorryContainer = connect(mapStateToProps)(
    VerifyEmailSorryContainer
);

ConnectedVerifyEmailSorryContainer.propTypes = {
    location: ReactRouterPropTypes.location.isRequired,
};

export { VerifyEmailSorryContainer, mapStateToProps };
export default ConnectedVerifyEmailSorryContainer;

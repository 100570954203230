import React from 'react';
import PropTypes from 'prop-types';
import { StyledChecklistA11y } from './checklistA11y.style';

/**
 * @typedef {object} ChecklistA11y
 * @property {number} progress Number of items completed
 * @property {number} totalProgress Total number of items available
 */

/**
 * ChecklistA11y provides additional accessibility elements and functionality
 *
 * @param {ChecklistA11yProps} props
 * @returns {React.ReactElement}
 */
export const ChecklistA11y = ({ progress, totalProgress }) => {
    return (
        <StyledChecklistA11y aria-live="polite">
            {`${progress} out of ${totalProgress} requirements completed`}
        </StyledChecklistA11y>
    );
};

ChecklistA11y.propTypes = {
    progress: PropTypes.number.isRequired,
    totalProgress: PropTypes.number.isRequired,
};

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useStateContext } from '../../modules/stateContext';
import Layout from '../pageLayout/layout';
import Text from '../shared/text';
import { getClient } from '../../../shared/data/clients';

const FederatedCompleted = () => {
    const { clientId } = useStateContext();
    const { service } = getClient(clientId);

    return (
        <Layout
            pageId="federated-completed-page"
            heading={<FormattedMessage id="federated.completed.allDone" />}
            showLoadingSpinner
        >
            <Text>
                <FormattedMessage
                    id="federated.completed.connected"
                    values={{ service }}
                />
            </Text>

            <Text>
                <FormattedMessage
                    id="federated.completed.return"
                    values={{ service }}
                />
            </Text>
        </Layout>
    );
};

FederatedCompleted.displayName = 'FederatedCompleted';

export default FederatedCompleted;

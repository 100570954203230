import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector, reduxForm } from 'redux-form';
import RegisterAdult from './register';
import connectToState from '../../connectToState';
import * as initialFormState from '../../../modules/initialFormState';
import * as reduxFormActionCreators from '../../../modules/reduxForm';
import {
    asyncHometownValidate,
    asyncPasswordBreachCheck,
    asyncPostcodeValidate,
    combineAsyncValidators,
} from '../../../services/reduxAsyncValidators';
import formNames from '../../../../shared/data/formNames';

const FORM_NAME = formNames.REGISTER;

const valueSelector = formValueSelector(FORM_NAME);

const mapStateToProps = state => {
    const { ab, isFederated, location, register, jsEnabled } = state;

    const initialValues = {
        ...register.data,
        location: location.country,
        dateOfBirth: {
            day: register.data.dateOfBirth?.day,
            month: register.data.dateOfBirth?.month,
            year: register.data.dateOfBirth?.year,
        },
        ageBracket: ab,
    };

    return {
        gender: valueSelector(state, 'gender') || initialValues.gender,
        initialErrors: register.initialErrors || {},
        initialValues,
        isFederated,
        jsEnabled,
        location,
        selectedCountry:
            valueSelector(state, 'location') || initialValues.location,
    };
};

const mapDispatchToProps = dispatch => ({
    dispatch,
    actions: bindActionCreators(
        {
            ...initialFormState,
            ...reduxFormActionCreators,
        },
        dispatch
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    connectToState(
        reduxForm({
            asyncValidate: combineAsyncValidators({
                hometown: asyncHometownValidate,
                password: asyncPasswordBreachCheck,
                postcode: asyncPostcodeValidate,
            }),
            asyncBlurFields: ['hometown', 'password', 'postcode'],
            form: FORM_NAME,
            destroyOnUnmount: false,
        })(RegisterAdult)
    )
);

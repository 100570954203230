import React from 'react';
import PropTypes from 'prop-types';
import { BbcBlocks } from '../bbcBlocks/bbcBlocks';
import { Heading } from '../heading/heading';
import { AccountLayoutSection } from '../accountLayoutSection/accountLayoutSection';
import { ServiceIdentifiers } from '../serviceIdentifiers/serviceIdentifiers';

import './accountLayout.scss';
import classNames from 'classnames';

const AccountLayout = ({
    contentContainerClassName,
    title,
    children,
    isBbcLogoLink,
    showServiceIdentifiers,
}) => {
    const wrappedLogo = isBbcLogoLink ? (
        <a href="https://www.bbc.co.uk" aria-label="Go to the BBC Homepage">
            <BbcBlocks size="large" />
        </a>
    ) : (
        <BbcBlocks size="large" />
    );

    return (
        <AccountLayoutSection isCentreAligned>
            <header className="sb-account-logo-wrapper">{wrappedLogo}</header>
            <main>
                <Heading level={1} fontScale="headlineSmall">
                    {title}
                </Heading>

                {showServiceIdentifiers && (
                    <div className="sb-account-service-identifier-wrapper">
                        <ServiceIdentifiers />
                    </div>
                )}
                <div
                    className={classNames(
                        'sb-account-content-container',
                        contentContainerClassName
                    )}
                >
                    {children}
                </div>
            </main>
        </AccountLayoutSection>
    );
};

AccountLayout.propTypes = {
    /**
     Custom content container class name
    */
    contentContainerClassName: PropTypes.string,
    /**
     It is the most prominent text of the form and renders in an H1.
    */
    title: PropTypes.node.isRequired,
    /**
     The content within the layout (below the title)
    */
    children: PropTypes.node.isRequired,
    /**
     Determines whether the BBC Logo should be a link (only if showBbcLogo is true)
     */
    isBbcLogoLink: PropTypes.bool,
    /**
     Determines whether the BBC Service Identifiers should be shown between the Title and the Children on screens smaller than 900px width.
     */
    showServiceIdentifiers: PropTypes.bool,
};

AccountLayout.defaultProps = {
    isBbcLogoLink: true,
    showServiceIdentifiers: false,
};

AccountLayout.displayName = 'AccountLayout';

export { AccountLayout };

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useShowExUkContent } from '../../../hooks/useShowExUkContent';

export function RegisterLayoutHeading() {
    const showExUkContent = useShowExUkContent();

    return (
        <FormattedMessage
            id={
                showExUkContent
                    ? 'register.title.introductionExUk'
                    : 'register.title.introduction'
            }
        />
    );
}

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@bbc-account/id-components';
import Form from '../../shared/form';
import FormError from '../../shared/formError';
import PermissionCheckboxFields from '../../shared/inputFields/permissionCheckboxFields';
import { errorShape } from '../../../propTypes';
import formNames from '../../../../shared/data/formNames';

const createOnPermissionFieldChange = actions => (event, value) => {
    const field = event.target.id;
    actions.changePermissions({
        form: formNames.MANAGE_PERMISSIONS,
        field,
        newValue: value,
    });
};

const PermissionsManagement = ({
    actions,
    initialValues,
    initialErrors,
    jsEnabled,
}) => (
    <Form>
        <FormError name="general" error={initialErrors.general} isAssertive />
        <PermissionCheckboxFields
            jsEnabled={jsEnabled}
            onChange={createOnPermissionFieldChange(actions)}
        />
        <input
            type="hidden"
            name="dateOfBirth"
            value={initialValues.dateOfBirth}
            key="hidden-date-of-birth-field"
        />
        <Button isFullWidth isSubmit id="submit-button">
            <FormattedMessage id="button.submit.value" />
        </Button>
    </Form>
);

PermissionsManagement.propTypes = {
    actions: PropTypes.shape({
        changePermissions: PropTypes.func.isRequired,
    }).isRequired,
    initialErrors: PropTypes.shape({
        general: errorShape,
    }),
    initialValues: PropTypes.shape({
        dateOfBirth: PropTypes.string.isRequired,
    }),
    jsEnabled: PropTypes.bool.isRequired,
};

PermissionsManagement.displayName = 'PermissionsManagement';

export default PermissionsManagement;

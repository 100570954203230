import authPages from '../../data/authPages';

function appendIframe(page) {
    return `${page}_iframe`;
}

export function resolvePageForEvent(page) {
    switch (page) {
        case authPages.IFRAME_IDENTIFIER_FORM:
            return appendIframe(authPages.IDENTIFIER_FORM);
        case authPages.IFRAME_MAGIC_LINK_CONFIRMATION:
            return appendIframe(authPages.MAGIC_LINK_CONFIRMATION);
        case authPages.IFRAME_PASSWORD_FORM:
            return appendIframe(authPages.PASSWORD_FORM);
        default:
            return page;
    }
}

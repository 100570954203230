import {
    prependBbcBaseUrl,
    prependBbcXProxyBaseUrl,
} from '../utilities/prependUrl';

const ABOUT_THE_BBC_URL = prependBbcBaseUrl('/aboutthebbc');
const ACCESSIBILITY_URL = prependBbcBaseUrl('/accessibility');
const BBCX_MARKETING_PREFERENCE_URL = prependBbcXProxyBaseUrl(
    '/set-marketing-consent'
);
const CONTACT_THE_BBC_URL = prependBbcBaseUrl('/contact');
const EXTERNAL_LINKING_HELP_URL = prependBbcBaseUrl('/help/web/links/');
const PAN_BBC_NEWSLETTER_URL = prependBbcBaseUrl('/bbcnewsletter');
const PARENTAL_GUIDANCE_URL = prependBbcBaseUrl('/iplayer/guidance');
const BBC_NEWSLETTERS_URL = prependBbcBaseUrl('/newsletters');

export {
    ABOUT_THE_BBC_URL,
    ACCESSIBILITY_URL,
    BBCX_MARKETING_PREFERENCE_URL,
    CONTACT_THE_BBC_URL,
    EXTERNAL_LINKING_HELP_URL,
    PAN_BBC_NEWSLETTER_URL,
    PARENTAL_GUIDANCE_URL,
    BBC_NEWSLETTERS_URL,
};

export const EMAIL_FIRST_PASSWORD_FORM_INPUT_CONTEXT_REDUCER_ACTIONS = {
    CHANGE_IS_VALID: 'changeIsValid',
};

export function emailFirstPasswordFormInputContextReducer(
    state,
    { isValid, type }
) {
    switch (type) {
        case EMAIL_FIRST_PASSWORD_FORM_INPUT_CONTEXT_REDUCER_ACTIONS.CHANGE_IS_VALID:
            return {
                ...state,
                isValid,
            };
        default:
            if (type) {
                throw new Error(
                    `Unknown emailFirstPasswordFormInputContextReducer type ${type}`
                );
            }

            throw new Error(
                'Missing emailFirstPasswordFormInputContextReducer type'
            );
    }
}

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../../pageLayout/layout';
import Text from '../../shared/text';

const MagicLinkValidateCancel = () => {
    return (
        <Layout
            pageId="signin-page"
            heading={
                <FormattedMessage id="auth.magicLink.validationCancel.title" />
            }
            showServiceIdentifiers
        >
            <Text>
                <FormattedMessage id="auth.magicLink.validationCancel.intro" />
            </Text>
            <Text>
                <FormattedMessage id="auth.magicLink.validationCancel.thanks" />
            </Text>
        </Layout>
    );
};

export default MagicLinkValidateCancel;

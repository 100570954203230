import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useForgottenPasswordEmail } from '../../useForgottenPasswordEmail';
import { forgottenPasswordConfirmationContext } from './forgottenPasswordConfirmationContext';

export function ForgottenPasswordConfirmationContextProvider({ children }) {
    const {
        getEmailFromSessionStorage,
        getEmailHasBeenResentFromSessionStorage,
        setEmailToSessionStorage,
        setEmailHasBeenResentToSessionStorage,
    } = useForgottenPasswordEmail();

    const email = getEmailFromSessionStorage();

    const emailHasBeenResent = getEmailHasBeenResentFromSessionStorage();

    const handleResendEmailFormSubmit = useCallback(
        ({ email: emailValue }) => {
            setEmailToSessionStorage(emailValue);
            setEmailHasBeenResentToSessionStorage();
        },
        [setEmailToSessionStorage, setEmailHasBeenResentToSessionStorage]
    );

    return (
        <forgottenPasswordConfirmationContext.Provider
            value={{
                email,
                emailHasBeenResent,
                handleResendEmailFormSubmit,
            }}
        >
            {children}
        </forgottenPasswordConfirmationContext.Provider>
    );
}

ForgottenPasswordConfirmationContextProvider.propTypes = {
    children: PropTypes.node,
};

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { propTypes as reduxFormPropTypes, Fields, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Button } from '@bbc-account/id-components';
import Form from '../../shared/form';
import { errorShape } from '../../../propTypes';
import DisplayNameField from '../../shared/inputFields/displayNameField';
import DateOfBirthFields, {
    DOB_FIELDS,
    DAY,
    MONTH,
    YEAR,
} from '../../shared/inputFields/dateOfBirthFields';
import { REGISTER_PROFILE_PATH } from '../../../../shared/endpoints/account';
import { TERMS_AND_CONDITIONS_URL } from '../../../../shared/endpoints/usingTheBbc';
import formNames from '../../../../shared/data/formNames';
import FormError from '../../shared/formError';
import validate from './validate';
import FormattedMessageWithAttributes from '../../shared/formattedMessageWithAttributes';

const isClient = process.env.CLIENT;

const RegisterProfile = ({ initialErrors = {}, error = {} }) => {
    const termsAndConditionsAttributes = {
        href: TERMS_AND_CONDITIONS_URL,
        external: true,
    };

    return (
        <Form action={REGISTER_PROFILE_PATH} method="post">
            <FormError
                name="general"
                error={initialErrors.general}
                isAssertive
            />

            <DisplayNameField initialError={initialErrors.displayName} />

            <Fields
                names={DOB_FIELDS}
                component={DateOfBirthFields}
                initialErrors={initialErrors}
                error={error.dateOfBirth}
            />

            <p className="button__label button__label--fine-print u-margin-bottom-triple">
                <FormattedMessageWithAttributes
                    id="register.profile.acceptTerms"
                    attributes={[termsAndConditionsAttributes]}
                />
            </p>

            <Button isFullWidth isSubmit id="register-profile-button">
                <FormattedMessage id="register.profile.button.submit.value" />
            </Button>
        </Form>
    );
};

RegisterProfile.displayName = 'RegisterProfile';

RegisterProfile.propTypes = {
    ...reduxFormPropTypes,
    initialValues: PropTypes.shape({
        displayName: PropTypes.string,
        [DAY]: PropTypes.string,
        [MONTH]: PropTypes.string,
        [YEAR]: PropTypes.string,
    }),
    initialErrors: PropTypes.shape({
        general: errorShape,
        displayName: errorShape,
        dateOfBirth: errorShape,
    }),
};

const mapStateToProps = ({ registerProfile }) => ({
    initialValues: registerProfile.data || {},
    initialErrors: registerProfile.initialErrors || {},
});

export default connect(mapStateToProps)(
    reduxForm({
        validate: isClient ? validate : () => {},
        form: formNames.REGISTER_PROFILE,
    })(RegisterProfile)
);

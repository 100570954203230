import hibp from '@bbc-account/id-services/dist/hibp';

export async function checkPasswordBreach(password) {
    const numberOfBreaches = await hibp.checkPassword(password);

    if (numberOfBreaches > 0) {
        return { error: 'validation.password.isBreached', numberOfBreaches };
    }

    return {
        numberOfBreaches: 0,
    };
}

import errorMessages from '../../server/errors/errorMessages';
import {
    AUTH_REGISTER,
    AUTH_REGISTER_FEDERATED,
} from '../../shared/endpoints/authV2';
import makeRequest from '../fetch';

export const register = async ({ paramsString, values, userContext }) => {
    try {
        const {
            isFederated,
            featureToggles: { emailFirstFederated },
        } = userContext;

        const registerEndpoint =
            isFederated && emailFirstFederated
                ? AUTH_REGISTER_FEDERATED
                : AUTH_REGISTER;

        const url = new URL(registerEndpoint, window.location.origin);

        if (paramsString) {
            const urlSearchParams = new URLSearchParams(paramsString);

            urlSearchParams.forEach((value, key) => {
                url.searchParams.append(key, value);
            });
        }

        return await makeRequest(url.toString(), {
            method: 'post',
            body: values,
        });
    } catch (error) {
        if (process.env.NODE_ENV === 'development') {
            console.error(error);
        }

        return { success: false, message: errorMessages.gracefulDegradation };
    }
};

import { countryCodes as crownCountryCodes } from '../data/crownDependencies';

function consentAgeConfigFromCountryCodesReducer(consentAge) {
    return (config, countryCode) => ({
        ...config,
        [countryCode]: {
            consentAge,
        },
    });
}

const consentAge13Config = crownCountryCodes.reduce(
    consentAgeConfigFromCountryCodesReducer(13),
    {}
);

const consentAge16Config = ['ca', 'default', 'us'].reduce(
    consentAgeConfigFromCountryCodesReducer(16),
    {}
);

const consentAge18Config = ['in'].reduce(
    consentAgeConfigFromCountryCodesReducer(18),
    {}
);

export const consentAgeConfig = {
    ...consentAge13Config,
    ...consentAge16Config,
    ...consentAge18Config,
};

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector, reduxForm } from 'redux-form';
import * as initialFormState from '../../../modules/initialFormState';
import * as reduxFormActionCreators from '../../../modules/reduxForm';
import {
    asyncDisplayNameValidate,
    asyncHometownValidate,
    combineAsyncValidators,
} from '../../../services/reduxAsyncValidators';
import connectToState from '../../connectToState';
import formNames from '../../../../shared/data/formNames';

import RegisterChildMoreDetails from './moreDetails';

const FORM_NAME = formNames.REGISTER_CHILD;

const valueSelector = formValueSelector(FORM_NAME);

const mapStateToProps = state => {
    const {
        location,
        register,
        jsEnabled,
        form: {
            registerChild: { values },
        },
    } = state;

    return {
        gender: valueSelector(state, 'gender'),
        initialErrors: register.initialErrors || {},
        initialValues: {
            ...register.data,
        },
        jsEnabled,
        location,
        previousFormData: values,
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            ...initialFormState,
            ...reduxFormActionCreators,
        },
        dispatch
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    connectToState(
        reduxForm({
            form: FORM_NAME,
            destroyOnUnmount: false,
            forceUnregisterOnUnmount: true,
            asyncValidate: combineAsyncValidators({
                displayName: asyncDisplayNameValidate,
                hometown: asyncHometownValidate,
            }),
            asyncBlurFields: ['hometown', 'displayName'],
        })(RegisterChildMoreDetails)
    )
);

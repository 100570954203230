import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './flexContainer.scss';

const FlexContainer = ({
    children,
    alignCenter,
    reverse,
    justifyContentStart,
}) => {
    const classes = classnames('sb-flex-container', {
        'sb-flex-container--align-center': alignCenter,
        'sb-flex-container--reverse': reverse,
        'sb-flex-container--justify-content-start': justifyContentStart,
    });

    return <div className={classes}>{children}</div>;
};

FlexContainer.propTypes = {
    children: PropTypes.node.isRequired,
    alignCenter: PropTypes.bool,
    reverse: PropTypes.bool,
    justifyContentStart: PropTypes.bool,
};

FlexContainer.defaultProps = {
    reverse: false,
    justifyContentStart: false,
};

export { FlexContainer };

import React from 'react';

const CloseIcon = () => (
    <div className="page__close-icon">
        <div className="scaling-svg__container">
            <svg
                className="scaling-svg__svg"
                role="presentation"
                preserveAspectRatio="xMidYMin slice"
                viewBox="0 0 32 32"
            >
                <use xlinkHref="#close_light" />
            </svg>
        </div>
    </div>
);

export default CloseIcon;

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CheckboxField from '../checkboxField';
import ApplicationLinkContainer from '../../applicationLink/applicationLinkContainer';
import { PERMISSION_ALLOW_COMMENTING_FIELD } from '../../../../../shared/data/permissionFieldConstants';
import { COMMENTS_URL } from '../../../../../shared/endpoints/usingTheBbc';

const getCommentsExplainerKeys = () => [
    <FormattedMessage id="childPermission.explainer.comments" />,
    <ApplicationLinkContainer href={COMMENTS_URL} external>
        <FormattedMessage id="link.aboutCommenting" />
    </ApplicationLinkContainer>,
];

const CommentingPermission = ({ jsEnabled, onChange }) => (
    <CheckboxField
        ariaLabel="childPermission.label.approveCommenting"
        explainers={getCommentsExplainerKeys()}
        jsEnabled={jsEnabled}
        label="childPermission.label.approveCommenting"
        name={PERMISSION_ALLOW_COMMENTING_FIELD}
        onChange={onChange}
    />
);

CommentingPermission.propTypes = {
    onChange: PropTypes.func.isRequired,
    jsEnabled: PropTypes.bool.isRequired,
};

export default CommentingPermission;

import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { injectIntl } from 'react-intl';
import getPageTitleTag from '../../shared/pageTags/getTitle';
import { intlShape } from '../propTypes';
import { useStateContext } from '../modules/stateContext';
import { isExUk } from '../utilities/isExUk';

const PageTitle = ({ pathname, intl }) => {
    const { featureToggles, location } = useStateContext();
    const { messages: translations } = intl;

    const title = getPageTitleTag(
        pathname,
        translations,
        isExUk(location),
        featureToggles.ipBlocking
    );

    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    );
};

PageTitle.propTypes = {
    pathname: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
};

PageTitle.displayName = 'PageTitle';

export { PageTitle };
export default injectIntl(PageTitle);

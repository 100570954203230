export const PAGE_BEFORE_UNLOAD_EVENT_CONTEXT_REDUCER_ACTIONS = {
    DISABLE_BEFORE_UNLOAD_EVENT_LISTENERS: 'disableBeforeUnloadEventListeners',
    ENABLE_BEFORE_UNLOAD_EVENT_LISTENERS: 'enableBeforeUnloadEventListeners',
};

export function pageBeforeUnloadEventContextReducer(state, { type }) {
    switch (type) {
        case PAGE_BEFORE_UNLOAD_EVENT_CONTEXT_REDUCER_ACTIONS.DISABLE_BEFORE_UNLOAD_EVENT_LISTENERS:
            return { ...state, beforeUnloadEventListenersEnabled: false };
        case PAGE_BEFORE_UNLOAD_EVENT_CONTEXT_REDUCER_ACTIONS.ENABLE_BEFORE_UNLOAD_EVENT_LISTENERS:
            return { ...state, beforeUnloadEventListenersEnabled: true };
        default:
            return { ...state };
    }
}

import { styled } from 'styled-components';

export const StyledEmailFirstPasswordFormInputWrapper = styled.div`
    display: flex;
    flex-flow: column;
    gap: 2rem;

    .sb-field__message-container {
        margin-top: var(--spacing-7);
    }
`;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../pageLayout/layout';
import Text from '../shared/text';
import ApplicationLinkContainer from '../shared/applicationLink/applicationLinkContainer';
import { HELP_SIGN_IN_URL } from '../../../shared/endpoints/usingTheBbc';
import { useStateContext } from '../../modules/stateContext';
import {
    FORGOTTEN_CREDENTIALS_FEDERATED_PATH,
    FORGOTTEN_CREDENTIALS_PATH,
    FORGOTTEN_PASSWORD_FEDERATED_PATH,
    FORGOTTEN_PASSWORD_PATH,
} from '../../../shared/endpoints/account';

const NeedHelpIndex = () => {
    const { isFederated } = useStateContext();

    const forgottenPasswordPath = isFederated
        ? FORGOTTEN_PASSWORD_FEDERATED_PATH
        : FORGOTTEN_PASSWORD_PATH;
    const forgottenCredentialsPath = isFederated
        ? FORGOTTEN_CREDENTIALS_FEDERATED_PATH
        : FORGOTTEN_CREDENTIALS_PATH;
    return (
        <Layout heading={<FormattedMessage id="cama.title.index" />}>
            <Text>
                <FormattedMessage id="cama.index.message.introduction" />
            </Text>
            <ul>
                <Text listItem>
                    <ApplicationLinkContainer href={forgottenPasswordPath}>
                        <FormattedMessage id="cama.link.forgotPassword" />
                    </ApplicationLinkContainer>
                </Text>
                <Text listItem>
                    <ApplicationLinkContainer href={forgottenCredentialsPath}>
                        <FormattedMessage id="cama.link.forgotCredential" />
                    </ApplicationLinkContainer>
                </Text>
                {!isFederated && (
                    <Text listItem>
                        <ApplicationLinkContainer
                            href={HELP_SIGN_IN_URL}
                            external
                        >
                            <FormattedMessage id="cama.link.otherIssue" />
                        </ApplicationLinkContainer>
                    </Text>
                )}
            </ul>
        </Layout>
    );
};

NeedHelpIndex.displayName = 'NeedHelpIndex';

export default NeedHelpIndex;

import { reducer as formReducer } from 'redux-form';
import {
    PERMISSION_ALLOW_ALL_FIELD,
    PERMISSION_ALLOW_COMMENTING_FIELD,
    PERMISSION_ALLOW_NOTIFICATIONS_FIELD,
    PERMISSION_ALLOW_PERSONALISATION_FIELD,
    PERMISSION_ALLOW_UPLOADS_FIELD,
} from '../../shared/data/permissionFieldConstants';
import formNames from '../../shared/data/formNames';

const CLEAR_PASSWORD = 'account/reduxForm/CLEAR_PASSWORD';
const CHANGE_PERMISSIONS = 'account/reduxForm/CHANGE_PERMISSIONS';

const clearPasswordFieldReducer = formName => (state = {}, action) => {
    const { values = {}, fields = {} } = state;

    switch (action.type) {
        case CLEAR_PASSWORD:
            if (action.payload.form !== formName) {
                return state;
            }

            return {
                ...state,
                values: {
                    ...values,
                    password: undefined,
                },
                fields: {
                    ...fields,
                    password: undefined,
                },
            };

        default:
            return state;
    }
};

const calculateAllowAll = values => {
    const fields = [
        PERMISSION_ALLOW_COMMENTING_FIELD,
        PERMISSION_ALLOW_NOTIFICATIONS_FIELD,
        PERMISSION_ALLOW_PERSONALISATION_FIELD,
        PERMISSION_ALLOW_UPLOADS_FIELD,
    ];

    return fields.every(field => values[field]);
};

const allowAllReducer = formName => (state = {}, action) => {
    if (
        action.type !== CHANGE_PERMISSIONS ||
        action.payload.form !== formName
    ) {
        return state;
    }

    const values = {
        ...state.values,
        [action.payload.field]: action.payload.newValue,
    };

    const newPermissionValues = {
        [PERMISSION_ALLOW_COMMENTING_FIELD]: values.permissionAllowAll,
        [PERMISSION_ALLOW_PERSONALISATION_FIELD]: values.permissionAllowAll,
        [PERMISSION_ALLOW_NOTIFICATIONS_FIELD]: values.permissionAllowAll,
        [PERMISSION_ALLOW_UPLOADS_FIELD]: values.permissionAllowAll,
    };

    switch (action.payload.field) {
        case PERMISSION_ALLOW_ALL_FIELD:
            return {
                ...state,
                values: {
                    ...values,
                    ...newPermissionValues,
                },
            };

        case PERMISSION_ALLOW_COMMENTING_FIELD:
        case PERMISSION_ALLOW_PERSONALISATION_FIELD:
        case PERMISSION_ALLOW_NOTIFICATIONS_FIELD:
        case PERMISSION_ALLOW_UPLOADS_FIELD:
            return {
                ...state,
                values: {
                    ...values,
                    permissionAllowAll: calculateAllowAll(values),
                },
            };

        default:
            return state;
    }
};

export default formReducer.plugin({
    signIn: clearPasswordFieldReducer('signIn'),
    register: clearPasswordFieldReducer('register'),
    registerChild: allowAllReducer(formNames.REGISTER_CHILD),
    permissionsManagement: allowAllReducer(formNames.MANAGE_PERMISSIONS),
});

const clearPassword = form => {
    return {
        type: CLEAR_PASSWORD,
        payload: {
            form,
        },
    };
};

const changePermissions = ({ field, form, newValue }) => ({
    type: CHANGE_PERMISSIONS,
    payload: {
        field,
        form,
        newValue,
    },
});

export { clearPassword, changePermissions };

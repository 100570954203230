import { useFormContext } from '@bbc-account/id-formaxe';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { dateOfBirth } from '@bbc-account/id-validators';
import { useShowExUkContent } from '../../../../../hooks/useShowExUkContent';
import {
    transformDay,
    transformMonthToTwoDigitNumber,
} from '../../../../../../shared/data/transformDate';
import { REGISTER_CHILD_GUARDIAN_PATH } from '../../../../../../shared/endpoints/account';
import { useEmailFirstInputFieldEvents } from '../text/useEmailFirstInputFieldEvents';
import { useStateContext } from '../../../../../modules/stateContext';
import { getAdultRegistrationDateValidator } from '../../../../../../shared/validation/validators/manageDateValidators';

export const EMAIL_FIRST_DATE_OF_BIRTH_FORM_INPUT_NAME = 'dateOfBirth';

export const EMAIL_FIRST_DATE_OF_BIRTH_FORM_FIELD_NAMES = {
    DAY: 'dateOfBirthDay',
    MONTH: 'dateOfBirthMonth',
    YEAR: 'dateOfBirthYear',
};

export const useEmailFirstDateOfBirthField = () => {
    const {
        location,
        featureToggles: { consentAgeByCountry },
    } = useStateContext();

    const [dateOfBirthDayTouched, setDateOfBirthDayTouched] = useState(false);

    const [dateOfBirthMonthTouched, setDateOfBirthMonthTouched] = useState(
        false
    );

    const [dateOfBirthYearTouched, setDateOfBirthYearTouched] = useState(false);

    const {
        fieldValues: {
            dateOfBirthDay = '',
            dateOfBirthMonth = '',
            dateOfBirthYear = '',
        },
    } = useFormContext();

    const showExUkContent = useShowExUkContent();

    const validateAge = useCallback(
        date => {
            const attributes = showExUkContent
                ? undefined
                : {
                      href: REGISTER_CHILD_GUARDIAN_PATH,
                  };

            if (showExUkContent || consentAgeByCountry) {
                return getAdultRegistrationDateValidator({
                    attributes,
                    consentAgeByCountryEnabled: consentAgeByCountry,
                    location,
                })(date);
            }

            return dateOfBirth.validateOver13(date, {
                under13ErrorMessage: {
                    id: 'validation.dateOfBirth.long.under13',
                    attributes,
                },
            });
        },
        [location, showExUkContent, consentAgeByCountry]
    );

    const validator = useMemo(
        () => ({
            validate: () => {
                const date = {
                    day: transformDay(dateOfBirthDay.trim()),
                    month: transformMonthToTwoDigitNumber(
                        dateOfBirthMonth.trim()
                    ),
                    year: dateOfBirthYear.trim(),
                };

                const validationResult = validateAge(date);

                if (validationResult.error) {
                    return validationResult;
                }

                return dateOfBirth.validateUnderMaxAge(date);
            },
        }),
        [dateOfBirthDay, dateOfBirthMonth, dateOfBirthYear]
    );

    const { errors, validate } = useEmailFirstInputFieldEvents({
        id: EMAIL_FIRST_DATE_OF_BIRTH_FORM_INPUT_NAME,
        validator,
    });

    const hasError = typeof errors?.error !== 'undefined';

    const handleInputBlur = useCallback(({ target: { id } }) => {
        switch (id) {
            case EMAIL_FIRST_DATE_OF_BIRTH_FORM_FIELD_NAMES.DAY:
                setDateOfBirthDayTouched(true);
                break;
            case EMAIL_FIRST_DATE_OF_BIRTH_FORM_FIELD_NAMES.MONTH:
                setDateOfBirthMonthTouched(true);
                break;
            case EMAIL_FIRST_DATE_OF_BIRTH_FORM_FIELD_NAMES.YEAR:
                setDateOfBirthYearTouched(true);
                break;
            default:
                break;
        }
    }, []);

    useEffect(() => {
        if (
            dateOfBirthDayTouched &&
            dateOfBirthMonthTouched &&
            dateOfBirthYearTouched
        ) {
            validate();
        }
    }, [
        dateOfBirthDayTouched,
        dateOfBirthMonthTouched,
        dateOfBirthYearTouched,
        validate,
    ]);

    return {
        error: hasError ? errors?.error : undefined,
        handleInputBlur,
    };
};

const policies = {
    COMMENT: 'comment',
    EMAIL_CONTACT: 'email-contact',
    NOTIFICATION: 'notification',
    PLAYLIST: 'playlist',
    TV: 'tv',
    U16: 'u16',
    U16_COMMENT: 'u16comment',
    UPLOAD: 'upload',
    UPLOAD_CONTENT: 'content-upload',
};

export default policies;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Text from '../../../shared/text';
import { ForgottenPasswordConfirmationContent } from './forgottenPasswordConfirmationContent';

export function ForgottenPasswordConfirmationContentUk() {
    return (
        <ForgottenPasswordConfirmationContent
            beforeFormContent={
                <>
                    <Text>
                        <FormattedMessage id="passwordReset.message.resetFromEmail" />{' '}
                        <FormattedMessage id="passwordReset.message.waitForEmail" />
                    </Text>

                    <Text>
                        <FormattedMessage id="passwordReset.message.emailNotReceived" />
                    </Text>
                </>
            }
        />
    );
}

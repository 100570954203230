import { prependBbcBaseUrl } from '../utilities/prependUrl';

const CHILD_PERMISSIONS_URL = prependBbcBaseUrl(
    '/usingthebbc/account/what-are-child-permissions'
);
const COMMENTS_URL = prependBbcBaseUrl(
    '/usingthebbc/account/what-happens-to-the-comments-i-post-on-the-bbc/'
);
const COOKIES_URL = prependBbcBaseUrl('/usingthebbc/cookies');
const EMAIL_VERIFICATION_URL = prependBbcBaseUrl(
    '/usingthebbc/account/why-and-when-do-i-need-to-verify-my-email-address'
);
const HELP_ACCOUNT_URL = prependBbcBaseUrl('/usingthebbc/account');
const HELP_EMAIL_URL = prependBbcBaseUrl(
    '/usingthebbc/account/im-having-problems-with-my-email-address'
);
const HELP_NEWSLETTER_URL = prependBbcBaseUrl(
    '/usingthebbc/account/what-emails-and-newsletters-will-the-bbc-send-me'
);
const HELP_PASSWORD_URL = prependBbcBaseUrl(
    '/usingthebbc/account/im-having-problems-with-my-password'
);
const HELP_REGISTER_URL = prependBbcBaseUrl(
    '/usingthebbc/account/help-registering'
);
const HELP_SIGN_IN_URL = prependBbcBaseUrl(
    '/usingthebbc/account/help-signing-in'
);
const HELP_SIGN_IN_TV_URL = prependBbcBaseUrl(
    '/usingthebbc/account/how-do-i-sign-in-on-my-tv/'
);
const HELP_USERNAME_URL = prependBbcBaseUrl(
    '/usingthebbc/account/im-having-problems-with-my-username'
);
const NOTIFICATIONS_URL = prependBbcBaseUrl(
    '/usingthebbc/account/what-are-notifications'
);
const PASSWORD_STORAGE_URL = prependBbcBaseUrl(
    '/usingthebbc/account/passwords-mobile-devices'
);
const PERSONALISATION_URL = prependBbcBaseUrl(
    '/usingthebbc/account/how-is-the-bbc-personalised-to-me'
);
const PERSONAL_DETAILS_URL = prependBbcBaseUrl(
    '/usingthebbc/account/why-do-i-have-to-provide-personal-info-when-i-register'
);

const PRIVACY_POLICY_URL = prependBbcBaseUrl('/usingthebbc/privacy');
const PRIVACY_POLICY_UK_FULL_URL = prependBbcBaseUrl(
    '/usingthebbc/privacy-policy'
);
const PRIVACY_POLICY_INTERNATIONAL_URL = prependBbcBaseUrl(
    '/usingthebbc/privacy-policy-outside-the-uk'
);

const TERMS_AND_CONDITIONS_URL = prependBbcBaseUrl('/usingthebbc/terms');

const WHATS_CHANGED_URL = prependBbcBaseUrl(
    '/usingthebbc/account/whats-changed-about-my-bbc-account'
);
const YOUR_ACCOUNT_URL = prependBbcBaseUrl('/usingthebbc/your-bbc-account');

export {
    CHILD_PERMISSIONS_URL,
    COMMENTS_URL,
    COOKIES_URL,
    EMAIL_VERIFICATION_URL,
    HELP_ACCOUNT_URL,
    HELP_EMAIL_URL,
    HELP_NEWSLETTER_URL,
    HELP_PASSWORD_URL,
    HELP_REGISTER_URL,
    HELP_SIGN_IN_URL,
    HELP_SIGN_IN_TV_URL,
    HELP_USERNAME_URL,
    NOTIFICATIONS_URL,
    PASSWORD_STORAGE_URL,
    PERSONALISATION_URL,
    PERSONAL_DETAILS_URL,
    PRIVACY_POLICY_INTERNATIONAL_URL,
    PRIVACY_POLICY_URL,
    PRIVACY_POLICY_UK_FULL_URL,
    TERMS_AND_CONDITIONS_URL,
    WHATS_CHANGED_URL,
    YOUR_ACCOUNT_URL,
};

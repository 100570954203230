import React from 'react';
import PropTypes from 'prop-types';

import { AdditionalInfo } from '../additionalInfo/additionalInfo';
import { Fieldset, Container } from './subscribeContainer.style';

/**
 * @description A layout container for subscribe to newsletters section,
 * a compound component with several sub-components
 * @param {{ children: React.ReactNode }} props nested components that may include
 * form: input+submit and additional information block like helper links, info text
 * @example
 * <SubscribeContainer>
 *  <SubscribeContainer.Fieldset>
 *      <TextInput
 *          minimal
 *          label="Email"
 *          id="email"
 *      />
 *      <Button type="submit" variant="focus-outlined"> Subscribe </Button>
 *  </SubscribeContainer.Fieldset>
 *  <SubscribeContainer.AdditionalInfo >
 *      <a href="#">Privacy Policy</a>
 *      <a href="#">Help</a>
 *  </SubscribeContainer.AdditionalInfo>
 * </SubscribeContainer>
 * @returns {JSX.Element}
 */
const SubscribeContainer = ({ children }) => {
    return <Container>{children}</Container>;
};

SubscribeContainer.Fieldset = Fieldset;
SubscribeContainer.AdditionalInfo = AdditionalInfo;

SubscribeContainer.propTypes = {
    /**
     * React child elements
     */
    children: PropTypes.node,
};

SubscribeContainer.displayName = 'SubscribeContainer';

export { SubscribeContainer };

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Text from '../shared/text';

const Introduction = ({ message }) => (
    <Text introduction>
        <FormattedMessage id={message} />
    </Text>
);

Introduction.displayName = 'Introduction';

Introduction.propTypes = {
    message: PropTypes.string.isRequired,
};

export default Introduction;

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Layout from '../pageLayout/layout';
import ContinueButton from '../shared/continueButton';
import Text from '../shared/text';

const AccountReactivation = ({ userIdentifier }) => {
    return (
        <Layout heading={<FormattedMessage id="reactivation.title" />}>
            <Fragment>
                <Text>
                    <FormattedMessage
                        id="reactivation.keepActive"
                        values={{ userIdentifier }}
                    />
                </Text>
                <ContinueButton />
            </Fragment>
        </Layout>
    );
};

AccountReactivation.propTypes = {
    userIdentifier: PropTypes.string.isRequired,
};

AccountReactivation.displayName = 'accountReactivation';

const mapStateToProps = state => {
    const verifyEmail = state.verifyEmail || {};
    return {
        userIdentifier: verifyEmail.email,
    };
};

export default connect(mapStateToProps)(AccountReactivation);

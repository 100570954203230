import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../../pageLayout/layout';
import { useStateContext } from '../../../modules/stateContext';
import { getClient } from '../../../../shared/data/clients';
import ApplicationLinkContainer from '../../shared/applicationLink/applicationLinkContainer';
import { REGISTER_FEDERATED_PATH } from '../../../../shared/endpoints/account';

const U13Blocked = () => {
    const { clientId } = useStateContext();

    return (
        <Layout
            pageId="federated-register-u13-blocked-page"
            heading={
                <FormattedMessage
                    id="federated.register.blocked"
                    values={{ service: getClient(clientId).service }}
                />
            }
        >
            <ApplicationLinkContainer
                external
                href={REGISTER_FEDERATED_PATH}
                withState
                button
                primary
                fullWidth
            >
                <FormattedMessage id="button.back.value" />
            </ApplicationLinkContainer>
        </Layout>
    );
};

U13Blocked.displayName = 'U13Blocked';

export default U13Blocked;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import ApplicationLinkContainer from '../shared/applicationLink/applicationLinkContainer';
import Layout from '../pageLayout/layout';
import Explainer from '../shared/explainer';
import Text from '../shared/text';
import {
    SIGN_IN_FEDERATED_PATH,
    SIGN_IN_FEDERATED_OTSI_PATH,
} from '../../../shared/endpoints/account';
import { useStateContext } from '../../modules/stateContext';
import { getClient } from '../../../shared/data/clients';
import OneTapSignIn from '../otsi/oneTapSignIn';
import Form from '../shared/form';

const FederatedSignInLanding = () => {
    const { clientId, otsiEligible } = useStateContext();
    const { service, privacyUrl: serviceUrl } = getClient(clientId);

    return (
        <Layout
            pageId="federated-landing-page"
            heading={
                <FormattedMessage
                    id="federated.landing.title"
                    values={{ service }}
                />
            }
            showLoadingSpinner
        >
            <Text>
                <FormattedMessage
                    id="federated.landing.shareAccount"
                    values={{ service }}
                />
            </Text>

            <Explainer
                additionalClasses="u-margin-bottom-double"
                cta={
                    <FormattedMessage id="federated.signIn.explainer.link.data" />
                }
                explainers={[
                    <FormattedMessage
                        id="federated.landing.explainer.dataTransparency"
                        values={{
                            service,
                            serviceUrl,
                        }}
                    />,
                ]}
                name="dataTransparency"
            />

            {otsiEligible ? (
                <Form method="post" action={SIGN_IN_FEDERATED_OTSI_PATH}>
                    <OneTapSignIn clientId={clientId} />
                </Form>
            ) : (
                <ApplicationLinkContainer
                    button
                    primary
                    fullWidth
                    href={SIGN_IN_FEDERATED_PATH}
                >
                    <FormattedMessage id="button.continue.value" />
                </ApplicationLinkContainer>
            )}
        </Layout>
    );
};

FederatedSignInLanding.displayName = 'FederatedSignInLanding';

export default FederatedSignInLanding;

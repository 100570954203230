import * as configFetchers from './fetchers';

let isClient = process.env.CLIENT;
let secureConfig;
let insecureConfig;

function create({ secure = {}, insecure = {} } = {}) {
    isClient = process.env.CLIENT;
    insecureConfig = insecure;
    secureConfig = secure;
}

function clear() {
    secureConfig = undefined;
    insecureConfig = undefined;
}

function checkConfigIsDefined() {
    if (!insecureConfig) {
        const configFetcher = isClient
            ? configFetchers.client
            : configFetchers.server;
        create(configFetcher());
    }
}

function get(key) {
    checkConfigIsDefined();

    const item = (secureConfig && secureConfig[key]) || insecureConfig[key];

    if (item === 'true') {
        return true;
    }

    if (item === 'false') {
        return false;
    }

    return typeof item !== 'undefined' ? item : '';
}

function getAllInsecure() {
    checkConfigIsDefined();

    return insecureConfig;
}

function getAllSecure() {
    checkConfigIsDefined();

    if (isClient) {
        throw new Error('Secure config is not available on the client');
    }

    return secureConfig;
}

export { get, getAllInsecure, getAllSecure, create, clear };

import PropTypes from 'prop-types';
import React from 'react';
import { memoize } from 'lodash';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import SelectInput from '../selectInput';
import Explainer from '../explainer';
import { locationShape } from '../../../propTypes';
import sortCountries from '../../../../shared/data/sortCountries';
import FormattedMessageWithAttributes from '../formattedMessageWithAttributes';
import { PERSONAL_DETAILS_URL } from '../../../../shared/endpoints/usingTheBbc';
import { isNorthAmericanCountry } from '../../../../shared/i18n/region';

const getSortedCountries = memoize(countries => sortCountries(countries));

const CountryField = props => {
    const {
        location: { country, isUk, initiallyUk } = {},
        countries,
        ...countryProps
    } = props;

    const explainerKey = isNorthAmericanCountry(country)
        ? 'explainer.message.northAmerica.country'
        : 'explainer.message.country';

    return country && isUk && initiallyUk ? (
        <input
            id="location-select"
            type="hidden"
            name="location"
            value={country}
        />
    ) : (
        <Field
            id="location-select"
            name="location"
            autoComplete="country-name"
            required
            label={<FormattedMessage id="label.country" />}
            component={SelectInput}
            options={getSortedCountries(countries).map(({ code, name }) => (
                <option key={code} value={code}>
                    {name}
                </option>
            ))}
            {...countryProps}
        >
            <Explainer
                explainers={
                    <FormattedMessageWithAttributes
                        id={explainerKey}
                        attributes={{
                            href: PERSONAL_DETAILS_URL,
                            external: true,
                        }}
                    />
                }
                name="location"
            />
        </Field>
    );
};

CountryField.displayName = 'CountryField';

CountryField.propTypes = {
    countries: PropTypes.objectOf(PropTypes.string).isRequired,
    location: locationShape.isRequired,
};

export default CountryField;

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ThanksDirectFlow from './thanksDirectFlow';
import ThanksEmailFlow from './thanksEmailFlow';

const ThanksContainer = ({
    username,
    displayName,
    userFlow,
    parentHasAccount,
}) =>
    userFlow === 'direct' ? (
        <ThanksDirectFlow username={username} displayName={displayName} />
    ) : (
        <ThanksEmailFlow
            username={username}
            parentHasAccount={parentHasAccount}
        />
    );

ThanksContainer.displayName = 'ThanksContainer';

ThanksContainer.propTypes = {
    username: PropTypes.string,
    displayName: PropTypes.string,
    userFlow: PropTypes.string.isRequired,
    parentHasAccount: PropTypes.bool,
};

const mapStatetoProps = ({ thanks: { username, displayName, userFlow } }) => ({
    username,
    displayName,
    userFlow,
});

export default connect(mapStatetoProps)(ThanksContainer);

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm, propTypes as reduxFormPropTypes } from 'redux-form';
import { Button } from '@bbc-account/id-components';
import { errorShape } from '../../../propTypes';
import Layout from '../../pageLayout/layout';
import { password as validatePassword } from '../../../../shared/validation/validators';
import Form from '../../shared/form';
import FormError from '../../shared/formError';
import formNames from '../../../../shared/data/formNames';
import PasswordGroup from '../../shared/inputFields/passwordGroup';
import { asyncPasswordBreachCheck } from '../../../services/reduxAsyncValidators';
import { useShowExUkContent } from '../../../hooks/useShowExUkContent';

const passwordValidator = [password => validatePassword.validate([password])];

const ResetPasswordForm = ({ initialErrors = {}, jsEnabled }) => {
    const formError =
        initialErrors.general !== true ? initialErrors.general : undefined;

    const showExUkContent = useShowExUkContent();

    return (
        <Layout
            heading={
                <FormattedMessage
                    id={
                        showExUkContent
                            ? 'cama.createNewPasswordExUk'
                            : 'cama.createNewPassword'
                    }
                />
            }
        >
            <FormError name="general" error={formError} isAssertive />

            <Form method="post">
                <PasswordGroup
                    initialErrors={initialErrors}
                    jsEnabled={jsEnabled}
                    passwordLabel={<FormattedMessage id="label.password.new" />}
                    validate={passwordValidator}
                />

                <Button isFullWidth isSubmit id="submit-button">
                    <FormattedMessage id="button.continue.value" />
                </Button>
            </Form>
        </Layout>
    );
};

ResetPasswordForm.propTypes = {
    ...reduxFormPropTypes,
    initialErrors: PropTypes.shape({
        password: errorShape,
        general: errorShape,
    }),
    jsEnabled: PropTypes.bool.isRequired,
};

ResetPasswordForm.displayName = 'ResetPasswordForm';

const mapStateToProps = state => {
    const { resetPassword = {}, jsEnabled } = state;
    return {
        initialErrors: resetPassword.initialErrors || {},
        jsEnabled,
    };
};

export default connect(mapStateToProps)(
    reduxForm({
        form: formNames.RESET_PASSWORD,
        asyncValidate: asyncPasswordBreachCheck,
        asyncBlurFields: ['password'],
    })(ResetPasswordForm)
);

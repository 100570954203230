const trimForwardSlashes = string => string.replace(/(^\/|\/$)/g, '');

const buildPageName = givenPathname => {
    const trimmedPath = trimForwardSlashes(givenPathname);
    return trimmedPath
        ? `id.${trimmedPath.replace(/\//g, '-')}.page`
        : undefined;
};

export default buildPageName;

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import PageTitle from './pageTitle';
import internationalisation from './internationalisation';
import connectToState from './connectToState';
import jsDetection from './jsDetection';
import ScrollToTop from './scrollToTop';
import UpdatePageData from './updatePageData';

class ApplicationWrapper extends React.Component {
    componentDidMount() {
        if (document.body.classList) {
            document.body.classList.remove('pre-component-mount');
        }
    }

    render() {
        const { children, location } = this.props;

        return (
            <ScrollToTop>
                <PageTitle pathname={location.pathname} />
                {children}
                <UpdatePageData pathname={location.pathname} />
            </ScrollToTop>
        );
    }
}

ApplicationWrapper.displayName = 'ApplicationWrapper';

ApplicationWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
};

const mapStateToProps = ({ featureToggles }) => ({ featureToggles });

const connectedHoC = connect(mapStateToProps);

export { ApplicationWrapper };

export default jsDetection(
    connectToState(internationalisation(connectedHoC(ApplicationWrapper)), [
        'translations',
    ])
);

import PropTypes from 'prop-types';
import React from 'react';
import { pick } from 'lodash';

const connectToState = (Component, keys = []) => {
    const StateConnected = (props, { userContext }) => {
        const selectedContext = keys.length > 0;
        const context = selectedContext ? pick(userContext, keys) : userContext;

        return selectedContext ? (
            <Component {...context} {...props} />
        ) : (
            <Component userContext={context} {...props} />
        );
    };

    StateConnected.contextTypes = {
        userContext: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    };

    StateConnected.displayName = 'StateConnected';

    return StateConnected;
};

export default connectToState;

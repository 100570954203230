import React from 'react';

import { FormattedMessage } from 'react-intl';
import { useConsentAge } from '../../../../context/consentAge';

export function ParentOutsideUkConsentAgeMessage() {
    const { consentAge } = useConsentAge();

    return (
        <FormattedMessage
            id="register.parentLed.parentOutsideUKConsentAge"
            values={{ consentAge }}
        />
    );
}

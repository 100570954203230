import PropTypes from 'prop-types';
import React from 'react';
import ApplicationLinkContainer from '../shared/applicationLink/applicationLinkContainer';
import CloseIcon from '../shared/icons/closeIcon';
import * as config from '../../../server/config';
import { useStateContext } from '../../modules/stateContext';
import { getClient } from '../../../shared/data/clients';

const CloseButton = ({ asButton, closeUrl }) => {
    const {
        ptrt: { value: ptrtValue = config.get('defaultPtrt') } = {},
        thirdPartyRedirectUri,
        clientId,
    } = useStateContext();

    const redirectLocation = getClient(clientId).useNonFederatedU13Registration
        ? thirdPartyRedirectUri
        : closeUrl || ptrtValue;

    return (
        <ApplicationLinkContainer
            button={asButton}
            tertiary={asButton}
            external
            bypassChameleon
            eventData={{
                name: 'close-button',
            }}
            ariaLabel="Close and return to where you originally came from"
            href={redirectLocation}
            className="page__close"
        >
            <CloseIcon />
        </ApplicationLinkContainer>
    );
};

CloseButton.displayName = 'CloseButton';

CloseButton.propTypes = {
    asButton: PropTypes.bool,
    closeUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

export default CloseButton;

import React from 'react';
import { createStore } from 'redux';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { hydrate } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { keys, pick } from 'lodash';
import { ConsentAgeProvider } from './context/consentAge';
import reducer, { reducers } from './modules';
import LegacyStateProvider from './components/legacyStateProvider';
import routes from './routes';
import { StateProvider } from './modules/stateContext';

const EMPTY_OBJECT = {};

const startReactApplication = () => {
    const { locals } = window.bbcAccount;
    const loadReduxDevTools =
        process.env.NODE_ENV === 'production'
            ? () => undefined
            : /* eslint-disable no-underscore-dangle */
              () =>
                  window.__REDUX_DEVTOOLS_EXTENSION__ &&
                  window.__REDUX_DEVTOOLS_EXTENSION__();
    const initialState = pick(locals, keys(reducers)) || {};
    const store = createStore(reducer, initialState, loadReduxDevTools());

    return hydrate(
        <ReduxProvider store={store}>
            <StateProvider value={locals || EMPTY_OBJECT}>
                <LegacyStateProvider data={locals || EMPTY_OBJECT}>
                    <HelmetProvider>
                        <ConsentAgeProvider>
                            <BrowserRouter>{routes()}</BrowserRouter>
                        </ConsentAgeProvider>
                    </HelmetProvider>
                </LegacyStateProvider>
            </StateProvider>
        </ReduxProvider>,
        document.getElementById('container')
    );
};

export default startReactApplication;

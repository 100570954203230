import React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactRouterPropTypes from 'react-router-prop-types';
import Layout from '../pageLayout/layout';
import Form from '../shared/form';
import { parseQueryParams } from '../../../shared/utilities/url';
import OneTapSignIn from './oneTapSignIn';

const ConfirmIdentity = ({ location: { search: queryString } }) => {
    const { clientId } = parseQueryParams(queryString);

    return (
        <Layout
            heading={<FormattedMessage id="signIn.title.signInToYourAccount" />}
        >
            <Form method="post">
                <OneTapSignIn
                    clientId={clientId}
                    signoutUserWhenSwitchingAccount
                />
            </Form>
        </Layout>
    );
};

ConfirmIdentity.propTypes = {
    location: ReactRouterPropTypes.location.isRequired,
};

export default ConfirmIdentity;

import PropTypes from 'prop-types';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const createFieldNotificationsClassName = className =>
    classnames('field__notifications', className);

const FieldNotifications = ({
    promptId,
    itemIds,
    className,
    ariaDescribedBy,
}) => (
    <div
        className={createFieldNotificationsClassName(className)}
        id={ariaDescribedBy}
    >
        {promptId && (
            <p className="field__notifications-prompt">
                <FormattedMessage id={promptId} />
            </p>
        )}
        <ul>
            {itemIds.map(itemId => (
                <li className="field__notification" key={itemId}>
                    <span
                        aria-hidden="true"
                        className="field__notification-symbol"
                    >
                        &bull;
                    </span>
                    <FormattedMessage id={itemId} />
                </li>
            ))}
        </ul>
    </div>
);

FieldNotifications.displayName = 'FieldNotifications';

FieldNotifications.propTypes = {
    promptId: PropTypes.string,
    className: PropTypes.string,
    ariaDescribedBy: PropTypes.string,
    itemIds: PropTypes.arrayOf(PropTypes.string),
};

FieldNotifications.defaultProps = {
    className: '',
    itemIds: [],
};

export default FieldNotifications;

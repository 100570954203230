import React from 'react';
import PropTypes from 'prop-types';
import { FormStateProvider, useFormState } from '@bbc-account/id-formaxe';
import { useStateContext } from '../../modules/stateContext';
import { useNewslettersEvents } from '../../hooks/iframe/useNewslettersEvents';
import withEventTracking from '../withEventTracking';
import { StyledAuthFormIframe } from './formIframe.style';

const AuthFormIframe = ({ children, action }) => {
    const {
        signIn: { initialErrors, initialValues },
    } = useStateContext();

    const formState = useFormState({
        initialValues,
        initialErrors,
    });


    useNewslettersEvents();

    return (
        <StyledAuthFormIframe
            method="post"
            action={action}
            noValidate
            data-testid="form"
        >
            <FormStateProvider value={formState}>{children}</FormStateProvider>
        </StyledAuthFormIframe>
    );
};

AuthFormIframe.displayName = 'AuthFormIframe';

AuthFormIframe.propTypes = {
    action: PropTypes.string,
    children: PropTypes.node,
};

export default withEventTracking(AuthFormIframe);

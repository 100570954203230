import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { LinkButtonForm } from '../../../../forms/linkButton';
import authPages from '../../../../../../shared/data/authPages';
import userJourneys from '../../../../../../shared/data/userJourneys';
import { AUTH_PATH } from '../../../../../../shared/endpoints/account';
import renderQueryString from '../../../../../../shared/urls/renderQueryString';
import { useStateContext } from '../../../../../modules/stateContext';
import { IframeResizeObserver } from '../../../../../utilities/observers';
import { IFRAME_IDENTIFIER } from '../../../constants';
import { AuthIframePasswordInput } from '../../../inputs/iframe';
import AuthFormIframe from '../../../formIframe';
import {
    StyledIframePasswordAdditionalInfo,
    StyledIframePasswordForm,
} from './iframePassword.style';
import {
    IframePasswordCreatePasswordLink,
    IframePasswordForgotPasswordLink,
} from './resetPasswordLink';

const eventContainer = authPages.IFRAME_PASSWORD_FORM;

const magicLinkEventTracker = {
    container: eventContainer,
    name: 'magic-link-cta',
    result: AUTH_PATH,
    type: 'link',
};

export function IframePasswordForm() {
    const userContext = useStateContext();

    const {
        featureToggles: {
            createPasswordContent,
            createPasswordIframe,
            magicLinkSigninExUkIframe,
        },
        signIn: {
            data,
            context: { hasPassword },
            initialValues: { isMinimumAge } = {},
        },
    } = userContext;

    const username = data[IFRAME_IDENTIFIER.FIELD_NAME];

    const passwordAction = useMemo(
        () =>
            `/auth/password${renderQueryString.call(userContext, {
                layout: 'iframe',
            })}`,
        [userContext]
    );

    const magicLinkUrl = useMemo(
        () =>
            `${AUTH_PATH}${renderQueryString.call(userContext, {
                strategy: userJourneys.MAGIC_LINK,
                userJourney: userJourneys.PASSWORDLESS,
            })}`,
        [userContext]
    );

    return (
        <IframeResizeObserver>
            <StyledIframePasswordForm>
                <AuthFormIframe action={passwordAction}>
                    <AuthIframePasswordInput />
                </AuthFormIframe>
                <StyledIframePasswordAdditionalInfo layout="space-between">
                    {createPasswordContent &&
                    createPasswordIframe &&
                    !hasPassword ? (
                        <IframePasswordCreatePasswordLink />
                    ) : (
                        <IframePasswordForgotPasswordLink />
                    )}
                    {magicLinkSigninExUkIframe && (
                        <LinkButtonForm
                            action={magicLinkUrl}
                            data={{
                                username,
                                isMinimumAge,
                            }}
                            eventTracker={magicLinkEventTracker}
                            method="post"
                        >
                            <FormattedMessage id="auth.password.magicLink.label" />
                        </LinkButtonForm>
                    )}
                </StyledIframePasswordAdditionalInfo>
            </StyledIframePasswordForm>
        </IframeResizeObserver>
    );
}

IframePasswordForm.displayName = 'IframePasswordForm';

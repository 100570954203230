import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactRouterPropTypes from 'react-router-prop-types';
import Layout from '../../pageLayout/layout';
import CloseButton from '../../pageLayout/closeButton';
import { useStateContext } from '../../../modules/stateContext';
import { PROFILE_PICKER_URL } from '../../../../shared/endpoints/account';
import {
    buildUrlWithPtrt,
    parseQueryParams,
} from '../../../../shared/utilities/url';

const RegisterProfileLayout = ({
    children,
    location: { search: queryString },
}) => {
    const {
        ptrt: { value: ptrtUrl },
    } = useStateContext();

    const { returnToPicker } = parseQueryParams(queryString);

    const closeUrl = returnToPicker
        ? buildUrlWithPtrt(PROFILE_PICKER_URL, ptrtUrl)
        : ptrtUrl;

    return (
        <Layout
            heading={<FormattedMessage id="register.profile.title" />}
            showLoadingSpinner
            closeButton={<CloseButton closeUrl={closeUrl} />}
        >
            {children}
        </Layout>
    );
};

RegisterProfileLayout.displayName = 'RegisterProfileLayout';

RegisterProfileLayout.propTypes = {
    children: PropTypes.node.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
};

export default RegisterProfileLayout;

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import ContinueButton from '../../shared/continueButton';
import FormattedMessageWithAttributes from '../../shared/formattedMessageWithAttributes';
import Text from '../../shared/text';

const VerifyEmailSorryUnder13 = ({ signOutSignInUrl }) => {
    return (
        <Fragment>
            <Text>
                <FormattedMessage id="emailVerification.sorryUnder13.requestNewEmail" />
            </Text>
            <FormattedMessageWithAttributes
                id="emailVerification.sorryUnder13.pleaseSignOut"
                attributes={{
                    href: signOutSignInUrl,
                    external: true,
                }}
            />
            <ContinueButton />
        </Fragment>
    );
};

VerifyEmailSorryUnder13.displayName = 'VerifyEmailSorryUnder13';

VerifyEmailSorryUnder13.propTypes = {
    signOutSignInUrl: PropTypes.string.isRequired,
};

export default VerifyEmailSorryUnder13;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Avatar = ({ text, type, disabled }) => {
    if (!text) {
        return null;
    }

    const initial = text.slice(0, 1).toUpperCase();

    const className = classNames('avatar', {
        'avatar--dodeca': type === 'primary',
        'avatar--hex': type === 'secondary',
        'avatar--octadeca': type === 'picker',
        'avatar--disabled': disabled,
    });

    return (
        <span className={className} aria-hidden="true">
            {initial}
        </span>
    );
};

Avatar.displayName = 'Avatar';

Avatar.propTypes = {
    text: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.oneOf(['primary', 'secondary', 'picker']),
};

Avatar.defaultProps = {
    disabled: false,
};

export default Avatar;

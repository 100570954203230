import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../../pageLayout/layout';

const VerifyEmailSorryLayout = ({ children, expiredToken }) => (
    <Layout
        heading={
            <FormattedMessage
                id={expiredToken ? 'cama.expiredLink' : 'cama.invalidLink'}
            />
        }
    >
        {children}
    </Layout>
);

VerifyEmailSorryLayout.displayName = 'VerifyEmailSorryLayout';

VerifyEmailSorryLayout.propTypes = {
    children: PropTypes.node.isRequired,
    expiredToken: PropTypes.bool.isRequired,
};

export default VerifyEmailSorryLayout;

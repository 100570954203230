import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
    pageBeforeUnloadEventContext,
    pageBeforeUnloadEventDispatchContext,
} from './pageBeforeUnloadEventContext';
import { pageBeforeUnloadEventContextReducer } from './pageBeforeUnloadEventContextReducer';

export const PageBeforeUnloadEventProvider = ({
    children,
    initBeforeUnloadEventListenersEnabled,
    onBeforeUnloadEventListenersChange,
}) => {
    const [state, dispatch] = useReducer(pageBeforeUnloadEventContextReducer, {
        beforeUnloadEventListenersEnabled: initBeforeUnloadEventListenersEnabled,
    });

    const { beforeUnloadEventListenersEnabled } = state;

    useEffect(() => {
        if (typeof onBeforeUnloadEventListenersChange !== 'undefined') {
            onBeforeUnloadEventListenersChange(
                beforeUnloadEventListenersEnabled
            );
        }
    }, [beforeUnloadEventListenersEnabled, onBeforeUnloadEventListenersChange]);

    return (
        <pageBeforeUnloadEventContext.Provider value={state}>
            <pageBeforeUnloadEventDispatchContext.Provider value={dispatch}>
                {children}
            </pageBeforeUnloadEventDispatchContext.Provider>
        </pageBeforeUnloadEventContext.Provider>
    );
};

PageBeforeUnloadEventProvider.propTypes = {
    children: PropTypes.node,
    initBeforeUnloadEventListenersEnabled: PropTypes.bool,
    onBeforeUnloadEventListenersChange: PropTypes.func,
};

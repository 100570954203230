import React, { Fragment } from 'react';
import { Heading } from '@bbc-account/id-components';
import { FormattedMessage } from 'react-intl';
import Text from '../../shared/text';

const HelpWhatEmailsAndNewsLetters = () => (
    <Fragment>
        <div className="u-padding-bottom-quad">
            <Heading level={1} fontScale="headlineSmall">
                <FormattedMessage id="help.whatEmailsAndNewsletters.title" />
            </Heading>
        </div>

        <Text>
            <FormattedMessage id="help.whatEmailsAndNewsletters.whenYouRegister" />
        </Text>

        <Text>
            <FormattedMessage id="help.whatEmailsAndNewsletters.regularNewsletters" />
        </Text>

        <Text>
            <FormattedMessage id="help.whatEmailsAndNewsletters.permission" />
        </Text>

        <div className="u-padding-bottom-double u-padding-bottom--tablet">
            <Heading level={2} fontScale="sectionHeading">
                <FormattedMessage id="help.whatEmailsAndNewsletters.outsideTheUk" />
            </Heading>
        </div>
        <Text>
            <FormattedMessage id="help.whatEmailsAndNewsletters.bbcWorldwide" />
        </Text>

        <div className="u-padding-bottom-double u-padding-bottom--tablet">
            <Heading level={2} fontScale="sectionHeading">
                <FormattedMessage id="help.whatEmailsAndNewsletters.otherNewsletters" />
            </Heading>
        </div>
        <Text>
            <FormattedMessage id="help.whatEmailsAndNewsletters.specificNewsletters" />
        </Text>

        <div className="u-padding-bottom-double u-padding-bottom--tablet">
            <Heading level={2} fontScale="sectionHeading">
                <FormattedMessage id="help.whatEmailsAndNewsletters.dontWantToReceiveAnymore" />
            </Heading>
        </div>
        <Text>
            <FormattedMessage id="help.whatEmailsAndNewsletters.youCanUnsubscribe" />
        </Text>

        <div className="u-padding-bottom-double u-padding-bottom--tablet">
            <Heading level={2} fontScale="sectionHeading">
                <FormattedMessage id="help.whatEmailsAndNewsletters.someEmailsEssential" />
            </Heading>
        </div>
        <Text>
            <FormattedMessage id="help.whatEmailsAndNewsletters.vitalEmails" />
        </Text>

        <div className="u-padding-bottom-double u-padding-bottom--tablet">
            <Heading level={2} fontScale="sectionHeading">
                <FormattedMessage id="help.whatEmailsAndNewsletters.dataIsConfidential" />
            </Heading>
        </div>
        <Text>
            <FormattedMessage id="help.whatEmailsAndNewsletters.dataIsAnonymous" />
        </Text>
    </Fragment>
);

export default HelpWhatEmailsAndNewsLetters;

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm, propTypes as reduxFormPropTypes } from 'redux-form';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Button } from '@bbc-account/id-components';
import { errorShape } from '../../propTypes';
import Layout from '../pageLayout/layout';
import Text from '../shared/text';
import EmailField from '../shared/inputFields/emailField';
import Form from '../shared/form';
import FormError from '../shared/formError';
import formNames from '../../../shared/data/formNames';
import FormattedMessageWithAttributes from '../shared/formattedMessageWithAttributes';
import { HELP_FEDERATED_PROBLEMS_WITH_MY_PASSWORD_PATH } from '../../../shared/endpoints/account';
import { HELP_PASSWORD_URL } from '../../../shared/endpoints/usingTheBbc';
import { buildHrefFromFederatedStatus } from '../../../shared/utilities/url';
import { getSessionStorageItemAndRemove } from '../../hooks/useSessionStorage';
import { useShowExUkContent } from '../../hooks/useShowExUkContent';
import { HelpLinks } from '../auth/helpLinks';
import { ONE_TIME_PERSISTENT_USER_IDENTIFIER_KEY } from '../forms';
import {
    ONE_TIME_PERSISTENT_FORGOTTEN_PASSWORD_EMAIL,
    useForgottenPasswordEmail,
} from './useForgottenPasswordEmail';
import { useStateContext } from '../../modules/stateContext';
import isEmail from '../../../shared/utilities/isEmail';

const helpLinksItems = [
    {
        href: HELP_PASSWORD_URL,
        label: 'passwordReset.helpLink.signIn',
    },
];

const buildPasswordResetMessage = ({
    isFederated,
    routerLocation,
    showExUkContent,
}) => {
    const helpPasswordHref = buildHrefFromFederatedStatus({
        isFederated,
        federatedUrl: HELP_FEDERATED_PROBLEMS_WITH_MY_PASSWORD_PATH,
        nonFederatedUrl: HELP_PASSWORD_URL,
        routerLocation,
    });

    return (
        <FormattedMessageWithAttributes
            id={
                showExUkContent
                    ? 'passwordReset.message.enterEmailExUk'
                    : 'passwordReset.message.enterEmail'
            }
            attributes={{
                href: helpPasswordHref,
                external: true,
                withState: isFederated,
            }}
        />
    );
};

const ForgottenPasswordForm = ({
    initialValues,
    initialErrors = {},
    isFederated,
    location: routerLocation,
}) => {
    const [email, setEmail] = useState();

    const { email: initialEmail } = initialValues;

    const formError =
        initialErrors.general !== true ? initialErrors.general : undefined;

    const showExUkContent = useShowExUkContent();

    const {
        featureToggles: { persistentIdentifier },
    } = useStateContext();

    const passwordResetMessage = buildPasswordResetMessage({
        isFederated,
        routerLocation,
        showExUkContent,
    });

    const {
        setEmailToSessionStorage: setForgottenPasswordEmailToSessionStorage,
    } = useForgottenPasswordEmail();

    const setEmailToSessionStorage = useCallback(
        newEmail => {
            if (newEmail && persistentIdentifier) {
                setForgottenPasswordEmailToSessionStorage(newEmail);
            }
        },
        [setForgottenPasswordEmailToSessionStorage]
    );

    const handleSubmitButtonClick = useCallback(() => {
        setEmailToSessionStorage(email);
    }, [email, setEmailToSessionStorage]);

    const handleEmailFieldChange = useCallback(event => {
        setEmail(event.target?.value);
    }, []);

    useEffect(() => {
        setEmail(initialEmail);
        setEmailToSessionStorage(initialEmail);
    }, [initialEmail, setEmailToSessionStorage]);

    return (
        <Layout heading={<FormattedMessage id="passwordReset.title" />}>
            <Text introduction>{passwordResetMessage}</Text>
            <FormError name="general" error={formError} isAssertive />

            <Form method="post">
                <EmailField
                    initialError={initialErrors.email}
                    onChange={handleEmailFieldChange}
                />

                <Button
                    isFullWidth
                    isSubmit
                    id="submit-button"
                    onClick={handleSubmitButtonClick}
                >
                    <FormattedMessage
                        id={
                            showExUkContent
                                ? 'passwordReset.button.sendEmail.valueExUk'
                                : 'passwordReset.button.sendEmail.value'
                        }
                    />
                </Button>
            </Form>
            {showExUkContent && (
                <HelpLinks
                    className="button__label u-margin-bottom-none"
                    items={helpLinksItems}
                    showMoreHelpLink={false}
                />
            )}
        </Layout>
    );
};

ForgottenPasswordForm.propTypes = {
    ...reduxFormPropTypes,
    initialErrors: PropTypes.shape({
        email: errorShape,
        general: errorShape,
    }),
    isFederated: PropTypes.bool.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
};

ForgottenPasswordForm.displayName = 'ForgottenPasswordForm';

const mapStateToProps = ({
    forgottenPassword = {},
    isFederated,
    featureToggles,
}) => {
    const initialValues = forgottenPassword.data || {};

    if (featureToggles.persistentIdentifier) {
        let persistedEmail = getSessionStorageItemAndRemove(
            ONE_TIME_PERSISTENT_USER_IDENTIFIER_KEY
        );

        if (!persistedEmail) {
            persistedEmail = getSessionStorageItemAndRemove(
                ONE_TIME_PERSISTENT_FORGOTTEN_PASSWORD_EMAIL
            );
        }

        if (typeof persistedEmail === 'string' && isEmail(persistedEmail)) {
            initialValues.email = persistedEmail;
        }
    }

    return {
        initialValues,
        initialErrors: forgottenPassword.initialErrors || {},
        isFederated,
    };
};

export default connect(mapStateToProps)(
    reduxForm({
        form: formNames.FORGOTTEN_PASSWORD,
    })(ForgottenPasswordForm)
);

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { pick, over } from 'lodash';
import connectToState from '../connectToState';
import withEventTracking from '../withEventTracking';
import renderQueryString from '../../../shared/urls/renderQueryString';

const SUBMIT_EVENT_DETAILS = {
    label: 'submit',
};

const ALLOWLISTED_PROP_KEYS = [
    'acceptCharset',
    'className',
    'data-testid',
    'action',
    'autoComplete',
    'children',
    'encType',
    'method',
    'onSubmit',
    'name',
    'jsEnabled',
    'target',
    'createEventHandler',
    'userContext',
];

const Form = props => {
    const {
        children,
        'data-testid': dataTestId = 'form',
        userContext,
        jsEnabled,
        createEventHandler,
        onSubmit: givenOnSubmit,
        ...formProps
    } = pick(props, ALLOWLISTED_PROP_KEYS);
    const actionWithState = props.action
        ? `${props.action}${renderQueryString.call(userContext)}`
        : props.action;

    const eventHandler = createEventHandler(SUBMIT_EVENT_DETAILS);
    const onSubmit = givenOnSubmit
        ? over([eventHandler, givenOnSubmit])
        : eventHandler;

    return (
        <form
            {...formProps}
            onSubmit={onSubmit}
            action={actionWithState}
            noValidate
            data-testid={dataTestId}
        >
            <input type="hidden" name="jsEnabled" value={jsEnabled} />
            {children}
        </form>
    );
};

Form.displayName = 'Form';

Form.propTypes = {
    acceptCharset: PropTypes.string,
    className: PropTypes.string,
    'data-testid': PropTypes.string,
    action: PropTypes.string,
    autoComplete: PropTypes.oneOf(['on', 'off']),
    children: PropTypes.node,
    encType: PropTypes.string,
    method: PropTypes.oneOf(['get', 'post']),
    onSubmit: PropTypes.func,
    name: PropTypes.string,
    jsEnabled: PropTypes.bool.isRequired,
    target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
    createEventHandler: PropTypes.func.isRequired,
    userContext: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

Form.defaultProps = {
    method: 'post',
};

export default connect(({ jsEnabled }) => ({
    jsEnabled,
}))(connectToState(withEventTracking(Form)));

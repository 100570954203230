import React from 'react';
import PropTypes from 'prop-types';
import { useResizeObserver } from './useResizeObserver';

export function ResizeObserver({ children, onResize }) {
    const { ref } = useResizeObserver({ onResize });

    return <div ref={ref}>{children}</div>;
}

ResizeObserver.propTypes = {
    children: PropTypes.node,
    onResize: PropTypes.func,
};

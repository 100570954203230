const ENABLED = 'account/jsEnabled/ENABLED';

export default function reducer(state = false, action = {}) {
    switch (action.type) {
        case ENABLED: {
            return true;
        }

        default:
            return state;
    }
}

export function setJsEnabled() {
    return {
        type: ENABLED,
    };
}

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FlexContainer } from '../../gel/containers/flex/flexContainer';

import './checkboxStatic.scss';

const CheckboxStatic = ({
    checked,
    label,
    checkedDescription,
    uncheckedDescription,
}) => {
    const classes = classnames('sb-checkbox-static', {
        'sb-checkbox-static--checked': checked,
    });

    return (
        <FlexContainer alignCenter>
            <p className="sb-checkbox-static__label">{label}</p>
            <div
                className={classes}
                title={checked ? checkedDescription : uncheckedDescription}
            >
                <span className="sb-checkbox-static__hidden-content">
                    {checked ? checkedDescription : uncheckedDescription}
                </span>
            </div>
        </FlexContainer>
    );
};

CheckboxStatic.propTypes = {
    /**
     This sets the checked value of the checkbox input
     */
    checked: PropTypes.bool,
    /**
    The text that is shown next to the checkbox submitted
    */
    label: PropTypes.string,
    /**
     This value is what is shown to screenreader users
     */
    checkedDescription: PropTypes.string,
    /**
     This sets the checked value of the checkbox input
     */
    uncheckedDescription: PropTypes.string,
};

CheckboxStatic.defaultProps = {
    checked: false,
    checkedDescription: 'Checked',
    uncheckedDescription: 'Unchecked',
};

CheckboxStatic.displayName = 'CheckboxStatic';

export { CheckboxStatic };

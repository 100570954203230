import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Track from './analytics/track';
import { useStateContext } from '../../modules/stateContext';

const ShowHideToggle = props => {
    const { children, className, ...anchorProps } = props;

    const { clientId } = useStateContext();

    return (
        <Track title="show-password" ignoreViews metadata={{ clientId }}>
            <button
                type="button"
                className={classNames(
                    'field__toggle field__toggle-link',
                    className
                )}
                {...anchorProps}
            >
                {children}
            </button>
        </Track>
    );
};

ShowHideToggle.displayName = 'ShowHideToggle';

ShowHideToggle.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

export default ShowHideToggle;

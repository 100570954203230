function touchEnabled() {
    if (
        'ontouchstart' in window ||
        (window.DocumentTouch && document instanceof window.DocumentTouch) ||
        (window.hasOwnProperty &&
            // eslint-disable-next-line no-prototype-builtins
            (window.hasOwnProperty('ontouchstart') ||
                (window.DocumentTouch &&
                    document instanceof window.DocumentTouch) ||
                navigator.MaxTouchPoints > 0 ||
                navigator.msMaxTouchPoints))
    ) {
        return true;
    }

    // Fallback for certain samsung devices that fail above but pass below... :/
    if ('ontouchstart' in window) {
        return true;
    }

    return false;
}

export default function applyTouchClasses() {
    if (touchEnabled()) {
        document.documentElement.className += ' touchevents';
    } else {
        document.documentElement.className += ' no-touchevents';
    }
}

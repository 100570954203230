import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { FormattedMessage } from 'react-intl';
import Layout from '../../pageLayout/layout';
import ApplicationLinkContainer from '../../shared/applicationLink/applicationLinkContainer';

const VerifyPasswordSorry = ({ location }) => {
    const isExpired = location.search.includes('reason=expired');

    return (
        <Layout
            heading={
                <FormattedMessage
                    id={isExpired ? 'cama.expiredLink' : 'cama.invalidLink'}
                />
            }
        >
            <ApplicationLinkContainer
                button
                primary
                fullWidth
                href="/signin/forgotten"
            >
                <FormattedMessage id="cama.button.resendEmail" />
            </ApplicationLinkContainer>
        </Layout>
    );
};

VerifyPasswordSorry.displayName = 'VerifyPasswordSorry';

VerifyPasswordSorry.propTypes = {
    location: ReactRouterPropTypes.location.isRequired,
};

export default VerifyPasswordSorry;

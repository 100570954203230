import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TvIntroduction from './introduction';
import TvPinEntry from './pinEntry';

const TvLandingPage = ({ idctaHtml, showPinEntry, showSignInMessage }) =>
    showPinEntry ? (
        <TvPinEntry />
    ) : (
        <TvIntroduction
            idctaHtml={idctaHtml}
            showSignInMessage={showSignInMessage}
        />
    );

TvLandingPage.displayName = 'TvLandingPage';

TvLandingPage.propTypes = {
    idctaHtml: PropTypes.string,
    showPinEntry: PropTypes.bool.isRequired,
    showSignInMessage: PropTypes.bool,
};

const mapStateToProps = ({
    tv: { idctaHtml, showPinEntry, showSignInMessage },
}) => ({
    idctaHtml,
    showPinEntry,
    showSignInMessage,
});

export default connect(mapStateToProps)(TvLandingPage);

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@bbc-account/id-components';
import { FormattedMessage } from 'react-intl';
import ApplicationLinkContainer from '../shared/applicationLink/applicationLinkContainer';
import {
    REGISTER_PATH,
    REGISTER_FEDERATED_PATH,
} from '../../../shared/endpoints/account';

const RegisterCta = ({ isFederated }) => (
    <Fragment>
        <div className="u-padding-bottom-double u-padding-bottom--tablet">
            <Heading level={2} fontScale="sectionHeading">
                <FormattedMessage id="signIn.noAccount.title" />
            </Heading>
        </div>
        <ApplicationLinkContainer
            href={isFederated ? REGISTER_FEDERATED_PATH : REGISTER_PATH}
            primary
        >
            <FormattedMessage id="signIn.noAccount.link" />
        </ApplicationLinkContainer>
    </Fragment>
);

RegisterCta.displayName = 'RegisterCta';

RegisterCta.propTypes = {
    isFederated: PropTypes.bool,
};

RegisterCta.defaultProps = {
    isFederated: false,
};

export default RegisterCta;

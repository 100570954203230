import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import buildPageName from '../../shared/utilities/buildPageName';

function UpdatePageData() {
    const { pathname } = useLocation();

    const hasMounted = useRef(false);

    useEffect(() => {
        if (!hasMounted.current) {
            hasMounted.current = true;
            return;
        }

        const pageName = buildPageName(pathname);
        const updatedPageData = {
            detail: {
                page: {
                    name: pageName,
                },
            },
        };

        document.dispatchEvent(
            new CustomEvent('bbc-page-reset', updatedPageData)
        );
    }, [pathname]);

    return null;
}

UpdatePageData.displayName = 'UpdatePageData';

export default UpdatePageData;

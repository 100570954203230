import renderQueryString from '../../shared/urls/renderQueryString';
import { useStateContext } from './stateContext';

export const useStatefulQueryString = (options = {}) => {
    const {
        additionalParameters = {},
        omittedParameters = [],
        serialisationKey = undefined,
    } = options;

    const state = useStateContext();

    return renderQueryString.call(
        state,
        additionalParameters,
        omittedParameters,
        serialisationKey
    );
};

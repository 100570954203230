import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { password as validatePassword } from '../../../../shared/validation/validators';
import PasswordInput from './passwordInput';

const passwordValidator = password => validatePassword.validate([password]);

const PasswordSignInField = props => {
    const { jsEnabled } = props;

    return (
        <Field
            id="password-input"
            name="password"
            component={PasswordInput}
            jsEnabled={jsEnabled}
            autoComplete="current-password"
            required
            label={<FormattedMessage id="label.password.title" />}
            validate={passwordValidator}
            {...props}
        />
    );
};

PasswordSignInField.displayName = 'PasswordSignInField';

PasswordSignInField.propTypes = {
    jsEnabled: PropTypes.bool.isRequired,
};

export default PasswordSignInField;

import { useMemo } from 'react';
import { useLocation } from 'react-router';

export function useLocationSearchParam(name) {
    const { search } = useLocation();

    const value = useMemo(() => {
        const searchParams = new URLSearchParams(search);

        return searchParams.get(name);
    }, [search]);

    return value;
}

import React from 'react';
import { useIntl } from 'react-intl';
import { FORGOT_PASSWORD_IFRAME_SIGNIN } from '../../../../../../../shared/endpoints/account';
import { IframePasswordResetPasswordLink } from './iframePasswordResetPasswordLink';

export function IframePasswordForgotPasswordLink() {
    const intl = useIntl();

    const label = intl.formatMessage({
        id: 'auth.iframe.forgotPassword.value',
    });

    return (
        <IframePasswordResetPasswordLink
            label={label}
            url={FORGOT_PASSWORD_IFRAME_SIGNIN}
        />
    );
}

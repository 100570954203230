import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { email as emailValidator } from '../../../../shared/validation/validators';
import TextInput from '../textInput';

const validator = email => emailValidator.validate([email]);

const EmailField = props => (
    <Field
        id="user-identifier-input"
        name="email"
        autoComplete="email"
        required
        type="email"
        label={<FormattedMessage id="label.email.title" />}
        component={TextInput}
        validate={validator}
        {...props}
    />
);

EmailField.displayName = 'EmailField';

EmailField.propTypes = {
    validate: PropTypes.func,
};

export default EmailField;

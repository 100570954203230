import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import formNames from '../../../shared/data/formNames';
import SignIn from './signIn';
import { removeInitialStateErrors } from '../../modules/initialFormState';
import { clearPassword } from '../../modules/reduxForm';

const mapStatetoProps = ({
    isNativeMobileApplication,
    jsEnabled,
    signIn: { initialErrors = {}, data: signInData = {}, allowUnder13s },
}) => ({
    initialErrors,
    initialValues: { attempts: 0, ...signInData },
    usernameAllowed: allowUnder13s,
    additionalErrorSentence: allowUnder13s
        ? undefined
        : 'validation.signIn.underAge',
    isNativeMobileApplication,
    jsEnabled,
});

export default connect(mapStatetoProps, {
    clearPassword,
    removeInitialStateErrors,
})(
    reduxForm({
        form: formNames.SIGN_IN,
        destroyOnUnmount: false,
    })(SignIn)
);

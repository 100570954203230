export function getSessionStorage() {
    const { sessionStorage } = global;

    if (typeof sessionStorage === 'undefined') {
        return undefined;
    }

    return sessionStorage;
}

export function getSessionStorageItemAndRemove(key) {
    const sessionStorage = getSessionStorage();

    if (typeof sessionStorage === 'undefined') {
        return undefined;
    }

    const value = sessionStorage.getItem(key);

    sessionStorage.removeItem(key);

    return value;
}

export function useSessionStorage() {
    const sessionStorage = getSessionStorage();

    return {
        getSessionStorageItemAndRemove,
        sessionStorage,
    };
}

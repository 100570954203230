import React from 'react';
import { Switch, Route } from 'react-router-dom';

import SignInLayout from '../components/signIn/signInLayout';
import AccountLockedContainer from '../components/signIn/accountLocked';
import AccountReactivation from '../components/accountReactivation/accountReactivation';
import ConfirmIdentity from '../components/otsi/confimIdentity';
import NeedHelpIndexContainer from '../components/forgotten/needHelpIndex';
import NeedHelpForgottenPasswordForm from '../components/forgotten/forgottenPasswordForm';
import NeedHelpForgottenCredentials from '../components/forgotten/forgottenCredentials';
import NeedHelpForgottenPasswordConfirmation from '../components/forgotten/confirmation';
import ReauthenticationLayout from '../components/signIn/reauthenticationLayout';
import SignInOtsi from '../components/otsi/signIn';
import VerifyEmailComplete from '../components/signIn/verifyEmail/complete';
import VerifyEmailAlreadyVerified from '../components/signIn/verifyEmail/alreadyVerified';
import VerifyEmailResendLimit from '../components/signIn/verifyEmail/resendLimit';
import VerifyEmailSorryContainer from '../components/signIn/verifyEmail/sorryContainer';
import VerifyEmailThanks from '../components/signIn/verifyEmail/thanks';
import VerifyPassword from '../components/signIn/verifyPassword';
import VerifyPasswordThanks from '../components/signIn/verifyPassword/thanks';
import VerifyPasswordSorry from '../components/signIn/verifyPassword/sorry';
import AccountLockedEmailContainer from '../components/signIn/accountLockedEmail';

function SignIn() {
    return (
        <Switch>
            <Route exact path="/signin" component={SignInLayout} />

            <Route path="/signin/otsi" component={SignInOtsi} />

            <Route path="/signin/identity" component={ConfirmIdentity} />

            <Route
                path="/signin/locked/email"
                component={AccountLockedEmailContainer}
            />

            <Route path="/signin/locked" component={AccountLockedContainer} />

            <Route exact path="/signin/stay" component={AccountReactivation} />

            <Route
                path="/signin/reauthenticate"
                component={ReauthenticationLayout}
            />

            <Route path="/signin/help" component={NeedHelpIndexContainer} />

            <Route
                exact
                path="/signin/forgotten"
                component={NeedHelpForgottenPasswordForm}
            />

            <Route
                exact
                path="/signin/create-password"
                component={NeedHelpForgottenPasswordForm}
            />

            <Route
                path="/signin/forgotten/credentials"
                component={NeedHelpForgottenCredentials}
            />

            <Route
                path="/signin/forgotten/confirmation"
                component={NeedHelpForgottenPasswordConfirmation}
            />

            <Route
                path="/signin/forgotten/verify"
                component={NeedHelpForgottenPasswordConfirmation}
            />

            <Route
                path="/signin/create-password/verify"
                component={NeedHelpForgottenPasswordConfirmation}
            />

            <Route
                path="/signin/verify/email/complete"
                component={VerifyEmailComplete}
            />

            <Route
                path="/signin/verify/email/already-verified"
                component={VerifyEmailAlreadyVerified}
            />

            <Route
                path="/signin/verify/email/resend-limit"
                component={VerifyEmailResendLimit}
            />

            <Route
                path="/signin/verify/email/sorry"
                component={VerifyEmailSorryContainer}
            />

            <Route
                path="/signin/verify/email/thanks"
                component={VerifyEmailThanks}
            />

            <Route
                exact
                path="/signin/verify/password"
                component={VerifyPassword}
            />

            <Route
                path="/signin/verify/password/thanks"
                component={VerifyPasswordThanks}
            />

            <Route
                path="/signin/verify/password/sorry"
                component={VerifyPasswordSorry}
            />
        </Switch>
    );
}

export default SignIn;

import React, { Fragment } from 'react';
import { Heading } from '@bbc-account/id-components';
import { FormattedMessage } from 'react-intl';
import Text from '../../shared/text';

const HelpProblemsWithMyEmailAddress = () => (
    <Fragment>
        <div className="u-padding-bottom-quad">
            <Heading level={1} fontScale="headlineSmall">
                <FormattedMessage id="help.problemsWithMyEmailAddress.title" />
            </Heading>
        </div>
        <Text>
            <FormattedMessage id="help.problemsWithMyEmailAddress.toCheck" />
        </Text>

        <ol>
            <Text listItem>
                <FormattedMessage id="help.problemsWithMyEmailAddress.checkFormatting" />
            </Text>

            <Text listItem>
                <FormattedMessage id="help.problemsWithMyEmailAddress.email" />
            </Text>

            <Text listItem>
                <FormattedMessage id="help.problemsWithMyEmailAddress.registerWhenUnder13" />
            </Text>

            <Text listItem>
                <FormattedMessage id="help.problemsWithMyEmailAddress.dormant" />
            </Text>
        </ol>
    </Fragment>
);

export default HelpProblemsWithMyEmailAddress;

import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../pageLayout/layout';
import Text from '../shared/text';
import FormattedMessageWithAttributes from '../shared/formattedMessageWithAttributes';
import { FORGOTTEN_PASSWORD_PATH } from '../../../shared/endpoints/account';
import { useShouldRenderOrRedirect } from './useShouldRenderOrRedirect';
import { useStateContext } from '../../modules/stateContext';
import useJsEnabled from '../../hooks/useJsEnabled';
import { ONE_TIME_PERSISTENT_USER_IDENTIFIER_KEY } from '../forms';
import { getSessionStorageItemAndRemove } from '../../hooks/useSessionStorage';
import { HELP_PASSWORD_URL } from '../../../shared/endpoints/usingTheBbc';
import MagicLinkCta from '../shared/magicLinkCta';
import actions from '../../../shared/data/actions';

const AccountLocked = () => {
    useShouldRenderOrRedirect();

    const { action, featureToggles } = useStateContext();

    const identifier = useMemo(
        () =>
            getSessionStorageItemAndRemove(
                ONE_TIME_PERSISTENT_USER_IDENTIFIER_KEY
            ),
        []
    );

    const isJsEnabled = useJsEnabled();
    const hasComeFromSignInJourney = action === actions.SIGN_IN;

    const isEligibleForMagicLinks =
        featureToggles.magicLinksAccountLocked &&
        featureToggles.magicLinks &&
        hasComeFromSignInJourney &&
        isJsEnabled;

    return (
        <Layout heading={<FormattedMessage id="accountLocked.title" />}>
            <div className="u-hide-pre-component-mount">
                {isEligibleForMagicLinks ? (
                    <>
                        <Text>
                            <FormattedMessage id="accountLocked.magicLink.intro" />
                        </Text>
                        <MagicLinkCta email={identifier} />
                        <Text className="u-margin-top-double">
                            <FormattedMessage id="accountLocked.magicLink.explainer" />
                        </Text>
                        <Text>
                            <FormattedMessageWithAttributes
                                id="accountLocked.magicLink.getHelp"
                                attributes={{
                                    href: HELP_PASSWORD_URL,
                                    external: true,
                                    withState: true,
                                }}
                            />
                        </Text>
                    </>
                ) : (
                    <Text>
                        <FormattedMessageWithAttributes
                            id="accountLocked.message.resetPassword"
                            attributes={{
                                href: FORGOTTEN_PASSWORD_PATH,
                                external: true,
                                withState: true,
                                primary: true,
                            }}
                        />
                    </Text>
                )}
            </div>
        </Layout>
    );
};

AccountLocked.displayName = 'AccountLocked';

export default AccountLocked;

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { tvPin as pinEntryValidator } from '../../../../shared/validation/validators';
import TextInput from '../textInput';

const validator = pin => pinEntryValidator.validate(pin);

const PinEntryField = props => (
    <Field
        id="pin"
        name="pin"
        required
        label={<FormattedMessage id="pinAndPair.label.code" />}
        component={TextInput}
        validate={validator}
        placeholder=" "
        type="text"
        inputMode="numeric"
        {...props}
    />
);

PinEntryField.displayName = 'PinEntryField';

PinEntryField.propTypes = {
    validate: PropTypes.func,
};

export default PinEntryField;

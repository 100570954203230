import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../pageLayout/layout';

const DefaultLayout = ({ children, heading }) => (
    <Layout heading={<FormattedMessage id={heading} />} showLoadingSpinner>
        {children}
    </Layout>
);

DefaultLayout.displayName = 'DefaultLayout';

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    heading: PropTypes.string.isRequired,
};

export default DefaultLayout;

import React, { Fragment } from 'react';
import { Heading } from '@bbc-account/id-components';
import { FormattedMessage } from 'react-intl';
import Text from '../../shared/text';

const HelpPasswordsMobileDevices = () => (
    <Fragment>
        <div className="u-padding-bottom-quad">
            <Heading level={1} fontScale="headlineSmall">
                <FormattedMessage id="help.passwordsMobileDevices.title" />
            </Heading>
        </div>

        <Text>
            <FormattedMessage id="help.passwordsMobileDevices.makeItEasier" />
        </Text>

        <Text>
            <FormattedMessage id="help.passwordsMobileDevices.otherApps" />
        </Text>

        <Text>
            <FormattedMessage id="help.passwordsMobileDevices.sharingInformation" />
        </Text>

        <div className="u-padding-bottom-double u-padding-bottom--tablet">
            <Heading level={2} fontScale="sectionHeading">
                <FormattedMessage id="help.passwordsMobileDevices.signOut" />
            </Heading>
        </div>

        <Text>
            <FormattedMessage id="help.passwordsMobileDevices.forgetYouWereSignedIn" />
        </Text>
    </Fragment>
);

export default HelpPasswordsMobileDevices;

import { date } from '@bbc-account/id-validators';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { EmailFirstTextFormInput } from '../text';
import { EMAIL_FIRST_DATE_OF_BIRTH_FORM_FIELD_NAMES } from './useEmailFirstDateOfBirthField';

export const EmailFirstDateOfBirthFormInput = ({
    'aria-describedby': ariaDescribedBy,
    id,
    isInvalid,
    name,
    label,
    autoComplete,
    onBlur,
    onChange,
}) => {
    const validator = useMemo(
        () => ({
            validate: currentValue => {
                let formatValidator;

                let valueValidator;

                switch (name) {
                    default:
                    case EMAIL_FIRST_DATE_OF_BIRTH_FORM_FIELD_NAMES.DAY:
                        formatValidator = date.isOneOrTwoDigits;
                        valueValidator = date.isValidDay;
                        break;
                    case EMAIL_FIRST_DATE_OF_BIRTH_FORM_FIELD_NAMES.MONTH:
                        formatValidator = date.isOneOrTwoDigits;
                        valueValidator = date.isValidMonth;
                        break;
                    case EMAIL_FIRST_DATE_OF_BIRTH_FORM_FIELD_NAMES.YEAR:
                        formatValidator = date.isFourDigits;
                        valueValidator = date.isValidYear;
                        break;
                }

                return date.validateDatePart(
                    currentValue,
                    formatValidator,
                    valueValidator
                );
            },
        }),
        [name]
    );

    return (
      <EmailFirstTextFormInput
        autoComplete={autoComplete}
        aria-describedby={ariaDescribedBy}
        isInvalid={isInvalid}
        id={id}
        label={label}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        validator={validator}
      />
    );
};

EmailFirstDateOfBirthFormInput.propTypes = {
    'aria-describedby': EmailFirstTextFormInput.propTypes['aria-describedby'],
    autoComplete: EmailFirstTextFormInput.propTypes.autoComplete,
    id: EmailFirstTextFormInput.propTypes.id,
    isInvalid: PropTypes.bool,
    label: EmailFirstTextFormInput.propTypes.label,
    name: EmailFirstTextFormInput.propTypes.name,
    onBlur: EmailFirstTextFormInput.propTypes.onBlur,
    onChange: EmailFirstTextFormInput.propTypes.onBlur,
};

import { useFieldState } from '@bbc-account/id-formaxe';
import { useEffect, useMemo } from 'react';
import { useEmailFirstInputFieldEvents } from './useEmailFirstInputFieldEvents';

export function useEmailFirstTextFormInputField({
    defaultValue,
    id,
    onBlur,
    onChange,
    onIsValidChange,
    validator,
}) {
    const { value } = useFieldState(id);

    const { errors, eventHandlers, isValid } = useEmailFirstInputFieldEvents({
        id,
        onBlur,
        onChange,
        validator,
    });

    const resolvedValue = useMemo(() => {
        if (typeof value !== 'undefined') {
            return value;
        }

        if (typeof defaultValue !== 'undefined') {
            return defaultValue;
        }

        return '';
    }, [defaultValue, value]);

    useEffect(() => {
        if (typeof onIsValidChange !== 'undefined') {
            onIsValidChange(isValid);
        }
    }, [isValid, onIsValidChange]);

    return {
        errors,
        eventHandlers,
        isValid,
        value: resolvedValue,
    };
}

import React from 'react';
import PropTypes from 'prop-types';
import { StyledMarketingOptionText } from './marketingPreferenceOption.style';

function MarketingPreferenceOption({
    id,
    ariaLabel,
    isChecked,
    onChange,
    fieldName,
    label,
    submitValue,
}) {
    return (
        <div className="button button--radio button, button--radio">
            <label className="button__radio-label" htmlFor={id}>
                <input
                    id={id}
                    type="radio"
                    className="button__input"
                    aria-label={ariaLabel}
                    name={fieldName}
                    value={submitValue}
                    required
                    checked={isChecked}
                    onChange={() => onChange(submitValue)}
                />

                <div className="button__text-wrapper u-padding-right-left">
                    <StyledMarketingOptionText className="button__text">
                        {label}
                    </StyledMarketingOptionText>
                </div>
            </label>
        </div>
    );
}

MarketingPreferenceOption.displayName = 'MarketingPreferenceOption';

MarketingPreferenceOption.propTypes = {
    id: PropTypes.string.isRequired,
    ariaLabel: PropTypes.string.isRequired,
    isChecked: PropTypes.bool,
    label: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
    fieldName: PropTypes.string.isRequired,
    submitValue: PropTypes.string.isRequired,
};

export default MarketingPreferenceOption;

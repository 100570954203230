import React from 'react';
import { policyShape } from '../../propTypes';

const PolicyFields = ({ policy }) => (
    <input type="hidden" name="policy" value={policy} />
);

PolicyFields.displayName = 'PolicyFields';

PolicyFields.propTypes = {
    policy: policyShape.isRequired,
};

export default PolicyFields;

import * as config from '../server/config';
import loadPolyfills from './utilities/polyfills';
import applyTouchClasses from './utilities/touchDetection';

const staticAssetsBaseUrl = config.get('staticAssetsBaseUrl');
const release = config.get('release');

// eslint-disable-next-line camelcase,no-undef
__webpack_public_path__ = `${staticAssetsBaseUrl}/${release}/`;

applyTouchClasses();

loadPolyfills(() => {
    // eslint-disable-next-line global-require
    require('./startReactApplication').default();
});

import React from 'react';
import { useIntl } from 'react-intl';
import { CREATE_PASSWORD_IFRAME } from '../../../../../../../shared/endpoints/account';
import { IframePasswordResetPasswordLink } from './iframePasswordResetPasswordLink';

export function IframePasswordCreatePasswordLink() {
    const intl = useIntl();

    const label = intl.formatMessage({
        id: 'auth.password.create.label',
    });

    return (
        <IframePasswordResetPasswordLink
            label={label}
            url={CREATE_PASSWORD_IFRAME}
        />
    );
}

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../pageLayout/layout';
import Text from '../shared/text';
import ApplicationLinkContainer from '../shared/applicationLink/applicationLinkContainer';
import {
    REGISTER_URL,
    REGISTER_FEDERATED_URL,
} from '../../../shared/endpoints/account';
import { SIGN_OUT_URL } from '../../../shared/endpoints/session';
import { useShowExUkContent } from '../../hooks/useShowExUkContent';
import { useStateContext } from '../../modules/stateContext';

const getSignOutUrlWithRegisterPtrt = registerUrl => renderQueryString => {
    const registerPtrt = encodeURIComponent(
        `${registerUrl}${renderQueryString()}`
    );
    return `${SIGN_OUT_URL}?switchTld=1&ptrt=${registerPtrt}`;
};

const ForgottenCredentials = () => {
    const { isFederated } = useStateContext();

    const registerUrl = isFederated ? REGISTER_FEDERATED_URL : REGISTER_URL;

    const emailOnly = useShowExUkContent();

    return (
        <Layout
            heading={
                <FormattedMessage
                    id={
                        emailOnly
                            ? 'cama.forgotEmail.title'
                            : 'cama.forgotCredential.title'
                    }
                />
            }
        >
            <Text introduction>
                <FormattedMessage id="cama.explainer.forgotCredential" />
            </Text>
            <ApplicationLinkContainer
                button
                primary
                fullWidth
                href={getSignOutUrlWithRegisterPtrt(registerUrl)}
                external
            >
                <FormattedMessage id="cama.button.registerNewID" />
            </ApplicationLinkContainer>
        </Layout>
    );
};

ForgottenCredentials.displayName = 'ForgottenCredentials';

export default ForgottenCredentials;

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { updateLocation } from '../../../modules/location';
import CountryField from './countryField';
import HometownField from './hometownField';
import PostcodeField from './postcodeField';
import connectToState from '../../connectToState';
import { errorShape, locationShape } from '../../../propTypes';

const LocationInputGroup = ({
    change,
    collectPostcode,
    dispatch,
    location,
    hometownLabel,
    initialErrors,
    jsEnabled,
    userContext: {
        translations: { countries },
        lang,
    },
}) => {
    const onCountryChange = event =>
        dispatch(updateLocation(event.target.value));

    const showLocationField = location?.isUk || !jsEnabled;

    return (
        <Fragment>
            <CountryField
                countries={countries}
                initialError={initialErrors.country}
                location={location}
                onChange={onCountryChange}
            />

            {collectPostcode && showLocationField && (
                <PostcodeField
                    initialError={initialErrors.postcode}
                    required={jsEnabled}
                />
            )}

            {!collectPostcode && showLocationField && (
                <HometownField
                    change={change}
                    initialError={initialErrors.hometown}
                    lang={lang}
                    required={jsEnabled}
                    showValid={false}
                    hometownLabel={hometownLabel}
                />
            )}
        </Fragment>
    );
};

LocationInputGroup.displayName = 'LocationInputGroup';

LocationInputGroup.propTypes = {
    change: PropTypes.func,
    collectPostcode: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    hometownLabel: PropTypes.node,
    initialErrors: PropTypes.shape({
        postcode: errorShape,
        country: errorShape,
        hometown: errorShape,
    }),
    jsEnabled: PropTypes.bool.isRequired,
    location: locationShape.isRequired,
    userContext: PropTypes.shape({
        translations: PropTypes.shape({
            countries: PropTypes.objectOf(PropTypes.string).isRequired,
        }).isRequired,
        lang: PropTypes.string,
    }).isRequired,
};

LocationInputGroup.defaultProps = {
    collectPostcode: true,
};

export { LocationInputGroup };
export default connectToState(LocationInputGroup);

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from '@bbc-account/id-components';
import Avatar from '../shared/avatar';
import ApplicationLinkContainer from '../shared/applicationLink/applicationLinkContainer';
import {
    AUTH_PATH,
    SIGN_IN_FEDERATED_PATH,
} from '../../../shared/endpoints/account';
import { SIGN_OUT_URL } from '../../../shared/endpoints/session';
import { useStateContext } from '../../modules/stateContext';
import { dispatchEvent } from '../withEventTracking';
import Track from '../shared/analytics/track';

const OneTapSignIn = ({ clientId, signoutUserWhenSwitchingAccount }) => {
    const { displayName, identifier, isFederated } = useStateContext();

    const signInUrl = isFederated ? SIGN_IN_FEDERATED_PATH : AUTH_PATH;

    const getSignInUrl = renderQueryString => {
        const queryString = renderQueryString({ disableOtsiRedirect: true });
        const signInUrlWithQueryString = `${signInUrl}${queryString}`;
        return signoutUserWhenSwitchingAccount
            ? `${SIGN_OUT_URL}?switchTld=1&ptrt=${encodeURIComponent(
                  signInUrlWithQueryString
              )}`
            : signInUrlWithQueryString;
    };

    const userDetails = displayName ? (
        <Fragment>
            <p className="text heading heading--tertiary u-margin-bottom">
                {displayName}
            </p>
            <p className="text text--muted">{identifier}</p>
        </Fragment>
    ) : (
        <p className="text text--heading">{identifier}</p>
    );

    return (
        <div className="gel-10_12 gel-12_12--tablet gel-9_12--widescreen u-margin-zero-auto">
            <div className="card u-box-shadow">
                <div className="u-margin-bottom-double">
                    <Avatar type="primary" text={displayName || identifier} />
                </div>
                <div className="u-text-align-center u-margin-bottom-double">
                    {userDetails}
                </div>

                <Button
                    isFullWidth
                    isSubmit
                    id="submit-button"
                    onClick={() =>
                        dispatchEvent({
                            container: 'otsi-signin',
                            label: 'continue-with-otsi',
                            source: clientId,
                            metadata: { clientId },
                        })
                    }
                >
                    <FormattedMessage id="button.continue.value" />
                </Button>
            </div>

            <div className="u-margin-top-double u-margin-bottom-quad">
                <Track
                    container="otsi-signin"
                    title="use-a-different-account"
                    metadata={{ clientId }}
                >
                    <ApplicationLinkContainer
                        fullWidth
                        button
                        tertiary
                        href={getSignInUrl}
                        className="button--plain u-box-shadow "
                    >
                        <FormattedMessage id="signIn.button.useDifferentAccount.value" />
                    </ApplicationLinkContainer>
                </Track>
            </div>
        </div>
    );
};

OneTapSignIn.propTypes = {
    clientId: PropTypes.string.isRequired,
    signoutUserWhenSwitchingAccount: PropTypes.bool,
};
OneTapSignIn.defaultProps = {
    signoutUserWhenSwitchingAccount: false,
};

export default OneTapSignIn;

import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import reduxForm from './reduxForm';
import initialFormState from './initialFormState';
import location from './location';
import jsEnabled from './jsEnabled';

const defaultPageState = {
    data: {},
    initialErrors: {},
};

const identity = (defaultState = defaultPageState) => (state = defaultState) =>
    state;

const reducers = {
    ab: identity(null),
    csrf: identity(),
    displayName: identity(null),
    featureToggles: identity(),
    form: reduxForm,
    location,
    jsEnabled,
    dateOfBirthSelect: identity(),
    forgottenPassword: identity(),
    guardianToken: identity(),
    tv: identity(),
    moreDetailsNextSteps: identity(),
    isFederated: identity(false),
    isNativeMobileApplication: identity(false),
    mvt: identity({ userId: null, experiments: {} }),
    permissionsManagement: identity(),
    policy: identity(null),
    register: identity(),
    registerGuardianProspect: identity(),
    registerProfile: identity(),
    registerThanks: identity(),
    requestPermissionsManagement: identity(),
    resetPassword: identity(),
    signIn: identity(),
    thanks: identity(),
    update: identity({ ...defaultPageState, components: [] }),
    updateNextSteps: identity(),
    verifyEmail: identity(),
};

const combinedReducers = combineReducers(reducers);

const reducerKeys = Object.keys(reducers);

export { reducers, reducerKeys };

export default function(state = {}, action) {
    const reducer = reduceReducers(initialFormState, combinedReducers);

    return reducer(state, action);
}

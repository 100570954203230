import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    IFRAME_MESSAGE_RESIZE,
    IFRAME_MESSAGE_TYPE,
} from '../../../components/auth/constants';
import { useIframe } from '../../../hooks/iframe/useIframe';
import { ResizeObserver } from './resizeObserver';

export function IframeResizeObserver({ children }) {
    const { sendEventToParent } = useIframe();

    const onResize = useCallback(
        ({ height, width }) => {
            sendEventToParent({
                type: IFRAME_MESSAGE_TYPE.INFO,
                message: IFRAME_MESSAGE_RESIZE,
                meta: {
                    height,
                    width,
                },
            });
        },
        [sendEventToParent]
    );

    return <ResizeObserver onResize={onResize}>{children}</ResizeObserver>;
}

IframeResizeObserver.propTypes = {
    children: PropTypes.node,
};

import React from 'react';
import PropTypes from 'prop-types';
import { StyledInstructionContainer } from './instructionContainer.style';

const InstructionContainer = ({ children }) => (
    <StyledInstructionContainer>{children}</StyledInstructionContainer>
);

InstructionContainer.propTypes = {
    /**
     * The content within the container
     */
    children: PropTypes.node.isRequired,
};

InstructionContainer.displayName = 'InstructionContainer';

export { InstructionContainer };

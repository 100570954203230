export const VALIDATORS_VALID = true;
export const VALIDATORS_INVALID_BOOL = false;
export const APPLICATION_VALID = false;
export const APPLICATION_INVALID_BOOL = true;

const isPrimitiveValid = value => value === APPLICATION_VALID;

export function isInvalid(error) {
    if (typeof error === 'object' && error !== null) {
        return Object.keys(error).some(value => isInvalid(error[value]));
    }

    return !isPrimitiveValid(error);
}

export function isValid(error) {
    return !isInvalid(error);
}

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CheckboxField from '../checkboxField';
import ApplicationLinkContainer from '../../applicationLink/applicationLinkContainer';
import { PERMISSION_ALLOW_PERSONALISATION_FIELD } from '../../../../../shared/data/permissionFieldConstants';
import { PERSONALISATION_URL } from '../../../../../shared/endpoints/usingTheBbc';

const getPersonalisationExplainerKeys = () => [
    <FormattedMessage id="childPermission.explainer.personalisation" />,
    <FormattedMessage id="childPermission.explainer.recommendations" />,
    <FormattedMessage id="childPermission.explainer.resume" />,
    <ApplicationLinkContainer href={PERSONALISATION_URL} external>
        <FormattedMessage id="personalisation.link.findOutPersonalisation" />
    </ApplicationLinkContainer>,
];

const PersonalisationPermission = ({ jsEnabled, onChange }) => (
    <CheckboxField
        ariaLabel="personalisation.label.enablePersonalisation"
        explainers={getPersonalisationExplainerKeys()}
        jsEnabled={jsEnabled}
        label="personalisation.label.enablePersonalisation"
        name={PERMISSION_ALLOW_PERSONALISATION_FIELD}
        onChange={onChange}
    />
);

PersonalisationPermission.propTypes = {
    onChange: PropTypes.func.isRequired,
    jsEnabled: PropTypes.bool.isRequired,
};

export default PersonalisationPermission;

import { styled } from 'styled-components';
import { description } from '../../gel/typography/typography';

export const StyledHint = styled.div`
    ${description}
    color: var(--davys-gray);
    font-weight: var(--regular-weight);

    .dark & {
        color: var(--full-white);
    }
`;

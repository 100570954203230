import PropTypes from 'prop-types';

const EventAttributesProps = PropTypes.shape({
    container: PropTypes.string,
    name: PropTypes.string,
    result: PropTypes.string,
    type: PropTypes.string,
    source: PropTypes.string,
    ignoreViews: PropTypes.string,
    metadata: PropTypes.string,
});

export default EventAttributesProps;

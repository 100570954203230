import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { username as validateUsername } from '../../../../shared/validation/validators';
import TextInput from '../textInput';

const validator = username => validateUsername.validate([username]);

const UsernameField = props => (
    <Field
        {...props}
        id="user-identifier-input"
        name="username"
        component={TextInput}
        autoComplete="username"
        required
        label={props.usernameLabel}
        validate={validator}
    />
);

UsernameField.displayName = 'UsernameField';

UsernameField.propTypes = {
    usernameLabel: PropTypes.node,
};

UsernameField.defaultProps = {
    usernameLabel: <FormattedMessage id="label.username" />,
};

export { UsernameField };

import hibp from '@bbc-account/id-services/dist/hibp';

const createReduxFieldCheckerForPasswordBreaches = ({
    password: fieldValue = '',
}) =>
    hibp
        .checkPassword(fieldValue)
        .catch(() => Promise.resolve())
        .then(numberOfBreaches => {
            if (numberOfBreaches > 0) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject({
                    password: {
                        id: 'validation.password.isBreached',
                        attributes: {
                            isWarning: true,
                        },
                    },
                });
            }

            return Promise.resolve();
        });

export default createReduxFieldCheckerForPasswordBreaches;

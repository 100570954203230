import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CheckboxField from '../checkboxField';
import ApplicationLinkContainer from '../../applicationLink/applicationLinkContainer';
import { PERMISSION_ALLOW_UPLOADS_FIELD } from '../../../../../shared/data/permissionFieldConstants';
import { CHILD_PERMISSIONS_URL } from '../../../../../shared/endpoints/usingTheBbc';

const getUploadingExplainerKeys = () => [
    <FormattedMessage id="childPermission.explainer.uploads" />,
    <ApplicationLinkContainer href={CHILD_PERMISSIONS_URL} external>
        <FormattedMessage id="link.aboutUploads" />
    </ApplicationLinkContainer>,
];

const UploadingPermission = ({ jsEnabled, onChange }) => (
    <CheckboxField
        ariaLabel="childPermission.label.allowUploads"
        explainers={getUploadingExplainerKeys()}
        jsEnabled={jsEnabled}
        label="childPermission.label.allowUploads"
        name={PERMISSION_ALLOW_UPLOADS_FIELD}
        onChange={onChange}
    />
);

UploadingPermission.propTypes = {
    onChange: PropTypes.func.isRequired,
    jsEnabled: PropTypes.bool.isRequired,
};

export default UploadingPermission;

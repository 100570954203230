async function makeRequest(url, options = {}) {
    const { body, headers = {} } = options;

    const mergedOptions = {
        ...options,
        headers: {
            'Content-Type': 'application/json',
            ...headers,
        },
        body: typeof body === 'undefined' ? undefined : JSON.stringify(body),
        credentials: 'include',
    };

    const response = await fetch(url, mergedOptions);
    return response.json();
}

export default makeRequest;

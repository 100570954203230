import React, { createContext, useCallback, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
    EMAIL_FIRST_PASSWORD_FORM_INPUT_CONTEXT_REDUCER_ACTIONS,
    emailFirstPasswordFormInputContextReducer,
} from './emailFirstPasswordFormInputContextReducer';

const defaultEmailFirstPasswordFormInputContext = {
    isValid: false,
};

export const emailFirstPasswordFormInputContext = createContext(
    defaultEmailFirstPasswordFormInputContext
);

export const EmailFirstPasswordFormInputContextProvider = ({ children }) => {
    const [state, dispatcher] = useReducer(
        emailFirstPasswordFormInputContextReducer
    );

    const isValidChangeHandler = useCallback(
        isValid =>
            dispatcher({
                isValid,
                type:
                    EMAIL_FIRST_PASSWORD_FORM_INPUT_CONTEXT_REDUCER_ACTIONS.CHANGE_IS_VALID,
            }),
        []
    );

    return (
        <emailFirstPasswordFormInputContext.Provider
            value={{
                ...defaultEmailFirstPasswordFormInputContext,
                ...state,
            }}
        >
            {typeof children === 'function'
                ? children({ isValidChangeHandler })
                : children}
        </emailFirstPasswordFormInputContext.Provider>
    );
};

EmailFirstPasswordFormInputContextProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

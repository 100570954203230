import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

const Spinner = ({ fillContainer }) => {
    const classes = classnames('spinner', {
        'spinner--fill': fillContainer,
    });

    return <div className={classes} />;
};

Spinner.displayName = 'Spinner';

Spinner.propTypes = {
    fillContainer: PropTypes.bool,
};

Spinner.defaultProps = {
    fillContainer: false,
};

export default Spinner;

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useStateContext } from '../../../../../modules/stateContext';
import ApplicationLinkContainer from '../../../../shared/applicationLink/applicationLinkContainer';

export function EmailFirstSignInCta() {
    const intl = useIntl();
    const {
        featureToggles: { emailFirstFederated },
        isFederated,
    } = useStateContext();

    return (
        <p>
            <FormattedMessage id="registration.haveAccount.title" />
            &nbsp;
            <ApplicationLinkContainer
                ariaLabel={intl.formatMessage({
                    id: 'common.link.footer.signIn.aria',
                })}
                href={
                    emailFirstFederated && isFederated
                        ? '/signin/federated'
                        : '/auth'
                }
            >
                <FormattedMessage id="registration.haveAccount.link" />
            </ApplicationLinkContainer>
        </p>
    );
}

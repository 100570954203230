import { prependSessionBaseUrl } from '../utilities/prependUrl';

const ANNOUNCE_URL = prependSessionBaseUrl('/announce');
const AUTHORISATION_ERROR_URL = prependSessionBaseUrl('/authorisation/error');
const BASE_URL = prependSessionBaseUrl('/');
const CALLBACK_FEDERATED_URL = prependSessionBaseUrl('/callback/federated');
const RENEW_SESSION_PATH = '/renew';
const SIGN_OUT_PATH = '/signout';
const SIGN_OUT_URL = prependSessionBaseUrl(SIGN_OUT_PATH);

export {
    ANNOUNCE_URL,
    AUTHORISATION_ERROR_URL,
    BASE_URL,
    CALLBACK_FEDERATED_URL,
    RENEW_SESSION_PATH,
    SIGN_OUT_PATH,
    SIGN_OUT_URL,
};

import React from 'react';
import DateOfBirthSelectContainer from '../../shared/pages/dateOfBirthSelectContainer';
import { dateOfBirth as dateOfBirthValidators } from '../../../../shared/validation/validators';
import { PERSONAL_DETAILS_URL } from '../../../../shared/endpoints/usingTheBbc';

const RegisterChildDateOfBirthSelect = () => (
    <DateOfBirthSelectContainer
        action="/register/details/children/age"
        explainer="explainer.message.ageUnder13"
        explainerHelpUrl={PERSONAL_DETAILS_URL}
        validator={dateOfBirthValidators.validateUnder13}
        title="register.parentLed.title.childAge"
    />
);

export default RegisterChildDateOfBirthSelect;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@bbc-account/id-components';
import FormattedMessageWithAttributes from '../../shared/formattedMessageWithAttributes';
import Text from '../../shared/text';
import { REGISTER_CHILD_GUARDIAN_PATH } from '../../../../shared/endpoints/account';

const RegisterChildInvalid = () => (
    <>
        <div className="u-margin-bottom-double u-padding-top-quad">
            <Heading level={2} fontScale="sectionHeading" fontWeight="regular">
                <FormattedMessage id="validation.goneWrongError" />
            </Heading>
        </div>
        <Text>
            <FormattedMessageWithAttributes
                id="validation.tryAgainEmail"
                attributes={{
                    href: REGISTER_CHILD_GUARDIAN_PATH,
                    primary: true,
                }}
            />
        </Text>
    </>
);

RegisterChildInvalid.displayName = 'RegisterChildInvalid';

export default RegisterChildInvalid;
